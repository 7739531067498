import { UserType } from 'domain/user';
import { createEffect, createEvent, createStore } from 'effector';
import { IUserApiService } from 'application/ports/Api';
import { userApiService } from 'services/ApiAdapter';
import { TResponse } from 'shared/api/types';

const initialUsersStore = {
    next: '',
    previous: '',
    count: 0,
    results: [],
    params: {
        offset: 0,
        limit: 10,
    },
};
const initialUserStore: UserType = {
    first_name: '',
    last_name: '',
    email: '',
    entities: [],
    is_staff: false,
};

const initialHeadStore: UserType = {
    first_name: '',
    last_name: '',
    email: '',
    entities: [],
};

const userApi: IUserApiService = userApiService();

const fetchUserFx = createEffect(async (id: string) => {
    return await userApi.getUser(id);
});

const fetchHeadFx = createEffect(async (id: string) => {
    return await userApi.getUser(id);
});

const fetchMeFx = createEffect(async () => {
    return await userApi.getMe();
});

const fetchUsersFx = createEffect(async ({ params }: { params: any }): Promise<TResponse> => {
    return await userApi.getUsers(params);
});

const createUserFx = createEffect(async (user: UserType): Promise<UserType> => {
    return await userApi.createUser(user);
});

const resetPasswordFx = createEffect(async (email: string): Promise<any> => {
    return await userApi.resetPassword(email);
});
const verifyPasswordFx = createEffect(
    async ({
        password,
        password_confirm,
        code,
    }: {
        password: string;
        password_confirm: string;
        code: string;
    }): Promise<any> => {
        return await userApi.verifyPassword(password, password_confirm, code);
    }
);

const changePasswordFx = createEffect(
    async ({
        old_password,
        new_password1,
        new_password2,
    }: {
        old_password: string;
        new_password1: string;
        new_password2: string;
    }) => {
        return await userApi.changePassword(old_password, new_password1, new_password2);
    }
);

const updateUserFx = createEffect(async (user: UserType) => {
    return await userApi.updateUser(user);
});

const deactivateUsersFx = createEffect(async (id: string): Promise<UserType> => {
    return await userApi.deactivateUser(id);
});

const activateUserFx = createEffect(async (id: string): Promise<UserType> => {
    return await userApi.activateUser(id);
});

const updateUser = createEvent<any>();
const clearUser = createEvent();

const $userStore = createStore<UserType>(initialUserStore)
    .on(updateUser, (_, user) => user)
    .on(fetchUserFx.doneData, (_, user) => user)
    .on(fetchUserFx.doneData, (state, user) => {
        state = user;
        return state;
    })
    .reset(clearUser);

const clearHead = createEvent();

const $headStore = createStore<UserType>(initialHeadStore)
    .on(fetchHeadFx.doneData, (_, user) => user)
    .reset(clearHead);

const updateMe = createEvent<any>();
const clearMe = createEvent();
const putMe = createEvent();

const $meStore = createStore<UserType>(initialUserStore)
    .on(updateMe, (_, user) => user)
    .on(fetchMeFx.doneData, (_, user) => user)
    .reset(clearMe);

// guard({
//     clock: putMe,
//     source: $meStore,
//     filter: (data) => !!data.email,
//     target: updateUserFx,
// });
const updateUsers = createEvent<TResponse>();
const clearUsers = createEvent();

const create = createEvent();
const update = createEvent();

// guard({
//     clock: create,
//     source: $userStore,
//     filter: (data) => !!data.email?.length && !!data.first_name?.length,
//     target: createUserFx,
// });

// guard({
//     clock: update,
//     source: $userStore,
//     filter: (data) => !!data.email?.length && !!data.first_name?.length,
//     target: updateUserFx,
// });

const $usersStore = createStore<TResponse>(initialUsersStore)
    .on(updateUsers, (_, user) => user)
    .on(fetchUsersFx.doneData, (_, user) => user)
    .on(deactivateUsersFx.doneData, (state, user) => {
        const newResults = state.results.map((u: UserType) => {
            if (u.id === user.id) {
                return user;
            }
            return u;
        });
        state.results = newResults;
        return state;
    })
    .reset(clearUsers);

export const usersStores = {
    $usersStore,
    $userStore,
    $meStore,
    $headStore,
};

export const usersEvents = {
    updateUsers,
    clearUsers,
    updateUser,
    clearUser,
    create,
    update,
    updateMe,
    clearMe,
    putMe,
};

export const usersEffects = {
    fetchUsersFx,
    deactivateUsersFx,
    activateUserFx,
    fetchUserFx,
    fetchMeFx,
    resetPasswordFx,
    verifyPasswordFx,
    createUserFx,
    updateUserFx,
    changePasswordFx,
    fetchHeadFx,
};
