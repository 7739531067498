const Base64toFile = (base64String: string, fileName: string) => {
    const byteCharacters = atob(base64String.split(',')[1]);
    const byteArrays = [];

    for (let i = 0; i < byteCharacters.length; i++) {
        byteArrays.push(byteCharacters.charCodeAt(i));
    }

    const blob = new Blob([new Uint8Array(byteArrays)], { type: 'image/png' });
    const file = new File([blob], fileName, { type: 'image/png' });

    return file;
};

export default Base64toFile;
