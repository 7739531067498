import { useCallback, useRef, useState } from 'react';
import { Crop } from 'react-image-crop';

export const useCropImage = (width: number, height: number, minWidth = 250, minHeight = 250) => {
    const imgRef = useRef<any>(null);
    const [crop, setCrop] = useState<Crop>({
        unit: 'px',
        x: 130,
        y: 36,
        width: width < minWidth ? minWidth : width,
        height: height < minHeight ? minHeight : height,
    });
    const [image, setImage] = useState<any>();
    const [imageSrc, setImageSrc] = useState<any>();
    const [nameFile, setNameFile] = useState('');

    const onSelectFile = (file: any) => {
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            setNameFile(file.name.replace(' ', ''));
            reader.addEventListener('load', () => setImageSrc(reader.result));
        }
    };

    const onLoad = useCallback((evt: any) => setImage(evt.target), []);

    // function blobToFile(theBlob: Blob, fileName: string) {
    //     return new File([theBlob], fileName, {
    //         lastModified: new Date().getTime(),
    //         type: theBlob.type,
    //     });
    // }

    const cropImageNow = (update: any) => {
        if (!image) {
            return;
        }

        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        if (ctx) {
            const pixelRatio = window.devicePixelRatio;
            canvas.width = crop.width * pixelRatio;
            canvas.height = crop.height * pixelRatio;
            ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
            ctx.imageSmoothingQuality = 'high';

            ctx.drawImage(
                image,
                crop.x * scaleX,
                crop.y * scaleY,
                crop.width * scaleX,
                crop.height * scaleY,
                0,
                0,
                crop.width,
                crop.height
            );
        }

        canvas.toBlob((blob: any) => {
            const formData = new FormData();
            formData.append('image', blob, 'image.png');
            const reader: any = new FileReader();
            reader.onabort = () => {};
            reader.onerror = () => {};
            reader.onload = () => {
                update(reader.result);
            };
            reader.readAsDataURL([...formData.entries()][0][1]);
        }, 'image/png');
    };

    return {
        crop,
        imgRef,
        onLoad,
        setCrop,
        imageSrc,
        nameFile,
        minWidth,
        minHeight,
        setImageSrc,
        cropImageNow,
        onSelectFile,
    };
};
