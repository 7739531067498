import React from 'react';
import { TransitionProps } from '@mui/material/transitions';
import { Dialog, Slide } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

export const CustomDialog = styled(Dialog)(() => ({
    '& .MuiPaper-elevation': {
        padding: '20px 24px 31px',
        borderRadius: '12px 0 0 12px',
    },
}));
