import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { Grid, Typography, Tooltip, IconButton, useTheme, TypographyProps } from '@mui/material';

interface ILabel {
    label: string;
    title?: string;
    isRequired?: boolean;
}
const Label = ({ label, title, isRequired, variant = 'body2' }: ILabel & TypographyProps) => {
    const {
        palette: {
            text: { secondary },
        },
    } = useTheme();
    return (
        <Grid display="flex" alignItems="center" mb="8px">
            <Typography variant={variant} fontWeight={500} color={secondary} sx={{ display: 'flex' }}>
                {label}{' '}
                {isRequired && (
                    <Typography component="span" ml={'4px'} color={'error'}>
                        *
                    </Typography>
                )}
            </Typography>
            {title && (
                <Tooltip title={title} sx={{ p: 0, ml: 1 }}>
                    <IconButton>
                        <HelpOutlineOutlinedIcon fontSize="small" sx={{ color: '#2E7D32' }} />
                    </IconButton>
                </Tooltip>
            )}
        </Grid>
    );
};

export default Label;
