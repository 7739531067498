import { Button, Grid } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Backing from '../Backing/Backing';
import { useNavigate } from 'react-router-dom';

export type BoardHeadProps = {
    id?: string;
    count?: number;
    title?: string;
    color?: string;
    funnel?: string;
};

const BoardHead = ({ id, count, title, funnel, color = 'rgba(156, 39, 176, 0.12)' }: BoardHeadProps) => {
    const navigate = useNavigate();
    const handleCreateObject = () => {
        navigate(`${window.location.pathname}/create/?funnel=${funnel}&funnel_stage=${id}`);
    };
    return (
        <Grid container gap={3}>
            <Backing name={title} count={count} color={color} more={false} />
            <Grid container sx={{ background: '#F6F6F7' }}>
                <Button
                    startIcon={<AddRoundedIcon />}
                    onClick={handleCreateObject}
                    variant="outlined"
                    color="primary"
                    fullWidth
                >
                    Создать обьект
                </Button>
            </Grid>
        </Grid>
    );
};

export default BoardHead;
