import { Box, CircularProgress, Divider, Grid, Pagination, Tab, Tabs, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTimelineStorage } from 'widgets/Timeline/lib/hooks/useTimelineStorage';
import { SyntheticEvent, useEffect, useState } from 'react';
import { TParamsRequest } from 'shared/api/types';
import CommentRoundedIcon from '@mui/icons-material/CommentRounded';
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded';
import { UserProfile } from '../../../Profile';
import { TimelineCommentForm } from '../TimelineCommentForm/TimelineCommentForm';
import { TimelineCard } from '../TimelineCard/TimelineCard';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDataStorage } from 'services/StorageAdapter';
import { useTick } from 'shared/hooks/useTick';

const types: { [key: number]: string } = {
    1: 'comment',
    2: 'event',
    3: 'task',
};

interface TimelineProps {
    objectId: string;
    isMobile?: boolean;
    mobileTab?: number;
    onTaskOpen: () => void;
    contract?: null | string;
}

export const Timeline = ({ isMobile, mobileTab, onTaskOpen, objectId, contract }: TimelineProps) => {
    const [params] = useSearchParams();
    const objectType = params?.get('task_id') ? 'task' : '';
    const { fetchDataCodeID, fetchDataCodeUpdater } = useDataStorage();
    const { t: timelineT } = useTranslation('timeline');
    const { timeline, getTimelines, getCommentsLoading, clearTimeline, updateTimeline, getTimelinesUpdater } =
        useTimelineStorage();
    const [tab, setTab] = useState(objectType ? 2 : 0);
    const [userProfileDrawerOpen, setUserProfileDrawerOpen] = useState<boolean>(false);
    const [selectedUserId, setSelectedUserId] = useState<string>('');
    const { tick } = useTick();

    useEffect(() => {
        return () => {
            clearTimeline();
        };
    }, []);

    useEffect(() => {
        if (tab !== 3 && contract) {
            setTab(3);
        }
    }, [contract]);

    useEffect(() => {
        if (objectId) {
            handleChangeTab({} as any, objectType ? 2 : 0);
        }
    }, [objectId, tick]);

    useEffect(() => {
        if (isMobile && mobileTab && mobileTab > 1) {
            handleChangeTab({} as any, mobileTab - 2);
        }
    }, [isMobile, mobileTab, tick]);

    const handleUpdater = (params: TParamsRequest) => {
        if (params.type === 'task') {
            fetchDataCodeUpdater({
                code: 'tasks',
                params: { ...timeline.params, ...params, entity_objects: objectId, type: undefined },
            }).then((res) => updateTimeline(res));
        } else {
            getTimelinesUpdater({
                entity_uuid: objectId,
                params: { ...timeline.params, ...params },
                type: objectType,
            });
        }
    };

    const handleChange = (params: TParamsRequest) => {
        if (params.type === 'task') {
            fetchDataCodeID({
                code: 'tasks',
                params: { ...timeline.params, ...params, entity_objects: objectId, type: undefined },
            }).then((res) => updateTimeline(res));
        } else {
            getTimelines({
                entity_uuid: objectId,
                params: { ...timeline.params, ...params },
                type: objectType,
            });
        }
    };

    const handleChangePage = (_event: unknown, newPage: number) => {
        handleChange({ offset: (newPage - 1) * (timeline.params?.limit || 10) });
    };

    const handleChangeTab = (_event: SyntheticEvent, newValue: number) => {
        setTab(newValue);
        if (tick > 1) {
            handleUpdater({ type: types[newValue] || '', offset: 0 });
        } else {
            handleChange({ type: types[newValue] || '', offset: 0 });
        }
    };

    const handleSelectUserId = (id: string) => {
        setUserProfileDrawerOpen(true);
        setSelectedUserId(id);
    };

    const handleClose = () => {
        setUserProfileDrawerOpen(false);
    };
    return (
        <>
            <UserProfile open={userProfileDrawerOpen} handleClose={handleClose} userId={selectedUserId} />
            {!isMobile && (
                <Tabs value={tab} onChange={handleChangeTab} variant="fullWidth" centered>
                    {!contract && !objectType && (
                        <Tab
                            iconPosition={'start'}
                            label="Таймлайн"
                            sx={{
                                minHeight: '50px',
                                textTransform: 'none',
                            }}
                            value={0}
                        />
                    )}
                    {!contract && (
                        <Tab
                            iconPosition={'start'}
                            label={objectType ? 'История' : 'События'}
                            sx={{
                                minHeight: '50px',
                                textTransform: 'none',
                            }}
                            value={2}
                            icon={objectType ? <HistoryRoundedIcon /> : ''}
                        />
                    )}
                    {!contract && (
                        <Tab
                            iconPosition={'start'}
                            label="Комментарии"
                            sx={{
                                minHeight: '50px',
                                textTransform: 'none',
                            }}
                            value={1}
                            icon={objectType ? <CommentRoundedIcon /> : ''}
                        />
                    )}
                    {!objectType && (
                        <Tab
                            iconPosition={'start'}
                            label="Задачи"
                            sx={{
                                minHeight: '50px',
                                textTransform: 'none',
                            }}
                            value={3}
                        />
                    )}
                </Tabs>
            )}
            <TimelineCommentForm onTaskOpen={onTaskOpen} objectId={objectId} />
            <Grid py={'16px'}>
                <Divider variant={'fullWidth'}>
                    <Grid container gap={'8px'} alignItems={'center'} flexWrap={'nowrap'}>
                        <Typography variant={'overline'}>{timelineT('important')}</Typography>
                        <ArrowDropDownIcon />
                    </Grid>
                </Divider>
                {timeline?.results?.length ? (
                    <Grid container gap={3} mt={2}>
                        {getCommentsLoading ? (
                            <CircularProgress color="primary" sx={{ margin: '0 auto 20px' }} />
                        ) : (
                            <>
                                {timeline.results.map((item) => (
                                    <TimelineCard
                                        props={item}
                                        key={item.id}
                                        handleSelectUserId={handleSelectUserId}
                                        objectId={objectId}
                                        isResponsible={tab === 3}
                                    />
                                ))}
                                {timeline.count >= (timeline.params?.limit || 10) && (
                                    <Pagination
                                        count={Math.ceil(timeline.count / (timeline.params?.limit || 10))}
                                        page={
                                            (timeline.params?.offset || 0) / (timeline.params?.limit || 10) +
                                            1
                                        }
                                        onChange={handleChangePage}
                                        shape="rounded"
                                        color="primary"
                                        sx={{ mt: 3, mb: 5 }}
                                    />
                                )}
                            </>
                        )}
                    </Grid>
                ) : (
                    !objectType && (
                        <Grid
                            container
                            py={'24px'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            textAlign={'center'}
                            flexDirection={'column'}
                            gap={'20px'}
                        >
                            <Box
                                sx={{
                                    borderRadius: '8px',
                                    background: 'rgba(88, 70, 250, 0.08)',
                                    padding: '12px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <CommentRoundedIcon color={'primary'} />
                            </Box>
                            {/*<Typography variant={'body1'}>{timelineT('emptyComment')}</Typography>*/}
                        </Grid>
                    )
                )}
            </Grid>
        </>
    );
};
