import { memo, useState } from 'react';
import { Avatar, Divider, Grid, IconButton, Menu, MenuItem, Typography, useTheme } from '@mui/material';
import { Button } from '../../../../shared/ui';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import UploadRoundedIcon from '@mui/icons-material/UploadRounded';
import Logout from '../../../../shared/helpers/Logout';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import { useMenu } from '../../../../shared/hooks/useMenu';
import { useNavigate } from 'react-router-dom';
import { useUsersStorage } from '../../../../services/StorageAdapter';
import { Profile } from '../../../Profile';
import { useTranslation } from 'react-i18next';

export const AppBarProfile = memo(() => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { open, anchorEl, handleClose, handleClick } = useMenu();
    const [profileDrawerOpen, setProfileDrawerOpen] = useState<boolean>(false);
    const { me } = useUsersStorage();
    const handleCloseProfileDrawer = () => setProfileDrawerOpen(false);
    const handleOpenProfileDrawer = () => setProfileDrawerOpen(true);
    const {
        palette: {
            action: { active },
            primary: { main },
        },
    } = useTheme();
    return (
        <>
            <Button variant="text" onClick={handleClick} sx={{ borderRadius: 2, px: 1.5, py: 0.5 }}>
                <Grid
                    container
                    width={'auto'}
                    gap={'12px'}
                    sx={{ flexFlow: 'nowrap', whiteSpace: 'nowrap' }}
                    alignItems="center"
                >
                    <Grid item>
                        <IconButton sx={{ p: 0 }}>
                            <Avatar
                                alt="Remy Sharp"
                                src={me.avatar || '/static/images/avatar/2.jpg'}
                                variant={'rounded'}
                                sx={{ border: '1px solid #2E7D32' }}
                            />
                        </IconButton>
                    </Grid>
                    <Grid
                        container
                        item
                        width={'auto'}
                        flexDirection={'column'}
                        display={{ xs: 'none', md: 'block' }}
                    >
                        <Typography variant={'subtitle2'} sx={{ color: main }}>
                            {me?.last_name} {me?.first_name?.[0]} {me?.surname?.[0]}
                        </Typography>
                    </Grid>
                </Grid>
            </Button>
            {open ? (
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <MenuItem
                        style={{ paddingTop: 8, paddingBottom: 8 }}
                        onClick={() => {
                            handleClose();
                            handleOpenProfileDrawer();
                        }}
                    >
                        <PersonRoundedIcon sx={{ mr: 3, color: active }} /> {t('profile')}
                    </MenuItem>
                    <MenuItem
                        style={{ paddingTop: 8, paddingBottom: 8 }}
                        onClick={() => {
                            handleClose();
                            navigate(`/import`);
                        }}
                    >
                        <UploadRoundedIcon sx={{ mr: 3, color: active }} /> {t('importData')}
                    </MenuItem>
                    <Divider />
                    <MenuItem style={{ paddingTop: 8, paddingBottom: 8 }} onClick={Logout}>
                        <LogoutRoundedIcon sx={{ mr: 3, color: active }} /> {t('logOut')}
                    </MenuItem>
                </Menu>
            ) : null}
            <Profile open={profileDrawerOpen} user={me} handleClose={handleCloseProfileDrawer} />
        </>
    );
});
