import React, { createContext, Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';
import { Box, Grid, useTheme } from '@mui/material';
import { DepartmentNode } from 'pages/DepartmentsControl/DepartmentsControl';
import { Nullable } from 'shared/types';
import DepartmentCard from 'widgets/DepartmentsMap/DepartmentCard/DepartmentCard';
import DropLine from 'widgets/DepartmentsMap/DropLine';
import Arrows from 'widgets/DepartmentsMap/Arrows';
import { useDepartmentsStorage } from 'services/StorageAdapter';

export const DepartmentsMapContext = createContext({
    changeNodeParent: (_nodeId: string, _parentId: string) => {},
    openCard: () => {},
    map: new Map(),
});

type DepartmentsMapProps = {
    tree: DepartmentNode;
    map: Map<string, DepartmentNode>;
    setMap: Dispatch<SetStateAction<Nullable<Map<string, DepartmentNode>>>>;
    openCard: () => void;
    isOverlay?: boolean;
};

const DepartmentsMap = ({ tree, map, setMap, openCard, isOverlay }: DepartmentsMapProps) => {
    const { updateDepartment } = useDepartmentsStorage();
    const [arrows, setArrows] = useState<Array<any>>([]);
    const [showChildren, setShowChildren] = useState(null);

    const {
        palette: {
            primary: { main: primaryMain },
            info: { main: infoMain },
            warning: { main: warningMain },
        },
    } = useTheme();

    const addGroup = (id: string, orient: string, ref: HTMLDivElement) => {
        if (!arrows.find((g) => g.id === id)) {
            setArrows((prev) => [
                ...prev,
                {
                    id: id,
                    ref,
                    orientation: orient,
                    children: [],
                },
            ]);
        }
    };

    const addArrow = (groupId: string, elementId: string, ref: HTMLDivElement) => {
        if (!arrows.find((g) => g.id === groupId)?.children?.some((a: any) => a.id === elementId)) {
            setArrows((prev: any) => {
                return prev.map((groups: any) => {
                    if (groups.id === groupId) {
                        return { ...groups, children: [...groups.children, { id: elementId, ref: ref }] };
                    }
                    return groups;
                });
            });
        }
    };

    const removeArrow = (groupId: string, elementId: string) => {
        setArrows((prev: any) => {
            return prev.map((groups: any) => {
                if (groups.id === groupId) {
                    return {
                        ...groups,
                        children: [...groups.children.filter((el: any) => el.id !== elementId)],
                    };
                }
                return groups;
            });
        });
    };

    const changeNodeParent = (nodeId: string, parentId: string) => {
        const currentNode = map.get(nodeId)!;
        const newParentNode = map.get(parentId)!;
        const prevParentNode = map.get(currentNode.parent!)!;
        prevParentNode.children = [
            ...(prevParentNode.children || []).filter((node) => node.id !== currentNode.id),
        ];
        newParentNode.children = [...(newParentNode.children || []), currentNode];
        removeArrow(currentNode.parent as string, nodeId);
        currentNode.parent = newParentNode.id;
        setMap(new Map(map));
        updateDepartment(nodeId, { parent: parentId });
    };

    useEffect(() => {
        setArrows([]);
    }, [tree]);

    return (
        <DepartmentsMapContext.Provider
            value={{
                changeNodeParent,
                openCard,
                map,
            }}
        >
            <Box height={'auto'} width={'100%'} position={'relative'}>
                <Arrows arrows={arrows} treeId={tree.id} isOverlay={isOverlay} />
                <Grid container mb={'80px'}>
                    <DepartmentCard
                        setMap={setMap}
                        map={map}
                        level={1}
                        node={tree}
                        setRef={(ref: HTMLDivElement) => {
                            if (ref) {
                                addGroup(tree.id, 'horizontal', ref);
                            }
                        }}
                        color={primaryMain}
                        bgColor={'#EBE9FE'}
                    />
                </Grid>
                <Grid container flexWrap={'nowrap'}>
                    {tree.children.map((secondLevelNode) => {
                        return (
                            <Fragment key={secondLevelNode.id}>
                                <Grid
                                    container
                                    flexDirection={'column'}
                                    width={'auto'}
                                    alignItems={'flex-end'}
                                >
                                    <DepartmentCard
                                        setMap={setMap}
                                        map={map}
                                        level={2}
                                        node={secondLevelNode}
                                        parentId={tree.id}
                                        setShowChildren={setShowChildren}
                                        showChildren={showChildren}
                                        setRef={(ref: HTMLDivElement) => {
                                            if (ref) {
                                                addGroup(secondLevelNode.id, 'vertical', ref);
                                                addArrow(tree.id, secondLevelNode.id, ref);
                                            }
                                        }}
                                        color={infoMain}
                                        bgColor={'#E1F1F9'}
                                    />
                                    <DropLine parentId={secondLevelNode.id} orientation={'horizontal'} />
                                    {secondLevelNode.children.map((thirdLevelNode) => {
                                        return (
                                            <Box key={thirdLevelNode.id} width={'280px'}>
                                                <DepartmentCard
                                                    setMap={setMap}
                                                    map={map}
                                                    level={3}
                                                    node={thirdLevelNode}
                                                    parentId={secondLevelNode.id}
                                                    setShowChildren={setShowChildren}
                                                    showChildren={showChildren}
                                                    setRef={(ref: HTMLDivElement) => {
                                                        if (ref) {
                                                            addArrow(
                                                                secondLevelNode.id,
                                                                thirdLevelNode.id,
                                                                ref
                                                            );
                                                        }
                                                    }}
                                                    color={warningMain}
                                                    bgColor={'#FDEDE1'}
                                                />
                                                <DropLine
                                                    parentId={secondLevelNode.id}
                                                    orientation={'horizontal'}
                                                />
                                            </Box>
                                        );
                                    })}
                                </Grid>
                                <DropLine parentId={tree.id} orientation={'vertical'} />
                            </Fragment>
                        );
                    })}
                </Grid>
            </Box>
        </DepartmentsMapContext.Provider>
    );
};

export default DepartmentsMap;
