import React, { FC, ReactNode, useState } from 'react';
import { ChatProvider } from '../../../../shared/context/ChatContext';
import {
    SelectedChatProvider,
    SelectedChatReadOnlyProvider,
    SelectedGroupProvider,
    SelectedUserProvider,
} from '../../../../widgets/ChatBar';
interface WithChatProps {
    children?: ReactNode;
}
export const WithChat: FC<WithChatProps> = ({ children }) => {
    const [isChatBarOpen, setIsChatBarOpen] = useState(false);
    return (
        <ChatProvider open={isChatBarOpen} setOpen={setIsChatBarOpen}>
            <SelectedGroupProvider>
                <SelectedChatProvider>
                    <SelectedUserProvider>
                        <SelectedChatReadOnlyProvider>{children}</SelectedChatReadOnlyProvider>
                    </SelectedUserProvider>
                </SelectedChatProvider>
            </SelectedGroupProvider>
        </ChatProvider>
    );
};
