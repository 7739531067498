import { Box, Chip, FormControl, Grid, IconButton, MenuItem, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { TFieldsMeta } from 'domain/field';
import { useEffect, useState } from 'react';
import { useConnectEntityModal } from 'services/StorageAdapter';
import { DatePicker, DateTimePicker, TimePicker, Input, Select } from 'shared/ui';
import CancelIcon from '@mui/icons-material/Cancel';
import i18n from '../../../../shared/config/i18n/i18n';
import { useTranslation } from 'react-i18next';
import { taskFields } from 'widgets/Filter/lib/const/taskFields';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const FilterFieldSwitch = ({
    field,
    value,
    onValue,
}: {
    field: TFieldsMeta;
    value: { [index: string]: any };
    onValue: (value: { [index: string]: any }) => void;
}) => {
    const { t } = useTranslation();
    const { open: openModal } = useConnectEntityModal();
    const [selected, setSelected] = useState<any[]>([]);
    const [setting, setSetting] = useState<any>('');
    const [id, type] = Object.keys(value)?.[0]?.split('__');
    const num = [
        { id: 'gt', text: 'Больше' },
        { id: 'gte', text: 'Больше или равно (не меньше)' },
        { id: 'icontains', text: 'Равно' },
        { id: 'lt', text: 'Меньше' },
        { id: 'lte', text: 'Меньше или равно (не больше)' },
    ];
    const equal = [
        { id: 'icontains', text: 'Равно одному значению' },
        { id: 'in', text: 'Содержит любое из указанных значений' },
    ];
    const yesno = [
        { id: true, text: i18n.t('yes') },
        { id: false, text: i18n.t('no') },
    ];
    const mainKey = taskFields?.find((field) => field.id === id)?.id;

    useEffect(() => setSetting(type), [type]);
    useEffect(() => {
        if (
            ['fk', 'uuid', 'fk_employee', 'fk_department', 'm2m', 'm2m_employee', 'm2m_department'].includes(
                field?.value_type
            )
        ) {
            setSelected(value?.[mainKey || `${id}__${setting}`] || []);
        }
    }, [value?.[mainKey || `${id}__${setting}`]]);

    const handleChange = (value: any) => {
        onValue({
            [mainKey || `${id}__${setting}`]: typeof value === 'string' ? [value] : value,
        });
    };

    const handleChangeDate = (val: string, type: string) => {
        const arr = value?.[mainKey || `${id}__${setting}`] || ['', ''];
        type === 'start' ? (arr[0] = val) : (arr[1] = val);
        onValue({ [mainKey || `${id}__${setting}`]: arr });
    };

    switch (field?.value_type) {
        case 'int':
        case 'float':
            return (
                <>
                    <Grid item xs={12} md={4}>
                        <FormControl fullWidth>
                            <Select
                                name={field.code}
                                size="small"
                                value={setting}
                                items={[...num]}
                                onChange={(event) => {
                                    setSetting(event?.target?.value as string);
                                    onValue({ [mainKey || `${id}__${event?.target?.value}`]: '' });
                                }}
                                placeholder={t('selectSettings')}
                                fullWidth
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <FormControl fullWidth>
                            <Input
                                fullWidth
                                placeholder="Введите значение"
                                onChange={(event) =>
                                    onValue({ [mainKey || `${id}__${setting}`]: event.target.value })
                                }
                                value={value?.[mainKey || `${id}__${setting}`]}
                                type={'number'}
                                inputMode={field?.value_type === 'int' ? 'numeric' : 'decimal'}
                            />
                        </FormControl>
                    </Grid>
                </>
            );

        case 'datetime':
        case 'date':
        case 'time':
            return (
                <>
                    {!mainKey && (
                        <Grid item xs={12} md={4}>
                            <FormControl fullWidth>
                                <Select
                                    name={field.code}
                                    size="small"
                                    value={setting}
                                    items={[...num, { id: 'deapazon', text: 'В диапазоне' }]}
                                    onChange={(event) => {
                                        setSetting(event?.target?.value as string);
                                        onValue({ [mainKey || `${id}__${event?.target?.value}`]: '' });
                                    }}
                                    placeholder={t('selectSettings')}
                                    fullWidth
                                />
                            </FormControl>
                        </Grid>
                    )}
                    {setting === 'deapazon' ? (
                        <Grid item xs={12} md={8} display={'flex'} gap={0.5}>
                            <Grid item width={'100%'}>
                                {field?.value_type === 'date' ? (
                                    <DatePicker
                                        placeholder={'Введите значение'}
                                        onChange={(v) =>
                                            handleChangeDate(dayjs(v).format('YYYY-MM-DD'), 'start')
                                        }
                                        value={dayjs(
                                            value?.[mainKey || `${id}__${setting}`]?.[0],
                                            'YYYY-MM-DD'
                                        )}
                                    />
                                ) : field?.value_type === 'time' ? (
                                    <TimePicker
                                        placeholder={'Введите значение'}
                                        onChange={(v) => handleChangeDate(dayjs(v).format('HH:mm'), 'start')}
                                        value={dayjs(value?.[mainKey || `${id}__${setting}`]?.[0], 'HH:mm')}
                                    />
                                ) : (
                                    <DateTimePicker
                                        placeholder={'Введите значение'}
                                        onChange={(v) =>
                                            handleChangeDate(dayjs(v).format('YYYY-MM-DD HH:mm'), 'start')
                                        }
                                        value={dayjs(
                                            value?.[mainKey || `${id}__${setting}`]?.[0],
                                            'YYYY-MM-DD HH:mm'
                                        )}
                                    />
                                )}
                            </Grid>
                            -
                            <Grid item width={'100%'}>
                                {field?.value_type === 'date' ? (
                                    <DatePicker
                                        placeholder={'Введите значение'}
                                        onChange={(v) =>
                                            handleChangeDate(dayjs(v).format('YYYY-MM-DD'), 'end')
                                        }
                                        value={dayjs(
                                            value?.[mainKey || `${id}__${setting}`]?.[1],
                                            'YYYY-MM-DD'
                                        )}
                                    />
                                ) : field?.value_type === 'time' ? (
                                    <TimePicker
                                        placeholder={'Введите значение'}
                                        onChange={(v) => handleChangeDate(dayjs(v).format('HH:mm'), 'end')}
                                        value={dayjs(value?.[mainKey || `${id}__${setting}`]?.[1], 'HH:mm')}
                                    />
                                ) : (
                                    <DateTimePicker
                                        placeholder={'Введите значение'}
                                        onChange={(v) =>
                                            handleChangeDate(dayjs(v).format('YYYY-MM-DD HH:mm'), 'end')
                                        }
                                        value={dayjs(
                                            value?.[mainKey || `${id}__${setting}`]?.[1],
                                            'YYYY-MM-DD HH:mm'
                                        )}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid item xs={12} md={mainKey ? 12 : 8}>
                            {field?.value_type === 'date' ? (
                                <DatePicker
                                    placeholder={'Введите значение'}
                                    onChange={(v) =>
                                        onValue({
                                            [mainKey || `${id}__${setting}`]: [dayjs(v).format('YYYY-MM-DD')],
                                        })
                                    }
                                    value={dayjs(value?.[mainKey || `${id}__${setting}`]?.[0], 'YYYY-MM-DD')}
                                />
                            ) : field?.value_type === 'time' ? (
                                <TimePicker
                                    placeholder={'Введите значение'}
                                    onChange={(v) =>
                                        onValue({
                                            [mainKey || `${id}__${setting}`]: [dayjs(v).format('HH:mm')],
                                        })
                                    }
                                    value={dayjs(value?.[mainKey || `${id}__${setting}`]?.[0], 'HH:mm')}
                                />
                            ) : (
                                <DateTimePicker
                                    placeholder={'Введите значение'}
                                    onChange={(v) =>
                                        onValue({
                                            [mainKey || `${id}__${setting}`]: [
                                                dayjs(v).format('YYYY-MM-DD HH:mm'),
                                            ],
                                        })
                                    }
                                    value={dayjs(
                                        value?.[mainKey || `${id}__${setting}`]?.[0],
                                        'YYYY-MM-DD HH:mm'
                                    )}
                                />
                            )}
                        </Grid>
                    )}
                </>
            );

        case 'bool':
            return (
                <>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <Select
                                size="small"
                                value={value?.[`${id}__icontains`]}
                                items={[...yesno]}
                                onChange={(event) =>
                                    onValue({
                                        [mainKey || `${id}__icontains`]: event?.target?.value as string,
                                    })
                                }
                                placeholder="Выберите значение"
                                fullWidth
                            />
                        </FormControl>
                    </Grid>
                </>
            );

        case 'multi_select':
        case 'select':
            return (
                <>
                    {!mainKey && (
                        <Grid item xs={12} md={4}>
                            <FormControl fullWidth>
                                <Select
                                    value={setting}
                                    size="small"
                                    items={
                                        field?.value_type === 'multi_select'
                                            ? [{ id: 'in', text: 'Содержит любое из указанных значений' }]
                                            : [...equal]
                                    }
                                    onChange={(event) => {
                                        setSetting(event?.target?.value as string);
                                        onValue({ [mainKey || `${id}__${event?.target?.value}`]: [] });
                                    }}
                                    placeholder={t('selectSettings')}
                                    fullWidth
                                />
                            </FormControl>
                        </Grid>
                    )}
                    <Grid item xs={12} md={mainKey ? 12 : 8}>
                        <FormControl fullWidth>
                            <Select
                                fullWidth
                                size="small"
                                value={value?.[mainKey || `${id}__${setting}`] || []}
                                multiple={setting === 'in'}
                                items={
                                    field?.settings?.options?.map((item) => {
                                        return { id: Object.keys(item)[0], text: Object.values(item)[0] };
                                    }) || []
                                }
                                renderValue={(selected: any) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {!!selected?.length &&
                                            selected?.map((value: any) => {
                                                return (
                                                    <Chip
                                                        key={value}
                                                        label={
                                                            field?.settings?.options?.find(
                                                                (item) => Object.keys(item)[0] === value
                                                            )?.[value]
                                                        }
                                                        size="small"
                                                    />
                                                );
                                            })}
                                    </Box>
                                )}
                                onChange={(event: any) => handleChange(event?.target?.value)}
                                placeholder="Выберите значение"
                                MenuProps={MenuProps}
                            >
                                {!!field?.settings?.options?.length &&
                                    field?.settings?.options
                                        ?.map((item) => {
                                            return { id: Object.keys(item)[0], text: Object.values(item)[0] };
                                        })
                                        ?.map((item) => (
                                            <MenuItem key={item.id} value={item.id}>
                                                <Typography variant="subtitle1">{item.text}</Typography>
                                            </MenuItem>
                                        ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </>
            );

        case 'fk':
        case 'uuid':
        case 'fk_employee':
        case 'fk_department':
            return (
                <>
                    {!mainKey && (
                        <Grid item xs={12} md={4}>
                            <FormControl fullWidth>
                                <Select
                                    value={setting}
                                    size="small"
                                    items={[...equal]}
                                    onChange={(event) => {
                                        setSetting(event?.target?.value as string);
                                        onValue({ [`${id}__${event?.target?.value}`]: '' });
                                        setSelected([]);
                                    }}
                                    placeholder={t('selectSettings')}
                                    fullWidth
                                />
                            </FormControl>
                        </Grid>
                    )}
                    <Grid item xs={12} md={mainKey ? 12 : 8}>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 0.5,
                                border: '1px solid rgba(0, 0, 0, 0.16)',
                                borderRadius: 3,
                                minHeight: '40px',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: 0.5,
                                    px: 1,
                                    py: 0.9,
                                    width: '100%',
                                }}
                                onClick={() =>
                                    openModal({
                                        code:
                                            field?.value_type === 'fk_department'
                                                ? 'departments'
                                                : field?.value_type === 'fk' || field?.value_type === 'uuid'
                                                ? 'uuid'
                                                : 'department-employees',
                                        handleSelect(selected: any): void {
                                            onValue({
                                                [mainKey || `${id}__${setting}`]:
                                                    setting === 'in'
                                                        ? [...selected]?.map((select) => {
                                                              return { id: select?.id, name: select?.name };
                                                          })
                                                        : [{ id: selected?.id, name: selected?.name }],
                                            });
                                            setSelected(setting === 'in' ? selected : [selected]);
                                        },
                                        selected: selected,
                                        type:
                                            setting === 'in'
                                                ? field?.value_type === 'fk_employee'
                                                    ? 'm2m_employee'
                                                    : 'm2m_department'
                                                : field?.value_type,
                                    })
                                }
                            >
                                {!!selected?.length &&
                                    selected?.map((value: any) => {
                                        return <Chip key={value?.id} label={value?.name} size="small" />;
                                    })}
                            </Box>
                            {!!selected?.length && (
                                <IconButton onClick={() => setSelected([])}>
                                    <CancelIcon fontSize={'small'} />
                                </IconButton>
                            )}
                        </Box>
                    </Grid>
                </>
            );

        case 'm2m':
        case 'm2m_employee':
        case 'm2m_department':
            return (
                <Grid item xs={12}>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 0.5,
                            border: '1px solid rgba(0, 0, 0, 0.16)',
                            borderRadius: 3,
                            minHeight: '40px',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 0.5,
                                px: 1,
                                py: 0.9,
                                width: '100%',
                            }}
                            onClick={() =>
                                openModal({
                                    code:
                                        field?.value_type === 'm2m_department'
                                            ? 'departments'
                                            : field?.value_type === 'm2m'
                                            ? 'uuid'
                                            : 'department-employees',
                                    handleSelect(selected: any): void {
                                        onValue({
                                            [mainKey || `${id}__in`]: [...selected]?.map((select) => {
                                                return { id: select?.id, name: select?.name };
                                            }),
                                        });
                                        setSelected([...selected]);
                                    },
                                    selected: selected,
                                    type: field?.value_type,
                                })
                            }
                        >
                            {!!selected?.length &&
                                selected?.map((value: any) => {
                                    return <Chip key={value?.id} label={value?.name} size="small" />;
                                })}
                        </Box>
                        {!!selected?.length && (
                            <IconButton onClick={() => setSelected([])}>
                                <CancelIcon fontSize={'small'} />
                            </IconButton>
                        )}
                    </Box>
                </Grid>
            );

        case 'str':
        case 'link':
        case 'phone':
        case 'email':
            return (
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <Input
                            fullWidth
                            placeholder="Введите значение"
                            onChange={(event) =>
                                onValue({ [mainKey || `${id}__icontains`]: event.target.value })
                            }
                            value={value?.[`${id}__icontains`]}
                        />
                    </FormControl>
                </Grid>
            );

        default:
            return (
                <Grid item xs={12}>
                    <Typography variant="body2">Нет фильтров для этого поля</Typography>
                </Grid>
            );
    }
};

export default FilterFieldSwitch;
