import React, { ChangeEvent, FC, memo, useCallback, useState } from 'react';
import { FormControl, Grid, IconButton, Input, ListItem, ListItemIcon, ListItemText } from '@mui/material';
// import DragIndicatorRoundedIcon from '@mui/icons-material/DragIndicatorRounded';
import { SettingsRounded } from '@mui/icons-material';
import { Draggable } from '@hello-pangea/dnd';
import { useMenu } from '../../../../shared/hooks/useMenu';
import { SidebarEditListItemMenu } from '../SidebarEditListItemMenu/SidebarEditListItemMenu';
import { TMenuItem } from '../../../../domain/menu';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { useMenuStorage } from '../../lib/hooks/useMenuStorage';
import DragIndicatorRoundedIcon from '@mui/icons-material/DragIndicatorRounded';

interface SidebarEditListItemProps {
    item: TMenuItem;
    index: number;
    handleDelete: (id: string) => void;
    handleSetMenuItems: (ids: string[]) => void;
    system: boolean;
    menuId: string;
}
export const SidebarEditListItem: FC<SidebarEditListItemProps> = memo(
    ({ index, item, handleDelete, handleSetMenuItems, system, menuId }) => {
        const { anchorEl, open, handleClick, handleClose } = useMenu();
        const [text, setText] = useState<string>(item.name);
        const { updateMenuItemIsEdit, updateMenuItem } = useMenuStorage();

        const handleChange = useCallback(
            (event: ChangeEvent<HTMLInputElement>) => {
                setText(event.target.value);
            },
            [item.name]
        );

        const handleUpdate = useCallback(async () => {
            await updateMenuItem({ menu: menuId, id: item.id, name: text });
            updateMenuItemIsEdit({ menuId, id: item.id, isEdit: false });
        }, [text, item.id, menuId]);

        const handleCancel = useCallback(
            () => updateMenuItemIsEdit({ menuId, id: item.id, isEdit: false }),
            [item.id, menuId]
        );
        return (
            <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided) => (
                    <ListItem sx={{ px: 1, py: 1.5 }} ref={provided.innerRef} {...provided.draggableProps}>
                        <div {...provided.dragHandleProps} />
                        <ListItemIcon {...provided.dragHandleProps}>
                            <DragIndicatorRoundedIcon color={'action'} />
                        </ListItemIcon>
                        {item.isEdit ? (
                            <Grid container alignItems={'center'} flexWrap={'nowrap'} width={'100%'}>
                                <Grid item>
                                    <FormControl fullWidth>
                                        <Input
                                            onChange={handleChange}
                                            placeholder={text}
                                            value={text}
                                            sx={{ border: 0, p: 0 }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container width={'auto'} flexWrap={'nowrap'} ml={1}>
                                    <IconButton
                                        onClick={handleUpdate}
                                        sx={{
                                            width: 28,
                                            height: 28,
                                            borderRadius: '4px',
                                            backgroundColor: open ? 'rgba(88, 70, 250, 0.3)' : null,
                                        }}
                                    >
                                        <CheckCircleRoundedIcon fontSize="small" color={'action'} />
                                    </IconButton>
                                    <IconButton
                                        onClick={handleCancel}
                                        sx={{
                                            width: 28,
                                            height: 28,
                                            borderRadius: '4px',
                                            backgroundColor: open ? 'rgba(88, 70, 250, 0.3)' : null,
                                        }}
                                    >
                                        <CancelRoundedIcon fontSize="small" color={'action'} />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        ) : (
                            <>
                                <ListItemText>{item.name}</ListItemText>
                                <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                                    {!system || item.uri ? (
                                        <IconButton
                                            color={open ? 'primary' : 'default'}
                                            sx={{
                                                width: 28,
                                                height: 28,
                                                borderRadius: '4px',
                                                backgroundColor: open ? 'rgba(88, 70, 250, 0.3)' : null,
                                            }}
                                            onClick={handleClick}
                                        >
                                            <SettingsRounded
                                                sx={{ fontSize: '20px' }}
                                                color={open ? 'primary' : 'action'}
                                            />
                                        </IconButton>
                                    ) : null}
                                </ListItemIcon>
                            </>
                        )}
                        <SidebarEditListItemMenu
                            menuId={menuId}
                            item={item}
                            open={open}
                            anchorEl={anchorEl}
                            handleClose={handleClose}
                            handleDelete={handleDelete}
                            handleSetMenuItems={handleSetMenuItems}
                            system={system}
                        />
                    </ListItem>
                )}
            </Draggable>
        );
    }
);
