import React, { FC, memo } from 'react';
import { Grid } from '@mui/material';
import { Notification } from '../../../../shared/ui';
import { useNotificationStorage } from '../../../../pages/Notifications';
import { useUsersStorage } from '../../../../services/StorageAdapter';
import dayjs from 'dayjs';
import { EEvent } from '../../../../pages/Notifications/types/notifications';
import { getEvent } from '../../../../pages/Notifications/lib/const/type';

interface NotificationBarListProps {
    handleClose: () => void;
}
export const NotificationBarList: FC<NotificationBarListProps> = memo(({ handleClose }) => {
    const { me } = useUsersStorage();
    const { lastNotifications, updateLastNotificationReceiver } = useNotificationStorage();

    const handleUpdate = async (id: string) => {
        await updateLastNotificationReceiver({ id, receiver: me.employee || '', is_read: true });
    };
    return (
        <Grid container gap={2} my={1}>
            {lastNotifications.results.slice(0, 3).map((notification) => (
                <Notification
                    key={notification.id}
                    data={{
                        id: notification.id,
                        read: notification.is_read,
                        user:
                            notification.type === 'user'
                                ? {
                                      name: notification.author?.fullname,
                                      avatar: notification.author?.avatar,
                                  }
                                : null,
                        date: dayjs(notification.created).format('DD.MM.YYYY • hh:mm'),
                        content: getEvent({
                            event: notification.event || EEvent.Object_Created,
                            data: notification,
                            update: updateLastNotificationReceiver,
                            receiver: me.employee || '',
                            handleClose,
                        }),
                    }}
                    isMore={!notification.is_read}
                    handleUpdate={handleUpdate}
                />
            ))}
        </Grid>
    );
});
