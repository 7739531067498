import React from 'react';
import { Chip as MuiChip, ChipProps as MuiChipProps } from '@mui/material';

type ChipProps = MuiChipProps & {
    actionIcon?: MuiChipProps['deleteIcon'];
    actionHandler?: MuiChipProps['onDelete'];
};

const Chip = ({ actionIcon, actionHandler, ...props }: ChipProps) => {
    return <MuiChip deleteIcon={actionIcon} onDelete={actionHandler} {...props} />;
};

export default Chip;
