import {
    alpha,
    MenuItem,
    Select as MuiSelect,
    SelectProps as MuiSelectProps,
    Typography,
    useTheme,
} from '@mui/material';

type OptionType = {
    id: any;
    text: string;
};

type SelectProps = MuiSelectProps & {
    placeholder?: string;
    items: OptionType[];
    selected?: any;
};

const Select = ({
    size = 'small',
    displayEmpty,
    placeholder,
    items,
    selected = {},
    ...props
}: SelectProps) => {
    const {
        palette: {
            text: { secondary },
        },
    } = useTheme();
    return (
        <MuiSelect
            displayEmpty={displayEmpty}
            variant={'outlined'}
            size={size}
            sx={{
                minWidth: '208px',
                width: '100%',
            }}
            renderValue={(selected: any) => {
                if (placeholder && selected?.length === 0) {
                    return <Typography sx={{ color: alpha(secondary, 0.6) }}>{placeholder}</Typography>;
                }
                return items.find((item) => item.id === selected)?.text;
            }}
            {...props}
        >
            {items?.map((option) => {
                return (
                    <MenuItem disabled={selected[option.id]} key={option.id} value={option.id}>
                        {option.text as string}
                    </MenuItem>
                );
            })}
        </MuiSelect>
    );
};

export default Select;
