import { Box, Grid, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import Modal from '@mui/material/Modal';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { MOBILE_QUERY_WIDTH } from 'shared/consts';

const defaultStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 2,
    py: 2,
    maxHeight: '90vh',
    overflowX: 'hidden',
    overflowY: 'auto',
};

export default function BasicModal({ children, open, onClose, title, width, style, confirm }: any) {
    const {
        palette: {
            background: { paper },
        },
    } = useTheme();
    const mobileQuery = useMediaQuery(MOBILE_QUERY_WIDTH);
    return (
        <Modal
            // sx={{
            //     zIndex: 10001, ломает zIndex дочерних елементов
            // }}
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    width: confirm ? 600 : mobileQuery ? '100%' : width || 1120,
                    bgcolor: paper,
                    ...defaultStyle,
                    ...style,
                }}
            >
                {title && (
                    <Grid
                        container
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        borderBottom={'1px solid #0000001F'}
                        px={3}
                        pb={1}
                    >
                        <Typography
                            variant="h5"
                            sx={{
                                fontSize: {
                                    lg: confirm ? 20 : 26,
                                    md: confirm ? 15 : 20,
                                    xs: 16,
                                },
                            }}
                        >
                            {title}
                        </Typography>
                        <IconButton
                            size={'small'}
                            color="primary"
                            onClick={(_) => onClose?.(_, 'backdropClick')}
                        >
                            <CloseRoundedIcon color="disabled" />
                        </IconButton>
                    </Grid>
                )}
                <Box
                    className="content"
                    sx={{
                        flexGrow: 1,
                        height: 'calc(100% - 61px)',
                        display: 'flex',
                        flexDirection: 'column',
                        paddingTop: confirm ? '30px' : 0,
                    }}
                    px={confirm ? 3 : 0}
                >
                    {children}
                </Box>
            </Box>
        </Modal>
    );
}
