import { createEffect, createEvent, createStore } from 'effector';
import { Field } from 'domain/field';
import { IMetaApiService } from 'application/ports/Api';
import { dataApiService, metaApiService } from 'services/ApiAdapter';
import { entityService } from 'shared/api/entity';
import { ConnectedObjectType } from 'pages/Tasks/List/Card/TaskListCard';

export type TaskData = {
    entity: string;
    funnel: {
        id: string;
        name: string;
    };
    funnel_stage: {
        id: string;
        name: string;
    };
    deleted_at: string;
    id: string;
    is_deleted: boolean;
    title: string;
    [key: string]: any;
};

export const initialLocalTaskData: TaskData = {
    deleted_at: '',
    entity: '',
    funnel: { id: '', name: '' },
    funnel_stage: { id: '', name: '' },
    id: '',
    is_deleted: false,
    title: 'Новая задача',
};

const fetchTaskFieldsFx = createEffect(async (params: any) => {
    const metaApi: IMetaApiService = metaApiService();
    const metaData = await metaApi.getMeta('tasks', params);
    return [metaData, 'tasks'] as const;
});
const deleteTaskFx = createEffect(async ({ id }: { id: string }) => {
    return await entityService.deleteTask(id);
});

const resetTasksDetail = createEvent();

const $taskFieldsStore = createStore<Field[]>([])
    .on(fetchTaskFieldsFx.doneData, (_, meta) => meta[0].views[0].entity_fields)
    .reset(resetTasksDetail)
    .reset(deleteTaskFx);

const fetchTaskDataFx = createEffect(async (id: string) => {
    const dataApi = dataApiService();

    return await dataApi.getTaskData(id);
});

const createTaskFx = createEffect(async (data: TaskData) => {
    return await entityService.createTask(data);
});

const updateTaskDataFx = createEffect(async (data: TaskData) => {
    return await entityService.updateTask(data.id!, data);
});

const updateLocalTaskData = createEvent<Partial<TaskData>>();
const syncLocalTitle = createEvent<string>();

const $taskDataStore = createStore<TaskData>(initialLocalTaskData)
    .on(fetchTaskDataFx.doneData, (_, entityData) => entityData)
    .on(createTaskFx.doneData, (_, entityData) => entityData)
    .on(fetchTaskFieldsFx.doneData, (state, meta) => ({
        ...state,
        title: state.title ? state.title : meta[0].name ? meta[0].name : state.title,
    }))
    .on(updateLocalTaskData, (state, newState) => {
        return { ...state, ...newState };
    })
    .on(updateTaskDataFx.doneData, (state, res) => ({ ...state, ...res }))
    .on(syncLocalTitle, (state, title) => ({ ...state, title }))
    .reset(resetTasksDetail);

const setDefaultCrmObject = createEvent<ConnectedObjectType[]>();
const clearDefaultCrmObject = createEvent();

const $crmObject = createStore<ConnectedObjectType[]>([])
    .on(setDefaultCrmObject, (_, data) => data)
    .reset(clearDefaultCrmObject);

export const taskStores = {
    $taskFieldsStore,
    $taskDataStore,
    $crmObject,
};

export const taskEvents = {
    resetTasksDetail,
    syncLocalTitle,
    updateLocalTaskData,
    setDefaultCrmObject,
    clearDefaultCrmObject,
};

export const taskEffects = {
    fetchTaskFieldsFx,
    deleteTaskFx,
    updateTaskDataFx,
    createTaskFx,
    fetchTaskDataFx,
};
