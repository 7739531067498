import Api from 'shared/api/axios';
import { TParamsRequest, TResponse } from 'shared/api/types';
import { IFilterApiService, TFilter } from '../../types/filter';

const filterService = {
    getFiltersUsers(params: TParamsRequest) {
        return Api.get<null, TResponse>(`/api/v1/organizations/filter-users`, { params: { ...params } });
    },
    getFilterUsers(id: string) {
        return Api.get<null, TFilter>(`/api/v1/organizations/filter-users/${id}`);
    },
    createFilterUsers(params: TFilter) {
        return Api.post<null, TFilter>(`/api/v1/organizations/filter-users`, params);
    },
    updateFilterUsers(params: TFilter) {
        return Api.put<null, TFilter>(`/api/v1/organizations/filter-users/${params.id}`, params);
    },
    deleteFilterUsers(id: string) {
        return Api.delete<null, TFilter>(`/api/v1/organizations/filter-users/${id}`);
    },
};

export function filterApiService(): IFilterApiService {
    return {
        getFiltersUsers: filterService.getFiltersUsers,
        getFilterUsers: filterService.getFilterUsers,
        createFilterUsers: filterService.createFilterUsers,
        updateFilterUsers: filterService.updateFilterUsers,
        deleteFilterUsers: filterService.deleteFilterUsers,
    };
}
