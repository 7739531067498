import React, { useEffect } from 'react';
import {
    Button,
    Divider,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Pagination,
    Paper,
    SelectChangeEvent,
    Table,
    TableBody,
    TableContainer,
    Typography,
} from '@mui/material';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import Checkbox from '@mui/material/Checkbox';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { NestedMenuItem } from 'mui-nested-menu';
import { Input, Select } from 'shared/ui';
import TableBottom from 'shared/ui/EnhancedTable/TableBottom';
import { Link, useParams } from 'react-router-dom';
import { useDataStorage, useMetaStorage } from 'services/StorageAdapter';
import SettingsIcon from '@mui/icons-material/Settings';
import { FieldValueType } from 'domain/field';
import NumberWithSpaces from 'shared/helpers/NumberWithSpaces';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

type Order = 'asc' | 'desc';

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (property: any) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
    edit: boolean;
    handleOpenModal: () => void;
}

const generateCell = (type: FieldValueType, id: string, value: any, nature: string) => {
    const format = `DD.MM.YYYY${type !== 'datetime' ? '' : ' HH:mm:ss'}`;
    switch (type) {
        case 'link':
            return <Link to={`entity/${nature}/${id}`}>{value}</Link>;
        case 'int':
            return (
                <Grid container gap={'12px'} alignItems={'center'} justifyContent={'end'}>
                    {NumberWithSpaces(value)} тенге
                </Grid>
            );
        case 'datetime':
        case 'date':
            if (Array.isArray(value)) {
                return value
                    ?.map((item: any) =>
                        typeof item === 'object'
                            ? dayjs(item.name).format(format)
                            : dayjs(item).format(format)
                    )
                    .join(', ');
            } else {
                return value ? dayjs(value).format(format) : '';
            }

        default:
            return <p>{value}</p>;
    }
};

function EnhancedTableHead(props: EnhancedTableProps) {
    const { t } = useTranslation();
    const { meta } = useMetaStorage();
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, edit, handleOpenModal } = props;
    // const createSortHandler = (property: any) => () => {
    //     onRequestSort(property);
    // };

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    return (
        <TableHead>
            <TableRow sx={{ pointerEvents: edit ? 'none' : 'auto' }}>
                <TableCell padding="checkbox" align="center">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                        disabled={edit}
                    />
                </TableCell>
                <TableCell padding="checkbox">
                    <IconButton
                        size="small"
                        onClick={handleOpen}
                        color={open ? 'primary' : 'default'}
                        disabled={edit}
                    >
                        <SettingsIcon fontSize={'inherit'} />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={!!open}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <MenuItem onClick={handleClose}>{t('displayMyFieldset')}</MenuItem>
                        <MenuItem onClick={handleClose}>{t('displayDefaultFieldset')}</MenuItem>
                        <Divider />
                        <MenuItem
                            onClick={() => {
                                handleOpenModal();
                                handleClose();
                            }}
                        >
                            {t('settingsFieldsToView')}
                        </MenuItem>
                    </Menu>
                </TableCell>
                {meta.views
                    .find((view) => view.view_type === 'tabular')
                    ?.entity_fields.map(
                        (headCell) =>
                            !headCell.hidden && (
                                <TableCell
                                    key={headCell.id}
                                    align={'left'}
                                    padding={'checkbox'}
                                    sortDirection={orderBy === headCell.id ? order : false}
                                >
                                    {headCell.name}
                                    {/* <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? order : 'asc'}
                                        onClick={createSortHandler(headCell.id)}
                                        disabled={edit}
                                    >
                                        {headCell.name}
                                        {orderBy === headCell.id ? (
                                            <Box component="span" sx={visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null}
                                    </TableSortLabel> */}
                                </TableCell>
                            )
                    )}
            </TableRow>
        </TableHead>
    );
}

const DuplicatesTable = () => {
    const { t } = useTranslation();
    const { t: duplicatesT } = useTranslation('duplicates');
    const { nature = 'contacts' } = useParams();
    const { meta } = useMetaStorage();
    const { data, fetchDataCodeID, deleteEntity } = useDataStorage();

    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<any>('');
    const [selected, setSelected] = React.useState<readonly string[]>([]);

    const onDelete = (id: string) => {
        deleteEntity({ code: nature, id, type: 'soft' });
    };

    const handleRequestSort = (property: any) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event?.target?.checked) {
            setSelected(data.results.map((n) => n.id));
            return;
        }
        setSelected([]);
    };

    const handleClick = (name: string) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: readonly string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected?.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (_event: unknown, newPage: number) => {
        fetchDataCodeID({
            code: nature,
            params: { ...data.params, offset: (newPage - 1) * (data?.params?.limit || 0) },
        });
    };

    const handleChangeRowsPerPage = (event: SelectChangeEvent<unknown>) => {
        const num = typeof event?.target?.value === 'number' ? event?.target?.value : 0;
        fetchDataCodeID({ code: nature, params: { ...data.params, offset: 0, limit: num } });
    };

    const isSelected = (name: string) => selected.indexOf(name) !== -1;

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [open, setOpen] = React.useState<number | null>(null);
    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>, id: number) => {
        setAnchorEl(event.currentTarget);
        setOpen(id);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setOpen(null);
    };

    const [edit, setEdit] = React.useState<boolean>(false);

    useEffect(() => {
        fetchDataCodeID({ code: nature, params: { offset: 0, limit: 10 } });
    }, []);
    return (
        <Paper sx={{ width: '100%', mb: 2, border: '1px solid rgba(0, 0, 0, 0.16)' }}>
            <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <EnhancedTableHead
                        numSelected={selected?.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={data.results?.length}
                        edit={edit}
                        handleOpenModal={() => {}}
                    />
                    <TableBody>
                        {
                            // stableSort(data.results, getComparator(order, orderBy))
                            data.results.map((row, index) => {
                                const isItemSelected = isSelected(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.id}
                                        selected={isItemSelected}
                                        sx={{
                                            pointerEvents: edit && !isItemSelected ? 'none' : 'auto',
                                        }}
                                    >
                                        <TableCell
                                            padding="checkbox"
                                            align="center"
                                            component="td"
                                            id={labelId}
                                            scope="row"
                                        >
                                            {edit && isItemSelected ? (
                                                <IconButton
                                                    size="small"
                                                    onClick={() => setEdit(false)}
                                                    color={open === row.id ? 'primary' : 'default'}
                                                >
                                                    <CheckCircleRoundedIcon fontSize={'inherit'} />
                                                </IconButton>
                                            ) : (
                                                <Checkbox
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                    disabled={edit}
                                                    onClick={() => handleClick(row.id)}
                                                />
                                            )}
                                        </TableCell>
                                        <TableCell padding="checkbox">
                                            {edit && isItemSelected ? (
                                                <IconButton
                                                    size="small"
                                                    onClick={() => setEdit(false)}
                                                    color={open === row.id ? 'primary' : 'default'}
                                                >
                                                    <CancelRoundedIcon fontSize={'inherit'} />
                                                </IconButton>
                                            ) : (
                                                <>
                                                    <IconButton
                                                        size="small"
                                                        onClick={(e) => handleOpen(e, row.id)}
                                                        color={open === row.id ? 'primary' : 'default'}
                                                        disabled={edit}
                                                    >
                                                        <MoreVertIcon fontSize={'inherit'} />
                                                    </IconButton>
                                                    <Menu
                                                        anchorEl={anchorEl}
                                                        open={open === row.id}
                                                        onClose={handleClose}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'left',
                                                        }}
                                                    >
                                                        <MenuItem onClick={handleClose}>
                                                            {t('overlook')}
                                                        </MenuItem>
                                                        <MenuItem onClick={handleClose}>{t('edit')}</MenuItem>
                                                        <NestedMenuItem
                                                            label={t('moveToAnotherFunnel')}
                                                            parentMenuOpen={!!open}
                                                        >
                                                            <MenuItem onClick={handleClose}>
                                                                {duplicatesT('newClients')}
                                                            </MenuItem>
                                                            <MenuItem onClick={handleClose}>
                                                                {duplicatesT('regularClients')}
                                                            </MenuItem>
                                                            <MenuItem onClick={handleClose}>
                                                                {duplicatesT('gold')}
                                                            </MenuItem>
                                                            <MenuItem onClick={handleClose}>
                                                                {duplicatesT('problemClients')}
                                                            </MenuItem>
                                                            <MenuItem onClick={handleClose}>
                                                                {duplicatesT('lifeInsurance')}
                                                            </MenuItem>
                                                            <MenuItem onClick={handleClose}>
                                                                {duplicatesT('investmentInsurance')}
                                                            </MenuItem>
                                                        </NestedMenuItem>
                                                        <MenuItem onClick={handleClose}>{t('copy')}</MenuItem>
                                                        <MenuItem onClick={() => onDelete(row.id)}>
                                                            <Typography color={'error'}>
                                                                {t('delete')}
                                                            </Typography>
                                                        </MenuItem>
                                                    </Menu>
                                                </>
                                            )}
                                        </TableCell>
                                        {meta.views
                                            .find((view) => view.view_type === 'tabular')
                                            ?.entity_fields.map(
                                                (field) =>
                                                    !field.hidden && (
                                                        <TableCell
                                                            key={field.id}
                                                            align={'left'}
                                                            padding={'checkbox'}
                                                            sortDirection={
                                                                orderBy === field.id ? order : false
                                                            }
                                                            sx={{ whiteSpace: 'nowrap' }}
                                                        >
                                                            {edit && isItemSelected ? (
                                                                <Input value={row[field?.id]} />
                                                            ) : (
                                                                generateCell(
                                                                    field.value_type,
                                                                    field?.id,
                                                                    row[field?.id],
                                                                    nature
                                                                )
                                                            )}
                                                        </TableCell>
                                                    )
                                            )}
                                    </TableRow>
                                );
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {!selected?.length ? (
                <Grid
                    container
                    gap={'12px'}
                    py={'19px'}
                    px={3}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    fontSize={12}
                >
                    <Grid>
                        <Pagination
                            count={Math.ceil(data.count / (data?.params?.limit || 0))}
                            page={(data?.params?.offset || 0) / (data?.params?.limit || 0) + 1}
                            onChange={handleChangePage}
                            shape="rounded"
                            color="primary"
                        />
                    </Grid>
                    <Grid container gap={'12px'} alignItems={'center'} width={'auto'}>
                        <Typography variant={'body1'}>{t('linePerPage')}</Typography>
                        <Select
                            items={[
                                {
                                    id: 5,
                                    text: '5',
                                },
                                {
                                    id: 10,
                                    text: '10',
                                },
                                {
                                    id: 15,
                                    text: '15',
                                },
                                {
                                    id: 20,
                                    text: '20',
                                },
                            ]}
                            value={data.params.limit}
                            onChange={handleChangeRowsPerPage}
                            sx={{
                                fontSize: 14,
                            }}
                        />
                    </Grid>
                </Grid>
            ) : edit ? (
                <Grid
                    container
                    gap={'16px'}
                    py={'19px'}
                    px={3}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                >
                    <Grid container gap={'16px'} alignItems={'center'} width={'auto'}>
                        <Grid
                            container
                            gap={'12px'}
                            alignItems={'center'}
                            width={'auto'}
                            borderRight={'1px solid rgba(0, 0, 0, 0.12)'}
                            pr={3}
                        >
                            <Typography variant={'h6'} fontSize={14}>
                                {t('editingCount', { count: selected?.length })}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container gap={'12px'} alignItems={'center'} width={'auto'}>
                        <Button color="primary" variant="contained" onClick={() => setEdit(false)}>
                            {t('saveAllChanges')}
                        </Button>
                        <Button color="primary" variant="outlined" onClick={() => setEdit(false)}>
                            {t('cancelAllChanges')}
                        </Button>
                    </Grid>
                </Grid>
            ) : (
                <TableBottom
                    count={selected?.length}
                    onEdit={() => setEdit(true)}
                    onClear={() => setSelected([])}
                />
            )}
        </Paper>
    );
};

export default DuplicatesTable;
