import {
    IChatApiService,
    TChatAddManagerToChannelParamsRequest,
    TChatCloseChannelParamsRequest,
    TChatCloseChannelResponse,
    TChatConnectParamsRequest,
    TChatCreateParamsRequest,
    TChatCreateResponse,
    TChatLastReadChannelParamsRequest,
    TChatLastReadChannelResponse,
    TChatListParamsRequest,
    TChatMessageParamsRequest,
    TChatPublishFileParamsRequest,
    TChatRedirectChannelParamsRequest,
} from '../../../types/chat';
import { wsApi } from '../../../../../shared/api/axios';
import { TChatConnect, TChatResponse, TMessagesResponse } from '../../../../../shared/api/types';
import { TMessageItem } from '../../../../../domain/chat';

const chatService = {
    connect({ id, name, source, avatar }: TChatConnectParamsRequest) {
        return wsApi.post<null, TChatConnect>(`/api/auth/connect`, { id, name, source, avatar });
    },
    list({ user_uuid, page, size, user, filter }: TChatListParamsRequest) {
        return wsApi.post<null, TChatResponse>(
            `/api/channel/list`,
            { user_uuid },
            {
                params: {
                    page,
                    size,
                    user,
                    filter,
                },
            }
        );
    },
    messages({ user_uuid, channel_uuid, size, page }: TChatMessageParamsRequest) {
        return wsApi
            .post<null, TMessagesResponse>(
                `/api/channel/messages`,
                {
                    user_uuid,
                    channel_uuid,
                },
                { params: { page, size } }
            )
            .then((res: TMessagesResponse) => {
                return { ...res, items: res.items.reverse() };
            });
    },
    createChannel({ user_uuid, source, subscriber }: TChatCreateParamsRequest) {
        return wsApi.post<null, TChatCreateResponse>('/api/channel/create', {
            user_uuid,
            source,
            subscriber,
        });
    },
    file({
        user_uuid,
        files,
        channel_uuid,
        replayed_message_uuid,
        attachment_type,
        text,
    }: TChatPublishFileParamsRequest) {
        const formData = new FormData();

        formData.append('user_uuid', user_uuid);
        formData.append('channel_uuid', channel_uuid);
        replayed_message_uuid && formData.append('replayed_message_uuid', replayed_message_uuid);
        attachment_type && formData.append('attachment_type', attachment_type);
        text && formData.append('text', text);
        if (files?.length) {
            for (let index = 0; index < files?.length; index++) {
                formData.append('files', files[index]);
            }
        }

        return wsApi.post<null, TMessageItem>(`/api/message/publish/file`, formData);
    },
    closeChatChannel({ user_uuid, channel_uuid, score, description }: TChatCloseChannelParamsRequest) {
        return wsApi.post<null, TChatCloseChannelResponse>('/api/channel/close-request', {
            user_uuid,
            channel_uuid,
            score,
            description,
        });
    },
    redirectChatChannel({
        channel_uuid,
        crm_manager_service_id,
        crm_manager_name,
    }: TChatRedirectChannelParamsRequest) {
        return wsApi.post<null, any>('/api/channel/redirect', {
            channel_uuid,
            crm_manager_service_id,
            crm_manager_name,
        });
    },
    chatLastReadChannel({ channel_uuid, user_uuid }: TChatLastReadChannelParamsRequest) {
        return wsApi
            .post<null, TChatLastReadChannelResponse[]>('/api/channel/last-read', {
                channel_uuid,
                user_uuid,
            })
            .then((res: any) => res.items);
    },
    chatAddManagerToChannel({
        channel_uuid,
        crm_manager_service_id,
        crm_manager_name,
    }: TChatAddManagerToChannelParamsRequest) {
        return wsApi.post<null, any>('/api/channel/add-manager', {
            channel_uuid,
            crm_manager_service_id,
            crm_manager_name,
        });
    },
};
export function chatApiService(): IChatApiService {
    return {
        connect: chatService.connect,
        list: chatService.list,
        messages: chatService.messages,
        createChannel: chatService.createChannel,
        file: chatService.file,
        closeChatChannel: chatService.closeChatChannel,
        redirectChatChannel: chatService.redirectChatChannel,
        chatLastReadChannel: chatService.chatLastReadChannel,
        chatAddManagerToChannel: chatService.chatAddManagerToChannel,
    };
}
