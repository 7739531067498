import { Divider, Typography } from '@mui/material';
import React, { useState } from 'react';
import TemplateHeader from './ui/TemplateHeader';
import HeaderTab from './ui/HeaderTab';
import TemplateSearch from './ui/TemplateSearch';
import EmptyTable from '../../shared/ui/EnhancedTable/EmptyTable';

const TemplateDocuments = () => {
    const [currentTab, setCurrentTab] = useState(0);

    const handleChangeTab = (_: React.SyntheticEvent, newValue: number) => {
        setCurrentTab(newValue);
    };

    return (
        <>
            <TemplateHeader />
            <Divider />
            <HeaderTab currentTab={currentTab} handleChangeTab={handleChangeTab} />
            <TemplateSearch />
            <EmptyTable>
                <>
                    <Typography variant="body1" sx={{ fontWeight: '500', fontSize: '20px' }} mb={1.5}>
                        Тип документов не созданы
                    </Typography>
                    <Typography variant="body2">
                        После создания типа документа перейдите на вкладку <br /> «Шаблоны» вверху и создайте
                        шаблон документа.
                    </Typography>
                </>
            </EmptyTable>
        </>
    );
};

export default TemplateDocuments;
