const INDEX_ROUTE = '/';
const TEMPLATE_DOCUMENTS = '/template-documents';
const DISTRIBUTION = '/distribution';
const TEMP_ROUTE = '/temp-route';
const ENTITY_LIST_ROUTE = '/entity/:nature';
const CREATE_ENTITY_ROUTE = '/entity/:nature/create';
const EDIT_ENTITY_ROUTE = '/entity/:nature/:id';
const LOGIN_ROUTE = '/login';
const RESET_ROUTE = '/reset-password';
const FORGOT_ROUTE = '/forgot-password';
const USERS_AND_ROLES_ROUTE = '/users-and-roles';
const USER_ID_ROUTE = '/users-and-roles/user/:id';
const ROLE_ID_ROUTE = '/users-and-roles/role/:id';
const TASKS_ROUTE = '/tasks';
const TRASH = '/trash';
const CHAT_ROUTE = '/chat';
const CHAT_LS_ROUTE = '/chat/:channel_uuid';
const DUPLICATE_SEARCH_ROUTE = '/duplicates';
const REPORT = '/report/create';
const REPORT_EDIT = '/report/:id';
const REPORT_VIEW = '/reports/:id';
const CONSTRUCTOR = '/constructor';
const CONSTRUCTOR_CREATE_ENTITY = '/constructor/entity/:nature';
const CONSTRUCTOR_CREATE_ENTITY_FUNNEL_SETTINGS = '/constructor/entity/:nature/:funnel_id/funnel-settings';
const CONSTRUCTOR_CREATE_FILTER = '/constructor/entity/:nature/filter';
const CONSTRUCTOR_EDIT_FILTER = '/constructor/entity/:nature/filter/:id';
const CONSTRUCTOR_CREATE_ENTITY_FIELD = '/constructor/entity/:nature/:group/field';
const CONSTRUCTOR_CREATE_META_FIELD = '/constructor/meta/field';
const CONSTRUCTOR_EDIT_FIELD = '/constructor/field/:id';
const NOTIFICATION = '/notification';
const IMPORT = '/import';
const CHAT_SETTINGS = '/channels';
const DEPARTMENTS_CONTROL = '/departments-control';

const REPORTS = '/reports';

export {
    INDEX_ROUTE,
    CREATE_ENTITY_ROUTE,
    EDIT_ENTITY_ROUTE,
    ENTITY_LIST_ROUTE,
    LOGIN_ROUTE,
    RESET_ROUTE,
    FORGOT_ROUTE,
    USERS_AND_ROLES_ROUTE,
    TASKS_ROUTE,
    USER_ID_ROUTE,
    ROLE_ID_ROUTE,
    TRASH,
    CHAT_ROUTE,
    CHAT_LS_ROUTE,
    DUPLICATE_SEARCH_ROUTE,
    REPORT,
    CONSTRUCTOR,
    CONSTRUCTOR_CREATE_ENTITY,
    CONSTRUCTOR_CREATE_FILTER,
    CONSTRUCTOR_EDIT_FILTER,
    CONSTRUCTOR_CREATE_ENTITY_FIELD,
    CONSTRUCTOR_CREATE_META_FIELD,
    CONSTRUCTOR_EDIT_FIELD,
    NOTIFICATION,
    IMPORT,
    CHAT_SETTINGS,
    DEPARTMENTS_CONTROL,
    CONSTRUCTOR_CREATE_ENTITY_FUNNEL_SETTINGS,
    REPORTS,
    REPORT_EDIT,
    REPORT_VIEW,
    TEMP_ROUTE,
    DISTRIBUTION,
    TEMPLATE_DOCUMENTS,
};
