import React, { useContext } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Button } from 'shared/ui';
import { MOBILE_QUERY_WIDTH } from 'shared/consts';
import { drawerWidth } from 'widgets/Sidebar/lib/const/consts';
import { LayoutContext } from 'widgets/Layout/Layout';
import { useTranslation } from 'react-i18next';

type EntityCardFooterProps = {
    acceptButtonHandler?: () => void;
    cancelButtonHandler?: () => void;
    acceptButtonText: string;
    cancelButtonText: string;
    error?: boolean;
    isSubmitting?: boolean;
};

const EntityCardFooter = ({
    isSubmitting,
    acceptButtonHandler,
    acceptButtonText,
    cancelButtonText,
    cancelButtonHandler,
    error,
}: EntityCardFooterProps) => {
    const { t } = useTranslation();
    const {
        palette: {
            error: { main },
            background: { paper },
        },
    } = useTheme();
    const mobileQuery = useMediaQuery(MOBILE_QUERY_WIDTH);
    const { isSidebarOpen } = useContext(LayoutContext);

    return (
        <Box
            sx={{
                position: 'fixed',
                left: { xs: 0, md: drawerWidth },
                bottom: 0,
                width: { xs: '100vw', md: isSidebarOpen ? 'calc(100% - 283px)' : '100%' },
                backgroundColor: paper,
                zIndex: 1,
                borderTop: '1px solid rgba(0, 0, 0, 0.16)',
                borderRadius: 0,
            }}
        >
            <Grid
                container
                justifyContent={'flex-end'}
                alignItems={'center'}
                py={'16px'}
                px={'21px'}
                gap={'19px'}
                flexWrap={'nowrap'}
            >
                {error && (
                    <Box width={'318px'}>
                        <Typography variant={'body2'} color={main}>
                            {t('incorrectRequiredFields')}
                        </Typography>
                    </Box>
                )}
                <LoadingButton
                    sx={{
                        flexGrow: mobileQuery ? 1 : 0,
                    }}
                    loading={isSubmitting}
                    onClick={acceptButtonHandler}
                    variant={'contained'}
                >
                    {acceptButtonText}
                </LoadingButton>
                <Button
                    sx={{
                        flexGrow: mobileQuery ? 1 : 0,
                    }}
                    onClick={cancelButtonHandler}
                    variant={'outlined'}
                >
                    {cancelButtonText}
                </Button>
            </Grid>
        </Box>
    );
};

export default EntityCardFooter;
