import React, { ChangeEvent, useContext, useRef, useState } from 'react';
import {
    Grid,
    Typography,
    MenuItem,
    IconButton,
    useTheme,
    Popper,
    Grow,
    Paper,
    ClickAwayListener,
    MenuList,
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import { ColorModeContext } from 'App';
import { useTranslation } from 'react-i18next';

export type BackingProps = {
    id?: string;
    count?: number;
    name?: string;
    color?: string;
    disabled?: boolean;
    update?: any;
    state?: any;
    funnel?: any;
    length?: any;
    remove?: boolean;
    more?: boolean;
};

const Backing = ({
    id,
    count = 0,
    length,
    state,
    name = '',
    funnel,
    color,
    disabled = false,
    update,
    remove = false,
    more = true,
}: BackingProps) => {
    const { t } = useTranslation();
    const {
        palette: {
            error: { main },
            text: { primary },
            background: { paper },
        },
    } = useTheme();
    const { currentMode } = useContext(ColorModeContext);
    const [edit, setEdit] = useState(false);
    const [value, setValue] = useState(name || '');
    const [currentColor, setCurrentColor] = useState(color);
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<any>(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClick = () => {
        setOpen(false);
    };

    const handleClose = (event: any) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const handleListKeyDown = (event: any) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    };

    const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    };

    const handleChangeColor = (e: ChangeEvent<HTMLInputElement>) => {
        setCurrentColor(e.target.value);
    };

    return (
        <>
            <Grid
                position={'relative'}
                container
                sx={{
                    ['&:hover, .MuiImageMarked-root']: {
                        '& .MuiIconButton-sizeMedium': {
                            opacity: 1,
                        },
                    },
                    minWidth: '180px',
                }}
            >
                <Grid
                    item
                    padding={1}
                    alignItems={'center'}
                    borderRadius={'8px 0px 0px 8px'}
                    borderBottom={`2px solid ${disabled ? 'rgba(0, 0, 0, 0.38)' : currentColor}`}
                    display={'flex'}
                    width={'100%'}
                    height="38px"
                >
                    <Grid
                        item
                        sx={{
                            bgcolor: disabled ? 'rgba(0, 0, 0, 0.38)' : currentColor,
                            borderRadius: '8px 0px 0px 8px',
                            filter: 'opacity(0.12)',
                            position: 'absolute',
                            display: 'block',
                            height: '100%',
                            width: '100%',
                            left: 0,
                        }}
                    />
                    {!!count && (
                        <Typography
                            variant={'body2'}
                            color={disabled ? 'text.disabled' : 'text.secondary'}
                            fontWeight={500}
                            mr={0.5}
                        >
                            ({count})
                        </Typography>
                    )}
                    {edit ? (
                        <input
                            autoFocus
                            onChange={handleChangeName}
                            value={value}
                            style={{
                                background: '#fff',
                                width: '100%',
                                border: 'none',
                                outline: 'none',
                                fontSize: '14px',
                                position: 'absolute',
                            }}
                        />
                    ) : (
                        <Typography
                            variant={'body2'}
                            color={disabled ? 'text.disabled' : primary}
                            fontWeight={500}
                        >
                            {name?.length > 16 ? `${name?.slice(0, 16)}...` : name}
                        </Typography>
                    )}
                </Grid>
                <svg
                    width="16"
                    height="39"
                    viewBox="0 0 17 38"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                        position: 'absolute',
                        backgroundColor: currentMode === 'light' ? '#FCFBFC' : 'transparent',
                        top: '-1px',
                        right: 0,
                    }}
                >
                    <path
                        d="M0.398438 0C1.86051 0 3.24008 0.677424 4.13406 1.83434L15.5085 16.5542C16.6217 17.9948 16.6217 20.0053 15.5085 21.4458L4.13349 36.1664C3.23987 37.3229 1.86086 38 0.399384 38L0.398438 0Z"
                        fillOpacity="0.12"
                        fill={disabled ? 'rgba(0, 0, 0, 0.38)' : currentColor}
                    />
                </svg>
                {!disabled && (
                    <Grid item sx={{ position: 'absolute', right: 0 }}>
                        {edit ? (
                            <>
                                <IconButton
                                    aria-label="color"
                                    component="label"
                                    sx={{
                                        boxShadow:
                                            '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)',
                                        borderRadius: '4px',
                                        backgroundColor: '#fff',
                                        mr: '4px',
                                        opacity: 1,
                                    }}
                                >
                                    <input
                                        type="color"
                                        id="color"
                                        style={{
                                            position: 'absolute',
                                            left: '-150px',
                                            bottom: '-5px',
                                            zIndex: -10,
                                            height: 0,
                                            width: 0,
                                        }}
                                        value={currentColor}
                                        onChange={(e) => handleChangeColor(e)}
                                    />
                                    <ColorLensIcon />
                                </IconButton>
                                <IconButton
                                    aria-label="check"
                                    sx={{
                                        boxShadow:
                                            '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)',
                                        borderRadius: '4px',
                                        backgroundColor: '#fff',
                                    }}
                                    onClick={() => {
                                        update({
                                            data: {
                                                id: funnel.id,
                                                state: state.type,
                                                stage: { id, color: currentColor, name: value },
                                            },
                                            type: 'update-stage',
                                        });
                                        setEdit(false);
                                    }}
                                >
                                    <CheckIcon />
                                </IconButton>
                            </>
                        ) : (
                            <>
                                {more ? (
                                    <IconButton
                                        ref={anchorRef}
                                        id={'backing-menu-button'}
                                        aria-controls={open ? 'menu-list-grow' : undefined}
                                        aria-haspopup="true"
                                        aria-label="more"
                                        sx={{
                                            boxShadow:
                                                '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)',
                                            borderRadius: '4px',
                                            backgroundColor: paper,
                                            mr: '4px',
                                            opacity: 0,
                                        }}
                                        onClick={handleToggle}
                                    >
                                        <MoreHorizIcon />
                                    </IconButton>
                                ) : null}
                                {state?.type === 'in_progress' && (
                                    <IconButton
                                        aria-label="add"
                                        sx={{
                                            boxShadow:
                                                '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)',
                                            borderRadius: '4px',
                                            backgroundColor: paper,
                                            opacity: 0,
                                        }}
                                        onClick={() =>
                                            update({
                                                data: {
                                                    name: 'Новый этап',
                                                    color: '#7d9df4',
                                                    order: length,
                                                    funnel: funnel?.id,
                                                    state: state?.type,
                                                },
                                                type: 'create-stage',
                                            })
                                        }
                                    >
                                        <AddIcon />
                                    </IconButton>
                                )}
                            </>
                        )}
                    </Grid>
                )}
            </Grid>
            <Popper open={open} anchorEl={anchorRef.current} transition>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="menu-list-grow"
                                    onKeyDown={handleListKeyDown}
                                >
                                    <MenuItem
                                        onClick={() => {
                                            handleClick();
                                            setEdit(true);
                                        }}
                                    >
                                        {t('editStage')}
                                    </MenuItem>
                                    {state?.type === 'in_progress' && remove && (
                                        <MenuItem
                                            onClick={() => {
                                                handleClick();
                                                update({
                                                    data: {
                                                        id: funnel?.id,
                                                        state: state?.type,
                                                        stage: { id, color, name },
                                                    },
                                                    type: 'delete-stage',
                                                });
                                            }}
                                            sx={{ color: main }}
                                        >
                                            {t('deleteStage')}
                                        </MenuItem>
                                    )}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};

export default Backing;
