import { Button } from '../../../../shared/ui';
import { alpha, Divider, Grid, useTheme } from '@mui/material';
import { FC, memo } from 'react';
// import ThemeSwitcher from 'widgets/ThemeSwitcher/ThemeSwitcher';
import { useTranslation } from 'react-i18next';

interface SidebarFooterProps {
    onClose: () => void;
    handleEdit: () => void;
}
export const SidebarFooter: FC<SidebarFooterProps> = memo(({ onClose, handleEdit }) => {
    const { t } = useTranslation();
    const {
        palette: {
            text: { secondary },
        },
    } = useTheme();
    return (
        <>
            <Divider />
            <Grid
                container
                flexDirection={'column'}
                sx={{
                    mt: '32px',
                }}
                gap={'18px'}
                alignItems={'flex-start'}
            >
                <Button
                    variant={'text'}
                    sx={{
                        color: alpha(secondary, 0.6),
                    }}
                    onClick={onClose}
                >
                    {t('hideMenu')}
                </Button>
                <Button
                    variant={'text'}
                    sx={{
                        color: alpha(secondary, 0.6),
                    }}
                    onClick={handleEdit}
                >
                    {t('settingsMenu')}
                </Button>
                {/*<ThemeSwitcher />*/}
            </Grid>
        </>
    );
});
