import React from 'react';
import { TFieldProps } from '../types';
import { Grid, IconButton, Typography } from '@mui/material';
import { Button, Input } from 'shared/ui';
import { CancelRounded } from '@mui/icons-material';
import AddCircleRounded from '@mui/icons-material/AddCircleRounded';
import { useTranslation } from 'react-i18next';

const DefaultField = ({ field, edit, error, setValue, value }: TFieldProps<string | string[]>) => {
    const { t } = useTranslation();
    const isMany = field.settings.many;
    return (
        <>
            {edit ? (
                <>
                    {isMany && Array.isArray(value) ? (
                        <Grid container gap={'8px'}>
                            {value?.map((v: string, i: number) => {
                                return (
                                    <Grid key={i} container flexWrap={'nowrap'} gap={'8px'}>
                                        <Input
                                            onChange={(e) => {
                                                value[i] = e.target.value;
                                                setValue?.(field.id, [...value]);
                                            }}
                                            error={!!error}
                                            placeholder={field.placeholder}
                                            value={v}
                                        />
                                        {i !== 0 && (
                                            <IconButton
                                                onClick={() => {
                                                    value.splice(i, 1);
                                                    setValue?.(field.id, [...value]);
                                                }}
                                                size={'small'}
                                            >
                                                <CancelRounded fontSize={'inherit'} />
                                            </IconButton>
                                        )}
                                    </Grid>
                                );
                            })}
                        </Grid>
                    ) : (
                        <Input
                            onChange={(e) => {
                                setValue?.(field.id, e.target.value);
                            }}
                            error={!!error}
                            placeholder={field.placeholder}
                            value={value}
                        />
                    )}
                    {isMany && (
                        <Grid mt={'8px'}>
                            <Button
                                startIcon={<AddCircleRounded />}
                                onClick={() => {
                                    setValue?.(field.id, [...value, '']);
                                }}
                            >
                                {t('add')}
                            </Button>
                        </Grid>
                    )}
                </>
            ) : (
                <>
                    {isMany && Array.isArray(value) ? (
                        <Grid container flexDirection={'column'} gap={'8px'}>
                            {value.map((v, i) => (
                                <Typography key={i} variant={'body1'}>
                                    {v}
                                </Typography>
                            ))}
                        </Grid>
                    ) : (
                        <Typography variant={'body1'}>{value}</Typography>
                    )}
                </>
            )}
        </>
    );
};

export default DefaultField;
