import { createEffect, createEvent, createStore, guard } from 'effector';
import { IRoleApiService } from 'application/ports/Api';
import { roleApiService } from 'services/ApiAdapter';
import { TResponse } from 'shared/api/types';

const initialRolesStore = {
    next: '',
    previous: '',
    count: 0,
    results: [],
    params: {
        offset: 0,
        limit: 10,
    },
};

const initialRoleStore = {
    name: '',
    permission_entities: [],
};

const roleApi: IRoleApiService = roleApiService();

const fetchRoleFx = createEffect(async (id: string) => {
    return await roleApi.getRole(id);
});

const deleteRoleFx = createEffect(async (id: string) => {
    return await roleApi.deleteRole(id);
});

const createRoleFx = createEffect(async (role: any) => {
    return await roleApi.createRole(role);
});

const updateRoleFx = createEffect(async (role: any) => {
    return await roleApi.updateRole(role);
});

const updateRole = createEvent<any>();
const clearRole = createEvent();

const $roleStore = createStore<any>(initialRoleStore)
    .on(updateRole, (_, role) => role)
    .on(fetchRoleFx.doneData, (_, role) => role)
    .on(createRoleFx.doneData, (state, role) => {
        state = role;
        return state;
    })
    .reset(clearRole);

const fetchRolesFx = createEffect(async (params: any): Promise<TResponse> => {
    return await roleApi.getRoles(params);
});

const updateRoles = createEvent<TResponse>();
const clearRoles = createEvent();

const createRole = createEvent();
const putRole = createEvent();

guard({
    clock: createRole,
    source: $roleStore,
    filter: (data) => !!data.name,
    target: createRoleFx,
});

guard({
    clock: putRole,
    source: $roleStore,
    filter: (data) => !!data.name,
    target: updateRoleFx,
});

const $rolesStore = createStore<TResponse>(initialRolesStore)
    .on(updateRoles, (_, role) => role)
    .on(fetchRolesFx.doneData, (_, role) => role)
    .reset(clearRoles);

export const rolesStores = {
    $rolesStore,
    $roleStore,
};

export const rolesEvents = {
    updateRoles,
    clearRoles,
    updateRole,
    clearRole,
    putRole,
    createRole,
};

export const rolesEffects = {
    fetchRolesFx,
    fetchRoleFx,
    deleteRoleFx,
};
