import React, { Dispatch, Fragment, memo, SetStateAction, useEffect, useRef } from 'react';
import { Box, CardProps as MuiCardProps, Menu, MenuItem } from '@mui/material';
import { Reorder, useDragControls, useMotionValue } from 'framer-motion';
import { Field, FieldsGroup } from 'domain/field';
import { useEntityDetailStorage } from 'services/StorageAdapter';
import { Button, Card } from 'shared/ui';
import { FormikProps, FormikValues } from 'formik';
import { DraggableField } from 'widgets/index';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import _ from 'lodash';
import DeleteIcon from '@mui/icons-material/Delete';
import { Nullable } from 'shared/types';
import useDebouncedCallback from 'shared/hooks/useDebouncedCallback';
import { useRaisedShadow } from 'shared/hooks/useRaisedShadow';
import DraggableItemWrapper from 'shared/ui/DraggableWrapper/DraggableItemWrapper';
import Emitter from 'shared/hooks/EventEmitter';
import { useTranslation } from 'react-i18next';

type FieldsCardProps = MuiCardProps & {
    group: FieldsGroup;
    hideUnfilled?: boolean;
    hiddenFields?: Field[];
    setGroups: Dispatch<SetStateAction<FieldsGroup[]>>;
    groupIndex: number;
    formikProps: FormikProps<FormikValues>;
    isDeletable?: boolean;
    isEditable?: boolean;
    handleShowField?: (field: Field, groupCode: Nullable<string>) => void;
    setFieldValueDecorator?: (
        setFieldValue: FormikProps<FormikValues>['setFieldValue']
    ) => (...args: [field: string, value: any, shouldValidate?: boolean | undefined]) => void;
};

function useTraceUpdate(props: any) {
    const prev = useRef(props);
    useEffect(() => {
        Object.entries(props).reduce((accumulator: Record<string, any>, [key, value]) => {
            // если значение изменилось - вернем его в результирующем объекте
            if (prev.current[key] !== value) {
                accumulator[key] = [prev.current[key], value];
            }
            return accumulator;
        }, {});
        prev.current = props;
    });
}

const FieldsCard = memo(
    (props: FieldsCardProps) => {
        const {
            group,
            setGroups,
            groupIndex,
            formikProps,
            isDeletable,
            isEditable,
            hiddenFields,
            setFieldValueDecorator,
            handleShowField,
            hideUnfilled,
        } = props;
        const { t } = useTranslation();
        useTraceUpdate(props);
        const { values, errors, setFieldValue, handleChange } = formikProps;

        const { hideField, entityData, updateFields, updateFieldsGroup, deleteGroup } =
            useEntityDetailStorage();

        const debouncedUpdateFields = useDebouncedCallback(updateFields, 300);

        const { name, fields } = group;

        const handleReorderFields = (groupIndex: number, fields: Field[]) => {
            fields = fields.map((f, i) => ({ ...f, order: i }));
            debouncedUpdateFields(fields.map((f) => ({ id: f.id, order: f.order })));
            setGroups((prev) => {
                return prev.map((group, idx) => (idx === groupIndex ? { ...group, fields } : group));
            });
        };

        const handleHideField = (field: Field) => {
            hideField(field);
        };

        const [openHiddenRef, setOpenHiddenRef] = React.useState<null | HTMLElement>(null);
        const open = Boolean(openHiddenRef);
        // const handleClickHiddenFields = (event: React.MouseEvent<HTMLButtonElement>) => {
        //     setOpenHiddenRef(event.currentTarget);
        // };
        const handleClose = () => {
            setOpenHiddenRef(null);
        };

        const handleEditGroup = () => {
            setGroups((prev) => prev.map((g) => (g.id === group.id ? { ...group, isEdit: true } : g)));
            Emitter.emit('entity-edited');
        };

        const controls = useDragControls();
        const y = useMotionValue(0);
        const boxShadow = useRaisedShadow(y);

        const handleUpdateGroupTitle = (title: string) => {
            updateFieldsGroup(group, { name: title });
        };

        const handleDeleteGroup = () => {
            deleteGroup(group.id as string);
        };

        return (
            <Reorder.Item
                dragListener={false}
                dragControls={controls}
                value={group}
                style={{
                    boxShadow,
                    y,
                    position: 'relative',
                    borderRadius: '12px',
                    width: '100%',
                }}
            >
                <DraggableItemWrapper
                    isDraggable={false}
                    dragHandler={(e) => controls.start(e)}
                    iconPosition={{
                        top: 30,
                        left: 5,
                    }}
                >
                    <Card px={'0px'}>
                        <Box px={'24px'} width={'100%'}>
                            <Card.Head
                                onTitleChange={handleUpdateGroupTitle}
                                editable={false}
                                title={name}
                                controls={[
                                    <Fragment key={'1'}>
                                        {isEditable && !group.isEdit ? (
                                            <Button onClick={handleEditGroup} color={'secondary'}>
                                                {t('edit')}
                                            </Button>
                                        ) : (
                                            <></>
                                        )}
                                    </Fragment>,
                                ]}
                            />
                        </Box>
                        <Box width={'100%'}>
                            <Reorder.Group
                                onReorder={(values) => handleReorderFields(groupIndex, values)}
                                values={fields}
                            >
                                {fields
                                    .filter((f) =>
                                        hideUnfilled
                                            ? values[f.id] !== null || f.settings.required
                                            : f.settings.show_unfilled
                                            ? true
                                            : values[f.id] !== null || f.settings.required || true
                                    )
                                    .map((field) => {
                                        if (field.hidden) {
                                            return;
                                        }
                                        return (
                                            <DraggableField
                                                current_funnel={values.funnel}
                                                value={values[field.id as keyof typeof values]}
                                                error={errors[field.id as keyof typeof errors]}
                                                handleChange={handleChange}
                                                setFieldValue={
                                                    setFieldValueDecorator
                                                        ? setFieldValueDecorator(setFieldValue)
                                                        : setFieldValue
                                                }
                                                options={{
                                                    read: !group.isEdit && !!entityData.id,
                                                    write: true,
                                                }}
                                                handleHideField={handleHideField}
                                                key={field.id}
                                                field={field}
                                            />
                                        );
                                    })}
                            </Reorder.Group>
                        </Box>
                        {group.id !== 'system' ? (
                            <Box px={'24px'} width={'100%'}>
                                <Card.Footer
                                    leftSide={
                                        <>
                                            {/*<Button*/}
                                            {/*    onClick={handleClickHiddenFields}*/}
                                            {/*    endIcon={<ArrowDropDownIcon />}*/}
                                            {/*    customColor={'gray'}*/}
                                            {/*>*/}
                                            {/*    Скрытые поля*/}
                                            {/*</Button>*/}
                                            <Menu
                                                id="basic-menu"
                                                anchorEl={openHiddenRef}
                                                open={open}
                                                onClose={handleClose}
                                                PaperProps={{
                                                    style: {
                                                        maxHeight: 336,
                                                        width: '321px',
                                                    },
                                                }}
                                            >
                                                {hiddenFields?.map((field) => {
                                                    return (
                                                        <MenuItem
                                                            key={field.id}
                                                            onClick={() => handleShowField?.(field, group.id)}
                                                        >
                                                            {field.name}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Menu>
                                        </>
                                    }
                                    rightSide={
                                        <>
                                            {isDeletable && group.isEdit && (
                                                <Button
                                                    onClick={handleDeleteGroup}
                                                    color={'error'}
                                                    startIcon={<DeleteIcon />}
                                                    sx={{ display: { xs: 'none', md: 'block' } }}
                                                >
                                                    {t('removeSection')}
                                                </Button>
                                            )}
                                        </>
                                    }
                                />
                            </Box>
                        ) : (
                            <></>
                        )}
                    </Card>
                </DraggableItemWrapper>
            </Reorder.Item>
        );
    },
    (prevProps, nextProps) => {
        const { formikProps: formikPrevProps, ...prev } = prevProps;
        const { formikProps: formikNextProps, ...next } = nextProps;
        if (_.isEqual(prev, next) && _.isEqual(formikPrevProps.errors, formikNextProps.errors)) {
            const {
                group: { fields },
            } = prevProps;
            const isNotEqual = Object.keys(formikNextProps.values)
                .filter((key) => fields.some((field) => field.id === key))
                .some((key) => formikNextProps.values[key] !== formikPrevProps.values[key]);
            return !isNotEqual;
        } else {
            return false;
        }
    }
);

export default FieldsCard;
