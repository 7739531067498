import { MenuItem, Typography } from '@mui/material';
import { NestedMenuItem } from 'mui-nested-menu';
import CircleIcon from '@mui/icons-material/Circle';

export interface ITableDropdown {
    type?: string;
    title?: string;
    func?: (value?: any) => void;
    color?: string;
    sub_menu?: ITableDropdown[] | null;
    disabled?: boolean;
    divider?: boolean;
    circle_color?: string;
    checked?: boolean;
    condition?: (item: any) => boolean;
}

interface IProps {
    menu: ITableDropdown[];
    item?: any;
    level?: number;
    onClose: () => void;
}

export default function TableDropdown({ menu, item, level = 0, onClose }: IProps): JSX.Element {
    return (
        <>
            {menu
                .filter((menuItem) => (menuItem.condition ? menuItem.condition(item) : true))
                ?.map(
                    (sub, idx) =>
                        !sub.disabled &&
                        (sub?.sub_menu !== null && sub?.sub_menu?.length ? (
                            <NestedMenuItem
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            pointerEvents: 'auto',
                                        },
                                    },
                                }}
                                label={sub?.title}
                                parentMenuOpen={true}
                                disabled={sub?.disabled}
                                key={idx + level.toString()}
                            >
                                <TableDropdown
                                    menu={sub?.sub_menu || []}
                                    item={item}
                                    level={level + 1}
                                    onClose={onClose}
                                />
                            </NestedMenuItem>
                        ) : (
                            <MenuItem
                                onClick={() => {
                                    sub?.func?.(item);
                                    onClose();
                                }}
                                disabled={sub?.disabled}
                                key={idx}
                                sx={{ display: 'flex', alignItems: 'center' }}
                            >
                                {sub?.circle_color && (
                                    <CircleIcon sx={{ color: sub?.circle_color, mr: 1 }} fontSize="small" />
                                )}
                                <Typography color={sub?.color}>{sub?.title}</Typography>
                            </MenuItem>
                        ))
                )}
        </>
    );
}
