import React, { UIEvent, useRef } from 'react';
import component from './Duplicates.module.scss';
import classNames from 'classnames';
import { Badge, Box, Grid, RadioGroup, Typography } from '@mui/material';
import { Radio } from 'shared/ui';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import { ChevronLeftRounded, ChevronRightRounded } from '@mui/icons-material';
import { useHorizontalScroll } from 'shared/hooks/useHorizontalScroll';

const ConflictsResolvingTable = () => {
    const bodyRef = useRef<HTMLDivElement>(null);
    const { showLeftScroll, showRightScroll, scrollStop, scrollStart } = useHorizontalScroll(bodyRef);
    const absoluteContentRef = useRef<HTMLDivElement>(null);
    const bodyScrollHandler = (e: UIEvent<HTMLDivElement>) => {
        if (absoluteContentRef.current) {
            absoluteContentRef.current.scrollTop = e.currentTarget.scrollTop;
        }
    };
    const absoluteContentScrollHandler = (e: UIEvent<HTMLDivElement>) => {
        if (bodyRef.current) {
            bodyRef.current.scrollTop = e.currentTarget.scrollTop;
        }
    };

    return (
        <div className={component['conflicts-table']}>
            <div
                ref={absoluteContentRef}
                onScroll={absoluteContentScrollHandler}
                className={component['conflicts-table__absolute-content']}
            >
                <div className={component['conflicts-table__column']}>
                    <div
                        className={classNames(
                            component['conflicts-table__cell'],
                            component['conflicts-table__cell--head']
                        )}
                    >
                        <Typography variant={'body1'}>Результат объединения</Typography>
                    </div>
                    <div className={component['conflicts-table__cell']}>
                        <Typography variant={'h6'}>О клиенте</Typography>
                    </div>
                    <div
                        className={classNames(
                            component['conflicts-table__cell'],
                            component['conflicts-table__cell--merged']
                        )}
                    >
                        <div className={component['conflicts-table__radio-content']}>
                            <Box mr={'10px'} display={'flex'} alignItems={'center'}>
                                <CircleRoundedIcon color={'primary'} fontSize={'small'} />
                            </Box>
                            <div className={component['conflicts-table__radio-data']}>
                                <Typography variant={'subtitle1'}>Фамилия</Typography>
                                <Grid
                                    container
                                    alignItems={'center'}
                                    flexWrap={'nowrap'}
                                    gap={'8px'}
                                    pl={'4px'}
                                >
                                    <Badge color="error" variant="dot" />
                                    <Typography variant={'body1'}>Адиев</Typography>
                                </Grid>
                            </div>
                        </div>
                    </div>
                    <div className={component['conflicts-table__cell']}>Col 0</div>
                    <div className={component['conflicts-table__cell']}>Col 0</div>
                    <div className={component['conflicts-table__cell']}>Col 0</div>
                    <div className={component['conflicts-table__cell']}>Col 0</div>
                    <div className={component['conflicts-table__cell']}>Col 0</div>
                    <div className={component['conflicts-table__cell']}>Col 0</div>
                    <div className={component['conflicts-table__cell']}>Col 0</div>
                    <div className={component['conflicts-table__cell']}>Col 0</div>
                    <div className={component['conflicts-table__cell']}>Col 0</div>
                    <div className={component['conflicts-table__cell']}>Col 0</div>
                    <div className={component['conflicts-table__cell']}>Col 0</div>
                    <div className={component['conflicts-table__cell']}>Col 0</div>
                    <div className={component['conflicts-table__cell']}>Col 0</div>
                    <div className={component['conflicts-table__cell']}>Col 0</div>
                    <div className={component['conflicts-table__cell']}>Col 0</div>
                    <div className={component['conflicts-table__cell']}>Col 0</div>
                    <div className={component['conflicts-table__cell']}>Col 0</div>
                    <div className={component['conflicts-table__cell']}>Col 0</div>
                    <div className={component['conflicts-table__cell']}>Col 0</div>
                    <div className={component['conflicts-table__cell']}>Col 0</div>
                </div>
            </div>
            <div className={component['conflicts-table__body-wrapper']}>
                <div
                    onMouseDown={() => scrollStart('right')}
                    onMouseUp={scrollStop}
                    className={component['scroll-button']}
                    style={{
                        display: showRightScroll ? 'flex' : 'none',
                    }}
                >
                    <ChevronRightRounded sx={{ color: 'white' }} fontSize={'medium'} />
                </div>
                <div
                    onMouseDown={() => scrollStart('left')}
                    onMouseUp={scrollStop}
                    className={classNames(component['scroll-button'], component['scroll-button--left'])}
                    style={{
                        display: showLeftScroll ? 'flex' : 'none',
                    }}
                >
                    <ChevronLeftRounded sx={{ color: 'white' }} fontSize={'medium'} />
                </div>
                <div
                    ref={bodyRef}
                    onScroll={(e) => {
                        bodyScrollHandler(e);
                    }}
                    className={component['conflicts-table__body']}
                >
                    <div className={component['conflicts-table__column']}>
                        <div
                            className={classNames(
                                component['conflicts-table__cell'],
                                component['conflicts-table__cell--head'],
                                component['conflicts-table__cell--selected']
                            )}
                        >
                            <RadioGroup value={'one'}>
                                <Radio value="one" label={'Создано: 01.01.2023'} />
                            </RadioGroup>
                        </div>
                        <div className={component['conflicts-table__cell']}>
                            <Typography variant={'h6'}>О клиенте</Typography>
                        </div>
                        <div
                            className={classNames(
                                component['conflicts-table__cell'],
                                component['conflicts-table__cell--selected']
                            )}
                        >
                            <div className={component['conflicts-table__radio-content']}>
                                <RadioGroup value={'two'}>
                                    <Radio label={''} value={'two'} />
                                </RadioGroup>
                                <div className={component['conflicts-table__radio-data']}>
                                    <Typography variant={'subtitle1'}>Фамилия</Typography>
                                    <Grid
                                        container
                                        alignItems={'center'}
                                        flexWrap={'nowrap'}
                                        gap={'8px'}
                                        pl={'4px'}
                                    >
                                        <Badge color="success" variant="dot" />
                                        <Typography variant={'body1'}>Адиев</Typography>
                                    </Grid>
                                </div>
                            </div>
                        </div>
                        <div className={component['conflicts-table__cell']}>
                            <div className={component['conflicts-table__radio-content']}>
                                <RadioGroup value={'two'}>
                                    <Radio label={''} value={'two'} />
                                </RadioGroup>
                                <div className={component['conflicts-table__radio-data']}>
                                    <Typography variant={'subtitle1'}>Фамилия</Typography>
                                    <Grid
                                        container
                                        alignItems={'center'}
                                        flexWrap={'nowrap'}
                                        gap={'8px'}
                                        pl={'4px'}
                                    >
                                        <Badge color="error" variant="dot" />
                                        <Typography variant={'body1'}>Адиев</Typography>
                                    </Grid>
                                </div>
                            </div>
                        </div>
                        <div className={classNames(component['conflicts-table__cell'])}>col 1</div>
                        <div className={classNames(component['conflicts-table__cell'])}>col 1</div>
                        <div className={classNames(component['conflicts-table__cell'])}>col 1</div>
                        <div className={classNames(component['conflicts-table__cell'])}>col 1</div>
                        <div className={classNames(component['conflicts-table__cell'])}>col 1</div>
                        <div className={classNames(component['conflicts-table__cell'])}>col 1</div>
                        <div className={classNames(component['conflicts-table__cell'])}>col 1</div>
                        <div className={classNames(component['conflicts-table__cell'])}>col 1</div>
                        <div className={classNames(component['conflicts-table__cell'])}>col 1</div>
                        <div className={classNames(component['conflicts-table__cell'])}>col 1</div>
                        <div className={classNames(component['conflicts-table__cell'])}>col 1</div>
                        <div className={classNames(component['conflicts-table__cell'])}>col 1</div>
                        <div className={classNames(component['conflicts-table__cell'])}>col 1</div>
                        <div className={classNames(component['conflicts-table__cell'])}>col 1</div>
                        <div className={classNames(component['conflicts-table__cell'])}>col 1</div>
                        <div className={classNames(component['conflicts-table__cell'])}>col 1</div>
                        <div className={classNames(component['conflicts-table__cell'])}>col 1</div>
                        <div className={classNames(component['conflicts-table__cell'])}>col 1</div>
                        <div className={classNames(component['conflicts-table__cell'])}>col 1</div>
                        <div className={classNames(component['conflicts-table__cell'])}>col 1</div>
                        <div className={classNames(component['conflicts-table__cell'])}>col 1</div>
                        <div className={classNames(component['conflicts-table__cell'])}>col 1</div>
                        <div className={classNames(component['conflicts-table__cell'])}>col 1</div>
                        <div className={classNames(component['conflicts-table__cell'])}>col 1</div>
                        <div className={classNames(component['conflicts-table__cell'])}>col 1</div>
                        <div className={classNames(component['conflicts-table__cell'])}>col 1</div>
                        <div className={classNames(component['conflicts-table__cell'])}>col 1</div>
                        <div className={classNames(component['conflicts-table__cell'])}>col 1</div>
                        <div className={classNames(component['conflicts-table__cell'])}>col 1</div>
                        <div className={classNames(component['conflicts-table__cell'])}>col 1</div>
                        <div className={classNames(component['conflicts-table__cell'])}>col 1</div>
                        <div className={classNames(component['conflicts-table__cell'])}>col 1</div>
                        <div className={classNames(component['conflicts-table__cell'])}>col 1</div>
                    </div>
                    <div className={component['conflicts-table__column']}>
                        <div
                            className={classNames(
                                component['conflicts-table__cell'],
                                component['conflicts-table__cell--head'],
                                component['conflicts-table__cell--not-selected']
                            )}
                        >
                            <Radio label={'Создано: 15.01.2023'} />
                        </div>
                        <div className={component['conflicts-table__cell']}>
                            <Typography variant={'h6'}>О клиенте</Typography>
                        </div>
                        <div
                            className={classNames(
                                component['conflicts-table__cell'],
                                component['conflicts-table__cell--not-selected']
                            )}
                        >
                            Col 2
                        </div>
                        <div className={component['conflicts-table__cell']}>Col 2</div>
                    </div>
                    <div className={component['conflicts-table__column']}>
                        <div
                            className={classNames(
                                component['conflicts-table__cell'],
                                component['conflicts-table__cell--head']
                            )}
                        >
                            Col 3
                        </div>
                        <div className={component['conflicts-table__cell']}>
                            <Typography variant={'h6'}>О клиенте</Typography>
                        </div>
                        <div className={component['conflicts-table__cell']}>Col 3</div>
                        <div className={component['conflicts-table__cell']}>Col 3</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConflictsResolvingTable;
