import React, { forwardRef, useMemo } from 'react';
import {
    alpha,
    Button as MuiButton,
    ButtonProps as MuiButtonProps,
    CircularProgress,
    useTheme,
} from '@mui/material';
import { ArrowBack, KeyboardArrowLeft } from '@mui/icons-material';

export type ButtonProps = MuiButtonProps & {
    arrow?: boolean;
    loading?: boolean;
    customColor?: 'gray';
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    ({ children, variant, arrow, loading = false, sx, customColor, ...props }, ref) => {
        const {
            palette: {
                action: { active },
            },
        } = useTheme();

        const customColors = useMemo(() => {
            return {
                gray: alpha(active, 0.54),
            };
        }, [active]);

        const arrowByVariant = () => {
            if (variant === 'contained') {
                return <ArrowBack />;
            } else if (variant === 'outlined' || variant === 'text') {
                return <KeyboardArrowLeft />;
            }
        };

        return (
            <MuiButton
                sx={{ ...sx, ...(customColor ? { color: customColors[customColor] } : {}) }}
                ref={ref}
                startIcon={
                    loading ? (
                        <CircularProgress
                            color="primary"
                            sx={{ width: '20px !important', height: '20px !important' }}
                        />
                    ) : (
                        arrow && arrowByVariant()
                    )
                }
                variant={variant}
                {...props}
                disabled={props.disabled || loading}
            >
                {children}
            </MuiButton>
        );
    }
);

export default Button;
