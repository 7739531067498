import { EmployeeType } from 'domain/employee';
import { useDrag } from 'react-dnd';
import React, { useContext, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { Avatar } from 'shared/ui';
import { SelectUserIdContext } from '../../pages/DepartmentsControl/DepartmentsControl';

type EmployeeItemProps = {
    employee: EmployeeType;
    onDrag: () => void;
};

const EmployeeItem = ({ employee, onDrag }: EmployeeItemProps) => {
    const {
        user: { first_name, last_name },
    } = employee;

    const [{ isDragging }, dragRef] = useDrag({
        type: 'employee',
        item: employee,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const { handleSelectUserId } = useContext(SelectUserIdContext);

    useEffect(() => {
        if (isDragging) {
            onDrag();
        }
    }, [isDragging]);

    return (
        <Grid
            ref={dragRef}
            container
            gap={'12px'}
            py={'14px'}
            px={'16px'}
            flexWrap={'nowrap'}
            onClick={() => handleSelectUserId(employee.user.id)}
        >
            <Avatar size={'small'} variant={'square'}>
                {first_name[0]}
                {last_name[0]}
            </Avatar>
            <Typography>
                {first_name} {last_name}
            </Typography>
        </Grid>
    );
};

export default EmployeeItem;
