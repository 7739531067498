import { useMenuStorage } from '../../lib/hooks/useMenuStorage';
import { List } from '@mui/material';
import { SidebarNavListItem } from '../SidebarNavListItem/SidebarNavListItem';
import { useUsersStorage } from '../../../../services/StorageAdapter';
import { Dispatch, SetStateAction, memo } from 'react';

export const SidebarNavList = memo(
    ({ setSidebarOpen }: { setSidebarOpen: Dispatch<SetStateAction<boolean>> }) => {
        const { menu } = useMenuStorage();
        const { me } = useUsersStorage();
        return (
            <List
                disablePadding
                sx={{
                    ['& > li']: {
                        mt: 1.5,
                        ['&:first-child']: {
                            mt: 0,
                        },
                    },
                    mb: 1.5,
                }}
            >
                {menu.results.map(({ id, name, items }) =>
                    !me.is_admin && items.length === 0 ? null : (
                        <SidebarNavListItem
                            key={id}
                            name={name}
                            items={items}
                            setSidebarOpen={setSidebarOpen}
                        />
                    )
                )}
            </List>
        );
    }
);
