import React, { FC, memo } from 'react';
import { Divider, Grid, Menu, Typography } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { SidebarAddedNewItemForm } from '../SidebarAddedNewItemForm/SidebarAddedNewItemForm';
import { useTranslation } from 'react-i18next';

interface SidebarAddedNewItemMenuProps {
    open: boolean;
    anchorEl: null | HTMLElement;
    handleClose: () => void;
}
export const SidebarAddedNewItemMenu: FC<SidebarAddedNewItemMenuProps> = memo(
    ({ anchorEl, open, handleClose }) => {
        const { t } = useTranslation();
        return (
            <Menu
                id={'sidebar-added-new-item-menu'}
                open={open}
                MenuListProps={{
                    sx: {
                        width: 380,
                        px: 3,
                    },
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                anchorEl={anchorEl}
                onClose={handleClose}
            >
                <Grid container alignItems={'center'} justifyContent={'space-between'}>
                    <Typography variant={'h6'}>{t('newItemLink')}</Typography>
                    <CloseRoundedIcon color={'action'} onClick={handleClose} sx={{ cursor: 'pointer' }} />
                </Grid>
                <Divider sx={{ my: 1.5 }} />
                <SidebarAddedNewItemForm handleClose={handleClose} />
            </Menu>
        );
    }
);
