import { FC, memo } from 'react';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Badge, BadgeProps, IconButton } from '@mui/material';
import { useNotificationStorage } from '../../../../pages/Notifications';
import { styled } from '@mui/material/styles';

interface NotificationBarIconProps {
    handleClick: (e: any) => void;
}

const StyledBadge = styled(Badge)<BadgeProps>(() => ({
    '& .MuiBadge-badge': {
        borderRadius: '4px',
        fontSize: 12,
        right: 5,
        top: 8,
    },
}));
export const NotificationBarIcon: FC<NotificationBarIconProps> = memo(({ handleClick }) => {
    const { lastNotifications } = useNotificationStorage();
    return (
        <StyledBadge
            badgeContent={lastNotifications.count_of_unread > 0 ? lastNotifications.count_of_unread : 0}
            color={'primary'}
            max={99}
            overlap={'rectangular'}
        >
            <IconButton onClick={handleClick}>
                {lastNotifications.results.length === 0 ? <NotificationsNoneIcon /> : <NotificationsIcon />}
            </IconButton>
        </StyledBadge>
    );
});
