import Api from 'shared/api/axios';
import { TBulkStage, TFunnels } from 'domain/funnels';
import { TParamsRequest, TResponse } from './types';

export const funnelsService = {
    getFunnels(params: TParamsRequest) {
        return Api.get<null, TResponse>(`/api/v1/funnels/funnels`, {
            params: { ...params },
        }).then((res) => {
            return { ...res, params: params, results: res.results };
        });
    },
    getFunnelsID(id: string) {
        return Api.get<null, TFunnels>(`/api/v1/funnels/funnels/${id}`);
    },
    createFunnel(data: any) {
        return Api.post<null, any>(`/api/v1/funnels/funnels`, data);
    },
    deleteFunnel(id: string) {
        return Api.delete<null, any>(`/api/v1/funnels/funnels/${id}/soft-delete`);
    },
    restoreFunnel(id: string) {
        return Api.put<null, any>(`/api/v1/funnels/funnels/${id}/restore`);
    },
    updateFunnel(data: any) {
        return Api.patch<null, any>(`/api/v1/funnels/funnels/${data.id}`, data);
    },
    bulkUpdateFunnel(data: any) {
        return Api.post<null, any>(`/api/v1/funnels/funnels/bulk-update`, data);
    },
    getStages(stage_id: string) {
        return Api.get<null, any>(`/api/v1/funnels/stages/${stage_id}`);
    },
    updateStage(data: any) {
        return Api.patch<null, any>(`/api/v1/funnels/stages/${data.id}`, data);
    },
    bulkUpdateStage(data: TBulkStage[]) {
        return Api.post<null, TBulkStage[]>('/api/v1/funnels/stages/bulk-update', data);
    },
    createStage(data: any) {
        return Api.post<null, any>(`/api/v1/funnels/stages`, data);
    },
    deleteStage(id: string) {
        return Api.delete<null, any>(`/api/v1/funnels/stages/${id}`);
    },
    setFunnelsStagesID(id: string, entity_objects: any) {
        return Api.post(`/api/v1/funnels/stages/${id}/move-objects`, entity_objects);
    },
    setFunnelsFunnelsID(id: string, entity_objects: any) {
        return Api.post(`/api/v1/funnels/funnels/${id}/move-objects`, entity_objects);
    },
};
