import { Typography } from '@mui/material';
import React from 'react';
import i18n from '../../../../shared/config/i18n/i18n';
export const notSpecified = (mb = '0') => {
    return (
        <Typography variant={'body2'} sx={{ fontSize: '14px', mb }} color={'text.secondary'}>
            {i18n.t('notSpecifiedMany')}
        </Typography>
    );
};
