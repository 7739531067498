import { alpha, Typography, TypographyProps, useTheme } from '@mui/material';
import React from 'react';

type FormFieldLabelProps = TypographyProps;

const FormFieldLabel = ({ children, ...props }: FormFieldLabelProps) => {
    const {
        typography: {
            subtitle2: { fontSize, fontWeight },
        },
        palette: {
            text: { secondary },
        },
    } = useTheme();

    return (
        <Typography fontSize={fontSize} color={alpha(secondary, 0.6)} fontWeight={fontWeight} {...props}>
            {children}
        </Typography>
    );
};

export default FormFieldLabel;
