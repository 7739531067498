import React from 'react';
import {
    alpha,
    MenuItem,
    Select as MuiSelect,
    Typography,
    useTheme,
    SelectProps as MuiSelectProps,
    Grid,
    Checkbox,
    ListItemText,
} from '@mui/material';
import { Chip } from 'shared/ui';
import CancelIcon from '@mui/icons-material/Cancel';

type OptionType = {
    id: any;
    text: string;
};

type SelectProps = MuiSelectProps & {
    placeholder?: string;
    items: OptionType[];
    onDelete: (items: Array<any>) => void;
};

const MultiSelect = ({
    size = 'small',
    displayEmpty,
    placeholder,
    items,
    value,
    onDelete,
    ...props
}: SelectProps) => {
    const {
        palette: {
            text: { secondary },
        },
    } = useTheme();
    return (
        <MuiSelect
            value={value}
            multiple
            displayEmpty={displayEmpty}
            variant={'outlined'}
            size={size}
            MenuProps={{
                PaperProps: {
                    sx: {
                        transform: 'translateY(-50%)',
                    },
                },
            }}
            sx={{
                minWidth: '208px',
                width: '100%',
            }}
            renderValue={(selected: any) => {
                if (placeholder && selected?.length === 0) {
                    return <Typography sx={{ color: alpha(secondary, 0.6) }}>{placeholder}</Typography>;
                }
                return (
                    <Grid container gap={'8px'}>
                        {Array.from(selected).map((selectedValue: any) => {
                            return (
                                <Chip
                                    key={selectedValue}
                                    label={items.find((opt) => opt.id === selectedValue)?.text}
                                    actionIcon={<CancelIcon />}
                                    onMouseDown={(e) => e.stopPropagation()}
                                    onMouseUp={(e) => e.stopPropagation()}
                                    actionHandler={() => {
                                        onDelete(
                                            (value as Array<string>).filter((val) => val !== selectedValue)
                                        );
                                    }}
                                />
                            );
                        })}
                    </Grid>
                );
            }}
            {...props}
        >
            {items.map((option) => {
                return (
                    <MenuItem key={option.id} value={option.id}>
                        <Checkbox checked={(value as Array<string>).some((item) => item === option.id)} />
                        <ListItemText primary={option.text} />
                    </MenuItem>
                );
            })}
        </MuiSelect>
    );
};

export default MultiSelect;
