import { useMenu } from '../../../shared/hooks/useMenu';
import { NotificationBarMenu } from './NotificationBarMenu/NotificationBarMenu';
import { NotificationBarIcon } from './NotificationBarIcon/NotificationBarIcon';
import { useEffect } from 'react';
import { useUsersStorage } from '../../../services/StorageAdapter';
import { useNotificationStorage } from '../../../pages/Notifications';

export const NotificationBar = () => {
    const { open, handleClose, handleClick, anchorEl } = useMenu();
    const { me } = useUsersStorage();
    const { getLastNotificationsReceivers, clearLastNotifications } = useNotificationStorage();

    useEffect(() => {
        if (me.employee) {
            getLastNotificationsReceivers({ receiver: me.employee.id, limit: 10, offset: 0 });
        }

        return () => {
            clearLastNotifications();
        };
    }, [me.employee]);
    return (
        <>
            <NotificationBarIcon handleClick={handleClick} />
            <NotificationBarMenu anchorEl={anchorEl} open={open} handleClose={handleClose} />
        </>
    );
};
