import { Field } from 'domain/field';
import { taskStatus } from 'pages/Tasks/List/Card/TaskListCard';

export const taskFields: Field[] = [
    {
        id: 'author',
        code: 'author',
        name: 'Постановщик',
        placeholder: 'Выбор сотрудника',
        value_type: 'fk_employee',
        type: 'system',
        order: 0,
        settings: {},
        hidden: false,
        ordering: false,
        group_label: '',
        group: 'Основной',
    },
    {
        id: 'changed_by',
        code: 'changed_by',
        name: 'Кем изменена',
        placeholder: 'Выбор сотрудника',
        value_type: 'fk_employee',
        type: 'system',
        order: 0,
        settings: {},
        hidden: false,
        ordering: false,
        group_label: '',
        group: 'Основной',
    },
    {
        id: 'co_executors',
        code: 'co_executors',
        name: 'Соисполнители',
        placeholder: 'Выбор сотрудника',
        value_type: 'm2m_employee',
        type: 'system',
        order: 0,
        settings: {},
        hidden: false,
        ordering: false,
        group_label: '',
        group: 'Основной',
    },
    {
        id: 'responsible',
        code: 'responsible',
        name: 'Ответственный',
        placeholder: 'Выбор сотрудника',
        value_type: 'fk_employee',
        type: 'system',
        order: 0,
        settings: {},
        hidden: false,
        ordering: false,
        group_label: '',
        group: 'Основной',
    },
    {
        id: 'status',
        code: 'status',
        name: 'Статус',
        placeholder: 'Выбор сотрудника',
        value_type: 'select',
        type: 'system',
        order: 0,
        settings: {
            options: taskStatus,
        },
        hidden: false,
        ordering: false,
        group_label: '',
        group: 'Основной',
    },
    {
        id: 'type',
        code: 'type',
        name: 'Тип задачи',
        placeholder: 'Выбор сотрудника',
        value_type: 'select',
        type: 'system',
        order: 0,
        settings: {
            options: [{ task: 'Задача' }, { call: 'Звонок' }, { meeting: 'Встреча' }],
        },
        hidden: false,
        ordering: false,
        group_label: '',
        group: 'Основной',
    },
    {
        id: 'completed_date_after',
        code: 'completed_date_after',
        name: 'Дата завершения (Больше)',
        placeholder: 'Выбор сотрудника',
        value_type: 'datetime',
        type: 'system',
        order: 0,
        settings: {},
        hidden: false,
        ordering: false,
        group_label: '',
        group: 'Основной',
    },
    {
        id: 'completed_date_before',
        code: 'completed_date_before',
        name: 'Дата завершения (Меньше)',
        placeholder: 'Выбор сотрудника',
        value_type: 'datetime',
        type: 'system',
        order: 0,
        settings: {},
        hidden: false,
        ordering: false,
        group_label: '',
        group: 'Основной',
    },
    {
        id: 'created_after',
        code: 'created_after',
        name: 'Дата создания (Больше)',
        placeholder: 'Выбор сотрудника',
        value_type: 'datetime',
        type: 'system',
        order: 0,
        settings: {},
        hidden: false,
        ordering: false,
        group_label: '',
        group: 'Основной',
    },
    {
        id: 'created_before',
        code: 'created_before',
        name: 'Дата создания (Меньше)',
        placeholder: 'Выбор сотрудника',
        value_type: 'datetime',
        type: 'system',
        order: 0,
        settings: {},
        hidden: false,
        ordering: false,
        group_label: '',
        group: 'Основной',
    },
    {
        id: 'created_by',
        code: 'created_by',
        name: 'Кем создана',
        placeholder: 'Выбор сотрудника',
        value_type: 'fk_employee',
        type: 'system',
        order: 0,
        settings: {},
        hidden: false,
        ordering: false,
        group_label: '',
        group: 'Основной',
    },
    {
        id: 'deadline_after',
        code: 'deadline_after',
        name: 'Дедлайн (Больше)',
        placeholder: 'Выбор сотрудника',
        value_type: 'datetime',
        type: 'system',
        order: 0,
        settings: {},
        hidden: false,
        ordering: false,
        group_label: '',
        group: 'Основной',
    },
    {
        id: 'deadline_before',
        code: 'deadline_before',
        name: 'Дедлайн (Меньше)',
        placeholder: 'Выбор сотрудника',
        value_type: 'datetime',
        type: 'system',
        order: 0,
        settings: {},
        hidden: false,
        ordering: false,
        group_label: '',
        group: 'Основной',
    },
    {
        id: 'is_overdue',
        code: 'is_overdue',
        name: 'Просрочена?',
        placeholder: 'Выбор сотрудника',
        value_type: 'bool',
        type: 'system',
        order: 0,
        settings: {},
        hidden: false,
        ordering: false,
        group_label: '',
        group: 'Основной',
    },
    {
        id: 'no_deadline',
        code: 'no_deadline',
        name: 'Без дедлайна',
        placeholder: 'Выбор сотрудника',
        value_type: 'bool',
        type: 'system',
        order: 0,
        settings: {},
        hidden: false,
        ordering: false,
        group_label: '',
        group: 'Основной',
    },
    {
        id: 'observers',
        code: 'observers',
        name: 'Наблюдатели',
        placeholder: 'Выбор сотрудника',
        value_type: 'm2m_employee',
        type: 'system',
        order: 0,
        settings: {},
        hidden: false,
        ordering: false,
        group_label: '',
        group: 'Основной',
    },
];
