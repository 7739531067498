import { Dialog, styled } from '@mui/material';

const gridSx = {
    height: '100%',
    marginTop: 0,
    ['& > .MuiGrid-item']: {
        height: '100%',
        paddingLeft: 0,
        paddingTop: 0,
        ['& > .MuiBox-root']: {
            height: '100%',
        },
    },
};

const ChatDialog = styled(Dialog)({
    maxWidth: '90%',
    width: '100%',
    height: 'calc(100% - 64px)',
    left: 'auto',
    top: 'auto',
    zIndex: '20',
});

const textFieldSx = {
    ['& .MuiInputBase-root']: {
        borderRadius: 0,
    },

    ['& .MuiOutlinedInput-notchedOutline']: {
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
    },
};

const avatarSx = {
    backgroundColor: 'orange',
    borderRadius: '4px',
    ['& svg']: { width: '24px', height: '24p' },
};

export { gridSx, ChatDialog, textFieldSx, avatarSx };
