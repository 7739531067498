import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import config from '../../config';

const token = localStorage.getItem('token');

export const axiosRequestInterceptor = (request: AxiosRequestConfig) => {
    const token = localStorage.getItem('token');
    if (token && request.headers) {
        request.headers['Authorization'] = `Bearer ${token}`;
    } else if (request.headers) {
        delete request.headers['Authorization'];
    }
    return request;
};

export const axiosResponseInterceptor = (response: AxiosRequestConfig) => {
    const { data } = response;
    if (data.access) {
        localStorage.setItem('token', data.access);
        localStorage.setItem('refresh', data.refresh);
        if (response.headers) {
            response.headers['Authorization'] = `Bearer ${token}`;
        }
    }
    return data;
};

const Api = axios.create({
    baseURL: config.base_url,
    headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
    },
});

export const wsApi = axios.create({
    baseURL: config.base_chat_url,
    headers: {
        'Content-Type': 'application/json',
    },
});
delete wsApi.defaults.headers.common['Authorization'];

Api.interceptors.request.use(axiosRequestInterceptor, (error) => Promise.reject(error));

Api.interceptors.response.use(axiosResponseInterceptor, (error: AxiosError) => {
    const { detail }: any = error.response?.data;
    if (error.response?.status === 401 && detail !== 'No active account found with the given credentials') {
        localStorage.removeItem('token');
        localStorage.removeItem('refresh');
        window.location.reload();
    }
    return Promise.reject(error);
});

export const CancelToken = axios.CancelToken;

wsApi.interceptors.request.use(
    (request: AxiosRequestConfig) => request,
    (error) => Promise.reject(error)
);

wsApi.interceptors.response.use(
    (response: AxiosRequestConfig) => {
        const { data } = response;
        return data;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default Api;
