import React, { PointerEventHandler, ReactNode, useState } from 'react';
import { Box, styled } from '@mui/material';
import { motion } from 'framer-motion';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

const DraggableIcon = styled(Box)(() => ({
    position: 'absolute',
    top: 5,
    left: 0,
    cursor: 'grab',
}));

const dragButtonVariants = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
};

type IconPositionType = {
    top: number;
    left: number;
};

type DraggableItemWrapperProps = {
    children: ReactNode | ((hovered: boolean) => ReactNode);
    dragHandler?: PointerEventHandler<HTMLDivElement>;
    iconPosition?: IconPositionType;
    isDraggable?: boolean;
};

const DraggableItemWrapper = ({
    children,
    dragHandler,
    iconPosition,
    isDraggable = true,
}: DraggableItemWrapperProps) => {
    const [hovered, setHovered] = useState(false);

    return (
        <Box
            sx={{
                position: 'relative',
            }}
        >
            <motion.div onHoverStart={() => setHovered(true)} onHoverEnd={() => setHovered(false)}>
                <DraggableIcon
                    sx={
                        iconPosition && {
                            top: iconPosition.top,
                            left: iconPosition.left,
                        }
                    }
                    onPointerDown={(e) => isDraggable && dragHandler?.(e)}
                >
                    <motion.div
                        variants={dragButtonVariants}
                        animate={hovered && isDraggable ? 'show' : 'hidden'}
                        transition={{ duration: 0.5 }}
                    >
                        <DragIndicatorIcon fontSize={'small'} color={'inherit'} />
                    </motion.div>
                </DraggableIcon>
                {typeof children === 'function' ? children(hovered) : children}
            </motion.div>
        </Box>
    );
};

export default DraggableItemWrapper;
