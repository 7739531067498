import React from 'react';
import { TFieldProps } from 'widgets/DraggableField/Fields/types';
import { ConnectedObjectType } from 'pages/Tasks/List/Card/TaskListCard';
import { ConnectEntityModalSettingsType } from 'services/store/entityDetail/entityDetail';
import ForeignKeyField from 'widgets/DraggableField/Fields/ForeignKeyField/ForeignKeyField';
import ManyToManyField from 'widgets/DraggableField/Fields/ManyToManyField/ManyToManyField';

type UuidFieldProps = TFieldProps<ConnectedObjectType | ConnectedObjectType[]> & {
    openConnectModal?: (data: Omit<ConnectEntityModalSettingsType, 'isOpen'>) => void;
};

const UuidField = ({ field, edit, openConnectModal, value, setValue, error }: UuidFieldProps) => {
    const isMany = field.settings.many;
    return (
        <>
            {edit ? (
                <>
                    {isMany && Array.isArray(value) ? (
                        <ManyToManyField
                            edit
                            field={field}
                            value={value.filter(Boolean)}
                            setValue={setValue}
                            error={error}
                            openConnectModal={openConnectModal}
                        />
                    ) : !Array.isArray(value) ? (
                        <ForeignKeyField
                            edit
                            field={field}
                            value={value}
                            setValue={setValue}
                            error={error}
                            openConnectModal={openConnectModal}
                        />
                    ) : (
                        <></>
                    )}
                </>
            ) : (
                <>
                    {isMany && Array.isArray(value) ? (
                        <ManyToManyField field={field} value={value.filter(Boolean)} />
                    ) : !Array.isArray(value) ? (
                        <ForeignKeyField field={field} value={value} />
                    ) : (
                        <></>
                    )}
                </>
            )}
        </>
    );
};

export default UuidField;
