import React from 'react';
import { TFieldProps } from '../types';
import { ConnectedObjectType } from 'pages/Tasks/List/Card/TaskListCard';
import { Button, Chip } from 'shared/ui';
import { Grid } from '@mui/material';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import LinkIcon from '@mui/icons-material/Link';
import { ConnectEntityModalSettingsType } from 'services/store/entityDetail/entityDetail';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type ForeignKeyFieldProps = TFieldProps<ConnectedObjectType> & {
    openConnectModal?: (data: Omit<ConnectEntityModalSettingsType, 'isOpen'>) => void;
};

const ForeignKeyField = ({ value, field, setValue, edit, openConnectModal }: ForeignKeyFieldProps) => {
    const { t } = useTranslation();
    const code =
        field.value_type === 'fk'
            ? field.settings.entity_code
            : field.value_type === 'fk_department'
            ? 'departments'
            : field.value_type === 'fk_employee' || field.value_type === 'system_fk'
            ? 'department-employees'
            : 'uuid';
    const navigate = useNavigate();
    const isClickable = field.value_type === 'fk' || field.value_type === 'uuid';

    const handleChipClick = (id: string, entity?: string) => {
        let url = `/entity`;
        if (field.settings.entity_code) {
            url += `/${field.settings.entity_code}`;
        } else {
            url += `/${entity}`;
        }
        if (field.settings.entity_code === 'tasks') {
            url += `/?task_id=${id}`;
        } else {
            url += `/${id}`;
        }
        navigate(url);
    };
    return (
        <>
            {edit ? (
                <Grid container flexDirection={'column'} alignItems={'flex-start'} gap={'12px'}>
                    {value && (
                        <Chip
                            label={value.name || `${value.user?.first_name} ${value.user?.last_name}`}
                            actionIcon={<CancelIcon />}
                            actionHandler={() => setValue?.(field.id, '')}
                        />
                    )}
                    <Button
                        onClick={() => {
                            openConnectModal?.({
                                code: code,
                                type: field.value_type,
                                handleSelect: (value) => setValue?.(field.id, value),
                                selected: value,
                            });
                        }}
                        startIcon={value ? <ChangeCircleIcon /> : <LinkIcon />}
                    >
                        {value ? t('change') : t('bind')}
                    </Button>
                </Grid>
            ) : (
                <>
                    {value?.name && (
                        <Chip
                            onPointerUp={(e) => e.stopPropagation()}
                            onClick={
                                isClickable
                                    ? () => {
                                          handleChipClick(value.id, value.entity || value.code);
                                      }
                                    : undefined
                            }
                            label={value?.name}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default ForeignKeyField;
