import Api from 'shared/api/axios';
import { UserType } from 'domain/user';
import { TResponse } from 'shared/api/types';
import Base64toFile from 'shared/helpers/Base64toFile';

export const userService = {
    getUser(id: string) {
        return Api.get<null, UserType>(`/api/v1/users/${id}`);
    },
    getMe() {
        return Api.get<null, UserType>(`/api/v1/users/me`);
    },
    getUsers(params: any) {
        return Api.get<null, TResponse>(`/api/v1/users/`, {
            params: { ...params },
        }).then((res) => {
            return { ...res, params: params };
        });
    },
    deactivateUser(id: string) {
        return Api.get<null, UserType>(`/api/v1/users/${id}/deactivate`);
    },
    activateUser(id: string) {
        return Api.get<null, UserType>(`/api/v1/users/${id}/activate`);
    },
    updateUser(user: UserType) {
        const formData = new FormData();
        // Список пар ключ/значение
        for (const [name, value] of Object.entries(user)) {
            if (name === 'is_staff') {
                formData.append(name, value);
            }
            if (value !== undefined) {
                if (name === 'department') {
                    formData.append(name, value.id);
                } else if (typeof value === 'string') {
                    if (name === 'avatar') {
                        if (value.startsWith('data:image/png;base64')) {
                            formData.append('avatar', Base64toFile(value, 'image.jpeg'));
                        } else {
                            formData.delete('avatar');
                        }
                    } else {
                        formData.append(name, value);
                    }
                }
            }
        }

        return Api.patch<null, UserType>(`/api/v1/users/${user.id}`, formData);
    },
    createUser(user: UserType) {
        const formData = new FormData();

        // Список пар ключ/значение
        for (const [name, value] of Object.entries(user)) {
            if (value) {
                if (name === 'department') {
                    formData.append(name, value.id);
                } else if (typeof value === 'string' && value.trim()) {
                    if (name === 'avatar') {
                        if (value.startsWith('data:image/png;base64')) {
                            formData.append('avatar', Base64toFile(value, 'image.jpeg'));
                        } else {
                            formData.delete('avatar');
                        }
                    } else {
                        formData.append(name, value);
                    }
                } else if (typeof value === 'boolean') {
                    formData.append(name, value.toString());
                }
            }
        }

        return Api.post<null, UserType>(`/api/v1/users/`, formData);
    },
    resetPassword(email: string) {
        return Api.post<null, UserType>(`/api/v1/users/reset`, { email: email });
    },
    verifyPassword(password: string, password_confirm: string, code: string) {
        return Api.post<null, UserType>(`/api/v1/users/reset-verify`, {
            password,
            password_confirm,
            code,
        });
    },
    changePassword(old_password: string, new_password1: string, new_password2: string) {
        return Api.post('/api/v1/users/password-change', { old_password, new_password1, new_password2 });
    },
};
