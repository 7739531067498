import { Nullable } from 'shared/types';
import { useDrag } from 'react-dnd';
import { Badge, Box, Divider, Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import React, {
    ChangeEvent,
    Dispatch,
    MouseEvent,
    SetStateAction,
    useContext,
    useEffect,
    useState,
} from 'react';
import DepartmentCardDropContainer from 'widgets/DepartmentsMap/DepartmentCard/DropContainer';
import { useNavigate } from 'react-router-dom';
import { DepartmentsMapContext } from 'widgets/DepartmentsMap/DepartmentsMap';
import { DepartmentNode } from 'pages/DepartmentsControl/DepartmentsControl';
import { DepartmentsMap } from 'widgets/index';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Button, Input } from 'shared/ui';
import { useDepartmentsStorage, useNotification, useUsersStorage } from 'services/StorageAdapter';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { useTranslation } from 'react-i18next';

type DepartmentCardProps = {
    color: string;
    bgColor: string;
    parentId?: string;
    width?: string;
    setRef?: (ref: HTMLDivElement) => void;
    node: DepartmentNode;
    level: number;
    map: Map<string, DepartmentNode>;
    setMap: Dispatch<SetStateAction<Nullable<Map<string, DepartmentNode>>>>;
    setShowChildren?: any;
    showChildren?: any;
};

const DepartmentCard = ({
    color,
    parentId,
    width,
    setRef,
    level,
    node,
    map,
    setMap,
    // bgColor,
    setShowChildren,
    showChildren,
}: DepartmentCardProps) => {
    const { t } = useTranslation();
    const { t: departmentsT } = useTranslation('departmentsControl');
    const { head, name, id: cardId, employees, children } = node;
    const [menuRef, setMenuRef] = useState<null | HTMLElement>(null);
    const [isTitleEdit, setIsTitleEdit] = useState(false);
    const [title, setTitle] = useState(name);
    const open = Boolean(menuRef);
    const navigate = useNavigate();
    const { openCard } = useContext(DepartmentsMapContext);
    // const [showChildren, setShowChildren] = useState(false);
    const { notify } = useNotification();
    const { updateDepartment } = useDepartmentsStorage();
    const { me } = useUsersStorage();

    const [, dragRef] = useDrag({
        type: 'card',
        item: {
            id: cardId,
            parentId,
            type: 'card',
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const { deleteDepartment } = useDepartmentsStorage();

    const handleClickMenu = (e: MouseEvent<HTMLButtonElement>) => {
        setMenuRef(e.currentTarget);
    };

    const handleCloseMenu = () => {
        setMenuRef(null);
    };

    const handleOpenDepartment = (isCreate?: boolean) => {
        handleCloseMenu();
        if (!isCreate) {
            navigate(`./?department_id=${cardId}`);
        }
        openCard();
    };

    const handleDeleteDepartment = () => {
        if (children.length > 0) {
            notify({
                message: departmentsT('deleteErrorMessage'),
            });
        } else {
            deleteDepartment(cardId);
        }
    };

    const handleStartTitleEdit = () => {
        setIsTitleEdit(true);
        handleCloseMenu();
    };
    const handleCancelTitleEdit = () => {
        setTitle(name);
        setIsTitleEdit(false);
    };
    const handleSaveTitle = () => {
        updateDepartment(cardId, { name: title });
        setIsTitleEdit(false);
    };
    const handleTitleEdit = (event: ChangeEvent<HTMLInputElement>) => {
        setTitle(event.currentTarget.value);
    };

    useEffect(() => {
        setTitle(name);
    }, [name]);

    return (
        <Box
            ref={(ref: any) => {
                dragRef(ref);
                setRef?.(ref);
            }}
            sx={{
                border: `1px solid #F5F5F5`,
                borderRadius: '16px',
                backgroundColor: '#F5F5F5',
                maxWidth: '100%',
                width: width || '340px',
                height: '152px',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Box>
                <Grid
                    container
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    p={'12px'}
                    flexWrap={'nowrap'}
                    overflow={'hidden'}
                >
                    {!isTitleEdit ? (
                        <Typography
                            variant={'body1'}
                            whiteSpace={'nowrap'}
                            textOverflow={'ellipsis'}
                            overflow={'hidden'}
                            color="#000"
                            sx={{ fontWeight: '600' }}
                        >
                            {title}
                        </Typography>
                    ) : (
                        <Input
                            draggable
                            onDragStart={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                            }}
                            onChange={handleTitleEdit}
                            fullWidth
                            sx={{
                                ['& .MuiInputBase-root']: {
                                    borderRadius: '8px',
                                },
                                ['& input']: {
                                    px: '10px',
                                    py: '3px',
                                },
                            }}
                            size={'small'}
                            value={title}
                        />
                    )}
                    {!isTitleEdit ? (
                        <IconButton onClick={handleClickMenu} size={'small'}>
                            <MoreHorizIcon fontSize={'inherit'} />
                        </IconButton>
                    ) : (
                        <Grid container width={'auto'} flexWrap={'nowrap'}>
                            <IconButton onClick={handleSaveTitle} size={'small'}>
                                <CheckCircleRoundedIcon fontSize={'inherit'} />
                            </IconButton>
                            <IconButton onClick={handleCancelTitleEdit} size={'small'}>
                                <CancelRoundedIcon fontSize={'inherit'} />
                            </IconButton>
                        </Grid>
                    )}
                </Grid>
            </Box>
            <Grid container sx={{ position: 'relative' }} height={'100%'}>
                <DepartmentCardDropContainer
                    cardId={cardId}
                    employees={employees}
                    head={head}
                    color={color}
                />
                {children.length > 0 && level === 3 && (
                    <>
                        <Box
                            sx={{
                                position: 'absolute',
                                bottom: '-22px',
                                left: '50%',
                                transform: 'translateX(-50%)',
                            }}
                        >
                            <Button onClick={() => setShowChildren(parentId)}>
                                <Badge
                                    sx={{
                                        position: 'relative',
                                        zIndex: 10,
                                        whiteSpace: 'nowrap',
                                        '& span': {
                                            borderRadius: '4px',
                                            position: 'static',
                                            transform: 'none',
                                        },
                                        cursor: 'pointer',
                                    }}
                                    color={'primary'}
                                    badgeContent={t('moreDepartmentsCount', { count: children.length })}
                                />
                            </Button>
                        </Box>
                        {showChildren === parentId && (
                            <Box
                                sx={{
                                    position: 'absolute',
                                    left: '290px',
                                    top: '-52px',
                                    background: 'rgba(0, 0, 0, 0.26)',
                                    zIndex: 99999,
                                    padding: '75px 85px',
                                    borderRadius: '20px',
                                }}
                            >
                                <IconButton
                                    sx={{
                                        color: 'white',
                                        position: 'absolute',
                                        top: '20px',
                                        right: '20px',
                                    }}
                                    onClick={() => setShowChildren(null)}
                                    size={'medium'}
                                >
                                    <CloseRoundedIcon fontSize={'inherit'} />
                                </IconButton>
                                <DepartmentsMap
                                    isOverlay
                                    tree={node}
                                    map={map}
                                    setMap={setMap}
                                    openCard={openCard}
                                />
                            </Box>
                        )}
                    </>
                )}
            </Grid>
            <Menu
                anchorEl={menuRef}
                open={open}
                onClose={handleCloseMenu}
                sx={{
                    zIndex: 9999999,
                }}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    sx: {
                        width: '222px',
                        zIndex: 9999999,
                    },
                }}
            >
                <MenuItem onClick={() => handleOpenDepartment()}>{t('open')}</MenuItem>
                <MenuItem onClick={() => handleOpenDepartment(true)}>Создать подразделение</MenuItem>
                {me.is_admin ? (
                    <>
                        <MenuItem onClick={handleStartTitleEdit}>{t('rename')}</MenuItem>
                        <Divider />
                        <MenuItem onClick={handleDeleteDepartment} sx={{ color: '#D32F2F' }}>
                            {departmentsT('delete')}
                        </MenuItem>
                    </>
                ) : null}
            </Menu>
        </Box>
    );
};

export default DepartmentCard;
