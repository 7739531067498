import React, { Fragment } from 'react';

type ArrowsProps = {
    arrows: Array<any>;
    treeId: string;
    isOverlay?: boolean;
};

const Arrows = ({ arrows, treeId, isOverlay }: ArrowsProps) => {
    return (
        <svg
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                pointerEvents: 'none',
            }}
        >
            <defs>
                <marker id="arrow" markerWidth="9" markerHeight="5" refX="0" refY="0" orient="auto">
                    <path d="M2,2 L2,11 L10,6 L2,2" style={{ fill: 'red' }} />
                </marker>
            </defs>
            <defs>
                <marker
                    markerWidth="5"
                    markerHeight="5"
                    refX="2.5"
                    refY="2.5"
                    viewBox="0 0 5 5"
                    orient="auto"
                    id={`SvgjsMarker1000-${treeId}`}
                >
                    <polygon
                        points="0,5 1.6666666666666667,2.5 0,0 5,2.5"
                        fill={isOverlay ? '#fff' : '#B3B3B3'}
                    ></polygon>
                </marker>
            </defs>
            {arrows.map((arrow) => {
                const parentRef = arrow.ref;
                const isHorizontal = arrow.orientation === 'horizontal';
                const x0 = isHorizontal
                    ? parentRef.offsetLeft + parentRef.clientWidth / 2
                    : parentRef.offsetLeft + 4;
                const y0 = isHorizontal
                    ? parentRef.offsetTop + parentRef.clientHeight + 40
                    : parentRef.offsetTop + parentRef.clientHeight;
                const startPos = `${x0} ${y0}`;
                return (
                    <Fragment key={arrow.id}>
                        {arrow.children.map((child: any) => {
                            const childRef = child.ref;
                            const x = isHorizontal
                                ? childRef.offsetLeft + childRef.clientWidth / 2
                                : childRef.offsetLeft - 10;
                            const y = isHorizontal
                                ? childRef.offsetTop - 10
                                : childRef.offsetTop + childRef.clientHeight / 2;
                            const endPos = `${x} ${y}`;
                            const point = isHorizontal ? `${x} ${y0}` : `${x0} ${y}`;
                            return (
                                <Fragment key={child.id}>
                                    <path
                                        d={`M ${startPos} C ${point}, ${point}, ${endPos}`}
                                        stroke={isOverlay ? '#fff' : '#B3B3B3'}
                                        strokeWidth={2.5}
                                        fill="transparent"
                                        markerEnd={`url(#SvgjsMarker1000-${treeId})`}
                                        name={child.id}
                                    />
                                </Fragment>
                            );
                        })}
                        {arrow.id === treeId && (
                            <path
                                d={`M ${parentRef.offsetLeft + parentRef.clientWidth / 2} ${
                                    parentRef.offsetTop + parentRef.clientHeight
                                } C ${parentRef.offsetLeft + parentRef.clientWidth / 2} ${y0}, ${
                                    parentRef.offsetLeft + parentRef.clientWidth / 2
                                } ${y0}, ${parentRef.offsetLeft + parentRef.clientWidth / 2} ${
                                    parentRef.offsetTop + parentRef.clientHeight + 40
                                }`}
                                stroke={isOverlay ? '#fff' : '#B3B3B3'}
                                strokeWidth={2.5}
                                fill="transparent"
                            />
                        )}
                    </Fragment>
                );
            })}
        </svg>
    );
};

export default Arrows;
