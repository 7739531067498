import { Grid, InputAdornment } from '@mui/material';
import { CheckBox, Input } from 'shared/ui';
import SearchIcon from '@mui/icons-material/Search';
import { useMetaStorage } from 'services/StorageAdapter';
import { Field } from 'domain/field';
import { useTranslation } from 'react-i18next';

interface IProps {
    onCheck: (field_id: string) => void;
    onSearch: (field_id: string) => void;
    selected: string[];
    data: Field[];
}

const SelectFields = ({ onCheck, onSearch, selected, data }: IProps) => {
    const { t } = useTranslation();
    const { meta } = useMetaStorage();

    return (
        <>
            <Grid px={3} py={3}>
                <Input
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    placeholder={t('findField')}
                    sx={{ mb: 3 }}
                    onChange={(event) => onSearch(event.target.value)}
                />
                {!!meta.views?.length && (
                    <Grid container rowGap={1}>
                        {data.map((item) => (
                            <Grid item md={3} xs={12} key={item.id}>
                                <CheckBox
                                    label={item.name}
                                    value={item.id}
                                    checked={!!selected?.find((field) => field === item.id)}
                                    onChange={(event) => onCheck(event.target.value)}
                                />
                            </Grid>
                        ))}
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default SelectFields;
