import React from 'react';
import { Grid, Typography } from '@mui/material';

const TemplateHeader = () => {
    return (
        <Grid container mb={'28px'} alignItems={'center'} justifyContent="space-between">
            <Typography variant={'h5'}>Шаблоны документов</Typography>
        </Grid>
    );
};

export default TemplateHeader;
