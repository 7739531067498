import React from 'react';
import { Button, Modal } from 'shared/ui';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { useUsersStorage } from 'services/StorageAdapter';
import { useTranslation } from 'react-i18next';

type RecoverPasswordProps = {
    onClose: () => void;
    open: boolean;
};

const RecoverPassword = ({ onClose, open }: RecoverPasswordProps) => {
    const { t } = useTranslation();
    const { me, resetPassword } = useUsersStorage();
    const {
        palette: {
            text: { primary },
        },
    } = useTheme();
    return (
        <Modal open={open} title={`Забыли пароль`} width={633} onClose={onClose}>
            <Box px={'24px'} py={'24px'}>
                <Typography
                    sx={{
                        ['b']: {
                            fontWeight: '500',
                        },
                    }}
                    style={{ color: primary }}
                    variant={'body1'}
                    dangerouslySetInnerHTML={{
                        __html: t('recoverPasswordText', {
                            email: me.email,
                        }),
                    }}
                />
                <Grid container gap={'8px'} flexWrap={'nowrap'} mt={'12px'}>
                    <Button
                        variant={'contained'}
                        onClick={() => {
                            me?.email && resetPassword(me.email);
                            onClose();
                        }}
                    >
                        {t('sendLink')}
                    </Button>
                    <Button variant={'outlined'} onClick={onClose}>
                        {t('cancel')}
                    </Button>
                </Grid>
            </Box>
        </Modal>
    );
};

export default RecoverPassword;
