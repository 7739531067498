import CommentRoundedIcon from '@mui/icons-material/CommentRounded';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import { Typography } from '@mui/material';
import i18n from '../../../../shared/config/i18n/i18n';

export const switchIcon = (type: string) => {
    switch (type) {
        case 'comment':
            return (
                <>
                    <CommentRoundedIcon color="primary" />
                    <Typography variant="subtitle2" fontSize={16}>
                        {i18n.t('comment')}
                    </Typography>
                </>
            );
        case 'event':
            return (
                <>
                    <NotificationsRoundedIcon color="primary" />
                    <Typography variant="subtitle2" fontSize={16}>
                        {i18n.t('event')}
                    </Typography>
                </>
            );
        case 'task':
            return (
                <>
                    <CommentRoundedIcon color="primary" />
                    <Typography variant="subtitle2" fontSize={16}>
                        {i18n.t('task')}
                    </Typography>
                </>
            );
        case 'call':
            return (
                <>
                    <CommentRoundedIcon color="primary" />
                    <Typography variant="subtitle2" fontSize={16}>
                        {i18n.t('callType')}
                    </Typography>
                </>
            );
        case 'meeting':
            return (
                <>
                    <CommentRoundedIcon color="primary" />
                    <Typography variant="subtitle2" fontSize={16}>
                        {i18n.t('meetingType')}
                    </Typography>
                </>
            );
        default:
            return (
                <>
                    <NotificationsRoundedIcon color="primary" />
                    <Typography variant="subtitle2" fontSize={16}>
                        {i18n.t('comment')}
                    </Typography>
                </>
            );
    }
};
