interface IGroup {
    isCreate: boolean;
    emptyTitle: string;
    emptyMessage: string;
}
export interface IGroups {
    manager: IGroup;
    client: IGroup;
}

const DEFAULT_GROUP = 'manager';
const groups: IGroups = {
    manager: {
        isCreate: true,
        emptyTitle: 'noInterlocutors',
        emptyMessage: 'managerEmptyMessage',
    },
    client: {
        isCreate: false,
        emptyTitle: 'noInterlocutors',
        emptyMessage: 'clientEmptyMessage',
    },
};

export { groups, DEFAULT_GROUP };
