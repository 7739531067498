import { Chip, Upload } from 'shared/ui';
import { Box, Divider, Grid, IconButton } from '@mui/material';
import { FileForUploadType } from 'shared/ui/Upload/Upload';
import { FileUploadStatus } from 'shared/ui/Upload/Upload';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import FilePresentRoundedIcon from '@mui/icons-material/FilePresentRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { useEntityDetailStorage } from 'services/StorageAdapter';
import { FileType } from 'widgets/DraggableField/Fields/FileField/FileField';

interface ITimelineCommentFormUpload {
    files: FileType[];
    edit: boolean;
    onDeleteFile: (removeId: string) => void;
    setFile: (file: FileType) => void;
}

export const TimelineCommentFormUpload = ({
    files,
    edit = false,
    onDeleteFile,
    setFile,
}: ITimelineCommentFormUpload) => {
    const { uploadFile } = useEntityDetailStorage();

    const uploadFiles = async (
        files: FileForUploadType[],
        progressCallback: (status: Omit<FileUploadStatus, 'file' | 'abortSignal' | 'controller'>) => void
    ) => {
        for (let i = 0; i < files.length; i++) {
            const { id, file, abortSignal, ...other } = files[i];
            if (file.size / 1024 ** 2 >= 10) {
                progressCallback({
                    ...other,
                    id,
                    status: 'error',
                    reason: 'Ошибка. Вес файла слишком большой',
                });
                continue;
            }
            progressCallback({
                ...other,
                id,
                status: 'uploading',
            });
            try {
                const uploadedFiles = await uploadFile({ file, abortSignal });
                progressCallback({
                    id,
                    status: 'success',
                });
                setFile(uploadedFiles[0]);
            } catch (err) {
                const { id } = files[i];
                progressCallback({
                    id,
                    status: 'error',
                    reason: 'Ошибка. Не удалось загрузить файл',
                });
            }
        }
    };

    const handleDownload = (url: string) => {
        window.open(url, '_blank');
    };

    return (
        <>
            {!!files?.length && (
                <>
                    <Divider sx={{ width: '100%' }} />
                    <Grid container gap={3}>
                        {Array.isArray(files) &&
                            files.map(({ file, id, title }) => {
                                const [, fileName] = /.*\/(.*\..*)\?/.exec(file) as string[];
                                const isImage = /\.(png|jpg|jpeg)/.test(fileName);
                                return (
                                    <>
                                        {isImage ? (
                                            <Box
                                                key={id}
                                                sx={{
                                                    width: '80px',
                                                    height: '80px',
                                                    position: 'relative',
                                                }}
                                            >
                                                <img
                                                    style={{
                                                        borderRadius: '4px',
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'cover',
                                                    }}
                                                    src={file}
                                                    alt={fileName}
                                                    onClick={() => !edit && handleDownload(file)}
                                                />
                                                {edit && (
                                                    <Box
                                                        onClick={() => onDeleteFile(id)}
                                                        sx={{
                                                            cursor: 'pointer',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            position: 'absolute',
                                                            top: '-6px',
                                                            right: '-6px',
                                                            width: '32px',
                                                            height: '32px',
                                                            backgroundColor: '#FFFFFF',
                                                            borderRadius: '4px',
                                                            boxShadow:
                                                                '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
                                                        }}
                                                    >
                                                        <CancelRoundedIcon
                                                            sx={{ color: '#00000029' }}
                                                            fontSize="small"
                                                        />
                                                    </Box>
                                                )}
                                            </Box>
                                        ) : (
                                            <>
                                                {edit ? (
                                                    <Chip
                                                        key={id}
                                                        avatar={<FilePresentRoundedIcon />}
                                                        label={title}
                                                        actionIcon={
                                                            <IconButton
                                                                sx={{ color: '#00000029' }}
                                                                size="small"
                                                            >
                                                                <DeleteRoundedIcon fontSize={'small'} />
                                                            </IconButton>
                                                        }
                                                        actionHandler={() => {
                                                            onDeleteFile(id);
                                                        }}
                                                    />
                                                ) : (
                                                    <Chip
                                                        key={id}
                                                        label={title}
                                                        avatar={<FilePresentRoundedIcon />}
                                                        onClick={() => handleDownload(file)}
                                                    />
                                                )}
                                            </>
                                        )}
                                    </>
                                );
                            })}
                    </Grid>
                </>
            )}
            {edit && (
                <>
                    <Divider sx={{ width: '100%' }} />
                    <Grid container>
                        <Upload name={'file'} format="*" uploadedFiles={uploadFiles} />
                    </Grid>
                </>
            )}
        </>
    );
};
