import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import {
    AlertTitle,
    alpha,
    Box,
    Checkbox,
    Chip,
    Divider,
    FormControl,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Pagination,
    Tab,
    Tabs,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { Avatar, Button, DateTimePicker, DefaultDrawer, FormField, Input } from 'shared/ui';
import LibraryAddCheckRoundedIcon from '@mui/icons-material/LibraryAddCheckRounded';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import CommentRoundedIcon from '@mui/icons-material/CommentRounded';
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import Card from 'shared/ui/Card/Card';
import { useMetaStorage, useTaskDetailStorage, useUsersStorage } from 'services/StorageAdapter';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import ConnectField from 'shared/ui/ConnectField/ConnectField';
import { normalize } from 'shared/helpers/normalize';
import { LoadingButton } from '@mui/lab';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import FileField from 'widgets/DraggableField/Fields/FileField/FileField';
import { Field } from 'domain/field';
import { TaskData } from 'services/store/tasks/tasks';
import { ActionList } from '../../../../widgets/ActionList';
import FormFieldLabel from '../../../../shared/ui/FormField/FormFieldLabel';
import { Switch } from '../../../../shared/ui';
import { taskCardValidationSchema } from './validation';
import { diff } from 'shared/helpers/differenceObjects';
import { Timeline } from 'widgets/Timeline';
import { useTranslation } from 'react-i18next';
import { MOBILE_QUERY_WIDTH } from 'shared/consts';
import { useTick } from 'shared/hooks/useTick';

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export const FieldsCodes = [
    'title',
    'type',
    'status',
    'deadline',
    'is_overdue',
    'completed_date',
    'parent',
    'subtasks',
    'entity_objects',
    'responsible',
    'author',
    'co_executors',
    'observers',
    'created',
    'modified',
    'created_by',
    'changed_by',
    'description',
    'files',
] as const;

const fields = [
    'type',
    'status',
    'deadline',
    'is_overdue',
    'completed_date',
    'parent',
    'subtasks',
    'entity_objects',
    'responsible',
    'author',
    'co_executors',
    'observers',
    'description',
    'files',
    'created',
    'modified',
    'created_by',
    'changed_by',
];

type FieldsCode = typeof FieldsCodes[number];

export const TaskFields = {
    Name: 'title',
    Director: 'author',
    Observers: 'observers',
    Description: 'description',
    Deadline: 'deadline',
    IsOverdue: 'is_overdue',
    CompletedDate: 'completed_date',
    Parent: 'parent',
    Subtasks: 'subtasks',
    // Completed: '6795843b-0254-490b-b776-9db70dbb5023',
    // CompletedDate: 'a4619384-5988-4bf2-93fe-77f341c002f4',
    // Overdue: '05f8fdb5-1ebc-42a8-b003-e97666168c3',
    TaskType: 'type',
    TaskStatus: 'status',
    CoExecutors: 'co_executors',
    Responsible: 'responsible',
    Created: 'created',
    Modified: 'modified',
    CreatedBy: 'created_by',
    ModifiedBy: 'changed_by',
    CRMObject: 'entity_objects',
    Files: 'files',
} as const;

type TaskListCardProps = {
    onClose: () => void;
    onOpen?: () => void;
    open: boolean;
};

export type ConnectedObjectType = {
    id: string;
    name: string;
    first_name?: string;
    last_name?: string;
    fullname?: string;
    title?: string;
    entity?: string;
    code?: string;
    user?: {
        id: string;
        first_name: string;
        last_name: string;
    };
};

export const taskTypes = [{ task: 'task' }, { call: 'callType' }, { meeting: 'meetingType' }];
export const taskStatus = [
    { new: 'Новая' },
    { in_progress: 'В работе' },
    { on_review: 'На проверке' },
    { done: 'Завершена' },
];

const TaskListCard = ({ onClose, onOpen = () => {}, open: modalOpen }: TaskListCardProps) => {
    const { t } = useTranslation();
    const [params] = useSearchParams();
    const isCreated = Boolean(params.get('task_id'));
    const dateStr = params.get('date')?.replace(' ', '+');
    modalOpen = modalOpen || isCreated;
    const [initialValues, setInitialValues] = useState<any>({});
    const [isCardEdit, setIsCardEdit] = useState(!isCreated);
    const [isEdited, setIsEdited] = useState(false);
    const { setFieldValue, values, resetForm, errors } = useFormik({
        initialValues: initialValues,
        onSubmit: () => {},
        enableReinitialize: true,
        validationSchema: taskCardValidationSchema,
    });
    const { me } = useUsersStorage();
    const { meta } = useMetaStorage();
    const {
        taskData,
        fetchData,
        createTask,
        deleteTask,
        updateTask,
        updateTaskLocal,
        defaultCrmObject,
        setDefaultCrmObject,
    } = useTaskDetailStorage();
    const isMobile = useMediaQuery(MOBILE_QUERY_WIDTH);
    const { tick } = useTick();
    const [isFullView, setIsFullView] = useState<boolean>(false);
    const sortedFields: FieldsCode[] = useMemo(() => {
        const newFields = [...fields];
        newFields.sort((a, b) => FieldsCodes.indexOf(a as FieldsCode) - FieldsCodes.indexOf(b as FieldsCode));
        return newFields as FieldsCode[];
    }, [fields]);
    const oldData = useMemo(() => JSON.parse(JSON.stringify(initialValues)), [initialValues]);

    const [value, setValue] = React.useState(0);
    const {
        palette: {
            text: { secondary },
            action: { active },
        },
    } = useTheme();
    function generateInitialValues() {
        return FieldsCodes.reduce(
            (acc, code) => {
                if (fields.some((f) => f === code)) {
                    const field = fields.find((f) => f === code)!;
                    if (field) {
                        switch (code) {
                            case 'status':
                            case 'type': {
                                const defaultOption =
                                    code === 'type' ? { task: t('task') } : { new: 'Новая' };
                                if (defaultOption) {
                                    const [key] = Object.keys(defaultOption);
                                    return { ...acc, [code]: key };
                                }
                                return { ...acc, [code]: null };
                            }
                            case 'deadline': {
                                return { ...acc, [code]: dateStr ? dayjs(dateStr) : dayjs() };
                            }
                            case 'co_executors':
                            case 'files':
                            case 'observers': {
                                return { ...acc, [code]: [] };
                            }
                            case 'entity_objects': {
                                return { ...acc, [code]: defaultCrmObject };
                            }
                            case 'author': {
                                return {
                                    ...acc,
                                    [code]: {
                                        id: me.employee?.id ?? '',
                                        name: `${me.first_name} ${me.last_name}`,
                                    },
                                };
                            }
                            default: {
                                return { ...acc, [code]: null };
                            }
                        }
                    }
                }
                return acc;
            },
            { title: 'Новая задача', responsible: null, created_by: null, changed_by: null }
        );
    }

    useLayoutEffect(() => {
        setDefaultCrmObject([]);
        updateTaskLocal(generateInitialValues());
        setInitialValues(generateInitialValues());
    }, []);

    useEffect(() => {
        if (!modalOpen) {
            return;
        }
        setIsCardEdit(!isCreated);
        if (!isCreated && fields.length) {
            setInitialValues(generateInitialValues());
            setIsCardEdit(true);
            setIsEdited(false);
        }
    }, [isCreated, fields, modalOpen, defaultCrmObject]);

    useEffect(() => {
        if (!modalOpen) {
            return;
        }
        if (params.get('task_id') && meta.views[0]) {
            if (tick < 1) {
                setInitialValues(generateInitialValues());
            }
            fetchData(params.get('task_id') as string);
        }
    }, [params, meta, fields, tick]);
    useEffect(() => {
        setInitialValues((prev: any) => {
            const clearValues = Object.keys(taskData)
                .filter((key) => key in prev)
                .reduce(
                    (acc, key) => ({
                        ...acc,
                        [key]: key === 'files' && taskData[key] === null ? [] : taskData[key],
                    }),
                    {}
                );
            return { ...initialValues, ...prev, ...clearValues };
        });
    }, [taskData]);

    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelect = (field: string, value: string) => {
        setFieldValueWrapper(field, value);
        handleClose();
    };

    const setFieldValueWrapper = (
        ...args: [field: string, value: any, shouldValidate?: boolean | undefined]
    ) => {
        setIsEdited(true);
        setFieldValue(...args);
    };

    const navigate = useNavigate();
    const [submitting, setSubmitting] = useState(false);

    const handleCreateTask = () => {
        setSubmitting(true);
        const normalizedValues: any = normalize(values);
        const clearValues = Object.keys(normalizedValues).reduce(
            (acc: any, key: keyof typeof normalizedValues) => {
                if (Array.isArray(normalizedValues[key]) && (normalizedValues[key] as Array<any>).length) {
                    acc[key] = normalizedValues[key];
                } else if (
                    !Array.isArray(normalizedValues[key]) &&
                    normalizedValues[key] !== null &&
                    normalizedValues[key] !== undefined
                ) {
                    acc[key] = normalizedValues[key];
                }
                return acc;
            },
            {}
        );
        createTask({ ...clearValues } as TaskData)
            .then((res) => {
                const params = [...new URLSearchParams(window.location.search).entries()].filter(
                    ([paramName]) => paramName !== 'task_id'
                );
                navigate(
                    `./?task_id=${res.id}${
                        params.length ? '&' + params.map((p) => p.join('=')).join('&') : ''
                    }`
                );
            })
            .finally(() => setSubmitting(false));
        setIsEdited(false);
    };

    const handleUpdateTask = (params: any = {}) => {
        const differenceFields = diff({ ...values, ...params }, oldData);
        const normalizedValues = normalize(differenceFields);
        // eslint-disable-next-line
        const { funnel, funnel_stage, ...clearEntityData } = taskData;
        updateTask({
            ...normalizedValues,
            id: clearEntityData.id,
        } as TaskData);
        setInitialValues(values);
        setIsCardEdit(false);
        setIsEdited(false);
    };
    const getSortedFields = (fields: FieldsCode[]) => {
        return fields.map((field) => {
            return (
                <>
                    {field === 'type' ? (
                        <FormField label={t('type')}>
                            <Button
                                id={field}
                                onClick={handleClick}
                                startIcon={<LibraryAddCheckRoundedIcon />}
                                endIcon={<ArrowDropDownIcon />}
                            >
                                {taskTypes.find((opt: any) => values[field] in opt)
                                    ? t(
                                          taskTypes.find((opt: any) => values[field] in opt)![
                                              values[field] as keyof typeof taskTypes[number]
                                          ] as string
                                      )
                                    : ''}
                            </Button>
                            <Menu
                                anchorEl={anchorEl}
                                open={anchorEl?.id === field}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                {taskTypes.map((o) => {
                                    const [key, value] = Object.entries(o)[0];
                                    return (
                                        <MenuItem key={key} onClick={() => handleSelect(field, key)}>
                                            {t(value as string)}
                                        </MenuItem>
                                    );
                                })}
                            </Menu>
                        </FormField>
                    ) : field === 'status' ? (
                        <FormField label={'Статус'}>
                            <Button
                                sx={{
                                    borderRadius: '4px',
                                }}
                                variant={'outlined'}
                                id={field}
                                onClick={handleClick}
                                startIcon={<CircleRoundedIcon />}
                                endIcon={<ArrowDropDownIcon />}
                            >
                                {taskStatus.find((opt) => values[field] in opt)
                                    ? (taskStatus.find((opt) => values[field] in opt)![
                                          values[field] as keyof typeof taskStatus[number]
                                      ] as string)
                                    : ''}
                            </Button>
                            <Menu
                                anchorEl={anchorEl}
                                open={anchorEl?.id === field}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                {taskStatus.map((o) => {
                                    const [key, value] = Object.entries(o)[0];
                                    return (
                                        <MenuItem key={key} onClick={() => handleSelect(field, key)}>
                                            {value as string}
                                        </MenuItem>
                                    );
                                })}
                            </Menu>
                        </FormField>
                    ) : field === 'deadline' ? (
                        <FormField label={'Крайний срок и напоминание'}>
                            {(!isCreated || isCardEdit) && (
                                <DateTimePicker
                                    onChange={(v) => setFieldValueWrapper(field, v)}
                                    value={values[field] ? dayjs(values[field]) : null}
                                />
                            )}
                            {isCreated && !isCardEdit && (
                                <Chip
                                    variant={'outlined'}
                                    icon={<NotificationsRoundedIcon />}
                                    label={
                                        values[field]
                                            ? dayjs(values[field]).format('DD MMMM, HH:mm')
                                            : 'Срок: не указан'
                                    }
                                />
                            )}
                        </FormField>
                    ) : isCreated && field === 'is_overdue' ? (
                        <FormField label={'Просрочена'}>
                            <Checkbox disabled checked={values[field] || false} />
                        </FormField>
                    ) : isCreated && values[field] && field === 'completed_date' ? (
                        <FormField label={'Дата завершения'}>
                            {isCreated && values[field] && (
                                <Chip
                                    variant={'outlined'}
                                    icon={<NotificationsRoundedIcon />}
                                    label={dayjs(values[field]).format('DD MMMM, HH:mm')}
                                />
                            )}
                        </FormField>
                    ) : field === 'parent' ? (
                        <ConnectField
                            field={field}
                            setValue={setFieldValueWrapper}
                            value={values[field]}
                            items={values[field]}
                            type={'fk'}
                            label={'Родительская задача'}
                            code={'tasks'}
                            isEdit={!isCreated || isCardEdit}
                        />
                    ) : field === 'subtasks' ? (
                        <ConnectField
                            field={field}
                            setValue={setFieldValueWrapper}
                            value={values[field]}
                            items={values[field]}
                            type={'m2m'}
                            label={'Подзадачи'}
                            code={'tasks'}
                        />
                    ) : field === 'entity_objects' ? (
                        <ConnectField
                            items={values[field]}
                            label={'Объекты CRM'}
                            setValue={setFieldValueWrapper}
                            value={values[field]}
                            field={field}
                            type={'uuid_many'}
                            code={'uuid'}
                            isEdit={!isCreated || isCardEdit}
                        />
                    ) : field === 'author' ? (
                        <ConnectField
                            error={errors['author'] as string}
                            required
                            items={values[field]}
                            label={'Постановщик'}
                            setValue={setFieldValueWrapper}
                            value={values[field]}
                            field={field}
                            type={'fk_employee'}
                            code={'department-employees'}
                            isEdit={!isCreated || isCardEdit}
                        />
                    ) : field === 'co_executors' ? (
                        <ConnectField
                            items={values[field]}
                            label={'Соисполнители'}
                            setValue={setFieldValueWrapper}
                            value={values[field]}
                            field={field}
                            type={'m2m_employee'}
                            code={'department-employees'}
                            isEdit={!isCreated || isCardEdit}
                        />
                    ) : field === 'observers' ? (
                        <ConnectField
                            items={values[field]}
                            label={'Наблюдатели'}
                            setValue={setFieldValueWrapper}
                            value={values[field]}
                            field={field}
                            type={'m2m_employee'}
                            code={'department-employees'}
                            isEdit={!isCreated || isCardEdit}
                        />
                    ) : field === 'description' ? (
                        <FormField label={'Описание'}>
                            {isCreated && !isCardEdit ? (
                                <Typography sx={{ whiteSpace: 'pre-line' }}>{values[field]}</Typography>
                            ) : (
                                <Input
                                    value={values[field]}
                                    onChange={(e) => setFieldValueWrapper(field, e.target.value)}
                                    placeholder={'Напишите что нужно сделать'}
                                    fullWidth
                                    multiline
                                />
                            )}
                        </FormField>
                    ) : field === 'files' ? (
                        <FormField label={'Приложения'}>
                            <FileField
                                field={{ id: field } as Field}
                                value={values[field]}
                                setValue={setFieldValueWrapper}
                                edit={isCardEdit}
                            />
                        </FormField>
                    ) : field === 'responsible' ? (
                        <ConnectField
                            error={errors['responsible'] as string}
                            items={values[field]}
                            label={t('responsible')}
                            required
                            setValue={setFieldValueWrapper}
                            value={values[field]}
                            field={field}
                            type={'fk_employee'}
                            code={'department-employees'}
                            isEdit={!isCreated || isCardEdit}
                        />
                    ) : field === 'created' && isCreated ? (
                        <FormField label={t('createdAt')}>
                            {isCreated && values[field] && (
                                <Chip
                                    variant={'outlined'}
                                    icon={<NotificationsRoundedIcon />}
                                    label={dayjs(values[field]).format('DD MMMM, HH:mm')}
                                />
                            )}
                        </FormField>
                    ) : field === 'modified' && isCreated ? (
                        <FormField label={t('modifiedAt')}>
                            {isCreated && values[field] && (
                                <Chip
                                    variant={'outlined'}
                                    icon={<NotificationsRoundedIcon />}
                                    label={dayjs(values[field]).format('DD MMMM, HH:mm')}
                                />
                            )}
                        </FormField>
                    ) : field === 'created_by' && isCreated ? (
                        <ConnectField
                            items={values[field]}
                            label={'Создан'}
                            setValue={setFieldValueWrapper}
                            value={values[field]}
                            field={TaskFields.CreatedBy}
                            type={'fk_employee'}
                            code={'department-employees'}
                            isEdit={isCardEdit}
                        />
                    ) : field === 'changed_by' && isCreated ? (
                        <ConnectField
                            items={values[field]}
                            label={'Изменён'}
                            setValue={setFieldValueWrapper}
                            value={values[field]}
                            field={TaskFields.ModifiedBy}
                            type={'fk_employee'}
                            code={'department-employees'}
                            isEdit={isCardEdit}
                        />
                    ) : (
                        <></>
                    )}
                </>
            );
        });
    };
    const getContent = () => (
        <>
            <Box
                sx={{
                    width: !isMobile ? '600px' : '100%',
                    padding: !isMobile ? '25px 29px' : null,
                }}
            >
                <Grid container flexDirection={'column'} gap={'24px'}>
                    <Card
                        sx={{
                            border: isMobile ? 'none' : '1px solid #0000001f',
                            ['& > div']: { padding: isMobile ? 0 : '20px 24px' },
                        }}
                    >
                        <Card.Head
                            editable
                            onTitleChange={(title) => setFieldValueWrapper('title', title)}
                            title={values['title']}
                            controls={
                                isCreated
                                    ? [
                                          <Button
                                              onClick={() => setIsCardEdit((prev) => !prev)}
                                              key={'change'}
                                              variant={'text'}
                                              color={'secondary'}
                                          >
                                              {isCardEdit ? 'Не изменять' : t('edit')}
                                          </Button>,
                                      ]
                                    : []
                            }
                        />
                        {isMobile ? (
                            <>
                                {getSortedFields(['responsible', 'deadline', 'description'])}
                                <FormControl
                                    fullWidth
                                    variant={'standard'}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <FormFieldLabel>Полный вид</FormFieldLabel>
                                    <Switch
                                        labelSx={{ mr: 0 }}
                                        value={isFullView}
                                        checked={isFullView}
                                        onChange={() => setIsFullView((prev) => !prev)}
                                    />
                                </FormControl>
                                {isFullView
                                    ? getSortedFields(
                                          sortedFields.filter(
                                              (s) => !['responsible', 'deadline', 'description'].includes(s)
                                          )
                                      )
                                    : null}
                            </>
                        ) : (
                            getSortedFields(sortedFields)
                        )}
                    </Card>
                    {/*<Card>*/}
                    {/*    <Card.Head*/}
                    {/*        title={'Приложения'}*/}
                    {/*        controls={[*/}
                    {/*            <Button key={'change'} variant={'text'}>*/}
                    {/*                Изменить*/}
                    {/*            </Button>,*/}
                    {/*        ]}*/}
                    {/*        hint={'test'}*/}
                    {/*        editable*/}
                    {/*    />*/}
                    {/*    <FilePreview*/}
                    {/*        label={'test'}*/}
                    {/*        link={'https://place-hold.it/300x500'}*/}
                    {/*        type={'image'}*/}
                    {/*        size={'443'}*/}
                    {/*    />*/}
                    {/*    <Button variant={'text'} endIcon={<AddRoundedIcon />}>*/}
                    {/*        Добавить*/}
                    {/*    </Button>*/}
                    {/*</Card>*/}
                    {/*<Card>*/}
                    {/*    <Card.Head*/}
                    {/*        title={'Привязка к объкту'}*/}
                    {/*        controls={[*/}
                    {/*            <Button key={'change'} variant={'text'}>*/}
                    {/*                Изменить*/}
                    {/*            </Button>,*/}
                    {/*        ]}*/}
                    {/*        hint={'test'}*/}
                    {/*        editable*/}
                    {/*    />*/}
                    {/*    <Button variant={'text'} endIcon={<AddRoundedIcon />}>*/}
                    {/*        Добавить*/}
                    {/*    </Button>*/}
                    {/*</Card>*/}
                    {/*<Card>*/}
                    {/*    <Card.Head*/}
                    {/*        title={'Подзадачи'}*/}
                    {/*        controls={[*/}
                    {/*            <Button key={'change'} variant={'text'}>*/}
                    {/*                Изменить*/}
                    {/*            </Button>,*/}
                    {/*        ]}*/}
                    {/*        hint={'test'}*/}
                    {/*        editable*/}
                    {/*    />*/}
                    {/*    <Button variant={'text'} endIcon={<AddRoundedIcon />}>*/}
                    {/*        Добавить*/}
                    {/*    </Button>*/}
                    {/*</Card>*/}
                </Grid>
                <Box sx={{ width: '100%' }} mt={'32px'}>
                    {isCreated && false && (
                        <>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs variant={'fullWidth'} value={value} onChange={handleChange}>
                                    <Tab
                                        icon={<HistoryRoundedIcon />}
                                        iconPosition={'start'}
                                        label="История"
                                        sx={{
                                            minHeight: '50px',
                                        }}
                                        {...a11yProps(0)}
                                    />
                                    <Tab
                                        icon={<CommentRoundedIcon />}
                                        iconPosition={'start'}
                                        label="Комментарии"
                                        sx={{
                                            minHeight: '50px',
                                        }}
                                        {...a11yProps(1)}
                                    />
                                </Tabs>
                            </Box>
                            <TabPanel value={value} index={0}>
                                <Card>
                                    <Grid container flexWrap={'nowrap'} gap={'16px'}>
                                        <Grid container item gap={'8px'} width={'auto'} alignItems={'center'}>
                                            <Avatar variant="rounded" size={'small'}>
                                                <Typography fontSize={'inherit'} color={'white'}>
                                                    AB
                                                </Typography>
                                            </Avatar>
                                            <Typography variant={'subtitle2'} color={'primary'}>
                                                Дархан Султанов
                                            </Typography>
                                        </Grid>
                                        <Typography
                                            variant={'subtitle1'}
                                            sx={{
                                                color: alpha(secondary, 0.6),
                                            }}
                                        >
                                            01.11.2022 • 14:00
                                        </Typography>
                                    </Grid>
                                    <Grid mt={'8px'} mb={'12px'}>
                                        <Typography variant={'body1'}>Добавлен комментарий</Typography>
                                    </Grid>
                                    <Card.Footer
                                        leftSide={
                                            <AlertTitle sx={{ marginBottom: '0' }}>{t('comment')}</AlertTitle>
                                        }
                                    />
                                </Card>
                                <Grid py={'12px'} mt={'16px'} mb={'12px'}>
                                    <Pagination color={'primary'} count={10} shape="rounded" />
                                </Grid>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <Grid
                                    container
                                    gap={'14px'}
                                    flexWrap={'nowrap'}
                                    mb={'32px'}
                                    alignItems={'center'}
                                >
                                    <Grid
                                        container
                                        flexWrap={'nowrap'}
                                        flexGrow={1}
                                        gap={'8px'}
                                        alignItems={'center'}
                                    >
                                        <Avatar variant={'rounded'} size={'small'}>
                                            AD
                                        </Avatar>
                                        <Input fullWidth placeholder={'Добавить комментарий'} />
                                    </Grid>
                                    <Grid>
                                        <IconButton size={'small'} color={'primary'}>
                                            <SendRoundedIcon fontSize={'inherit'} />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                <Card>
                                    <Grid container flexWrap={'nowrap'}>
                                        <Grid container item flexGrow={1} flexWrap={'nowrap'} gap={'16px'}>
                                            <Grid
                                                container
                                                item
                                                gap={'8px'}
                                                width={'auto'}
                                                alignItems={'center'}
                                            >
                                                <Avatar variant="rounded" size={'small'}>
                                                    <Typography fontSize={'inherit'} color={'white'}>
                                                        AB
                                                    </Typography>
                                                </Avatar>
                                                <Typography variant={'subtitle2'} color={'primary'}>
                                                    Дархан Султанов
                                                </Typography>
                                            </Grid>
                                            <Typography
                                                variant={'subtitle1'}
                                                sx={{
                                                    color: alpha(secondary, 0.6),
                                                }}
                                            >
                                                01.11.2022 • 14:00
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <IconButton onClick={handleClick} size={'small'}>
                                                <MoreHorizRoundedIcon fontSize={'inherit'} />
                                            </IconButton>
                                            <Menu
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}
                                                MenuListProps={{
                                                    'aria-labelledby': 'basic-button',
                                                }}
                                            >
                                                <MenuItem>{t('delete')}</MenuItem>
                                                <MenuItem>{t('edit')}</MenuItem>
                                                <MenuItem>Закрепить</MenuItem>
                                            </Menu>
                                        </Grid>
                                    </Grid>
                                    <Grid mt={'16px'} mb={'24px'}>
                                        <Typography variant={'body1'}>
                                            Нужно поторопиться. Если сделаешь раньше срока — получишь печеньки
                                        </Typography>
                                    </Grid>
                                    <Card.Footer
                                        leftSide={
                                            <Grid
                                                container
                                                item
                                                width={'auto'}
                                                alignItems={'center'}
                                                gap={'8px'}
                                            >
                                                <CommentRoundedIcon fontSize={'small'} color={'primary'} />
                                                <AlertTitle sx={{ marginBottom: '0' }}>
                                                    {t('comment')}
                                                </AlertTitle>
                                            </Grid>
                                        }
                                        rightSide={
                                            <Button
                                                variant={'text'}
                                                sx={{
                                                    color: alpha(active, 0.54),
                                                }}
                                            >
                                                Ответить на комментарий
                                            </Button>
                                        }
                                    />
                                </Card>
                                <Grid py={'12px'} mt={'16px'} mb={'12px'}>
                                    <Pagination color={'primary'} count={10} shape="rounded" />
                                </Grid>
                            </TabPanel>
                        </>
                    )}
                    {!!params.get('task_id') && (
                        <Timeline onTaskOpen={() => {}} objectId={params.get('task_id') as string} />
                    )}
                    <Divider />
                    <Grid container justifyContent={'flex-end'} mt={'12px'} gap={'8px'}>
                        <LoadingButton
                            loading={submitting}
                            onClick={() => {
                                if (!isCreated) {
                                    handleCreateTask();
                                } else if (isCreated && isEdited) {
                                    handleUpdateTask();
                                } else {
                                    const idx = taskStatus.findIndex(
                                        (status) => Object.keys(status)[0] === values.status
                                    );
                                    const newStatus = Object.keys(taskStatus[(idx + 1) % 4])[0];
                                    handleUpdateTask({ status: newStatus });
                                }
                            }}
                            variant={'contained'}
                        >
                            {isCreated
                                ? isEdited
                                    ? t('saveChange')
                                    : values.status === 'new'
                                    ? 'Начать работу'
                                    : values.status === 'in_progress'
                                    ? 'Отправить на проверку'
                                    : values.status === 'on_review'
                                    ? 'Завершить задачу'
                                    : 'Вернуть в работу'
                                : 'Создать задачу'}
                        </LoadingButton>
                        <LoadingButton
                            loading={submitting}
                            onClick={() => {
                                if (!isCreated) {
                                    onClose();
                                } else if (isCreated && isEdited) {
                                    setIsEdited(false);
                                    setIsCardEdit(false);
                                    resetForm();
                                } else {
                                    setSubmitting(true);
                                    deleteTask({ id: params.get('task_id') as string }).finally(() => {
                                        onClose();
                                        setSubmitting(false);
                                    });
                                }
                            }}
                            variant={'outlined'}
                        >
                            {isCreated ? (isCardEdit ? t('cancelChange') : t('delete')) : 'Отменить'}
                        </LoadingButton>
                    </Grid>
                </Box>
            </Box>
        </>
    );
    return isMobile ? (
        <ActionList onOpen={onOpen} onClose={onClose} open={modalOpen} maxDrawerHeightPercent={'100%'}>
            {getContent()}
        </ActionList>
    ) : (
        <DefaultDrawer
            open={modalOpen}
            onClose={() => {
                onClose();
                setIsEdited(false);
                setIsCardEdit(false);
            }}
        >
            {getContent()}
        </DefaultDrawer>
    );
};

export default TaskListCard;
