export type TCard = {
    id: string;
    name: string;
    color: string;
    order: number;
    funnel: string;
    results: any[];
};

export type TStages = {
    id: string;
    name: string;
    color: string;
    order: number;
    funnel: string;
    results?: any[];
    state: string;
};

export type TFunnels = {
    id: string;
    name: string;
    entity: string;
    type: string;
    archived: boolean;
    stages: Array<TStages>;
};

export type TBulkStage = {
    id: string;
    order: number;
    funnel: string;
    name: string;
    color: string;
};

export const states = [
    { name: 'В работе', type: 'in_progress' },
    { name: 'Успешные', type: 'success' },
    { name: 'Провальные', type: 'failed' },
    { name: 'Забраковано', type: 'invalid' },
];

export const stateObj: any = {
    in_progress: 'В работе',
    success: 'Успешные',
    failed: 'Провальные',
    invalid: 'Забраковано',
};
