import { Box, Card, CardContent, Divider, Grid, IconButton, Typography, useTheme } from '@mui/material';
import { Button, CheckBox, EditTitle, Input, Label, Select } from 'shared/ui';
import { useEffect, useMemo, useState } from 'react';
import { FieldValueType, FieldValueTypes, TFieldsMeta } from 'domain/field';
import {
    // useConnectEntityModal,
    useFieldsStorage,
    useFunnelsListStorage,
    useMetaStorage,
    useNotification,
} from 'services/StorageAdapter';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Reorder, useDragControls, useMotionValue } from 'framer-motion';
import { useRaisedShadow } from 'shared/hooks/useRaisedShadow';
// import AddRoundedIcon from '@mui/icons-material/AddRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import DraggableItemWrapper from 'shared/ui/DraggableWrapper/DraggableItemWrapper';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import uuid from 'react-uuid';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import RequiredStagesModal from 'pages/Constructor/RequiredStagesModal/RequiredStagesModal';

export const FieldValueTypesArray = [
    {
        id: FieldValueTypes.Int,
        text: 'Целое число',
    },
    {
        id: FieldValueTypes.Float,
        text: 'Число',
    },
    {
        id: FieldValueTypes.Bool,
        text: 'Да/нет',
    },
    {
        id: FieldValueTypes.Select,
        text: 'Список',
    },
    {
        id: FieldValueTypes.MultiSelect,
        text: 'Множественный список',
    },
    {
        id: FieldValueTypes.Str,
        text: 'Строка',
    },
    {
        id: FieldValueTypes.Datetime,
        text: 'Дата + Время',
    },
    {
        id: FieldValueTypes.Date,
        text: 'Дата',
    },
    {
        id: FieldValueTypes.Time,
        text: 'Время',
    },
    {
        id: FieldValueTypes.Link,
        text: 'Ссылка',
    },
    {
        id: FieldValueTypes.Uuid,
        text: 'Привязка к любому объекту',
    },
    {
        id: FieldValueTypes.Fk,
        text: 'Привязка к другому объекту',
    },
    {
        id: FieldValueTypes.M2m,
        text: 'Привязка к другим объектам',
    },
    {
        id: FieldValueTypes.FkEmployee,
        text: 'Привязка к сотруднику',
    },
    {
        id: FieldValueTypes.M2mEmployee,
        text: 'Привязка к сотрудникам',
    },
    {
        id: FieldValueTypes.FkDepartment,
        text: 'Привязка к департаменту',
    },
    {
        id: FieldValueTypes.M2mDepartment,
        text: 'Привязка к департаментам',
    },
    {
        id: FieldValueTypes.Phone,
        text: 'Телефон',
    },
    {
        id: FieldValueTypes.Email,
        text: 'Email',
    },
    {
        id: FieldValueTypes.File,
        text: 'Файл',
    },
];

const initialData = {
    id: '',
    name: '',
    description: '',
    code: '',
    value_type: '',
    type: '',
    placeholder: '',
    settings: {
        options: [],
        positive: true,
    },
    is_deleted: false,
    deleted_at: '',
    help_text: '',
    entity: '',
    group: '',
    departments: [],
    employees: [],
    roles: [],
    required_fields: [],
};

const ConstructorCreateField = () => {
    const { t } = useTranslation();
    const { t: constructorT } = useTranslation('entityConstructor');
    const navigate = useNavigate();
    const { notify } = useNotification();
    const { nature, id } = useParams();
    const [params] = useSearchParams();
    const { bulkUpdateStage } = useFunnelsListStorage();
    const [requiredStagesModalOpen, setRequiredStagesModalOpen] = useState(false);
    const { funnelsList } = useFunnelsListStorage();
    const [requiredStages, setRequiredStages] = useState<Array<any>>([]);
    const [isCreating, setIsCreating] = useState(false);
    const { fetchMetas, metas } = useMetaStorage();
    const { createMetaField, getField, getMetaField, createEntityField, updateField, updateMetaField } =
        useFieldsStorage();
    // const { open } = useConnectEntityModal();
    const [data, setData] = useState<TFieldsMeta>(initialData);
    const {
        palette: {
            background: { paper },
        },
    } = useTheme();

    const stages = useMemo(() => {
        if (!data.entity) {
            return [];
        }
        return funnelsList.results.map((funnel) => funnel.stagesDefault).flat();
    }, [funnelsList.results, data.entity]);

    const deletedStages = useMemo(() => {
        if (!data.entity) {
            return [];
        }
        const deletedStagesArr = data.required_fields.filter(({ uuid }) => {
            return !requiredStages.some((s: any) => s.id === uuid);
        });
        return stages.filter(({ id }) => deletedStagesArr.some(({ uuid }) => uuid === id));
    }, [requiredStages, data.required_fields, data.entity]);

    useEffect(() => {
        if (!data.entity) {
            return;
        }
        setRequiredStages(data.required_fields.map(({ uuid, name }) => ({ id: uuid, name })));
    }, [data.required_fields, data.entity]);

    useEffect(() => {
        fetchMetas({ limit: 10000 });
    }, []);

    useEffect(() => {
        setData(initialData);
    }, []);

    useEffect(() => {
        if (!id) {
            return;
        }
        if (params.get('type') === 'meta') {
            getMetaField(id as string).then((res) => setData(res));
        } else {
            getField(id as string).then((res) => setData(res));
        }
    }, [id]);

    const handleChange = (type: string, content: any, settings?: boolean) => {
        settings
            ? setData({ ...data, settings: { ...data.settings, [type]: content } })
            : setData({ ...data, [type]: content });
    };

    const onCreate = () => {
        const { code, settings, value_type, help_text, name, placeholder, group } = data;
        setIsCreating(true);
        createMetaField({
            code,
            settings: {
                ...settings,
                many:
                    [
                        FieldValueTypes.M2m,
                        FieldValueTypes.M2mEmployee,
                        FieldValueTypes.M2mDepartment,
                        FieldValueTypes.MultiSelect,
                        FieldValueTypes.File,
                    ].includes(value_type as any) || settings.many,
            },
            value_type,
            help_text,
            name,
            placeholder,
        })
            .then((res) => {
                if (nature && group) {
                    createEntityField({
                        name,
                        entity: nature,
                        group,
                        field: res.id,
                    }).then((entityField) => {
                        bulkUpdateStage(
                            requiredStages.map((stage: any) => {
                                return {
                                    ...stage,
                                    required_fields: [...stage.required_fields, entityField.id],
                                };
                            })
                        );
                    });
                }
                return res;
            })
            .then((res) => {
                notify({ message: constructorT('field.createMessage', { name: res.name }) });
                navigate(`/constructor/field/${res.id}/?type=${params.get('type') || 'meta'}`);
                setIsCreating(false);
            })
            .catch((err) => {
                const errorCode: string = err.response.data.code;
                const nonFieldErrors: string[] = err.response.data.non_field_errors;
                setIsCreating(false);
                if (errorCode) {
                    notify({ message: errorCode });
                }

                if (nonFieldErrors.length) {
                    notify({ message: nonFieldErrors.join('\r\n') });
                } else {
                    notify({ message: t('somethingWentWrong') });
                }
            });
    };
    const onSave = () => {
        bulkUpdateStage([
            ...requiredStages.map(({ id: stageId }) => {
                const stage = stages.find((stage) => stage.id === stageId);
                return {
                    id: stage.id,
                    required_fields: [...stage.required_fields, id],
                };
            }),
            ...deletedStages.map((stage: any) => {
                return {
                    id: stage.id,
                    required_fields: stage.required_fields.filter((uuid: string) => uuid !== id),
                };
            }),
        ]);
        const { code, settings, value_type, help_text, name, placeholder, group } = data;
        if (data.entity) {
            updateField({
                id: id as string,
                params: { code, settings, value_type, help_text, name, placeholder, group },
            })
                .then((res) => {
                    notify({ message: constructorT('field.updateMessage', { name: res.name }) });
                    getField(id as string).then((res) => setData(res));
                })
                .catch((err) => {
                    const errorCode: string = err.response.data.code;
                    const nonFieldErrors: string[] = err.response.data.non_field_errors;
                    if (errorCode) {
                        notify({ message: errorCode });
                    }

                    if (nonFieldErrors.length) {
                        notify({ message: nonFieldErrors.join('\r\n') });
                    } else {
                        notify({ message: t('somethingWentWrong') });
                    }
                });
        } else {
            updateMetaField({
                id: id as string,
                params: { code, settings, value_type, help_text, name, placeholder },
            })
                .then((res) => {
                    notify({ message: constructorT('field.updateMessage', { name: res.name }) });
                    getMetaField(id as string).then((res) => setData(res));
                })
                .catch((err) => {
                    const errorCode: string = err.response.data.code;
                    const nonFieldErrors: string[] = err.response.data.non_field_errors;
                    if (errorCode) {
                        notify({ message: errorCode });
                    }

                    if (nonFieldErrors.length) {
                        notify({ message: nonFieldErrors.join('\r\n') });
                    } else {
                        notify({ message: t('somethingWentWrong') });
                    }
                });
        }
    };

    const handleReorderGroups = (newOrder: Array<Record<string, string>>) => {
        handleChange('options', newOrder, true);
    };

    // const handleChangeSettings = (type: string, value: string[]) => {
    //     updateField({
    //         id: id as string,
    //         params: { [type]: value },
    //     })
    //         .then((res) => {
    //             notify({ message: constructorT('field.updateMessage', { name: res.name }) });
    //             getField(id as string).then((res) => setData(res));
    //         })
    //         .catch((err) => {
    //             const errorCode: string = err.response.data.code;
    //             const nonFieldErrors: string[] = err.response.data.non_field_errors;
    //             if (errorCode) {
    //                 notify({ message: errorCode });
    //             }
    //
    //             if (nonFieldErrors.length) {
    //                 notify({ message: nonFieldErrors.join('\r\n') });
    //             } else {
    //                 notify({ message: t('somethingWentWrong') });
    //             }
    //         });
    // };

    const isDisabled = () => {
        if (data.name && data.code && data.help_text) {
            if (data.value_type === FieldValueTypes.Fk || data.value_type === FieldValueTypes.M2m) {
                if ((data.settings.entity?.length || '') > 0) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return false;
            }
        }

        return true;
    };

    useEffect(() => {
        if (!id) {
            setData((prev) => ({ ...prev, settings: initialData.settings }));
        }
    }, [data.value_type]);
    return (
        <>
            <>
                <Box mb={10}>
                    <Grid mb={3} container alignItems={'center'} spacing={2}>
                        <Grid item xs={8} display="flex" alignItems="center">
                            <EditTitle
                                title={data.name}
                                onSave={(text) => handleChange('name', text)}
                                placeholder={constructorT('field.new')}
                                required
                            />
                        </Grid>
                    </Grid>

                    <Divider variant="fullWidth" />

                    <Card sx={{ width: '100%', mt: 3 }}>
                        <CardContent>
                            <Grid container gap={3}>
                                <Grid item xs={12}>
                                    <Typography variant="h6">{t('basicSettings')}</Typography>
                                    <Divider variant="fullWidth" sx={{ mt: 3 }} />
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid item gap={1} xl={6} lg={8} md={10} xs={12}>
                                        <Label
                                            label={constructorT('field.code.label')}
                                            isRequired
                                            title={constructorT('field.code.title')}
                                        />
                                        <Grid item xs={12}>
                                            <Input
                                                disabled={!!id}
                                                fullWidth
                                                placeholder={constructorT('field.code.placeholder')}
                                                onChange={(e) => {
                                                    let value = e.target.value;
                                                    value = value.replace(/[^a-z]/, '');
                                                    handleChange('code', value);
                                                }}
                                                value={data.code}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid item gap={1} xl={6} lg={8} md={10} xs={12}>
                                        <Card sx={{ width: '100%' }}>
                                            <CardContent>
                                                <Grid container gap={3}>
                                                    <Grid item xs={12} gap={1}>
                                                        <Label
                                                            label={constructorT('field.type.label')}
                                                            title={constructorT('field.type.title')}
                                                        />
                                                        <Grid item xs={12}>
                                                            <Select
                                                                disabled={!!id}
                                                                fullWidth
                                                                size="small"
                                                                items={FieldValueTypesArray}
                                                                placeholder={constructorT(
                                                                    'field.type.placeholder'
                                                                )}
                                                                onChange={(e) => {
                                                                    handleChange(
                                                                        'value_type',
                                                                        e.target.value as FieldValueType
                                                                    );
                                                                    setData((prev) => ({
                                                                        ...prev,
                                                                        settings: { ...initialData.settings },
                                                                    }));
                                                                }}
                                                                value={data.value_type}
                                                            />
                                                        </Grid>
                                                    </Grid>

                                                    {(data.value_type === FieldValueTypes.Select ||
                                                        data.value_type === FieldValueTypes.MultiSelect) && (
                                                        <Card sx={{ width: '100%' }}>
                                                            <CardContent>
                                                                <Grid container>
                                                                    <Grid item xs={12}>
                                                                        <Label
                                                                            label={constructorT(
                                                                                'field.listValues'
                                                                            )}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <Reorder.Group
                                                                            onReorder={handleReorderGroups}
                                                                            values={
                                                                                data.settings
                                                                                    .options as Array<
                                                                                    Record<string, string>
                                                                                >
                                                                            }
                                                                        >
                                                                            {data.settings.options?.map(
                                                                                (option, index) => {
                                                                                    const [key, value] =
                                                                                        Object.entries(
                                                                                            option
                                                                                        )[0];
                                                                                    return (
                                                                                        <SelectOption
                                                                                            key={key}
                                                                                            reorderValue={
                                                                                                option
                                                                                            }
                                                                                            value={value}
                                                                                            data={data}
                                                                                            index={index}
                                                                                            handleChange={
                                                                                                handleChange
                                                                                            }
                                                                                            valueKey={key}
                                                                                        />
                                                                                    );
                                                                                }
                                                                            )}
                                                                        </Reorder.Group>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <Button
                                                                            onClick={() => {
                                                                                handleChange(
                                                                                    'options',
                                                                                    [
                                                                                        ...(data.settings
                                                                                            .options || []),
                                                                                        { [uuid()]: '' },
                                                                                    ],
                                                                                    true
                                                                                );
                                                                            }}
                                                                            variant="text"
                                                                            startIcon={
                                                                                <AddCircleRoundedIcon />
                                                                            }
                                                                        >
                                                                            {t('add')}
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </CardContent>
                                                        </Card>
                                                    )}
                                                    {data.value_type === FieldValueTypes.Select && (
                                                        <Grid item xs={12} gap={1}>
                                                            <Label
                                                                label={constructorT(
                                                                    'field.selectDefaultValue'
                                                                )}
                                                            />
                                                            <Grid item xs={12}>
                                                                <Select
                                                                    fullWidth
                                                                    size="small"
                                                                    defaultValue={'none'}
                                                                    items={[
                                                                        { id: 'none', text: t('notChosen') },
                                                                        ...(data.settings.options?.map(
                                                                            (item) => {
                                                                                const [key, value] =
                                                                                    Object.entries(item)[0];
                                                                                return {
                                                                                    id: key,
                                                                                    text: value,
                                                                                };
                                                                            }
                                                                        ) || []),
                                                                    ]}
                                                                    placeholder={constructorT(
                                                                        'field.type.placeholder'
                                                                    )}
                                                                    onChange={(e) => {
                                                                        if (e.target.value === 'none') {
                                                                            handleChange(
                                                                                'default_value',
                                                                                null,
                                                                                true
                                                                            );
                                                                        } else {
                                                                            handleChange(
                                                                                'default_value',
                                                                                e.target.value,
                                                                                true
                                                                            );
                                                                        }
                                                                    }}
                                                                    value={
                                                                        data.settings.default_value || 'none'
                                                                    }
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    )}
                                                    {data.value_type === FieldValueTypes.Bool && (
                                                        <Grid item xs={12}>
                                                            <Grid item gap={1} xl={6} lg={8} md={10} xs={12}>
                                                                <Label
                                                                    label={constructorT(
                                                                        'field.defaultChecked.label'
                                                                    )}
                                                                    title={constructorT(
                                                                        'field.defaultChecked.title'
                                                                    )}
                                                                />
                                                                <Grid item xs={12}>
                                                                    <CheckBox
                                                                        checked={
                                                                            !!data.settings.default_value ||
                                                                            false
                                                                        }
                                                                        onChange={(_, checked) => {
                                                                            handleChange(
                                                                                'default_value',
                                                                                checked,
                                                                                true
                                                                            );
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    )}

                                                    {(data.value_type === FieldValueTypes.Int ||
                                                        data.value_type === FieldValueTypes.Float) && (
                                                        <Grid item xs={12}>
                                                            <Grid item gap={1} xl={6} lg={8} md={10} xs={12}>
                                                                <Label
                                                                    label={constructorT(
                                                                        'field.negativeValues.label'
                                                                    )}
                                                                    title={constructorT(
                                                                        'field.negativeValues.title'
                                                                    )}
                                                                />
                                                                <Grid item xs={12}>
                                                                    <CheckBox
                                                                        disabled={!!id}
                                                                        onChange={(_, checked) =>
                                                                            handleChange(
                                                                                'positive',
                                                                                !checked,
                                                                                true
                                                                            )
                                                                        }
                                                                        value={data.settings.positive}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    )}

                                                    {/*{(data.value_type === FieldValueTypes.Select ||*/}
                                                    {/*    data.value_type === FieldValueTypes.MultiSelect) && (*/}
                                                    {/*    <Grid item xs={12}>*/}
                                                    {/*        <Grid item gap={1} xl={6} lg={8} md={10} xs={12}>*/}
                                                    {/*            <Label*/}
                                                    {/*                label={constructorT(*/}
                                                    {/*                    'field.emptyValues.label'*/}
                                                    {/*                )}*/}
                                                    {/*                title={constructorT(*/}
                                                    {/*                    'field.emptyValues.title'*/}
                                                    {/*                )}*/}
                                                    {/*            />*/}
                                                    {/*            <Grid item xs={12}>*/}
                                                    {/*                <CheckBox disabled={!!id} />*/}
                                                    {/*            </Grid>*/}
                                                    {/*        </Grid>*/}
                                                    {/*    </Grid>*/}
                                                    {/*)}*/}

                                                    {data.value_type === FieldValueTypes.Str && (
                                                        <Grid item xs={12} gap={1}>
                                                            <Label
                                                                label={constructorT(
                                                                    'field.characterLimit.label'
                                                                )}
                                                                title={constructorT(
                                                                    'field.characterLimit.title'
                                                                )}
                                                            />
                                                            <Grid item xs={12}>
                                                                <Input
                                                                    disabled={!!id}
                                                                    fullWidth
                                                                    placeholder="0"
                                                                    type="number"
                                                                    onChange={(e) =>
                                                                        handleChange(
                                                                            'max',
                                                                            e.target.value,
                                                                            true
                                                                        )
                                                                    }
                                                                    value={data.settings.max}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    )}

                                                    {data.value_type === FieldValueTypes.Str && (
                                                        <Grid item xs={12} gap={1}>
                                                            <Label
                                                                label={constructorT(
                                                                    'field.numberOfLines.label'
                                                                )}
                                                                title={constructorT(
                                                                    'field.numberOfLines.title'
                                                                )}
                                                            />
                                                            <Grid item xs={12}>
                                                                <Input
                                                                    disabled={!!id}
                                                                    fullWidth
                                                                    placeholder="0"
                                                                    type="number"
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    )}

                                                    {(data.value_type === FieldValueTypes.Int ||
                                                        data.value_type === FieldValueTypes.Float ||
                                                        data.value_type === FieldValueTypes.M2m ||
                                                        data.value_type === FieldValueTypes.Str) && (
                                                        <Grid item xs={12}>
                                                            <Divider variant="fullWidth" />
                                                        </Grid>
                                                    )}

                                                    {(data.value_type === FieldValueTypes.Int ||
                                                        data.value_type === FieldValueTypes.Float ||
                                                        data.value_type === FieldValueTypes.Str) && (
                                                        <Grid item xs={12} gap={1}>
                                                            <Label
                                                                label={constructorT(
                                                                    'field.defaultValue.label'
                                                                )}
                                                                title={constructorT(
                                                                    'field.defaultValue.title'
                                                                )}
                                                            />
                                                            <Grid item xs={12}>
                                                                <Input
                                                                    value={
                                                                        data.settings.default_value as string
                                                                    }
                                                                    onChange={(e) =>
                                                                        handleChange(
                                                                            'default_value',
                                                                            e.target.value,
                                                                            true
                                                                        )
                                                                    }
                                                                    fullWidth
                                                                    placeholder="0"
                                                                    type={
                                                                        [
                                                                            FieldValueTypes.Int,
                                                                            FieldValueTypes.Float,
                                                                        ].includes(data.value_type as any)
                                                                            ? 'number'
                                                                            : 'text'
                                                                    }
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    )}

                                                    {[
                                                        FieldValueTypes.Str,
                                                        FieldValueTypes.Phone,
                                                        FieldValueTypes.Email,
                                                        FieldValueTypes.Link,
                                                    ].includes(data.value_type as any) && (
                                                        <Grid item xs={12} gap={1}>
                                                            <Label
                                                                label={constructorT(
                                                                    'field.alternativeText.label'
                                                                )}
                                                                title={constructorT(
                                                                    'field.alternativeText.title'
                                                                )}
                                                            />
                                                            <Grid item xs={12}>
                                                                <Input
                                                                    fullWidth
                                                                    placeholder={constructorT(
                                                                        'field.alternativeText.placeholder'
                                                                    )}
                                                                    onChange={(e) => {
                                                                        handleChange(
                                                                            'placeholder',
                                                                            e.target.value.slice(0, 200)
                                                                        );
                                                                    }}
                                                                    value={data.placeholder}
                                                                />
                                                                <Typography
                                                                    variant={'caption'}
                                                                    color="text.secondary"
                                                                    ml={2}
                                                                >
                                                                    {constructorT(
                                                                        'field.alternativeText.text',
                                                                        {
                                                                            length:
                                                                                200 -
                                                                                data.placeholder?.length,
                                                                        }
                                                                    )}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    )}

                                                    {(data.value_type === FieldValueTypes.M2m ||
                                                        data.value_type === FieldValueTypes.Fk) && (
                                                        <Grid item xs={12} gap={1}>
                                                            <Label
                                                                label={constructorT(
                                                                    'field.objectEntity.label'
                                                                )}
                                                                title={constructorT(
                                                                    'field.objectEntity.title'
                                                                )}
                                                            />
                                                            <Grid item xs={12}>
                                                                <Select
                                                                    disabled={!!id}
                                                                    fullWidth
                                                                    size="small"
                                                                    items={metas.results.map((meta) => ({
                                                                        id: meta.id,
                                                                        text: meta.name,
                                                                    }))}
                                                                    placeholder={t('select')}
                                                                    onChange={(e) => {
                                                                        handleChange(
                                                                            'entity',
                                                                            e.target.value,
                                                                            true
                                                                        );
                                                                    }}
                                                                    value={data.settings.entity}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid item gap={1} xl={6} lg={8} md={10} xs={12}>
                                        <Label
                                            label={constructorT('field.fieldComment.label')}
                                            title={constructorT('field.fieldComment.title')}
                                            isRequired
                                        />
                                        <Grid item xs={12}>
                                            <Input
                                                fullWidth
                                                placeholder={constructorT('field.fieldComment.placeholder')}
                                                onChange={(e) => {
                                                    handleChange('help_text', e.target.value.slice(0, 300));
                                                }}
                                                value={data.help_text}
                                            />
                                            <Typography variant={'caption'} color="text.secondary" ml={2}>
                                                {constructorT('field.fieldComment.text', {
                                                    length: 300 - (data.help_text?.length || 0),
                                                })}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {![
                                    FieldValueTypes.Fk,
                                    FieldValueTypes.M2m,
                                    FieldValueTypes.FkEmployee,
                                    FieldValueTypes.M2mEmployee,
                                    FieldValueTypes.FkDepartment,
                                    FieldValueTypes.M2mDepartment,
                                    FieldValueTypes.Bool,
                                    FieldValueTypes.File,
                                    FieldValueTypes.Select,
                                    FieldValueTypes.MultiSelect,
                                ].includes(data.value_type as any) && (
                                    <Grid item xs={12}>
                                        <Grid item gap={1} xl={6} lg={8} md={10} xs={12}>
                                            <Label
                                                label={constructorT('field.many.label')}
                                                title={constructorT('field.many.title')}
                                            />
                                            <Grid item xs={12}>
                                                <CheckBox
                                                    disabled={!!id}
                                                    onChange={(_, checked) =>
                                                        handleChange('many', checked, true)
                                                    }
                                                    checked={data.settings.many || false}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                                {[
                                    FieldValueTypes.Str,
                                    FieldValueTypes.Int,
                                    FieldValueTypes.Float,
                                    FieldValueTypes.Link,
                                    FieldValueTypes.Phone,
                                    FieldValueTypes.Email,
                                ].includes(data.value_type as any) && !data.settings.many ? (
                                    <Grid item xs={12}>
                                        <Grid item gap={1} xl={6} lg={8} md={10} xs={12}>
                                            <Label label={constructorT('field.uniqueValue.label')} />
                                            <Grid item xs={12}>
                                                <CheckBox
                                                    disabled={!!id}
                                                    onChange={(_, checked) =>
                                                        handleChange('unique', checked, true)
                                                    }
                                                    checked={data.settings.unique || false}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ) : null}
                            </Grid>
                        </CardContent>
                    </Card>

                    {/*{(nature || data.entity) && (*/}
                    {/*    <Card sx={{ width: '100%', mt: 3 }}>*/}
                    {/*        <CardContent>*/}
                    {/*            <Grid container gap={3}>*/}
                    {/*                <Grid item xs={12}>*/}
                    {/*                    <Typography variant="h6">*/}
                    {/*                        {constructorT('field.additionalSettings')}412421*/}
                    {/*                    </Typography>*/}
                    {/*                    <Divider variant="fullWidth" sx={{ mt: 3 }} />*/}
                    {/*                </Grid>*/}

                    {/*                <Grid item xs={12}>*/}
                    {/*                    <Grid item gap={1} xl={6} lg={8} md={10} xs={12}>*/}
                    {/*                        <Label*/}
                    {/*                            label={constructorT('field.requiredValue.label')}*/}
                    {/*                            title={constructorT('field.requiredValue.title')}*/}
                    {/*                        />*/}
                    {/*                        <Grid item xs={12}>*/}
                    {/*                            <CheckBox*/}
                    {/*                                onChange={(_, checked) =>*/}
                    {/*                                    handleChange('required', checked, true)*/}
                    {/*                                }*/}
                    {/*                                checked={data.settings.required}*/}
                    {/*                            />*/}
                    {/*                        </Grid>*/}
                    {/*                    </Grid>*/}
                    {/*                </Grid>*/}

                    {/*                <Grid item xs={12}>*/}
                    {/*                    <Grid container item gap={2} xl={6} lg={8} md={10} xs={12}>*/}
                    {/*                        <Label*/}
                    {/*                            label={constructorT('field.requiredStages.label')}*/}
                    {/*                            title={constructorT('field.requiredStages.title')}*/}
                    {/*                        />*/}
                    {/*                        {requiredStages.length > 0 ? (*/}
                    {/*                            <Grid container item xs={12} gap={'8px'}>*/}
                    {/*                                {requiredStages.map(({ id, name }) => {*/}
                    {/*                                    return (*/}
                    {/*                                        <Chip*/}
                    {/*                                            variant={'outlined'}*/}
                    {/*                                            key={id}*/}
                    {/*                                            label={name}*/}
                    {/*                                            onDelete={() => {*/}
                    {/*                                                setRequiredStages((prev) =>*/}
                    {/*                                                    prev.filter((s: any) => s.id !== id)*/}
                    {/*                                                );*/}
                    {/*                                            }}*/}
                    {/*                                        />*/}
                    {/*                                    );*/}
                    {/*                                })}*/}
                    {/*                            </Grid>*/}
                    {/*                        ) : (*/}
                    {/*                            <Grid container item xs={12} alignItems="center">*/}
                    {/*                                <Typography variant={'body2'} color="text.secondary">*/}
                    {/*                                    {constructorT('field.requiredStages.text')}*/}
                    {/*                                </Typography>*/}
                    {/*                            </Grid>*/}
                    {/*                        )}*/}
                    {/*                        <Grid container item xs={12} gap={2} alignItems="center">*/}
                    {/*                            <Button*/}
                    {/*                                onClick={() => setRequiredStagesModalOpen(true)}*/}
                    {/*                                variant="text"*/}
                    {/*                                endIcon={<AddRoundedIcon />}*/}
                    {/*                            >*/}
                    {/*                                {t('add')}*/}
                    {/*                            </Button>*/}
                    {/*                            <Button*/}
                    {/*                                variant="text"*/}
                    {/*                                onClick={() => setRequiredStages([])}*/}
                    {/*                                startIcon={<AddCircleRoundedIcon />}*/}
                    {/*                                disabled={requiredStages.length === 0}*/}
                    {/*                                color={'error'}*/}
                    {/*                            >*/}
                    {/*                                {t('clear')}*/}
                    {/*                            </Button>*/}
                    {/*                        </Grid>*/}
                    {/*                    </Grid>*/}
                    {/*                </Grid>*/}
                    {/*            </Grid>*/}
                    {/*        </CardContent>*/}
                    {/*    </Card>*/}
                    {/*)}*/}

                    {/*{(nature || data.entity) && (*/}
                    {/*    <Card sx={{ width: '100%', mt: 3 }}>*/}
                    {/*        <CardContent>*/}
                    {/*            <Grid container gap={3}>*/}
                    {/*                <Grid item xs={12}>*/}
                    {/*                    <Typography variant="h6">*/}
                    {/*                        {constructorT('field.accessSettings')}*/}
                    {/*                    </Typography>*/}
                    {/*                    <Divider variant="fullWidth" sx={{ mt: 3 }} />*/}
                    {/*                </Grid>*/}

                    {/*                <Grid item xs={12}>*/}
                    {/*                    <Grid container item gap={2} xl={6} lg={8} md={10} xs={12}>*/}
                    {/*                        <Label*/}
                    {/*                            label={t('employees')}*/}
                    {/*                            title={constructorT('field.employees.title')}*/}
                    {/*                        />*/}
                    {/*                        {data?.employees?.length ? (*/}
                    {/*                            <Grid container gap={1} mb={'10px'}>*/}
                    {/*                                {data.employees?.map((employe) => (*/}
                    {/*                                    <Chip*/}
                    {/*                                        key={employe.id}*/}
                    {/*                                        label={employe.fullname}*/}
                    {/*                                        variant="outlined"*/}
                    {/*                                        onDelete={() =>*/}
                    {/*                                            handleChangeSettings(*/}
                    {/*                                                'employees',*/}
                    {/*                                                data.employees*/}
                    {/*                                                    .filter(*/}
                    {/*                                                        (item) => item.id !== employe.id*/}
                    {/*                                                    )*/}
                    {/*                                                    .map((item) => item.id)*/}
                    {/*                                            )*/}
                    {/*                                        }*/}
                    {/*                                    />*/}
                    {/*                                ))}*/}
                    {/*                            </Grid>*/}
                    {/*                        ) : (*/}
                    {/*                            <Grid container item xs={12} alignItems="center">*/}
                    {/*                                <Typography variant={'body2'} color="text.secondary">*/}
                    {/*                                    {constructorT('field.employees.textFirst')}*/}
                    {/*                                    <br />*/}
                    {/*                                    {constructorT('field.employees.textSecondary')}*/}
                    {/*                                </Typography>*/}
                    {/*                            </Grid>*/}
                    {/*                        )}*/}
                    {/*                        <Grid container item xs={12} gap={2} alignItems="center">*/}
                    {/*                            <Button*/}
                    {/*                                variant="text"*/}
                    {/*                                endIcon={<AddRoundedIcon />}*/}
                    {/*                                onClick={() => {*/}
                    {/*                                    open?.({*/}
                    {/*                                        code: 'department-employees',*/}
                    {/*                                        type: 'm2m_employee',*/}
                    {/*                                        handleSelect: (selected) => {*/}
                    {/*                                            const arr = Array.isArray(selected)*/}
                    {/*                                                ? [...selected.map((depart) => depart.id)]*/}
                    {/*                                                : [selected.id];*/}
                    {/*                                            handleChangeSettings('employees', arr);*/}
                    {/*                                        },*/}
                    {/*                                        selected: data.employees.map((item) => {*/}
                    {/*                                            return { id: item.id, name: item.fullname };*/}
                    {/*                                        }),*/}
                    {/*                                    });*/}
                    {/*                                }}*/}
                    {/*                            >*/}
                    {/*                                {t('add')}*/}
                    {/*                            </Button>*/}
                    {/*                            <Button*/}
                    {/*                                variant="text"*/}
                    {/*                                startIcon={<AddCircleRoundedIcon />}*/}
                    {/*                                onClick={() => handleChangeSettings('employees', [])}*/}
                    {/*                                disabled={!data?.employees?.length}*/}
                    {/*                            >*/}
                    {/*                                {t('clear')}*/}
                    {/*                            </Button>*/}
                    {/*                        </Grid>*/}
                    {/*                    </Grid>*/}
                    {/*                </Grid>*/}

                    {/*                <Grid item xs={12}>*/}
                    {/*                    <Grid container item gap={2} xl={6} lg={8} md={10} xs={12}>*/}
                    {/*                        <Label*/}
                    {/*                            label={constructorT('field.departments.label')}*/}
                    {/*                            title={constructorT('field.departments.title')}*/}
                    {/*                        />*/}
                    {/*                        {data?.departments?.length ? (*/}
                    {/*                            <Grid container gap={1} mb={'10px'}>*/}
                    {/*                                {data.departments?.map((department) => (*/}
                    {/*                                    <Chip*/}
                    {/*                                        key={department.id}*/}
                    {/*                                        label={department.name}*/}
                    {/*                                        variant="outlined"*/}
                    {/*                                        onDelete={() =>*/}
                    {/*                                            handleChangeSettings(*/}
                    {/*                                                'departments',*/}
                    {/*                                                data.departments*/}
                    {/*                                                    .filter(*/}
                    {/*                                                        (item) =>*/}
                    {/*                                                            item.id !== department.id*/}
                    {/*                                                    )*/}
                    {/*                                                    .map((item) => item.id)*/}
                    {/*                                            )*/}
                    {/*                                        }*/}
                    {/*                                    />*/}
                    {/*                                ))}*/}
                    {/*                            </Grid>*/}
                    {/*                        ) : (*/}
                    {/*                            <Grid container item xs={12} alignItems="center">*/}
                    {/*                                <Typography variant={'body2'} color="text.secondary">*/}
                    {/*                                    {constructorT('field.departments.textFirst')}*/}
                    {/*                                    <br />*/}
                    {/*                                    {constructorT('field.departments.textSecondary')}*/}
                    {/*                                </Typography>*/}
                    {/*                            </Grid>*/}
                    {/*                        )}*/}
                    {/*                        <Grid container item xs={12} gap={2} alignItems="center">*/}
                    {/*                            <Button*/}
                    {/*                                variant="text"*/}
                    {/*                                endIcon={<AddRoundedIcon />}*/}
                    {/*                                onClick={() => {*/}
                    {/*                                    open?.({*/}
                    {/*                                        code: 'departments',*/}
                    {/*                                        type: 'uuid_many',*/}
                    {/*                                        handleSelect: (selected) => {*/}
                    {/*                                            const arr = Array.isArray(selected)*/}
                    {/*                                                ? [...selected.map((depart) => depart.id)]*/}
                    {/*                                                : [selected.id];*/}
                    {/*                                            handleChangeSettings('departments', arr);*/}
                    {/*                                        },*/}
                    {/*                                        selected: data.departments,*/}
                    {/*                                    });*/}
                    {/*                                }}*/}
                    {/*                            >*/}
                    {/*                                {t('add')}*/}
                    {/*                            </Button>*/}
                    {/*                            <Button*/}
                    {/*                                variant="text"*/}
                    {/*                                startIcon={<AddCircleRoundedIcon />}*/}
                    {/*                                onClick={() => handleChangeSettings('departments', [])}*/}
                    {/*                                disabled={!data?.departments?.length}*/}
                    {/*                            >*/}
                    {/*                                {t('clear')}*/}
                    {/*                            </Button>*/}
                    {/*                        </Grid>*/}
                    {/*                    </Grid>*/}
                    {/*                </Grid>*/}

                    {/*                <Grid item xs={12}>*/}
                    {/*                    <Grid container item gap={2} xl={6} lg={8} md={10} xs={12}>*/}
                    {/*                        <Label*/}
                    {/*                            label={constructorT('field.roles.label')}*/}
                    {/*                            title={constructorT('field.roles.title')}*/}
                    {/*                        />*/}
                    {/*                        {data?.roles?.length ? (*/}
                    {/*                            <Grid container gap={1} mb={'10px'}>*/}
                    {/*                                {data.roles?.map((role) => (*/}
                    {/*                                    <Chip*/}
                    {/*                                        key={role.id}*/}
                    {/*                                        label={role.name}*/}
                    {/*                                        variant="outlined"*/}
                    {/*                                        onDelete={() =>*/}
                    {/*                                            handleChangeSettings(*/}
                    {/*                                                'roles',*/}
                    {/*                                                data.roles*/}
                    {/*                                                    .filter((item) => item.id !== role.id)*/}
                    {/*                                                    .map((item) => item.id)*/}
                    {/*                                            )*/}
                    {/*                                        }*/}
                    {/*                                    />*/}
                    {/*                                ))}*/}
                    {/*                            </Grid>*/}
                    {/*                        ) : (*/}
                    {/*                            <Grid container item xs={12} alignItems="center">*/}
                    {/*                                <Typography variant={'body2'} color="text.secondary">*/}
                    {/*                                    {constructorT('field.roles.textFirst')}*/}
                    {/*                                    <br />*/}
                    {/*                                    {constructorT('field.roles.textSecondary')}*/}
                    {/*                                </Typography>*/}
                    {/*                            </Grid>*/}
                    {/*                        )}*/}
                    {/*                        <Grid container item xs={12} gap={2} alignItems="center">*/}
                    {/*                            <Button*/}
                    {/*                                variant="text"*/}
                    {/*                                endIcon={<AddRoundedIcon />}*/}
                    {/*                                onClick={() => {*/}
                    {/*                                    open?.({*/}
                    {/*                                        code: 'roles',*/}
                    {/*                                        type: 'uuid_many',*/}
                    {/*                                        handleSelect: (selected) => {*/}
                    {/*                                            const arr = Array.isArray(selected)*/}
                    {/*                                                ? [...selected.map((depart) => depart.id)]*/}
                    {/*                                                : [selected.id];*/}
                    {/*                                            handleChangeSettings('roles', arr);*/}
                    {/*                                        },*/}
                    {/*                                        selected: data.roles,*/}
                    {/*                                    });*/}
                    {/*                                }}*/}
                    {/*                            >*/}
                    {/*                                {t('add')}*/}
                    {/*                            </Button>*/}
                    {/*                            <Button*/}
                    {/*                                variant="text"*/}
                    {/*                                startIcon={<AddCircleRoundedIcon />}*/}
                    {/*                                onClick={() => handleChangeSettings('roles', [])}*/}
                    {/*                                disabled={!data?.roles?.length}*/}
                    {/*                            >*/}
                    {/*                                {t('clear')}*/}
                    {/*                            </Button>*/}
                    {/*                        </Grid>*/}
                    {/*                    </Grid>*/}
                    {/*                </Grid>*/}
                    {/*            </Grid>*/}
                    {/*        </CardContent>*/}
                    {/*    </Card>*/}
                    {/*)}*/}
                </Box>
                <RequiredStagesModal
                    currentSelected={requiredStages}
                    entity={data.entity}
                    open={requiredStagesModalOpen}
                    handleClose={() => setRequiredStagesModalOpen(false)}
                    handleSubmit={(selected) => setRequiredStages(selected)}
                />
            </>
            <Box
                sx={{
                    position: 'fixed',
                    left: 0,
                    bottom: 0,
                    width: '100%',
                    backgroundColor: paper,
                    zIndex: 1,
                    borderTop: '1px solid rgba(0, 0, 0, 0.16)',
                }}
            >
                <Grid
                    container
                    justifyContent={'flex-end'}
                    alignItems={'center'}
                    py={'16px'}
                    px={'21px'}
                    gap={'19px'}
                    flexWrap={'nowrap'}
                >
                    <LoadingButton
                        onClick={() => (id ? onSave() : onCreate())}
                        variant={'contained'}
                        loading={isCreating}
                        disabled={isDisabled()}
                    >
                        {id ? t('edit') : t('create')} поле
                    </LoadingButton>
                    <LoadingButton
                        loading={isCreating}
                        variant={'outlined'}
                        onClick={() => {
                            setData(initialData);
                            navigate(-1);
                        }}
                    >
                        {id ? t('cancelEdit') : t('cancelCreate')}
                    </LoadingButton>
                </Grid>
            </Box>
        </>
    );
};

interface SelectOptionProps {
    value: string;
    data: TFieldsMeta;
    index: number;
    handleChange: (type: string, content: any, settings?: boolean) => void;
    valueKey: string;
    reorderValue: Record<string, string>;
}

const SelectOption = ({ value, data, index, handleChange, valueKey, reorderValue }: SelectOptionProps) => {
    const controls = useDragControls();
    const y = useMotionValue(0);
    const boxShadow = useRaisedShadow(y);
    return (
        <Reorder.Item
            dragListener={false}
            dragControls={controls}
            value={reorderValue}
            style={{
                boxShadow,
                y,
                position: 'relative',
                backgroundColor: 'white',
                borderRadius: '12px',
            }}
        >
            <DraggableItemWrapper
                dragHandler={(e) => controls.start(e)}
                iconPosition={{
                    top: 18,
                    left: 10,
                }}
            >
                <Grid container alignItems="center">
                    <Grid item p={1} xs={1}></Grid>
                    <Grid item p={1} xs={10}>
                        <Input
                            value={value}
                            fullWidth
                            placeholder="0"
                            onChange={(e) => {
                                if (data.settings.options) {
                                    const optionIndex = data.settings.options.findIndex(
                                        (o) => valueKey in o
                                    )!;
                                    data.settings.options[optionIndex][valueKey] = e.target.value;
                                    handleChange('options', [...data.settings.options], true);
                                }
                            }}
                        />
                    </Grid>
                    <Grid item p={1} xs={1}>
                        <IconButton
                            onClick={() => {
                                if (data.settings.options) {
                                    data.settings?.options?.splice(index, 1);
                                    handleChange('options', [...data.settings.options], true);
                                }
                            }}
                            size={'small'}
                            color="default"
                        >
                            <CloseRoundedIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DraggableItemWrapper>
        </Reorder.Item>
    );
};

export default ConstructorCreateField;
