import React from 'react';
import { TFieldProps } from 'widgets/DraggableField/Fields/types';
import { Grid, Link } from '@mui/material';
import DefaultField from 'widgets/DraggableField/Fields/DefaultField/DefaultField';
import { Button } from 'shared/ui';
import MailRoundedIcon from '@mui/icons-material/MailRounded';
import { useTranslation } from 'react-i18next';

const EmailField = ({ field, edit, error, setValue, value }: TFieldProps<string | string[]>) => {
    const { t } = useTranslation();
    const isMany = field.settings.many;
    return (
        <>
            {edit ? (
                <DefaultField field={field} value={value} setValue={setValue} error={error} edit />
            ) : (
                <>
                    {isMany && Array.isArray(value) ? (
                        <Grid container flexDirection={'column'} gap={'8px'}>
                            {value.map((v, i) => (
                                <Grid key={i} container flexWrap={'nowrap'} gap={'8px'}>
                                    <DefaultField field={field} value={v} />
                                    {v && (
                                        <Link
                                            onPointerUp={(e) => e.stopPropagation()}
                                            href={`mailto:${v}`}
                                            underline={'none'}
                                        >
                                            <Button size={'small'} startIcon={<MailRoundedIcon />}>
                                                {t('write')}
                                            </Button>
                                        </Link>
                                    )}
                                </Grid>
                            ))}
                        </Grid>
                    ) : (
                        <DefaultField field={field} value={value} />
                    )}
                </>
            )}
        </>
    );
};

export default EmailField;
