import { isDeepEqual } from 'shared/helpers/deepCompare';

export function diff(currentFields: any, oldFields: any) {
    const keys = Object.keys(currentFields);
    const difference: any = {};

    for (const key of keys) {
        const oldValue = oldFields[key];
        const newValue = currentFields[key];
        if (oldValue !== undefined) {
            if (typeof oldValue === 'object' && oldValue !== null && newValue !== null) {
                if (!isDeepEqual(oldValue, newValue)) {
                    difference[key] = newValue;
                }
            } else {
                if (oldValue !== newValue) {
                    difference[key] = newValue;
                }
            }
        } else {
            if (newValue) {
                difference[key] = newValue;
            }
        }
    }
    return difference;
}
