import { useEffect, useState } from 'react';
import { Grid, Typography, IconButton, Input, FormControl } from '@mui/material';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';

interface ILabel {
    title?: string;
    fontSize?: string | number;
    placeholder?: string;
    required?: boolean;
    disabled?: boolean;
    onSave: (text: string) => void;
    onClickToTitle?: () => void;
    onClose?: () => void;
    onRemove?: () => void;
}
const EditTitle = ({
    title,
    fontSize,
    placeholder,
    required = false,
    disabled = false,
    onSave,
    onClose,
    onRemove,
    onClickToTitle = () => {},
}: ILabel) => {
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [text, setText] = useState<string>('');

    useEffect(() => {
        title && setText(title);
    }, [title]);

    return isEdit ? (
        <Grid
            container
            alignItems={'center'}
            flexWrap={'nowrap'}
            width={'100%'}
            onClick={(e) => e.stopPropagation()}
        >
            <Grid item>
                <FormControl fullWidth>
                    <Input
                        onChange={(event) => setText(event.target.value)}
                        placeholder={placeholder}
                        value={text}
                        sx={{ fontSize: fontSize, border: 0, p: 0 }}
                    />
                </FormControl>
            </Grid>
            <Grid container gap={'8px'} width={'auto'} flexWrap={'nowrap'}>
                <IconButton
                    sx={{ ml: 1 }}
                    onClick={() => {
                        setIsEdit(false);
                        onSave(text);
                    }}
                    color="success"
                    size="small"
                >
                    <CheckCircleRoundedIcon color="success" fontSize="small" />
                </IconButton>
                <IconButton
                    onClick={() => {
                        title && setText(title);
                        setIsEdit(false);
                        onClose?.();
                    }}
                    color="error"
                    size="small"
                >
                    <CancelRoundedIcon color="error" fontSize="small" />
                </IconButton>
            </Grid>
        </Grid>
    ) : (
        <Grid
            container
            alignItems={'center'}
            flexWrap={'nowrap'}
            maxWidth={'100%'}
            width={'fit-content'}
            onClick={(e) => e.stopPropagation()}
        >
            <Typography variant={'h5'} fontSize={fontSize} onClick={onClickToTitle}>
                {title || placeholder}
            </Typography>
            {required && (
                <Typography component="span" variant="h5" ml={1} color={'error'}>
                    *
                </Typography>
            )}
            {!disabled && (
                <Grid container gap={'8px'} width={'auto'} flexWrap={'nowrap'}>
                    <IconButton sx={{ ml: 1 }} onClick={() => setIsEdit(true)} size="small">
                        <EditIcon fontSize="small" />
                    </IconButton>
                    {!!onRemove && (
                        <IconButton onClick={onRemove} size="small">
                            <CancelIcon fontSize="small" />
                        </IconButton>
                    )}
                </Grid>
            )}
        </Grid>
    );
};

export default EditTitle;
