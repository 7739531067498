import { Grid } from '@mui/material';
import { Button, Modal } from 'shared/ui';
import { useTranslation } from 'react-i18next';

interface IProps {
    open: boolean;
    onClose: (() => void) | undefined;
    onApply: (() => void) | undefined;
    title: string;
    children: any;
}

const DeleteModal = ({ onClose, onApply, open, title, children }: IProps) => {
    const { t } = useTranslation();
    return (
        <Modal open={open} title={title} width={600} onClose={onClose}>
            <>
                <Grid px={2} py={2}>
                    {children}
                </Grid>
                <Grid container justifyContent={'flex-end'} gap={2} px={1}>
                    <Button variant={'contained'} onClick={onApply}>
                        {t('delete')}
                    </Button>
                    <Button variant={'outlined'} onClick={onClose}>
                        {t('cancel')}
                    </Button>
                </Grid>
            </>
        </Modal>
    );
};

export default DeleteModal;
