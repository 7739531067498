import { EmployeeType } from 'domain/employee';
import { Nullable } from 'shared/types';
import React, { useContext, useMemo, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Box, Divider, Grid, Menu, Typography } from '@mui/material';
import { Avatar, Input } from 'shared/ui';
import EmployeeItem from 'widgets/DepartmentsMap/EmployeeItem';
import { DepartmentsMapContext } from 'widgets/DepartmentsMap/DepartmentsMap';
import { useDepartmentsStorage, useEmployeesStorage, useUsersStorage } from 'services/StorageAdapter';
import { DepartmentNode, SelectUserIdContext } from 'pages/DepartmentsControl/DepartmentsControl';
import { similarity } from 'shared/helpers/compareString';
import { useTranslation } from 'react-i18next';

const CardDrop = () => {
    const { t } = useTranslation('departmentsControl');
    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'white',
            }}
        >
            <Typography variant={'h6'}>{t('addSubDepartment')}</Typography>
        </Box>
    );
};

type EmployeeHeadDropProps = {
    cardId: string;
    headId?: string;
};

const EmployeeHeadDrop = ({ cardId, headId }: EmployeeHeadDropProps) => {
    const { t } = useTranslation('departmentsControl');
    const { updateDepartment } = useDepartmentsStorage();
    const [{ isOver, canDrop }, dropRef] = useDrop({
        accept: ['employee', 'head'],
        collect: (monitor) => ({
            isOver: monitor.isOver({ shallow: true }),
            canDrop: monitor.canDrop(),
        }),
        drop: (item: any, monitor) => {
            if (monitor.canDrop()) {
                const itemType = monitor.getItemType();
                if (itemType === 'head') {
                    updateDepartment(item.cardId, { head: null });
                }
                updateDepartment(cardId, { head: item.user.id });
            }
        },
        canDrop: (item: any, monitor) => {
            if (monitor.getItemType() === 'employee' || monitor.getItemType() === 'head') {
                const isAlreadyHead = item.user.id === headId;
                return !isAlreadyHead;
            }
            return false;
        },
    });

    return (
        <Box
            ref={dropRef}
            flexGrow={1}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'white',
                opacity: isOver && canDrop ? 1 : 0,
            }}
        >
            <Typography variant={'h6'}>{t('addHead')}</Typography>
        </Box>
    );
};

type EmployeeDropProps = {
    cardId: string;
};

const EmployeeDrop = ({ cardId }: EmployeeDropProps) => {
    const { t } = useTranslation('departmentsControl');
    const { updateEmployee } = useEmployeesStorage();
    const [{ isOver, canDrop }, dropRef] = useDrop({
        accept: ['employee', 'head'],
        collect: (monitor) => ({
            isOver: monitor.isOver({ shallow: true }),
            canDrop: monitor.canDrop(),
        }),
        drop: (item: any, monitor) => {
            if (monitor.canDrop()) {
                updateEmployee(item.user.id, { department: cardId });
            }
        },
        canDrop: (item: any, monitor) => {
            if (monitor.getItemType() === 'head' || monitor.getItemType() === 'employee') {
                return cardId !== item.department.id;
            }
            return false;
        },
    });
    return (
        <Box
            ref={dropRef}
            flexGrow={1}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'white',
                opacity: isOver && canDrop ? 1 : 0,
            }}
        >
            <Typography variant={'h6'}>{t('addEmployees')}</Typography>
        </Box>
    );
};

type EmployeeDropContainerProps = {
    cardId: string;
    headId?: string;
};

const EmployeeDropContainer = ({ cardId, headId }: EmployeeDropContainerProps) => {
    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <EmployeeHeadDrop cardId={cardId} headId={headId} />
            <EmployeeDrop cardId={cardId} />
        </Box>
    );
};

type DropOverlayProps = {
    cardId: string;
    headId?: string;
};

const DropOverlay = ({ cardId, headId }: DropOverlayProps) => {
    const { changeNodeParent } = useContext(DepartmentsMapContext);
    const [{ isCardOver }, dragRef] = useDrop({
        accept: ['card', 'employee', 'head'],
        collect: (monitor) => ({
            isCardOver: (() => {
                return monitor.isOver({ shallow: true }) && monitor.getItemType() === 'card';
            })(),
        }),
        canDrop: (item: any) => {
            const isSameDepartment = item.id === cardId;
            const isSameParent = item.parentId === cardId;
            return !isSameDepartment && !isSameParent;
        },
        drop: (_item, monitor) => {
            if (monitor.getItemType() === 'card') {
                changeNodeParent(monitor.getItem().id, cardId);
            }
        },
    });

    return (
        <Box
            ref={dragRef}
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                top: 0,
                left: 0,
            }}
        >
            {isCardOver ? <CardDrop /> : <EmployeeDropContainer headId={headId} cardId={cardId} />}
        </Box>
    );
};

type DepartmentCardHeadSlotProps = {
    head: Nullable<EmployeeType>;
    cardId: string;
    color: string;
};

const DepartmentCardHeadSlot = ({ head, cardId }: DepartmentCardHeadSlotProps) => {
    const [, dragRef] = useDrag({
        type: 'head',
        item: {
            ...head,
            cardId,
            type: 'head',
        },
    });

    const { handleSelectUserId } = useContext(SelectUserIdContext);
    return (
        <Box
            ref={dragRef}
            px={'12px'}
            py={'13px'}
            display={'flex'}
            gap={'12px'}
            alignItems={'center'}
            onClick={() => handleSelectUserId(head?.user.id || '')}
        >
            <Avatar src={head?.user?.avatar || ''} style={{ opacity: head ? 1 : 0 }} size={'32'} circle>
                {head?.user.first_name?.[0]}
                {head?.user.last_name?.[0]}
            </Avatar>
            <Typography variant={'body2'} color={'#000'}>
                {head?.user.first_name} {head?.user.last_name}
            </Typography>
        </Box>
    );
};

type DepartmentCardEmployeeSlotProps = {
    employee: EmployeeType;
};

const DepartmentCardEmployeeSlot = ({ employee }: DepartmentCardEmployeeSlotProps) => {
    const {
        id,
        user: { first_name, last_name },
    } = employee;
    const [, dragRef] = useDrag({
        type: 'employee',
        item: employee,
    });

    const { handleSelectUserId } = useContext(SelectUserIdContext);
    return (
        <Box ref={dragRef} onClick={() => handleSelectUserId(employee.user.id)}>
            <Avatar src={employee.user.avatar || ''} key={id + '-card-employee'} size={'24'} circle>
                {first_name[0]}
                {last_name[0]}
            </Avatar>
        </Box>
    );
};

type DepartmentCardDropContainerProps = {
    cardId: string;
    employees: Set<EmployeeType>;
    head: Nullable<EmployeeType>;
    color: string;
};

const checkRecursiveRelations = (nodeId: string, parentId: string, map: Map<string, DepartmentNode>) => {
    let currentNode = map.get(parentId);
    while (currentNode) {
        if (currentNode.parent) {
            const parent = map.get(currentNode.parent);
            currentNode = parent;
            if (parent?.id === nodeId) {
                return true;
            }
        } else {
            break;
        }
    }
    return false;
};

const DepartmentCardDropContainer = ({
    cardId,
    employees,
    head,
    color,
}: DepartmentCardDropContainerProps) => {
    const { t: departmentsT } = useTranslation('departmentsControl');
    const [search, setSearch] = useState('');
    const { map } = useContext(DepartmentsMapContext);
    const [{ isOver, canDrop }, dropDepartmentRef] = useDrop({
        accept: ['employee', 'card', 'head'],
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
        canDrop: (item: any, monitor: any) => {
            const type = monitor.getItemType();
            const currentItem = monitor.getItem();
            const isAlreadyHead = item?.id === head?.id;
            if (type === 'card') {
                return (
                    currentItem.id !== cardId &&
                    currentItem.parentId &&
                    !checkRecursiveRelations(currentItem.id, cardId, map)
                );
            } else if (type === 'employee') {
                return (
                    ![...employees.values()].some((e) => e.id === currentItem.id) || currentItem.id !== head
                );
            } else {
                return !isAlreadyHead;
            }
        },
    });
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const filteredEmployees = useMemo(() => {
        return [...employees.values()].filter((employee) => {
            const fullName = `${employee.user.first_name} ${employee.user.last_name}`;
            const fullNameSimilarity = similarity(fullName, search);
            const firstNameSimilarity = similarity(employee.user.first_name, search);
            const lastNameSimilarity = similarity(employee.user.last_name, search);
            return (
                firstNameSimilarity > 0.5 ||
                lastNameSimilarity > 0.5 ||
                fullNameSimilarity > 0.5 ||
                fullName.includes(search)
            );
        });
    }, [employees, search]);
    const { me } = useUsersStorage();
    // const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
    //     setAnchorEl(event.currentTarget);
    // };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Box ref={me.is_admin ? dropDepartmentRef : null} height={'100%'} width={'100%'}>
            {isOver && canDrop ? (
                <DropOverlay headId={head?.user.id} cardId={cardId} />
            ) : (
                <Grid>
                    <DepartmentCardHeadSlot head={head} cardId={cardId} color={color} />
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '15px',
                            paddingTop: '0',
                        }}
                        px={'12px'}
                        py={'13px'}
                    >
                        {[...employees.values()].slice(0, 6).map((employee) => {
                            return <DepartmentCardEmployeeSlot key={employee.id} employee={employee} />;
                        })}
                        {employees.size > 0 ? `+${employees.size}` : ''}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        {/*{employees.size > 6 && (*/}
                        {/*    <Link*/}
                        {/*        href={'#'}*/}
                        {/*        onClick={(e: any) => {*/}
                        {/*            e.preventDefault();*/}
                        {/*            handleClick(e);*/}
                        {/*        }}*/}
                        {/*        underline={'none'}*/}
                        {/*    >*/}
                        {/*        {departmentsT('moreEmployeesCount', { size: employees.size - 6 })}*/}
                        {/*    </Link>*/}
                        {/*)}*/}
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                            PaperProps={{
                                style: {
                                    width: '370px',
                                },
                            }}
                        >
                            <Box px={'16px'} pb={'12px'} pt={'6px'}>
                                <Input
                                    onChange={(e) => setSearch(e.target.value)}
                                    value={search}
                                    fullWidth
                                    placeholder={departmentsT('searchFullName')}
                                />
                            </Box>
                            <Divider />
                            <Box
                                sx={{
                                    overflowY: 'auto',
                                }}
                                maxHeight={'164px'}
                            >
                                {filteredEmployees.map((employee) => {
                                    return (
                                        <EmployeeItem
                                            onDrag={handleClose}
                                            key={employee.id + '-card-menu-employee'}
                                            employee={employee}
                                        />
                                    );
                                })}
                            </Box>
                        </Menu>
                    </Box>
                </Grid>
            )}
        </Box>
    );
};

export default DepartmentCardDropContainer;
