import { createEffect, createEvent, createStore } from 'effector';
import {
    TChatConnect,
    TChatNotification,
    TChatResponse,
    TMessagesResponse,
} from '../../../../../shared/api/types';
import { TMessageItem } from '../../../../../domain/chat';
import {
    IChatApiService,
    TChatCloseChannelParamsRequest,
    TChatConnectParamsRequest,
    TChatCreateParamsRequest,
    TChatCreateResponse,
    TChatLastReadChannelParamsRequest,
    TChatLastReadChannelResponse,
    TChatListParamsRequest,
    TChatMessageParamsRequest,
    TChatPublishFileParamsRequest,
    TChatRedirectChannelParamsRequest,
    TChatData,
    TChatCloseChannelResponse,
    TChatAddManagerToChannelParamsRequest,
} from '../../../types/chat';
import { chatApiService } from '../services/chatApiService';
import { filterSystemCloseMessages } from '../../../lib/functions/filterSystemCloseMessages';
import update from 'immutability-helper';

const initialChatState: TChatData = {
    connect: null,
    list: null,
    messages: null,
    centrifuge: null,
    subscribe: null,
    lastRead: null,
    personalSubscribe: null,
    notifications: [],
};

const redirectChannelState: TChatRedirectChannelParamsRequest = {
    channel_uuid: '',
    crm_manager_service_id: '',
    crm_manager_name: '',
};

const chatApi: IChatApiService = chatApiService();

const fetchChatConnectFx = createEffect(
    async ({ id, name, source, avatar }: TChatConnectParamsRequest): Promise<TChatConnect> => {
        return await chatApi.connect({ id, name, source, avatar });
    }
);

const fetchChatListFx = createEffect(
    async ({ user_uuid, page, size, user, filter }: TChatListParamsRequest): Promise<TChatResponse> => {
        return await chatApi.list({ user_uuid, page, size, user, filter });
    }
);

const fetchChatMessagesFx = createEffect(
    async ({
        user_uuid,
        channel_uuid,
        page,
        size,
    }: TChatMessageParamsRequest): Promise<TMessagesResponse> => {
        return await chatApi.messages({ user_uuid, channel_uuid, page, size });
    }
);

const createChannelFx = createEffect(
    async ({ user_uuid, source, subscriber }: TChatCreateParamsRequest): Promise<TChatCreateResponse> => {
        return await chatApi.createChannel({ user_uuid, source, subscriber });
    }
);

const uploadChatFileFx = createEffect(
    async ({
        user_uuid,
        channel_uuid,
        replayed_message_uuid,
        attachment_type,
        text,
        files,
    }: TChatPublishFileParamsRequest): Promise<TMessageItem> => {
        return await chatApi.file({
            user_uuid,
            channel_uuid,
            replayed_message_uuid,
            attachment_type,
            text,
            files,
        });
    }
);

const closeChatChannelFx = createEffect(
    async ({
        user_uuid,
        channel_uuid,
        score,
        description,
    }: TChatCloseChannelParamsRequest): Promise<TChatCloseChannelResponse> => {
        return await chatApi.closeChatChannel({ user_uuid, channel_uuid, score, description });
    }
);

const redirectChatChannelFx = createEffect(
    async ({
        channel_uuid,
        crm_manager_service_id,
        crm_manager_name,
    }: TChatRedirectChannelParamsRequest): Promise<any> => {
        return await chatApi.redirectChatChannel({ channel_uuid, crm_manager_service_id, crm_manager_name });
    }
);

const chatLastReadChannelFx = createEffect(
    async ({
        channel_uuid,
        user_uuid,
    }: TChatLastReadChannelParamsRequest): Promise<TChatLastReadChannelResponse[]> => {
        return await chatApi.chatLastReadChannel({ channel_uuid, user_uuid });
    }
);

const addManagerToChatChannelFx = createEffect(
    async ({
        channel_uuid,
        crm_manager_service_id,
        crm_manager_name,
    }: TChatAddManagerToChannelParamsRequest): Promise<any> => {
        return await chatApi.chatAddManagerToChannel({
            channel_uuid,
            crm_manager_service_id,
            crm_manager_name,
        });
    }
);

const clearWS = createEvent();
const updateChatWS = createEvent<TChatData>();
const updateChatMessage = createEvent<TMessageItem>();
const removeChatList = createEvent<any>();
const updateNotifications = createEvent<TChatNotification>();

const $chatStore = createStore(initialChatState)
    .on(updateChatWS, (_, newWS) => newWS)
    .on(updateChatMessage, (state, newMessage: TMessageItem) => {
        return {
            ...state,
            messages: {
                ...state?.messages,
                items: filterSystemCloseMessages([...(state?.messages?.items || []), newMessage]),
            },
        };
    })
    .on(removeChatList, (state, payload) => {
        return update(state, {
            list: { items: (arr) => arr.filter(({ name }) => name !== payload.channel) },
        });
    })
    .on(updateNotifications, (state, payload) => {
        return {
            ...state,
            notifications: [...state.notifications, payload],
        };
    })
    .on(fetchChatConnectFx.doneData, (state, newWS) => {
        return { ...state, connect: newWS };
    })
    .on(fetchChatListFx.doneData, (state, newWS) => {
        return { ...state, list: newWS };
    })
    .on(fetchChatMessagesFx.doneData, (state, newWS) => {
        return { ...state, messages: { ...newWS, items: filterSystemCloseMessages(newWS.items) } };
    })
    .on(chatLastReadChannelFx.doneData, (state, payload: TChatLastReadChannelResponse[]) => {
        return { ...state, lastRead: payload };
    })
    .reset(clearWS);

const clearRedirectChannel = createEvent();
const updateRedirectChannel = createEvent<TChatRedirectChannelParamsRequest>();

const $redirectChannelStore = createStore(redirectChannelState)
    .on(updateRedirectChannel, (_, payload) => payload)
    .reset(clearRedirectChannel);
export const chatStores = {
    $chatStore,
    $redirectChannelStore,
};
export const chatEvents = {
    clearWS,
    updateChatWS,
    updateChatMessage,
    removeChatList,
    updateNotifications,
    updateRedirectChannel,
};

export const chatEffects = {
    fetchChatConnectFx,
    fetchChatListFx,
    fetchChatMessagesFx,
    createChannelFx,
    uploadChatFileFx,
    closeChatChannelFx,
    redirectChatChannelFx,
    chatLastReadChannelFx,
    addManagerToChatChannelFx,
};
