import { memo } from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import { Label } from '../../../../shared/ui';
import { notSpecified } from '../../lib/helpers/notSpecified';
import { useUsersStorage } from '../../../../services/StorageAdapter';
import { useTranslation } from 'react-i18next';

export const ProfileDepartment = memo(() => {
    const { t } = useTranslation();
    const { user }: any = useUsersStorage();
    return (
        <Grid
            px={'24px'}
            py={'28px'}
            sx={{ border: '1px solid rgba(0, 0, 0, 0.16)', borderRadius: '12px', mb: '24px' }}
        >
            <Typography variant={'h6'}>{t('departmentAndPosition')}</Typography>
            <Divider sx={{ my: '24px' }} />
            <Label label={t('department')} title="" />
            {user.employee?.department ? (
                <Typography sx={{ mb: '24px' }}>{user.employee?.department?.name}</Typography>
            ) : (
                notSpecified('24px')
            )}
            <Label label={t('position')} title="" />
            {user.position ? (
                <Typography sx={{ mb: '24px' }}>{user.position}</Typography>
            ) : (
                notSpecified('24px')
            )}
        </Grid>
    );
});
