import React, { FC, memo } from 'react';
import { Divider, Grid, Menu, Typography } from '@mui/material';
import { Button } from '../../../../shared/ui';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import { useNavigate } from 'react-router-dom';
import { useNotificationStorage } from '../../../../pages/Notifications';
import { NotificationBarList } from '../NotificationBarList/NotificationBarList';
import { useTranslation } from 'react-i18next';

interface NotificationBarMenuProps {
    open: boolean;
    anchorEl: null | HTMLElement;
    handleClose: () => void;
}
export const NotificationBarMenu: FC<NotificationBarMenuProps> = memo(({ anchorEl, handleClose, open }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { handleAllRead, allReadLoading } = useNotificationStorage();

    const handleNavigate = () => {
        handleClose();
        navigate(`/notification`);
    };
    return (
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                maxWidth={505}
                gap={1}
                px={2}
                py={1}
            >
                <Grid item xs={6}>
                    <Typography variant={'h6'}>{t('notifications')}</Typography>
                </Grid>
                {/*<Grid item xs={5} display="flex" justifyContent="end">*/}
                {/*    <Button startIcon={<SettingsIcon fontSize={'inherit'} />} variant="text">*/}
                {/*        Настройки*/}
                {/*    </Button>*/}
                {/*</Grid>*/}
                <Grid item xs={12}>
                    <Divider variant="fullWidth" />
                </Grid>
                <NotificationBarList handleClose={handleClose} />
                <Grid item xs={12}>
                    <Divider variant="fullWidth" />
                </Grid>
                <Grid item xs={7}>
                    <Button
                        variant="text"
                        onClick={handleAllRead}
                        loading={allReadLoading}
                        disabled={allReadLoading}
                    >
                        {t('markAllAsRead')}
                    </Button>
                </Grid>
                <Grid item xs={4} display="flex" justifyContent="end">
                    <Button
                        endIcon={<ArrowForwardRoundedIcon fontSize={'inherit'} />}
                        onClick={handleNavigate}
                        variant="text"
                    >
                        {t('showAll')}
                    </Button>
                </Grid>
            </Grid>
        </Menu>
    );
});
