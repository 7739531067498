import { memo } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

export const TimelineCommentFormLoading = memo(() => {
    return (
        <Box
            sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                background: 'rgba(255, 255, 255, 0.8)',
                width: '100%',
                height: '100%',
                zIndex: 10,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Box sx={{ display: 'grid' }}>
                <CircularProgress color="primary" sx={{ margin: '0 auto 20px' }} />
                <Typography>Отправка комментария...</Typography>
            </Box>
        </Box>
    );
});
