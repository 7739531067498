import { Grid, Link, Typography } from '@mui/material';
import ArrowRightAltRoundedIcon from '@mui/icons-material/ArrowRightAltRounded';
import React from 'react';
import i18n from '../../../../shared/config/i18n/i18n';
import { saveAs } from 'file-saver';

const titles = [
    { id: 'type', name: i18n.t('type') },
    { id: 'status', name: 'Статус' },
    { id: 'title', name: i18n.t('name') },
    { id: 'deadline', name: 'Крайний срок и напоминание' },
    { id: 'is_overdue', name: 'Просрочена' },
    { id: 'completed_date', name: 'Дата завершения' },
    { id: 'parent', name: 'Родительская задача' },
    { id: 'subtasks', name: 'Подзадачи' },
    { id: 'entity_objects', name: 'Объекты CRM' },
    { id: 'author', name: 'Постановщик' },
    { id: 'co_executors', name: 'Соисполнители' },
    { id: 'observers', name: 'Наблюдатели' },
    { id: 'description', name: 'Описание' },
    { id: 'files', name: 'Приложения' },
    { id: 'responsible', name: i18n.t('responsible') },
    { id: 'created', name: i18n.t('createdAt') },
    { id: 'modified', name: i18n.t('modifiedAt') },
    { id: 'created_by', name: 'Создан' },
    { id: 'changed_by', name: 'Изменён' },
];

type TaskStatus = {
    readonly new: 'Новая';
    readonly in_progress: 'В работе';
    readonly on_review: 'На проверке';
    readonly done: 'Завершена';
};

const taskStatus: TaskStatus = {
    new: 'Новая',
    in_progress: 'В работе',
    on_review: 'На проверке',
    done: 'Завершена',
};

const handleDownload = async (file: string, fileName: string) => {
    await fetch(file)
        .then((res) => res.blob())
        .then((blob) => {
            saveAs(blob, fileName);
        });
};

const File = (props: { file: string; fileName: string; title: string }) => {
    const { file, title } = props;
    return (
        <Link onClick={() => handleDownload(file, title)} sx={{ cursor: 'pointer' }}>
            {title}
        </Link>
    );
};

const Chipper = (value: string, type: string) => {
    const checkImg = (url: string) => {
        if (type === 'file') {
            return url?.split('/')?.pop()?.split('?')[0];
        }
        return taskStatus[url as keyof TaskStatus] || url;
    };
    const showValue = (value: any) => {
        if (typeof value === 'object' && value !== null) {
            if (Array.isArray(value)) {
                value?.map((item: any) => {
                    return checkImg(item?.name || item?.value || item);
                });
            } else {
                return checkImg(value?.name || value?.value || value);
            }
        } else {
            return checkImg(value?.name || value?.value || value);
        }
    };
    return <Typography variant="body2">&ldquo;{showValue(value)}&ldquo;</Typography>;
};

export const switchContent = (type: string, content: any, fields: any[]) => {
    const boolMap = {
        true: i18n.t('yes'),
        false: i18n.t('no'),
    };
    const getField = (value: any) => {
        const field = fields?.find(
            (field) => field.id === (value?.before?.id || value?.after?.id || value?.id)
        );
        if (field?.name || field?.value) {
            return field?.name || field?.value || '';
        } else {
            return titles?.find((title) => value?.toString()?.indexOf(title?.id) !== -1)?.name || '';
        }
    };
    const renderData = (before: any, after: any): React.ReactNode => {
        if (Array.isArray(before)) {
            after ??= [];
            const length = before?.length > after?.length ? before.length : after.length;
            const numberArray = Array.from({ length: length }, (_, index) => (index + 1).toString());
            return numberArray.map((num) => renderData(before[Number(num) - 1], after[Number(num) - 1]));
        } else if (Array.isArray(after)) {
            before ??= [];
            const length = before?.length > after?.length ? before.length : after.length;
            const numberArray = Array.from({ length: length }, (_, index) => (index + 1).toString());
            return numberArray.map((num) => renderData(before[Number(num) - 1], after[Number(num) - 1]));
        } else if (typeof before === 'object' && before !== null) {
            if (before?.file || after?.file) {
                return (
                    <Grid container gap={0.5} alignItems="center">
                        <File {...before} />
                        <ArrowRightAltRoundedIcon color="inherit" />
                        <File {...after} />
                    </Grid>
                );
            } else {
                return renderData(before?.name || before?.value || '', after?.name || after?.value || '');
            }
        } else if (typeof after === 'object' && after !== null) {
            if (before?.file || after?.file) {
                return (
                    <Grid container gap={0.5} alignItems="center">
                        <File {...before} />
                        <ArrowRightAltRoundedIcon color="inherit" />
                        <File {...after} />
                    </Grid>
                );
            } else {
                return renderData(before?.name || before?.value || '', after?.name || after?.value || '');
            }
        } else {
            const newBefore = typeof before === 'object' ? before?.name || before?.value || '' : before || '';
            const newAfter = typeof after === 'object' ? after?.name || after?.value || '' : after || '';
            if (typeof newBefore === 'boolean' || typeof newAfter === 'boolean') {
                return (
                    <Grid container gap={0.5} alignItems="center">
                        {boolMap[(!!newBefore).toString() as keyof typeof boolMap]}
                        <ArrowRightAltRoundedIcon color="inherit" />
                        {boolMap[(!!newAfter).toString() as keyof typeof boolMap]}
                    </Grid>
                );
            }
            return (
                <Grid container gap={0.5} alignItems="center">
                    {Chipper(newBefore, type)}
                    <ArrowRightAltRoundedIcon color="inherit" />
                    {Chipper(newAfter, type)}
                </Grid>
            );
        }
    };
    const renderValue = (value: any) => {
        if (Array.isArray(value)) {
            return value.map((item, index) => {
                return (
                    <Grid container gap={0.5} alignItems="center" my={1} key={index}>
                        {getField(item) && (
                            <Typography variant="caption" fontWeight={500}>
                                {getField(item)}
                            </Typography>
                        )}
                        {renderData(item?.before, item?.after)}
                    </Grid>
                );
            });
        } else if (typeof value === 'object' && value !== null) {
            if (Object.keys(value).find((item) => item === 'before' || item === 'after')) {
                return (
                    <Grid container gap={0.5} alignItems="center" my={1}>
                        {getField(value) && (
                            <Typography variant="caption" fontWeight={500}>
                                {getField(value)}
                            </Typography>
                        )}
                        {renderData(value?.before, value?.after)}
                    </Grid>
                );
            } else {
                return Object.values(value).map((item: any, index) => {
                    return (
                        <Grid container gap={0.5} alignItems="center" my={1} key={index}>
                            {getField(Object.keys(value)[index]) && (
                                <Typography variant="caption" fontWeight={500}>
                                    {getField(Object.keys(value)[index])}
                                </Typography>
                            )}
                            {renderData(item?.before, item?.after)}
                        </Grid>
                    );
                });
            }
        }
    };
    switch (type) {
        case 'task_created':
        case 'object_created':
            return <Typography variant="subtitle2">Объект создан</Typography>;
        case 'task_modified':
        case 'object_modified':
            return (
                <>
                    <Typography variant="subtitle2">Объект изменён:</Typography>
                    {renderValue(content)}
                </>
            );
        case 'task_soft_deleted':
        case 'object_soft_deleted':
            return <Typography variant="subtitle2">Объект перемещён в корзину</Typography>;
        case 'task_restored':
        case 'object_restored':
            return <Typography variant="subtitle2">Объект восстановлен из корзины</Typography>;
        case 'task_responsible_changed':
        case 'responsible_changed':
            return (
                <>
                    <Typography variant="subtitle2">Изменён ответственный сотрудник:</Typography>
                    {renderValue(content)}
                </>
            );
        case 'task_stage_changed':
        case 'stage_changed':
            return (
                <>
                    <Typography variant="subtitle2">Изменён этап воронки:</Typography>
                    {renderValue(content)}
                </>
            );
        case 'task_funnel_changed':
        case 'funnel_changed':
            return (
                <>
                    <Typography variant="subtitle2">Объект перемещён в другую воронку:</Typography>
                    {renderValue(content)}
                </>
            );

        default:
            return (
                <>
                    <Typography variant="subtitle2">{i18n.t('detail')}</Typography>
                    {renderValue(content)}
                </>
            );
    }
};
