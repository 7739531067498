import { ConnectedObjectType } from 'pages/Tasks/List/Card/TaskListCard';

export function isMultiple(
    value: ConnectedObjectType | ConnectedObjectType[]
): value is ConnectedObjectType[] {
    return Array.isArray(value);
}

export function isItemsArray(
    items: ConnectedObjectType | ConnectedObjectType[]
): items is ConnectedObjectType[] {
    return Array.isArray(items);
}
