import { TFunnelView } from 'domain/funnel-view';
import Api from 'shared/api/axios';

export const funnelViewsService = {
    getViewType(params?: any) {
        return Api.get(`/api/v1/funnel-views/view_type`, { params: { ...params } });
    },
    createViewType(params?: any) {
        return Api.post<null, TFunnelView>(`/api/v1/funnel-views/view_type`, { ...params });
    },
    setViewType(id?: string, params?: any) {
        return Api.put<null, TFunnelView>(`/api/v1/funnel-views/view_type/${id}`, { ...params });
    },
    updateFields(id: string, fields: string[]) {
        return Api.patch(`/api/v1/funnel-views/view_type/${id}`, { fields });
    },
    getUserFunnelView(params: any) {
        return Api.get(`/api/v1/funnel-views/get-user-funnel-view`, { params: { ...params } });
    },
};
