import { Component, type ErrorInfo, type ReactNode, Suspense } from 'react';
import { InternalServerError } from '../../../../widgets/InternalServerError';

interface Props {
    children?: ReactNode;
}

interface State {
    hasError: boolean;
}
class ErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(): State {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        // eslint-disable-next-line no-console
        console.log('Error Boundary:', error, errorInfo);
    }

    render(): ReactNode {
        const { children } = this.props;
        const { hasError } = this.state;
        if (hasError) {
            return (
                <Suspense fallback={''}>
                    <InternalServerError />
                </Suspense>
            );
        }

        return children;
    }
}

export default ErrorBoundary;
