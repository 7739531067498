import React, { ChangeEvent, FC, memo, useCallback, useState } from 'react';
import {
    Divider,
    FormControl,
    Grid,
    IconButton,
    Input,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
// import DragIndicatorRoundedIcon from '@mui/icons-material/DragIndicatorRounded';
import { SettingsRounded } from '@mui/icons-material';
import { TMenu } from '../../../../domain/menu';
import { SidebarEditList } from '../SidebarEditList/SidebarEditList';
import { Draggable } from '@hello-pangea/dnd';
import { useMenu } from '../../../../shared/hooks/useMenu';
import { SidebarEditNavListItemMenu } from '../SidebarEditNavListItemMenu/SidebarEditNavListItemMenu';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { useMenuStorage } from '../../lib/hooks/useMenuStorage';
import DragIndicatorRoundedIcon from '@mui/icons-material/DragIndicatorRounded';

interface SidebarEditNavListItemProps {
    menuItem: TMenu;
    index: number;
}
export const SidebarEditNavListItem: FC<SidebarEditNavListItemProps> = memo(({ index, menuItem }) => {
    const { id, name, system, isEdit, items } = menuItem;
    const { open, anchorEl, handleClose, handleClick } = useMenu();
    const isBorder = items.length > 0;
    const [text, setText] = useState<string>(name);
    const { updateMenuName, updateMenuIsEdit, createMenu, updateMenuEvent, menu, updateMenuItem } =
        useMenuStorage();
    const [isChangeName, setIsChangeName] = useState<boolean>(false);

    const handleToggleChangeName = () => setIsChangeName(true);

    const handleChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setText(event.target.value);
        },
        [name]
    );

    const handleUpdate = useCallback(async () => {
        if (isChangeName) {
            await updateMenuName({ id, name: text });
            handleCancel();
            setIsChangeName(false);
        } else {
            const creatableMenu = menu.results[0];
            const res = await createMenu({ name: text, order: 0 });
            await updateMenuItem({ id: creatableMenu.items[0].id, menu: res.id });
            updateMenuEvent({
                ...menu,
                results: menu.results.map((m, index) => {
                    if (index === 0) {
                        return {
                            ...m,
                            ...res,
                            items: creatableMenu.items,
                            isEdit: false,
                        };
                    }
                    return m;
                }),
            });
        }
    }, [text, id, isChangeName]);

    const handleCancel = () => {
        setText('');
        updateMenuIsEdit({ id, isEdit: false });
    };
    return (
        <Draggable key={id} draggableId={id} index={index}>
            {(provided) => (
                <ListItem
                    disablePadding
                    sx={{
                        border: isBorder ? '1px solid rgba(0, 0, 0, 0.16)' : null,
                        borderRadius: '12px',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                >
                    <Grid container alignItems={'center'} justifyContent={'space-between'} px={1} py={1.5}>
                        <div {...provided.dragHandleProps} />
                        <ListItemIcon {...provided.dragHandleProps}>
                            <DragIndicatorRoundedIcon color={'action'} />
                        </ListItemIcon>
                        {isEdit ? (
                            <Grid container alignItems={'center'} flexWrap={'nowrap'} width={'100%'}>
                                <Grid item>
                                    <FormControl fullWidth>
                                        <Input
                                            onChange={handleChange}
                                            placeholder={text}
                                            value={text}
                                            sx={{ border: 0, p: 0 }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container width={'auto'} flexWrap={'nowrap'} ml={1}>
                                    <IconButton
                                        onClick={handleUpdate}
                                        sx={{
                                            width: 28,
                                            height: 28,
                                            borderRadius: '4px',
                                            backgroundColor: open ? 'rgba(88, 70, 250, 0.3)' : null,
                                        }}
                                    >
                                        <CheckCircleRoundedIcon fontSize="small" color={'action'} />
                                    </IconButton>
                                    <IconButton
                                        onClick={handleCancel}
                                        sx={{
                                            width: 28,
                                            height: 28,
                                            borderRadius: '4px',
                                            backgroundColor: open ? 'rgba(88, 70, 250, 0.3)' : null,
                                        }}
                                    >
                                        <CancelRoundedIcon fontSize="small" color={'action'} />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        ) : (
                            <>
                                <ListItemText>{name}</ListItemText>
                                <ListItemIcon
                                    sx={{
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    {!system ? (
                                        <IconButton
                                            color={open ? 'primary' : 'default'}
                                            sx={{
                                                width: 28,
                                                height: 28,
                                                borderRadius: '4px',
                                                backgroundColor: open ? 'rgba(88, 70, 250, 0.3)' : null,
                                            }}
                                            onClick={handleClick}
                                        >
                                            <SettingsRounded
                                                sx={{
                                                    fontSize: '20px',
                                                }}
                                            />
                                        </IconButton>
                                    ) : null}
                                </ListItemIcon>
                            </>
                        )}
                        <SidebarEditNavListItemMenu
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            handleClose={handleClose}
                            handleToggleChangeName={handleToggleChangeName}
                        />
                    </Grid>
                    {isBorder ? <Divider variant={'fullWidth'} flexItem /> : null}
                    <SidebarEditList menuId={id} items={items} system={system} />
                </ListItem>
            )}
        </Draggable>
    );
});
