import React, { MouseEvent, useEffect, useMemo, useState } from 'react';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import {
    Box,
    Checkbox,
    Divider,
    IconButton,
    Menu,
    MenuItem,
    TableFooter,
    Typography,
    useTheme,
} from '@mui/material';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import TableBody from '@mui/material/TableBody';
import { Button, Input } from 'shared/ui';
import { ConnectedObjectType } from 'pages/Tasks/List/Card/TaskListCard';
import { useConnectEntityModal, useUsersStorage } from 'services/StorageAdapter';
import { FormikProps, FormikValues } from 'formik';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { similarity } from 'shared/helpers/compareString';
import SearchIcon from '@mui/icons-material/Search';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useTranslation } from 'react-i18next';

interface DepartmentEmployeesTabProps {
    setValue: (...args: [field: string, value: any, shouldValidate?: boolean | undefined]) => void;
    employees: ConnectedObjectType[];
}

const DepartmentEmployeesTab = ({ setValue, employees }: DepartmentEmployeesTabProps) => {
    const { t } = useTranslation();
    const { t: departmentsT } = useTranslation('departmentsControl');
    const { open: openModal } = useConnectEntityModal();
    const { me } = useUsersStorage();
    const {
        palette: {
            background: { paper },
        },
    } = useTheme();
    const [selected, setSelected] = useState<Record<string, boolean>>({});
    const addEmployees = () => {
        openModal({
            code: 'department-employees',
            handleSelect(entity): void {
                if (Array.isArray(entity)) {
                    setValue('employees', [
                        ...employees,
                        ...entity.filter((employee) => {
                            return !employees.some((e) => e.id === employee.id);
                        }),
                    ]);
                }
            },
            selected: employees,
            type: 'm2m_employee',
        });
    };

    const deleteEmployees = () => {
        setValue(
            'employees',
            employees.filter((employee) => {
                return !selected[employee.id];
            })
        );
        setSelected((prev) => {
            return Object.keys(prev).reduce((acc: any, key) => {
                if (!prev[key]) {
                    acc[key] = false;
                }
                return acc;
            }, {});
        });
    };

    useEffect(() => {
        setSelected((prev) => ({
            ...employees.reduce(
                (acc, employee) => ({
                    ...acc,
                    [employee.id]: false,
                }),
                {}
            ),
            ...prev,
        }));
    }, [employees]);

    const isSomeChecked = useMemo(() => {
        return Object.keys(selected).some((key: any) => selected[key]);
    }, [selected]);

    const isAllChecked = useMemo(() => {
        const keys = Object.keys(selected);
        return !!keys.length && !keys.some((key: any) => !selected[key]);
    }, [selected]);

    const isIndeterminate = useMemo(() => {
        return !isAllChecked && isSomeChecked;
    }, [isSomeChecked, isAllChecked]);

    const setAll = (state: boolean) => {
        setSelected((prev) => {
            return Object.keys(prev).reduce((acc, key) => ({ ...acc, [key]: state }), {});
        });
    };

    const [search, setSearch] = useState('');
    const [isSearchActive, setIsSearchActive] = useState(false);

    const filteredEmployees = useMemo(() => {
        return [...employees.values()].filter((employee) => {
            const fullName = `${employee.user?.first_name} ${employee.user?.last_name}`;
            const fullNameSimilarity = similarity(fullName, search);
            const firstNameSimilarity = similarity(`${employee.user?.first_name}`, search);
            const lastNameSimilarity = similarity(`${employee.user?.last_name}`, search);
            return (
                firstNameSimilarity > 0.5 ||
                lastNameSimilarity > 0.5 ||
                fullNameSimilarity > 0.5 ||
                fullName.includes(search)
            );
        });
    }, [employees, search]);

    return (
        <Box
            sx={{
                width: '100%',
                mb: 2,
                position: 'relative',
                borderRadius: '12px !important',
                border: '1px solid #00000029',
                boxShadow: 'none',
                overflow: 'hidden',
            }}
        >
            <TableContainer sx={{ height: 400 }}>
                <Table
                    stickyHeader
                    sx={{
                        height: '100%',
                    }}
                >
                    <TableHead
                        sx={{
                            boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.08)',
                        }}
                    >
                        <TableRow>
                            {me.is_admin ? (
                                <TableCell padding={'checkbox'} width={'54px'}>
                                    <Checkbox
                                        onChange={(_event, checked) => {
                                            setAll(checked);
                                        }}
                                        checked={isAllChecked}
                                        indeterminate={isIndeterminate}
                                        size={'small'}
                                    />
                                </TableCell>
                            ) : null}
                            <TableCell colSpan={isSearchActive ? 3 : 2}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                    }}
                                >
                                    {isSearchActive ? (
                                        <Input
                                            onChange={(e) => setSearch(e.target.value)}
                                            value={search}
                                            fullWidth
                                            InputProps={{
                                                startAdornment: <SearchIcon />,
                                            }}
                                            placeholder={departmentsT('searchEmployeesPlaceholder')}
                                        />
                                    ) : (
                                        <Typography variant={'h6'}>
                                            {departmentsT('listEmployees')}
                                        </Typography>
                                    )}
                                    {isSearchActive && (
                                        <IconButton
                                            onClick={() => {
                                                setIsSearchActive(false);
                                                setSearch('');
                                            }}
                                            size={'medium'}
                                        >
                                            <CloseRoundedIcon fontSize={'inherit'} />
                                        </IconButton>
                                    )}
                                </Box>
                            </TableCell>
                            {!isSearchActive && (
                                <TableCell align={'right'}>
                                    <IconButton onClick={() => setIsSearchActive(true)} size={'medium'}>
                                        <SearchRoundedIcon fontSize={'inherit'} />
                                    </IconButton>
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredEmployees.map((employee) => {
                            return (
                                <TableEmployeeRow
                                    setChecked={(id, state) =>
                                        setSelected((prev) => ({ ...prev, [id]: state }))
                                    }
                                    checked={selected[employee.id] || isAllChecked}
                                    setValue={setValue}
                                    employees={employees}
                                    key={employee?.id}
                                    employee={employee}
                                />
                            );
                        })}
                        <TableRow />
                    </TableBody>
                    {me.is_admin ? (
                        <TableFooter sx={{ backgroundColor: paper }}>
                            <TableRow>
                                <TableCell
                                    sx={{
                                        backgroundColor: paper,
                                        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                                    }}
                                    align={'left'}
                                    colSpan={4}
                                >
                                    <Box
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <Button size={'small'} variant={'text'} onClick={addEmployees}>
                                            {departmentsT('addMoveEmployees')}
                                        </Button>
                                        {(isAllChecked || isIndeterminate) && (
                                            <Button
                                                color={'error'}
                                                size={'small'}
                                                variant={'text'}
                                                onClick={deleteEmployees}
                                                startIcon={<DeleteRoundedIcon />}
                                            >
                                                {t('removeFromList')}
                                            </Button>
                                        )}
                                    </Box>
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    ) : null}
                </Table>
            </TableContainer>
        </Box>
    );
};

type TableEmployeeRowProps = {
    employee: ConnectedObjectType;
    setValue: FormikProps<FormikValues>['setFieldValue'];
    employees: ConnectedObjectType[];
    checked: boolean;
    setChecked: (id: string, state: boolean) => void;
};

// const TableHeadRow = ({ employee, setValue, employees }: TableEmployeeRowProps) => {
//     const [ref, setRef] = useState<null | HTMLElement>(null);
//     const open = Boolean(ref);
//
//     const handleClickMenu = (e: MouseEvent<HTMLButtonElement>) => {
//         setRef(e.currentTarget);
//     };
//
//     const handleCloseMenu = () => {
//         setRef(null);
//     };
//     const handleSetEmployee = () => {
//         if (employee && !employees.some((e) => e.id === employee.user?.id)) {
//             setValue('employees', [employee, ...employees]);
//             handleCloseMenu();
//         }
//     };
//
//     const handleDelete = () => {
//         setValue('head', null);
//         handleCloseMenu();
//     };
//     return (
//         <>
//             <TableRow>
//                 <TableCell style={{ top: 73 }} padding={'checkbox'}>
//                     <Checkbox size={'small'} />
//                 </TableCell>
//                 <TableCell
//                     style={{ top: 73 }}
//                     sx={{
//                         width: '54px',
//                     }}
//                     padding={'none'}
//                 >
//                     <IconButton onClick={handleClickMenu} size={'small'}>
//                         <MoreVertRoundedIcon fontSize={'inherit'} />
//                     </IconButton>
//                 </TableCell>
//                 <TableCell style={{ top: 73 }} align={'left'} padding={'none'}>
//                     {employee.user ? (
//                         <>
//                             {employee.user?.first_name} {employee.user?.last_name}
//                         </>
//                     ) : (
//                         <>{employee.name}</>
//                     )}
//                 </TableCell>
//                 <TableCell style={{ top: 73 }}>
//                     <Badge
//                         sx={{
//                             '& span': {
//                                 position: 'static',
//                                 transform: 'none',
//                             },
//                         }}
//                         badgeContent={'Руководитель'}
//                         variant={'standard'}
//                         color={'primary'}
//                     />
//                 </TableCell>
//             </TableRow>
//             <Menu
//                 id="basic-menu"
//                 anchorEl={ref}
//                 open={open}
//                 onClose={handleCloseMenu}
//                 MenuListProps={{
//                     'aria-labelledby': 'basic-button',
//                 }}
//             >
//                 <MenuItem onClick={handleCloseMenu}>Открыть</MenuItem>
//                 <MenuItem onClick={handleSetEmployee}>Назначить сотрудником</MenuItem>
//                 <Divider />
//                 <MenuItem onClick={handleDelete} sx={{ color: '#D32F2F' }}>
//                     Удалить из списка
//                 </MenuItem>
//             </Menu>
//         </>
//     );
// };

const TableEmployeeRow = ({ employee, setValue, employees, checked, setChecked }: TableEmployeeRowProps) => {
    const { t } = useTranslation();
    const { t: departmentsT } = useTranslation('departmentsControl');
    const [ref, setRef] = useState<null | HTMLElement>(null);
    const open = Boolean(ref);
    const { me } = useUsersStorage();

    const handleClickMenu = (e: MouseEvent<HTMLButtonElement>) => {
        setRef(e.currentTarget);
    };

    const handleCloseMenu = () => {
        setRef(null);
    };

    const handleSetHead = () => {
        setValue('head', employee);
        handleCloseMenu();
    };

    const handleDelete = () => {
        setValue(
            'employees',
            employees.filter((e) => e.id !== employee.user?.id && e.id !== employee.id)
        );
        handleCloseMenu();
    };

    return (
        <>
            <TableRow sx={{ height: '76px' }}>
                {me.is_admin ? (
                    <TableCell padding={'checkbox'}>
                        <Checkbox
                            onChange={(_event, checked) => setChecked(employee.id, checked)}
                            checked={checked}
                            size={'small'}
                        />
                    </TableCell>
                ) : null}
                <TableCell
                    sx={{
                        width: '54px',
                    }}
                    padding={'none'}
                >
                    <IconButton onClick={handleClickMenu} size={'small'}>
                        <MoreVertRoundedIcon fontSize={'inherit'} />
                    </IconButton>
                </TableCell>
                <TableCell align={'left'} padding={'none'}>
                    {employee.first_name || employee.last_name ? (
                        <>
                            {employee.first_name} {employee.last_name}
                        </>
                    ) : employee.name ? (
                        <>{employee.name}</>
                    ) : (
                        <>
                            {employee.user?.first_name} {employee.user?.last_name}
                        </>
                    )}
                </TableCell>
                <TableCell>{t('employee')}</TableCell>
            </TableRow>
            <Menu
                id="basic-menu"
                anchorEl={ref}
                open={open}
                onClose={handleCloseMenu}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    sx: {
                        width: '222px',
                    },
                }}
            >
                <MenuItem onClick={handleCloseMenu}>{t('open')}</MenuItem>
                {me.is_admin ? (
                    <>
                        <MenuItem onClick={handleSetHead}>{departmentsT('setHead')}</MenuItem>
                        <Divider />
                        <MenuItem onClick={handleDelete} sx={{ color: '#D32F2F' }}>
                            {t('removeFromList')}
                        </MenuItem>
                    </>
                ) : null}
            </Menu>
        </>
    );
};

export default DepartmentEmployeesTab;
