import { useCallback, useRef } from 'react';

const useDebouncedCallback = (func: (...args: any) => void, wait: number) => {
    // Use a ref to store the timeout between renders
    // and prevent changes to it from causing re-renders
    const timeout = useRef<ReturnType<typeof setTimeout>>();

    return useCallback(
        (...args: any) => {
            const later = () => {
                clearTimeout(timeout.current);
                func(...args);
            };

            clearTimeout(timeout.current);
            timeout.current = setTimeout(later, wait);
        },
        [func, wait]
    );
};

export default useDebouncedCallback;
