import {
    AlertTitle,
    Button,
    CircularProgress,
    Grid,
    IconButton,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import CreateRoundedIcon from '@mui/icons-material/CreateRounded';
import CancelIcon from '@mui/icons-material/Cancel';
import { Notice } from 'shared/ui';
import FilterField from '../FilterField/FilterField';
import { useEffect, useState } from 'react';
import { useMetaStorage, useNotification, useUsersStorage } from 'services/StorageAdapter';
import { useFilterStorage } from 'widgets/Filter/lib/hooks/useFilterStorage';
import { useTranslation } from 'react-i18next';
import { taskFields } from 'widgets/Filter/lib/const/taskFields';
import { MOBILE_QUERY_WIDTH } from 'shared/consts';

interface IFilterPresetSettings {
    onCloseFilter: () => void;
    onOpenFields: () => void;
    onClearFields: () => void;
    onNewPreset: () => void;
    onRejectPreset: () => void;
    startSearch: () => void;
    fields: string[];
    newPreset: boolean;
    newPresetTitle?: string;
}

const FilterPresetSettings = ({
    onCloseFilter,
    onOpenFields,
    onClearFields,
    onNewPreset,
    onRejectPreset,
    startSearch,
    fields,
    newPreset,
    newPresetTitle,
}: IFilterPresetSettings) => {
    const { t } = useTranslation();
    const { me } = useUsersStorage();
    const { meta } = useMetaStorage();
    const { notify } = useNotification();
    const isMobile = useMediaQuery(MOBILE_QUERY_WIDTH);
    const [loading, setLoading] = useState<boolean>(false);
    const { filter, filters, createFilterUsers, updateFilterStore, getFiltersUsers } = useFilterStorage();
    const isTask = Boolean(meta.code === 'tasks');
    const {
        palette: {
            background: { paper },
        },
    } = useTheme();
    const preset = {
        id: '',
        name: '',
        filters: {},
        type: 'user',
        entity: meta.id,
    };

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 300);
    }, [Object.keys(filter?.filters as object).length]);

    useEffect(() => {
        handleAdd(fields);
    }, [fields]);

    const handleGetAllPresets = () => {
        onRejectPreset();
        getFiltersUsers({
            ...filters.params,
            entity: meta.id as string,
            user: me.id as string,
            limit: 10,
            offset: 0,
        });
    };

    const handleChange = (type: string, value: any) => {
        filter?.type === 'user' ? (filter.user = me.id as string) : delete filter?.user;
        updateFilterStore({ ...filter, [type]: value });
    };

    const handleAdd = (selected: string[]) => {
        const newObject: { [index: string]: string } = {};
        for (const key of selected) {
            if (taskFields.find((field) => field.id === key)) {
                newObject[key] = '';
            } else {
                newObject[key + '__'] = '';
            }
        }
        handleChange('filters', { ...newObject });
    };

    const handleValue = (value: { [index: string]: string }) => {
        const newKey = Object.keys(value)[0];
        const arr: { key: string; value: string }[] = Object.entries(
            filter.filters as { [index: string]: string }
        ).map(([key, value]) => ({ key, value }));

        const index = arr.findIndex((item) => item.key?.split('__')[0] === newKey?.split('__')[0]);

        if (index !== -1) {
            arr[index] = { key: newKey, value: value[newKey] };
        }

        const obj: { [index: string]: string } = {};
        arr.forEach(({ key, value }) => {
            obj[key] = value;
        });

        handleChange('filters', obj);
    };

    const handleRemove = (idx: number) => {
        const keys = Object.keys(filter?.filters as object);
        filter?.filters && delete filter.filters[keys[idx]];
        handleChange('filters', { ...filter.filters });
    };

    const onCreate = () => {
        const newData = { ...filter, name: filter.name || (newPresetTitle as string) };
        createFilterUsers(newData)
            .then(() => {
                notify({ message: `Фильтор "${newData.name}" создан` });
                handleGetAllPresets();
            })
            .catch(() => notify({ message: t('somethingWentWrong') }));
    };

    return (
        <>
            <Grid item xs={!isTask ? 9 : 12} display="flex" flexDirection="column" bgcolor={paper}>
                <Grid
                    bgcolor={paper}
                    px={2}
                    py={1}
                    container
                    width="100%"
                    alignItems={'center'}
                    justifyContent="space-between"
                    borderBottom={'1px solid #0000001F'}
                    flex="0 0 auto"
                >
                    <Grid item xs={5} display={'flex'} alignItems={'center'}>
                        <Typography variant="h6">{t('filters')}</Typography>
                    </Grid>
                    <Grid item xs={5} display={'flex'} justifyContent={'end'} alignItems={'center'}>
                        <IconButton size="small" onClick={onCloseFilter}>
                            <CloseIcon fontSize={'small'} />
                        </IconButton>
                    </Grid>
                </Grid>

                <Grid
                    item
                    width={'100%'}
                    overflow={'scroll'}
                    px={3}
                    py={1.5}
                    flex="1 1 auto"
                    height={isMobile ? 'calc(100vh - 195px)' : '378px'}
                    bgcolor={paper}
                >
                    {loading ? (
                        <Grid item xs={12} display="flex" justifyContent="center">
                            <CircularProgress color="primary" />
                        </Grid>
                    ) : (
                        <>
                            {!Object.entries(filter?.filters as object).length ? (
                                <Grid item mb={1} gap={0.5} display="flex" alignItems="center">
                                    <Notice>
                                        <AlertTitle>{t('emptySetFields')}</AlertTitle>
                                    </Notice>
                                </Grid>
                            ) : (
                                <Grid item mb={2} gap={3} xs={12} display="grid">
                                    {Object.entries(filter?.filters as object).map((field, idx: number) => (
                                        <FilterField
                                            value={{ [field[0]]: field[1] }}
                                            onValue={(value) => handleValue(value)}
                                            onRemove={() => handleRemove(idx)}
                                            key={idx}
                                        />
                                    ))}
                                </Grid>
                            )}
                            <Grid item mb={0.5} gap={1} display="flex" alignItems="center">
                                <Button
                                    variant={'text'}
                                    color="primary"
                                    size={'small'}
                                    startIcon={<CreateRoundedIcon />}
                                    onClick={onOpenFields}
                                >
                                    {t('settingsSetFields')}
                                </Button>
                                {!!fields.length && (
                                    <Button
                                        variant={'text'}
                                        color="inherit"
                                        size={'small'}
                                        startIcon={<CancelIcon />}
                                        onClick={onClearFields}
                                    >
                                        {t('clearFields')}
                                    </Button>
                                )}
                            </Grid>
                        </>
                    )}
                </Grid>

                <Grid
                    item
                    py={1.5}
                    px={2}
                    gap={1}
                    width="100%"
                    display="flex"
                    justifyContent="space-between"
                    borderTop={'1px solid #0000001F'}
                    flex="0 0 auto"
                    bgcolor={paper}
                >
                    {!isTask && (
                        <Button
                            variant={'text'}
                            color="primary"
                            size="small"
                            startIcon={<AddIcon fontSize={'small'} />}
                            onClick={() => {
                                onNewPreset();
                                updateFilterStore(preset);
                            }}
                            disabled={newPreset}
                            sx={{ display: { xs: 'none', md: 'flex' } }}
                        >
                            {t('newSet')}
                        </Button>
                    )}
                    <Grid item gap={1} display="flex" justifyContent="space-between">
                        <Button
                            endIcon={newPreset ? '' : <SearchIcon fontSize={'small'} />}
                            variant={'contained'}
                            color="primary"
                            size="small"
                            onClick={() => (newPreset ? onCreate() : startSearch())}
                        >
                            {newPreset ? t('saveSet') : t('search')}
                        </Button>
                        <Button
                            variant={'outlined'}
                            size="small"
                            onClick={() => {
                                updateFilterStore(preset);
                                onRejectPreset();
                            }}
                        >
                            {t('reset')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default FilterPresetSettings;
