import React, { useContext } from 'react';
import { useDrop } from 'react-dnd';
import { Box, useTheme } from '@mui/material';
import { DepartmentsMapContext } from 'widgets/DepartmentsMap/DepartmentsMap';

type DropLineProps = {
    orientation: 'vertical' | 'horizontal';
    parentId: string;
};

const DropLine = ({ orientation, parentId }: DropLineProps) => {
    const { changeNodeParent } = useContext(DepartmentsMapContext);
    const {
        palette: {
            primary: { main },
        },
    } = useTheme();
    const [{ isOver, canDrop }, dropRef] = useDrop({
        accept: 'card',
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
        canDrop: (item: any) => {
            return item.parentId !== parentId && item.id !== parentId;
        },
        drop: (_item, monitor: any) => {
            changeNodeParent(monitor.getItem().id, parentId);
        },
    });

    return (
        <Box
            ref={dropRef}
            sx={{
                ...(orientation === 'vertical'
                    ? {
                          height: '213px',
                          width: '60px',
                      }
                    : {
                          height: '80px',
                          width: '340px',
                          transform: 'translateY(15px)',
                      }),
                maxWidth: '100%',
                opacity: isOver && canDrop ? 1 : 0,
                position: 'relative',
                flexShrink: 0,
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    ...(orientation === 'vertical'
                        ? {
                              top: 0,
                              left: '50%',
                              height: '100%',
                              width: '1px',
                              transform: 'translateX(-50%)',
                              borderLeft: `3px solid ${main}`,
                              borderLeftStyle: 'dashed',
                          }
                        : {
                              top: '50%',
                              left: 0,
                              height: '1px',
                              width: '100%',
                              transform: 'translateY(-50%)',
                              borderTop: `3px solid ${main}`,
                              borderTopStyle: 'dashed',
                          }),
                }}
            />
        </Box>
    );
};

export default DropLine;
