import { createEffect, createEvent, createStore } from 'effector';
import { TMenuItemResponse, TMenuResponse, TParamsRequest } from 'shared/api/types';
import { IBulkUpdateMenuItem, IMenuApiService, IMenuItem } from '../../types/menuSchema';
import { menuApiService } from '../../api/services/menuApiService';
import { TMenu, TMenuItem } from '../../../../domain/menu';

const initialMenuStore: TMenuResponse = {
    next: '',
    previous: '',
    count: 0,
    results: [],
};

const initialMenuItemStore: TMenuItemResponse = {
    next: '',
    previous: '',
    count: 0,
    results: [],
};

const menuApi: IMenuApiService = menuApiService();

const fetchMenuFx = createEffect(async (): Promise<TMenuResponse> => {
    return await menuApi.fetchMenu();
});

const createMenuFx = createEffect(async (data: { name: string; order: number }): Promise<TMenu> => {
    return await menuApi.createMenu(data);
});

const fetchMenuDetailFx = createEffect(async ({ id }: { id: string }): Promise<TMenuItem> => {
    return await menuApi.fetchMenuDetail({ id });
});

const updateMenuFx = createEffect(async (data: { id: string; name: string }): Promise<{ name: string }> => {
    return await menuApi.updateMenu(data);
});

const updateMenuNameFx = createEffect(
    async (data: { id: string; name: string }): Promise<{ name: string }> => {
        return await menuApi.updateMenu(data);
    }
);

const deleteMenuFx = createEffect(async ({ id }: { id: string }): Promise<null> => {
    return await menuApi.deleteMenu({ id });
});

const fetchMenuItemFx = createEffect(async (data: TParamsRequest): Promise<TMenuItemResponse> => {
    return await menuApi.fetchMenuItem(data);
});

const createMenuItemFx = createEffect(async (data: IMenuItem): Promise<IMenuItem> => {
    return await menuApi.createMenuItem(data);
});

const fetchMenuItemDetailFx = createEffect(async ({ id }: { id: string }): Promise<IMenuItem> => {
    return await menuApi.fetchMenuItemDetail({ id });
});

const updateMenuItemFx = createEffect(
    async (data: {
        id: string;
        name?: string;
        uri?: string;
        entity?: string;
        menu?: string;
    }): Promise<IMenuItem> => {
        return await menuApi.updateMenuItem(data);
    }
);

const deleteMenuItemFx = createEffect(async ({ id }: { id: string }): Promise<null> => {
    return await menuApi.deleteMenuItem({ id });
});

const setMenuItemsFx = createEffect(async (data: { id: string; menu_items: string[] }): Promise<null> => {
    return await menuApi.setMenuItems(data);
});

const unSetMenuItemsFx = createEffect(async (data: { id: string; menu_items: string[] }): Promise<null> => {
    return await menuApi.unSetMenuItems(data);
});

const bulkUpdateMenuFx = createEffect(async (data: IBulkUpdateMenuItem): Promise<IBulkUpdateMenuItem> => {
    return await menuApi.bulkUpdateMenu(data);
});

const updateMenu = createEvent<TMenuResponse>();
const updateMenuIsEdit = createEvent<{ isEdit: boolean; id: string }>();
const updateMenuItemIsEdit = createEvent<{ isEdit: boolean; id: string; menuId: string }>();
const updateMenuItemMove = createEvent<{ item: TMenuItem; menu: string; currMenuId: string }>();
const updateMenuItem = createEvent();
const clearMenu = createEvent();

const $menuStore = createStore<TMenuResponse>(initialMenuStore)
    .on(updateMenu, (_, newMenu) => newMenu)
    .on(updateMenuIsEdit, (state, { isEdit, id }) => {
        return {
            ...state,
            results: state.results.map((menu) => {
                if (menu.id === id) {
                    return {
                        ...menu,
                        isEdit,
                    };
                }
                return menu;
            }),
        };
    })
    .on(updateMenuItemIsEdit, (state, { isEdit, id, menuId }) => {
        return {
            ...state,
            results: state.results.map((menu) => {
                if (menu.id === menuId) {
                    return {
                        ...menu,
                        items: menu.items.map((menuItem) => {
                            if (menuItem.id === id) {
                                return {
                                    ...menuItem,
                                    isEdit,
                                };
                            }
                            return menuItem;
                        }),
                    };
                }
                return menu;
            }),
        };
    })
    .on(updateMenuNameFx.done, (state, { params, result }) => {
        return {
            ...state,
            results: state.results.map((menu) => {
                if (menu.id === params.id) {
                    return {
                        ...menu,
                        name: result.name,
                    };
                }
                return menu;
            }),
        };
    })
    .on(deleteMenuFx.done, (state, { params }) => {
        return {
            ...state,
            results: state.results.filter((menu) => menu.id !== params.id),
        };
    })
    .on(fetchMenuFx.doneData, (_, newMenu) => newMenu)
    .on(setMenuItemsFx.done, (state, { params }: { params: any }) => {
        return {
            ...state,
            results: state.results.map((menu) => {
                if (menu.id === params.menuId) {
                    return {
                        ...menu,
                        items: menu.items.filter((item) => item.id !== params.menu_items[0]),
                    };
                }
                return menu;
            }),
        };
    })
    .on(updateMenuItemFx.done, (state, { params, result }) => {
        return {
            ...state,
            results: state.results.map((m) => {
                if (m.id === params.menu) {
                    return {
                        ...m,
                        items: m.items.map((i) => {
                            if (i.id === params.id) {
                                return {
                                    ...i,
                                    ...result,
                                };
                            }
                            return i;
                        }),
                    };
                }
                return m;
            }),
        };
    })
    .on(updateMenuItemMove, (state, payload) => {
        const deleteActions = {
            ...state,
            results: state.results.map((menu) => {
                if (menu.id === payload.currMenuId) {
                    return {
                        ...menu,
                        items: menu.items.filter((i) => i.id !== payload.item.id),
                    };
                }
                return menu;
            }),
        };
        const newState = {
            ...deleteActions,
            results: deleteActions.results.map((menu) => {
                if (menu.id === payload.menu) {
                    return {
                        ...menu,
                        items: [...menu.items, payload.item],
                    };
                }
                return menu;
            }),
        };
        return newState;
    })
    .on(updateMenuItem, (state, payload: any) => {
        return {
            ...state,
            results: state.results.map((m) => {
                if (m.id === payload.menuId) {
                    return {
                        ...m,
                        items: payload.items,
                    };
                }
                return m;
            }),
        };
    })
    .reset(clearMenu);

const clearMenuItem = createEvent();
const $menuItemStore = createStore<TMenuItemResponse>(initialMenuItemStore)
    .on(fetchMenuItemFx.doneData, (_, newMenu) => newMenu)
    .on(unSetMenuItemsFx.done, (state, { params }) => {
        return {
            ...state,
            results: state.results.filter((i) => i.id !== params.menu_items[0]),
        };
    })
    .reset(clearMenuItem);

export const menuStores = {
    $menuStore,
    $menuItemStore,
};

export const menuEffects = {
    fetchMenuFx,
    createMenuFx,
    fetchMenuDetailFx,
    updateMenuFx,
    deleteMenuFx,
    createMenuItemFx,
    fetchMenuItemDetailFx,
    updateMenuItemFx,
    deleteMenuItemFx,
    fetchMenuItemFx,
    setMenuItemsFx,
    unSetMenuItemsFx,
    updateMenuNameFx,
    bulkUpdateMenuFx,
};

export const menuEvents = {
    updateMenu,
    clearMenu,
    clearMenuItem,
    updateMenuIsEdit,
    updateMenuItemIsEdit,
    updateMenuItemMove,
    updateMenuItem,
};
