import { useSnackbar } from 'notistack';
import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { FC } from 'react';

interface SnackbarCloseButtonProps {
    snackbarKey: string | number;
}
export const SnackbarCloseButton: FC<SnackbarCloseButtonProps> = ({ snackbarKey }) => {
    const { closeSnackbar } = useSnackbar();
    const onClose = () => closeSnackbar(snackbarKey);
    return (
        <Box
            width={28}
            height={28}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            boxShadow={
                '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)'
            }
            borderRadius={'4px'}
            position={'absolute'}
            top={'-12px'}
            left={'-12px'}
            sx={{ cursor: 'pointer', backgroundColor: '#FFFFFF' }}
        >
            <CloseIcon color={'action'} onClick={onClose} />
        </Box>
    );
};
