import { createEvent, createStore, guard, sample } from 'effector';
import { createEffect } from 'effector/effector.umd';
import { authService } from 'shared/api/auth.service';
import { AxiosError } from 'axios';

const initialFormState = {
    email: '',
    password: '',
};

type AuthFormStateType = typeof initialFormState;

const loginFx = createEffect(async (data: AuthFormStateType) => {
    const result = await authService.login(data);
    return {
        accessToken: result.access,
        refreshToken: result.refresh,
    };
});

const updateLogin = createEvent<string>();
const updatePassword = createEvent<string>();
const submitForm = createEvent();

const authFormStore = createStore<AuthFormStateType>(initialFormState)
    .on(updateLogin, (state, payload) => ({ ...state, email: payload }))
    .on(updatePassword, (state, payload) => ({ ...state, password: payload }));

const initialAuthStore = {
    accessToken: localStorage.getItem('token') ?? '',
    refreshToken: '',
};

type AuthStoreType = typeof initialAuthStore;

const updateToken = createEvent<string>();

const authStore = createStore<AuthStoreType>(initialAuthStore)
    .on(loginFx.doneData, (_, payload) => payload)
    .on(updateToken, (state, payload) => ({ ...state, accessToken: payload }));
const isAuthenticatingStore = loginFx.pending;
const isAuthenticatedStore = authStore.map((state) => !!state.accessToken);

sample({
    clock: authStore,
    source: authStore,
    filter: (data) => !!data.accessToken,
    fn: (_, clockData) => !!clockData,
    target: isAuthenticatedStore,
});

guard({
    clock: submitForm,
    source: authFormStore,
    filter: (data) => !!data.email?.length && !!data.password?.length,
    target: loginFx,
});

type AuthErrorsStateType = { email?: string[]; password?: string[]; detail?: string };

const authErrorsStore = createStore<AuthErrorsStateType>({})
    .on(loginFx.failData, (state, payload) => {
        const { response } = payload as AxiosError;
        if (response?.data) {
            return { ...response.data };
        }
        return state;
    })
    .on(updatePassword, () => ({}))
    .on(updateLogin, () => ({}));

export const authStores = {
    authFormStore,
    authStore,
    isAuthenticatingStore,
    authErrorsStore,
    isAuthenticatedStore,
};

export const authEvents = {
    updateLogin,
    updatePassword,
    submitForm,
    updateToken,
};
