import { createEffect, createEvent, createStore } from 'effector';
import { permissionsApiService } from 'services/ApiAdapter';
import { TParamsRequest, TResponse } from 'shared/api/types';

const initialPermissonsStore = {
    next: '',
    previous: '',
    count: 0,
    results: [],
    params: {
        offset: 0,
        limit: 10,
        is_deleted: false,
    },
};

const updatePermissionFx = createEffect(async (data: any) => {
    const permissionsApi: any = permissionsApiService();
    return await permissionsApi.updatePermission(data);
});

const deletePermissionFx = createEffect(async (id: string) => {
    const permissionsApi: any = permissionsApiService();
    return await permissionsApi.deletePermission(id);
});

const fetchPermissionsFx = createEffect(async (params: TParamsRequest): Promise<TResponse> => {
    const permissionsApi: any = permissionsApiService();
    return await permissionsApi.getPermissions(params);
});

const createPermissionFx = createEffect(async (data: any) => {
    const permissionsApi: any = permissionsApiService();
    return await permissionsApi.createPermission(data);
});

const updatePermissions = createEvent<TResponse>();
const clearPermissions = createEvent();

const $permissionsStore = createStore<TResponse>(initialPermissonsStore)
    .on(updatePermissions, (_, permission) => permission)
    .on(fetchPermissionsFx.doneData, (_, permission) => permission)
    .on(updatePermissionFx.doneData, (state, permission) => {
        const newResults = state.results.map((u: any) => {
            if (u.id === permission.id) {
                return { ...u, ...permission };
            }
            return u;
        });
        state.results = newResults;
        return state;
    })
    .reset(clearPermissions);

export const permissionsStores = {
    $permissionsStore,
};

export const permissionsEvents = {
    updatePermissions,
    clearPermissions,
};

export const permissionsEffects = {
    updatePermissionFx,
    fetchPermissionsFx,
    deletePermissionFx,
    createPermissionFx,
};
