import { TFunnelViewResponse, TResponse } from 'shared/api/types';
import { createEffect, createEvent, createStore } from 'effector';
import { IFunnelViewsApiService } from 'application/ports/Api';
import { funnelViewsApiService } from 'services/ApiAdapter';
import { TFunnelView } from 'domain/funnel-view';

const initialFunnelViewStore: TFunnelViewResponse = {
    next: '',
    previous: '',
    count: 0,
    results: [],
    params: {
        offset: 0,
        limit: 10,
    },
};

const funnelViewApi: IFunnelViewsApiService = funnelViewsApiService();

const getViewTypeFx = createEffect(async (params: any): Promise<TResponse> => {
    return await funnelViewApi.getViewType(params);
});

const createViewTypeFx = createEffect(async (params: any): Promise<TFunnelView> => {
    return await funnelViewApi.createViewType(params);
});

const setViewTypeFx = createEffect(
    async ({ id, params }: { id: string; params: any }): Promise<TFunnelView> => {
        return await funnelViewApi.setViewType(id, params);
    }
);

const getUserFunnelViewFx = createEffect(async (params: any): Promise<TResponse> => {
    return await funnelViewApi.getUserFunnelView(params);
});

const updateFunnelView = createEvent<TFunnelViewResponse>();
const clearFunnelView = createEvent();

const $funnelViewStore = createStore<TFunnelViewResponse>(initialFunnelViewStore)
    .on(updateFunnelView, (_, funnelView) => funnelView)
    .on(getViewTypeFx.doneData, (_, funnelView) => funnelView)
    .reset(clearFunnelView);

export const funnelViewStores = {
    $funnelViewStore,
};

export const funnelViewEffects = {
    getViewTypeFx,
    createViewTypeFx,
    setViewTypeFx,
    getUserFunnelViewFx,
};

export const funnelViewEvents = {
    updateFunnelView,
    clearFunnelView,
};
