import Api from 'shared/api/axios';
import { TResponse } from 'shared/api/types';

export const permissionsService = {
    getPermissions(params: any) {
        return Api.get<null, TResponse>(`/api/v1/access/permissions`, {
            params: { ...params },
        }).then((res) => {
            return { ...res, params: params };
        });
    },
    updatePermission(permission: any) {
        return Api.patch(`/api/v1/access/permissions/${permission.id}`, permission);
    },
    deletePermission(id: string) {
        return Api.delete<null, any>(`/api/v1/access/permissions/${id}/soft-delete`);
    },
    createPermission(permission: any) {
        return Api.post(`/api/v1/access/permissions`, permission);
    },
};
