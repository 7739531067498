import { Dispatch, FC, SetStateAction, memo } from 'react';
import { ListItem, ListItemText } from '@mui/material';
import { SidebarLink, SidebarNavLink } from '../../lib/const/consts';

interface SidebarListItemProps {
    name: string;
    uri: string;
    entityCode?: string;
    setSidebarOpen: Dispatch<SetStateAction<boolean>>;
}
export const SidebarListItem: FC<SidebarListItemProps> = memo(
    ({ name, uri = '', entityCode, setSidebarOpen }) => {
        return (
            <ListItem sx={{ pr: 0, pl: 0 }}>
                {entityCode ? (
                    <SidebarNavLink
                        to={`/entity/${entityCode}`}
                        onClick={() => setSidebarOpen((prev) => !prev)}
                    >
                        <ListItemText>{name}</ListItemText>
                    </SidebarNavLink>
                ) : (
                    <SidebarLink
                        href={uri}
                        onClick={() => setSidebarOpen((prev) => !prev)}
                        rel={'noopener noreferrer'}
                    >
                        <ListItemText>{name}</ListItemText>
                    </SidebarLink>
                )}
            </ListItem>
        );
    }
);
