import EventEmitter from 'eventemitter3';

const eventEmitter = new EventEmitter();

const Emitter = {
    emit(event: string | symbol, ...args: any[]) {
        return eventEmitter.emit(event, ...args);
    },
    on(event: string | symbol, fn: (...args: any[]) => void, context?: any) {
        return eventEmitter.on(event, fn, context);
    },
    once(event: string | symbol, fn: (...args: any[]) => void, context?: any) {
        return eventEmitter.once(event, fn, context);
    },
    off(event: string | symbol, fn: (...args: any[]) => void, context?: any) {
        return eventEmitter.off(event, fn, context);
    },
    listeners(event: string | symbol) {
        return eventEmitter.listeners(event);
    },
};

Object.freeze(Emitter);

export default Emitter;
