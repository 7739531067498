import { IWSApiService } from 'application/ports/Api';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { wsApiService } from 'services/ApiAdapter';
import { TChatConnect, TChatResponse, TMessagesResponse } from 'shared/api/types';
import { Centrifuge, Subscription } from 'centrifuge';
import { TMessageItem } from 'domain/chat';

export interface IChatData {
    connect?: TChatConnect | null;
    list?: TChatResponse | null;
    messages?: TMessagesResponse | null;
    centrifuge?: Centrifuge | null;
    subscribe?: Subscription | null;
}

const initialWSStore: IChatData = {
    connect: null,
    list: null,
    messages: null,
};
const wsApi: IWSApiService = wsApiService();

const fetchConnectFx = createEffect(
    async ({
        id,
        name,
        source,
    }: {
        id: string;
        name: string;
        source?: string | null;
    }): Promise<TChatConnect> => {
        return await wsApi.connect(id, name, source);
    }
);

const fetchListFx = createEffect(async (user_uuid: string): Promise<TChatResponse> => {
    return await wsApi.list(user_uuid);
});
const fetchFileFx = createEffect(
    async ({
        user_uuid,
        channel_uuid,
        replayed_message_uuid,
        attachment_type,
        text,
        files,
    }: {
        user_uuid: string;
        channel_uuid: string;
        replayed_message_uuid?: string | null;
        attachment_type?: string | null;
        text?: string | null;
        files?: FileList | null;
    }): Promise<TMessageItem> => {
        return await wsApi.file(user_uuid, channel_uuid, replayed_message_uuid, attachment_type, text, files);
    }
);
const fetchMessagesFx = createEffect(
    async ({
        user_uuid,
        channel_uuid,
    }: {
        user_uuid: string;
        channel_uuid: string;
    }): Promise<TMessagesResponse> => {
        return await wsApi.messages(user_uuid, channel_uuid);
    }
);
const fetchRedirectFx = createEffect(
    async ({
        channel_uuid,
        crm_manager_service_id,
        crm_manager_name,
    }: {
        channel_uuid: string;
        crm_manager_service_id: string;
        crm_manager_name: string;
    }): Promise<TMessagesResponse> => {
        return await wsApi.redirect(channel_uuid, crm_manager_service_id, crm_manager_name);
    }
);

const updateWS = createEvent<any>();
const updateMessage = createEvent<any>();
const clearWS = createEvent<any>();

const $wsStore = createStore(initialWSStore)
    .on(updateWS, (_, newWS) => newWS)
    .on(updateMessage, (state, newMessage: TMessageItem) => {
        return {
            ...state,
            messages: { ...state?.messages, items: [...(state?.messages?.items || []), newMessage] },
        };
    })
    .on(fetchConnectFx.doneData, (state, newWS) => {
        return { ...state, connect: newWS };
    })
    .on(fetchListFx.doneData, (state, newWS) => {
        return { ...state, list: newWS };
    })
    .on(fetchMessagesFx.doneData, (state, newWS) => {
        return { ...state, messages: newWS };
    })
    .reset(clearWS);

sample({
    clock: fetchRedirectFx,
    source: clearWS,
    // filter: (data) => !!data.accessToken,
    // fn: (_, clockData) => !!clockData,
    target: fetchListFx,
});

// guard({
//     clock: fetchRedirectFx, //юнит, при срабатывании которого будет выполняться filter
//     source: $wsStore, //данные, которые будут передаваться в target
//     filter: () => true, //дальнейший вызов target возможен при filter = true
//     target: fetchListFx, // юнит, который будет вызван при вызове clock и истинном значении filter
// });

export const wsStores = {
    $wsStore,
};

export const wsEffects = {
    fetchConnectFx,
    fetchListFx,
    fetchFileFx,
    fetchMessagesFx,
    fetchRedirectFx,
};

export const wsEvents = {
    updateWS,
    clearWS,
    updateMessage,
};
