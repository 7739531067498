import { ITimeline, ITimelineApiService, TTimelineResponse } from '../../types/timeline';
import Api from '../../../../shared/api/axios';
import { TParamsRequest } from 'shared/api/types';

const timelineService = {
    // Object
    createComment(entity_uuid: string, comment: { comment: string; files: string[] }) {
        return Api.post<null, ITimeline>(`/api/v1/timelines/${entity_uuid}`, comment);
    },
    updateComment(entity_uuid: string, comment: { comment: string; files: string[] }, comment_id: string) {
        return Api.patch<null, ITimeline>(`/api/v1/timelines/${entity_uuid}/${comment_id}`, comment);
    },
    deleteComment(entity_uuid: string, comment_id: string) {
        return Api.delete<null, ITimeline>(`/api/v1/timelines/${entity_uuid}/${comment_id}`);
    },
    getTimelines(entity_uuid: string, params: TParamsRequest) {
        return Api.get<null, TTimelineResponse>(`/api/v1/timelines/${entity_uuid}`, {
            params: { ...params },
        }).then((res) => {
            return { ...res, params: params };
        });
    },
    // Task
    createCommentTask(entity_uuid: string, comment: { comment: string; files: string[] }) {
        return Api.post<null, ITimeline>(`/api/v1/timelines/timeline/${entity_uuid}/task`, comment);
    },
    updateCommentTask(
        entity_uuid: string,
        comment: { comment: string; files: string[] },
        comment_id: string
    ) {
        return Api.patch<null, ITimeline>(
            `/api/v1/timelines/timeline/${entity_uuid}/task/${comment_id}`,
            comment
        );
    },
    deleteCommentTask(entity_uuid: string, comment_id: string) {
        return Api.delete<null, ITimeline>(`/api/v1/timelines/timeline/${entity_uuid}/task/${comment_id}`);
    },
    getTimelinesTask(entity_uuid: string, params: TParamsRequest) {
        return Api.get<null, TTimelineResponse>(`/api/v1/timelines/timeline/${entity_uuid}/task`, {
            params: { ...params },
        }).then((res) => {
            return { ...res, params: params };
        });
    },
};

export function timelineApiService(): ITimelineApiService {
    return {
        // object
        createComment: timelineService.createComment,
        updateComment: timelineService.updateComment,
        deleteComment: timelineService.deleteComment,
        getTimelines: timelineService.getTimelines,
        // task
        createCommentTask: timelineService.createCommentTask,
        updateCommentTask: timelineService.updateCommentTask,
        deleteCommentTask: timelineService.deleteCommentTask,
        getTimelinesTask: timelineService.getTimelinesTask,
    };
}
