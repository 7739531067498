import { Box, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';

type NoticeProps = {
    children: React.ReactElement | string;
    type?: string;
};

const Notice = ({ children, type }: NoticeProps) => {
    const switchBG = () => {
        switch (type) {
            case 'info':
                return 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #0288D1';
            case 'warning':
                return 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #ED6C02';
            case 'success':
                return 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #2E7D32';

            default:
                return 'none';
        }
    };

    const switchColor = () => {
        switch (type) {
            case 'info':
                return '#0288D1';
            case 'warning':
                return '#ED6C02';
            case 'success':
                return '#2E7D32';

            default:
                return '#00000099';
        }
    };

    const switchIcon = () => {
        switch (type) {
            case 'info':
                return <InfoOutlinedIcon color="info" height={18} />;
            case 'warning':
                return <ReportProblemRoundedIcon color="warning" />;
            case 'success':
                return <CheckCircleOutlineRoundedIcon color="success" />;

            default:
                return <InfoOutlinedIcon color="secondary" />;
        }
    };

    return (
        <Box
            sx={{
                gap: '12px',
                p: type ? '15px 18px' : 0,
                display: 'flex',
                borderRadius: '12px',
                background: switchBG(),
                color: switchColor(),
                alignItems: 'start',
            }}
        >
            {switchIcon()}
            <Typography variant="body2" mt={0.3} color={'text.secondary'}>
                {children}
            </Typography>
        </Box>
    );
};

export default Notice;
