import { createEffect, createEvent, createStore } from 'effector';
import { IFunnelsApiService } from 'application/ports/Api';
import { funnelsApiService } from 'services/ApiAdapter';
import { TFunnels } from 'domain/funnels';

const initialFunnelsStore: TFunnels = {
    id: '',
    name: '',
    entity: '',
    type: '',
    archived: false,
    stages: [],
};

const funnelsApi: IFunnelsApiService = funnelsApiService();

const fetchFunnelsIDFx = createEffect(async (id: string): Promise<TFunnels> => {
    return await funnelsApi.getFunnelsID(id);
});

const fetchFunnelsStagesIDFx = createEffect(async ({ id, entity_objects }: any): Promise<any> => {
    return await funnelsApi.setFunnelsStagesID(id, entity_objects);
});
const fetchFunnelsFunnelsIDFx = createEffect(async ({ id, entity_objects }: any): Promise<any> => {
    return await funnelsApi.setFunnelsFunnelsID(id, entity_objects);
});

const fetchFunnelsStagesFx = createEffect((funnel: TFunnels): TFunnels => {
    return funnel;
});

const updateFunnels = createEvent<TFunnels>();
const clearFunnels = createEvent();

const $funnelsStore = createStore<TFunnels>(initialFunnelsStore)
    .on(updateFunnels, (_, newFunnels) => {
        return newFunnels;
    })
    .on(fetchFunnelsIDFx.doneData, (_, newFunnels) => {
        return newFunnels;
    })
    .on(fetchFunnelsIDFx.doneData, (_, newFunnels) => {
        return newFunnels;
    })
    .on(fetchFunnelsStagesFx.doneData, (oldFunnels, newFunnels) => {
        return {
            ...oldFunnels,
            stages: oldFunnels.stages.map((stage) =>
                stage?.id === newFunnels.id ? { ...stage, ...newFunnels } : stage
            ),
        };
    })
    .reset(clearFunnels);

export const funnelsStores = {
    $funnelsStore,
};

export const funnelsEffects = {
    fetchFunnelsIDFx,
    fetchFunnelsStagesFx,
    fetchFunnelsStagesIDFx,
    fetchFunnelsFunnelsIDFx,
};

export const funnelsEvents = {
    updateFunnels,
    clearFunnels,
};
