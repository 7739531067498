import {
    IBulkUpdateNotificationsReceiverParams,
    IGetNotificationsReceiverParams,
    IGetNotificationsReceiversParams,
    INotification,
    INotificationService,
    IUpdateNotificationsReceiverParams,
    IUpdateNotificationsReceiverResponse,
    TNotificationsResponse,
} from '../types/notifications';
import { createEffect, createEvent, createStore } from 'effector';
import { notificationApiService } from '../api/services/notifications';

const initialNotificationsStore: TNotificationsResponse = {
    next: '',
    previous: '',
    count: 0,
    count_of_unread: 0,
    results: [],
    params: {
        offset: 0,
        limit: 10,
    },
};

const initialLastNotificationsStore: TNotificationsResponse = {
    next: '',
    previous: '',
    count: 0,
    count_of_unread: 0,
    results: [],
    params: {
        offset: 0,
        limit: 10,
    },
};

const initialNotificationStore: INotification = {
    id: '',
    author: null,
    entity_object: null,
    task: null,
    created: '',
    modified: '',
    event: null,
    type: null,
    is_read: false,
    event_history: null,
};

const api: INotificationService = notificationApiService();

const getNotificationsReceiversFx = createEffect(
    async (data: IGetNotificationsReceiversParams): Promise<TNotificationsResponse> => {
        return await api.getNotificationsReceivers(data);
    }
);

const getNotificationsReceiverFx = createEffect(
    async (data: IGetNotificationsReceiverParams): Promise<INotification> => {
        return await api.getNotificationsReceiver(data);
    }
);

const updateNotificationReceiverFx = createEffect(
    async (data: IUpdateNotificationsReceiverParams): Promise<IUpdateNotificationsReceiverResponse> => {
        return await api.updateNotificationReceiver(data);
    }
);

const deleteNotificationReceiverFx = createEffect(
    async (data: IGetNotificationsReceiverParams): Promise<null> => {
        return await api.deleteNotificationReceiver(data);
    }
);

const bulkUpdateNotificationReceiverFx = createEffect(
    async (data: IBulkUpdateNotificationsReceiverParams): Promise<IUpdateNotificationsReceiverResponse> => {
        return await api.bulkUpdateNotificationReceiver(data);
    }
);

const getNotificationsReceiversAllReadFx = createEffect(async (receiver: string): Promise<null> => {
    return await api.getNotificationsReceiversAllRead(receiver);
});

const getLastNotificationsReceiversFx = createEffect(
    async (data: IGetNotificationsReceiversParams): Promise<TNotificationsResponse> => {
        return await api.getNotificationsReceivers(data);
    }
);

const updateLastNotificationReceiverFx = createEffect(
    async (data: IUpdateNotificationsReceiverParams): Promise<IUpdateNotificationsReceiverResponse> => {
        return await api.updateNotificationReceiver(data);
    }
);

const clearNotifications = createEvent();
const clearNotification = createEvent();
const clearLastNotifications = createEvent();
const signalNotification = createEvent<INotification>();

const $notificationsStore = createStore(initialNotificationsStore)
    .on(getNotificationsReceiversFx.done, (state, { result, params }) => {
        return {
            ...state,
            ...result,
            params,
        };
    })
    .on(updateNotificationReceiverFx.doneData, (state, payload) => {
        return {
            ...state,
            count_of_unread: state.count_of_unread - 1,
            results: state.results.map((n) => {
                if (n.id === payload.id) {
                    return {
                        ...n,
                        is_read: payload.is_read,
                    };
                }
                return n;
            }),
        };
    })
    .on(updateLastNotificationReceiverFx.doneData, (state, payload) => {
        return {
            ...state,
            count_of_unread: state.count_of_unread - 1,
            results: state.results.map((n) => {
                if (n.id === payload.id) {
                    return {
                        ...n,
                        is_read: payload.is_read,
                    };
                }
                return n;
            }),
        };
    })
    .on(getNotificationsReceiversAllReadFx.doneData, (state) => {
        return {
            ...state,
            count_of_unread: 0,
        };
    })
    .on(signalNotification, (state, payload) => {
        return {
            ...state,
            count_of_unread: state.count_of_unread + 1,
            count: state.count + 1,
            results: [payload, ...state.results],
        };
    })
    .reset(clearNotifications);

const $notificationStore = createStore(initialNotificationStore)
    .on(getNotificationsReceiverFx.doneData, (_, payload) => payload)
    .reset(clearNotification);

const $lastNotificationsStore = createStore(initialLastNotificationsStore)
    .on(getLastNotificationsReceiversFx.doneData, (_, payload) => payload)
    .on(updateLastNotificationReceiverFx.doneData, (state, payload) => {
        return {
            ...state,
            count_of_unread: state.count_of_unread - 1,
            results: state.results.map((n) => {
                if (n.id === payload.id) {
                    return {
                        ...n,
                        is_read: payload.is_read,
                    };
                }
                return n;
            }),
        };
    })
    .on(updateNotificationReceiverFx.doneData, (state, payload) => {
        return {
            ...state,
            count_of_unread: state.count_of_unread - 1,
            results: state.results.map((n) => {
                if (n.id === payload.id) {
                    return {
                        ...n,
                        is_read: payload.is_read,
                    };
                }
                return n;
            }),
        };
    })
    .on(getNotificationsReceiversAllReadFx.doneData, (state) => {
        return {
            ...state,
            count_of_unread: 0,
        };
    })
    .on(signalNotification, (state, payload) => {
        return {
            ...state,
            count_of_unread: state.count_of_unread + 1,
            count: state.count + 1,
            results: [payload, ...state.results],
        };
    })
    .reset(clearLastNotifications);

export const notificationStores = {
    $notificationsStore,
    $notificationStore,
    $lastNotificationsStore,
};
export const notificationEvents = {
    clearNotifications,
    clearNotification,
    clearLastNotifications,
    signalNotification,
};

export const notificationEffects = {
    getNotificationsReceiversFx,
    getNotificationsReceiverFx,
    updateNotificationReceiverFx,
    deleteNotificationReceiverFx,
    bulkUpdateNotificationReceiverFx,
    getNotificationsReceiversAllReadFx,
    getLastNotificationsReceiversFx,
    updateLastNotificationReceiverFx,
};
