import React, { ReactNode } from 'react';
import { SnackbarProvider } from 'notistack';
import { styled } from '@mui/material';
import { useChatToggle } from '../../../../shared/context/ChatContext';

const StyledSnackbarProvider = styled(SnackbarProvider, {
    shouldForwardProp: (prop) => prop !== 'open',
})<{ open: boolean }>(({ open }) => ({
    ['&.SnackbarItem-contentRoot']: {
        backgroundColor: 'white',
    },
    ['&.SnackbarContainer-root']: {
        flexDirection: 'column-reverse',
    },
    ...(open && {
        ['&.SnackbarItem-contentRoot']: {
            display: 'none',
        },
    }),
}));
export const CustomSnackbarProvider = ({ children }: { children: ReactNode }) => {
    const { open } = useChatToggle();
    return (
        <StyledSnackbarProvider
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={open}
        >
            {children}
        </StyledSnackbarProvider>
    );
};
