import { useRef } from 'react';

interface IUseAudio {
    audioPlayer: any;
    playSound: () => void;
    stopSound: () => void;
}
export const useAudio = (): IUseAudio => {
    const audioPlayer = useRef<any>(null);

    const playSound = () => {
        audioPlayer.current.play();
    };

    const stopSound = () => {
        if (audioPlayer.current) {
            audioPlayer.current.pause();
            audioPlayer.current.currentTime = 0;
        }
    };

    return {
        audioPlayer,
        playSound,
        stopSound,
    };
};
