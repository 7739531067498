import React, { Dispatch, FC, SetStateAction, memo, useState } from 'react';
import { Divider, Grid, ListItem, Typography, useTheme } from '@mui/material';
import { TMenuItem } from '../../../../domain/menu';
import { SidebarList } from '../SidebarList/SidebarList';
import { KeyboardArrowDownRounded } from '@mui/icons-material';
import KeyboardArrowUpRounded from '@mui/icons-material/KeyboardArrowUpRounded';

interface SidebarNavListItemProps {
    name: string;
    items: TMenuItem[];
    setSidebarOpen: Dispatch<SetStateAction<boolean>>;
}
export const SidebarNavListItem: FC<SidebarNavListItemProps> = memo(({ name, items, setSidebarOpen }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const {
        palette: { outlineBorder },
    } = useTheme();

    const onOpen = () => setIsOpen((prev) => !prev);
    return (
        <ListItem
            sx={{
                flexDirection: 'column',
                border: isOpen ? `1px solid ${outlineBorder}` : null,
                borderRadius: isOpen ? '12px' : null,
                padding: 2,
            }}
        >
            <Grid
                container
                onClick={onOpen}
                justifyContent="space-between"
                flexWrap={'nowrap'}
                sx={{ cursor: 'pointer' }}
            >
                <Typography marginRight={2}>{name}</Typography>
                {isOpen ? (
                    <KeyboardArrowUpRounded color={'action'} />
                ) : (
                    <KeyboardArrowDownRounded color={'action'} />
                )}
            </Grid>
            {isOpen && items.length > 0 ? <Divider flexItem variant={'fullWidth'} sx={{ mt: 2 }} /> : null}
            {isOpen ? (
                <Grid container>
                    {items.length > 0 ? <SidebarList items={items} setSidebarOpen={setSidebarOpen} /> : null}
                </Grid>
            ) : null}
        </ListItem>
    );
});
