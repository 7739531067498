import React, { memo } from 'react';
import { useMenuStorage } from '../../lib/hooks/useMenuStorage';
import { List } from '@mui/material';
import { SidebarEditNavListItem } from '../SidebarEditNavListItem/SidebarEditNavListItem';
import { DragDropContext, Droppable, OnDragEndResponder } from '@hello-pangea/dnd';
import { reorder } from '../../../../shared/helpers/reorder';
import { getItemsUri } from '../../lib/const/getItemsUri';

export const SidebarEditNavList = memo(() => {
    const { menu, bulkUpdateMenu, updateMenuEvent } = useMenuStorage();

    const onDragEnd: OnDragEndResponder = async (result) => {
        if (!result.destination) return;
        const updatedItems = reorder(menu.results, result.source.index, result.destination.index);
        const orderedItems = updatedItems.map((i, idx) => ({
            ...i,
            items: getItemsUri(i.items),
            order: idx,
        }));
        updateMenuEvent({ ...menu, results: orderedItems });
        await bulkUpdateMenu({ menu_list: orderedItems });
    };
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="menus">
                {(provided) => (
                    <List
                        disablePadding
                        sx={{
                            ['& > li']: {
                                mt: 1.5,
                                ['&:first-child']: {
                                    mt: 0,
                                },
                            },
                            mb: 3,
                        }}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        {menu.results.map((menuItem, index) => (
                            <SidebarEditNavListItem key={menuItem.id} index={index} menuItem={menuItem} />
                        ))}
                        {provided.placeholder}
                    </List>
                )}
            </Droppable>
        </DragDropContext>
    );
});
