import { TResponse } from 'shared/api/types';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { IMetaApiService } from 'application/ports/Api';
import { funnelViewsApiService, metaApiService } from 'services/ApiAdapter';
import { TMeta } from 'domain/meta';
// import { tasksMeta } from 'services/store/meta/tasks';

const initialMetaStore: TMeta = {
    id: '',
    name: '',
    type: '',
    views: [],
    permissions: [],
    tab: new URLSearchParams(window.location.search).get('view') ?? 'tabular',
    no_funnel_view: false,
    is_deleted: false,
    deleted_at: '',
    code: '',
    entityName: '',
};

const initialMetasStore = {
    next: '',
    previous: '',
    count: 0,
    results: [],
    params: {
        offset: 0,
        limit: 10,
    },
};

const metaApi: IMetaApiService = metaApiService();

const fetchMetasFx = createEffect(async (params: any): Promise<TResponse> => {
    return await metaApi.getMetas(params);
});

const updateMetas = createEvent<TResponse>();
const clearMetas = createEvent();

const $metasStore = createStore<TResponse>(initialMetasStore)
    .on(updateMetas, (_, meta) => meta)
    .on(fetchMetasFx.doneData, (_, meta) => meta)
    .reset(clearMetas);

const fetchMetaFx = createEffect(
    async ({ meta, params }: { meta: string; params?: any }): Promise<[TMeta, string]> => {
        // if (meta === 'tasks') {
        //     return [tasksMeta as any, meta];
        // }
        const metaData = await metaApi.getMeta(meta, params);

        return [metaData, meta];
    }
);

const fetchMetaUpdaterFx = createEffect(
    async ({ meta, params }: { meta: string; params?: any }): Promise<[TMeta, string]> => {
        // if (meta === 'tasks') {
        //     return [tasksMeta as any, meta];
        // }
        const metaData = await metaApi.getMeta(meta, params);
        return [metaData, meta];
    }
);

const switchHideFieldStatusFx = createEffect(async ({ id, fields }: { id: string; fields: string[] }) => {
    const funnelViewService = funnelViewsApiService();
    await funnelViewService.updateFields(id, fields);
});

const createMetaFx = createEffect(
    async ({
        code,
        name,
        no_funnel_view,
        can_have_tasks,
    }: {
        code: string;
        name: string;
        no_funnel_view: boolean;
        can_have_tasks?: boolean;
    }): Promise<TMeta> => {
        return await metaApi.createMeta(code, name, no_funnel_view, can_have_tasks);
    }
);

const updateMetaFx = createEffect(
    async ({
        code,
        name,
        no_funnel_view,
    }: {
        code: string;
        name: string;
        no_funnel_view: boolean;
    }): Promise<TMeta> => {
        return await metaApi.updateMeta(code, name, no_funnel_view);
    }
);

const deleteMetaFx = createEffect(async (code: string): Promise<TMeta> => {
    return await metaApi.deleteMeta(code);
});

// guard({
//     clock: deleteMetaFx, //юнит, при срабатывании которого будет выполняться filter
//     filter: () => true, //дальнейший вызов target возможен при filter = true
//     // source: initialMetasStore.params, //данные, которые будут передаваться в target
//     target: fetchMetasFx, // юнит, который будет вызван при вызове clock и истинном значении filter
// });

const updateMeta = createEvent<TMeta>();
const clearMeta = createEvent();

const $metaStore = createStore<TMeta>(initialMetaStore)
    .on(updateMeta, (_, newMeta) => newMeta)
    .on(fetchMetaFx.doneData, (_, newMeta) => {
        const [metaData, meta] = newMeta;
        return { ...metaData, tab: initialMetaStore.tab, entityName: meta };
    })
    .on(fetchMetaUpdaterFx.doneData, (_, newMeta) => {
        const [metaData, meta] = newMeta;
        return { ...metaData, tab: initialMetaStore.tab, entityName: meta };
    })
    .reset(clearMeta);

sample({
    clock: switchHideFieldStatusFx.doneData,
    source: $metaStore,
    target: fetchMetaFx,
    fn: (source: TMeta) => ({ meta: source.entityName, params: { funnel_view_type: 'detail' } }),
});

export const metaStores = {
    $metaStore,
    $metasStore,
};

export const metaEffects = {
    fetchMetaUpdaterFx,
    fetchMetaFx,
    fetchMetasFx,
    deleteMetaFx,
    createMetaFx,
    updateMetaFx,
    switchHideFieldStatusFx,
};

export const metaEvents = {
    updateMetas,
    clearMetas,
    updateMeta,
    clearMeta,
};
