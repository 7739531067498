import { Field } from 'domain/field';
import { Box, Grid } from '@mui/material';
import { ChangeEvent, PointerEvent, useEffect, useMemo, useState } from 'react';
import { Reorder, useDragControls, useMotionValue } from 'framer-motion';
import { useRaisedShadow } from 'shared/hooks/useRaisedShadow';
import DraggableItemWrapper from 'shared/ui/DraggableWrapper/DraggableItemWrapper';
import { Chip, FormField, MultiSelect, Select } from 'shared/ui';
// import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import dayjs from 'dayjs';
import { FormikErrors } from 'formik';
import { ConnectEntityModalSettingsType } from 'services/store/entityDetail/entityDetail';
import { useConnectEntityModal, useFunnelsListStorage } from 'services/StorageAdapter';
import Emitter from 'shared/hooks/EventEmitter';
import LinkField from 'widgets/DraggableField/Fields/LinkField/LinkField';
import DefaultField from './Fields/DefaultField/DefaultField';
import ForeignKeyField from './Fields/ForeignKeyField/ForeignKeyField';
import ManyToManyField from 'widgets/DraggableField/Fields/ManyToManyField/ManyToManyField';
import CheckboxField from 'widgets/DraggableField/Fields/CheckboxField/CheckboxField';
import NumberField from 'widgets/DraggableField/Fields/NumberField/NumberField';
import DateTimeField from 'widgets/DraggableField/Fields/DateTimeField/DateTimeField';
import EmailField from 'widgets/DraggableField/Fields/EmailField/EmailField';
import PhoneField from 'widgets/DraggableField/Fields/PhoneField/PhoneField';
import UuidField from 'widgets/DraggableField/Fields/UuidField/UuidField';
import FileField from 'widgets/DraggableField/Fields/FileField/FileField';

interface DraggableFieldProps {
    field: Field;
    handleHideField: (field: Field) => void;
    options?: {
        read: boolean;
        write: boolean;
    };
    handleChange: (event: ChangeEvent<any>) => void;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    value: any;
    error?: string | string[] | FormikErrors<any> | FormikErrors<any>[];
    current_funnel: { id: string; name: string };
}

const DraggableField = ({
    field,
    // handleHideField,
    setFieldValue,
    value,
    error,
    current_funnel,
    options = { read: true, write: true },
}: DraggableFieldProps) => {
    // const {
    //     palette: {
    //         action: { active },
    //     },
    // } = useTheme();
    const { funnelsList } = useFunnelsListStorage();
    const { open } = useConnectEntityModal();
    const [clickCursorPos, setClickCursorPos] = useState({
        x: 0,
        y: 0,
    });
    const controls = useDragControls();
    const y = useMotionValue(0);
    const boxShadow = useRaisedShadow(y);
    const { name, id } = field;
    const [isEdit, setIsEdit] = useState(false);
    const renderType = useMemo(() => {
        if (options.read && options.write) {
            return isEdit ? 'write' : 'read';
        } else {
            return options.read ? 'read' : 'write';
        }
    }, [isEdit, options]);

    const offEdit = () => {
        setIsEdit(false);
    };

    useEffect(() => {
        const listener = Emitter.on('reset-form', offEdit);
        return () => {
            listener.off('reset-form', offEdit);
        };
    }, []);

    const handlePointerDown = (e: PointerEvent<any>) => {
        setClickCursorPos({
            x: e.clientX,
            y: e.clientY,
        });
    };

    const handlePointerUp = (e: PointerEvent<any>) => {
        if (
            Math.abs(e.clientX - clickCursorPos.x) < 10 &&
            Math.abs(e.clientY - clickCursorPos.y) < 10 &&
            options.read &&
            options.write
        ) {
            if (field.group !== 'system' || ['responsible', 'funnel_stage', 'observers'].includes(field.id)) {
                Emitter.emit('entity-edited');
                setIsEdit(true);
            }
        }
    };

    return (
        <Reorder.Item
            dragListener={false}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            value={field}
            dragControls={controls}
            style={{
                boxShadow,
                y,
                position: 'relative',
            }}
        >
            <DraggableItemWrapper isDraggable={false} dragHandler={(e) => controls.start(e)} key={id}>
                {() => {
                    return (
                        <Box
                            width={'100%'}
                            px={'24px'}
                            sx={{
                                transition: '0.2s background-color linear',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                },
                            }}
                        >
                            <FormField
                                error={!!error}
                                helperText={typeof error === 'string' ? error : ''}
                                hint={field.help_text}
                                required={field.settings.required}
                                label={name}
                            >
                                <Grid container flexWrap={'nowrap'} alignItems={'center'} gap={'8px'}>
                                    <Grid item flexGrow={1}>
                                        {renderField(field, renderType, {
                                            setFieldValue,
                                            value,
                                            name: field.id,
                                            error,
                                            openConnectModal: open,
                                            funnels: funnelsList.results,
                                            currentFunnel: funnelsList.results.find(
                                                (f) => f.id === current_funnel?.id
                                            ),
                                        })}
                                    </Grid>
                                    <Grid>
                                        {/*<IconButton*/}
                                        {/*    size={'small'}*/}
                                        {/*    sx={{*/}
                                        {/*        color: alpha(active, 0.54),*/}
                                        {/*        opacity:*/}
                                        {/*            hovered &&*/}
                                        {/*            field.group !== 'system' &&*/}
                                        {/*            !field.settings.required*/}
                                        {/*                ? 1*/}
                                        {/*                : 0,*/}
                                        {/*        transition: '0.2s opacity linear',*/}
                                        {/*    }}*/}
                                        {/*    disabled={field.settings.required}*/}
                                        {/*    onPointerDown={(e) => e.stopPropagation()}*/}
                                        {/*    onPointerUp={(e) => e.stopPropagation()}*/}
                                        {/*    onClick={(e) => {*/}
                                        {/*        e.stopPropagation();*/}
                                        {/*        if (field.group !== 'system') {*/}
                                        {/*            handleHideField(field);*/}
                                        {/*        }*/}
                                        {/*    }}*/}
                                        {/*>*/}
                                        {/*    <VisibilityOffRoundedIcon fontSize={'inherit'} />*/}
                                        {/*</IconButton>*/}
                                    </Grid>
                                </Grid>
                                {/*<Typography variant={'body1'}>*/}
                                {/*    {entityData[id]}*/}
                                {/*</Typography>*/}
                            </FormField>
                        </Box>
                    );
                }}
            </DraggableItemWrapper>
        </Reorder.Item>
    );
};

const generateReadableField = (field: Field, fieldValue: any) => {
    switch (field.value_type) {
        case 'file': {
            return <FileField field={field} value={fieldValue} />;
        }
        case 'select': {
            const option = field.settings.options?.find((opt) => fieldValue in opt);
            return <DefaultField field={field} value={option?.[fieldValue] as string} />;
        }
        case 'multi_select': {
            const options = field.settings.options?.filter((opt) =>
                fieldValue?.some((val: any) => val in opt)
            );
            if (!options) {
                return;
            }
            return (
                <DefaultField
                    field={field}
                    value={options
                        .map((o) => {
                            return Object.values(o)[0] as string;
                        })
                        .join(', ')}
                />
            );
        }
        case 'email': {
            return <EmailField field={field} value={fieldValue} />;
        }
        case 'link': {
            return <LinkField field={field} value={fieldValue} />;
        }
        case 'phone': {
            return <PhoneField field={field} value={fieldValue} />;
        }
        case 'date': {
            return (
                <DateTimeField
                    format={'date'}
                    field={field}
                    value={
                        fieldValue
                            ? Array.isArray(fieldValue)
                                ? fieldValue.map(dayjs)
                                : dayjs(fieldValue)
                            : null
                    }
                />
            );
        }
        case 'time': {
            return (
                <DateTimeField
                    format={'time'}
                    field={field}
                    value={
                        fieldValue
                            ? Array.isArray(fieldValue)
                                ? fieldValue.map((v) => dayjs(v, 'HH:mm'))
                                : dayjs(fieldValue, 'HH:mm')
                            : null
                    }
                />
            );
        }
        case 'datetime': {
            return (
                <DateTimeField
                    format={'datetime'}
                    field={field}
                    value={
                        fieldValue
                            ? Array.isArray(fieldValue)
                                ? fieldValue.map(dayjs)
                                : dayjs(fieldValue)
                            : null
                    }
                />
            );
        }
        case 'bool': {
            return <CheckboxField field={field} value={fieldValue} />;
        }
        case 'uuid': {
            return <UuidField field={field} value={fieldValue} />;
        }
        case 'fk_employee':
        case 'fk_department':
        case 'fk': {
            return <ForeignKeyField field={field} value={fieldValue} />;
        }
        case 'system_fk': {
            if (!fieldValue?.user && !fieldValue?.name) {
                return null;
            }
            return (
                <Chip
                    label={
                        fieldValue?.user
                            ? `${fieldValue?.user.first_name} ${fieldValue?.user.last_name}`
                            : fieldValue?.name
                    }
                />
            );
        }
        case 'system_m2m': {
            return <ManyToManyField field={field} value={fieldValue} />;
        }
        case 'm2m_employee':
        case 'm2m_department':
        case 'm2m': {
            return <ManyToManyField field={field} value={fieldValue} />;
        }
        case 'system_select_funnel_stage':
        case 'system_select_funnel': {
            return <DefaultField field={field} value={fieldValue?.name} />;
        }
        default: {
            return <DefaultField field={field} value={fieldValue} />;
        }
    }
};

const generateWritableField = (
    field: Field,
    { value, setFieldValue, error, openConnectModal, ...fieldProps }: FieldProps
) => {
    value = value ?? '';
    switch (field.value_type) {
        case 'file': {
            return <FileField field={field} value={value} setValue={setFieldValue} edit error={error} />;
        }
        case 'system_select_funnel': {
            return (
                <Select
                    onChange={(event) => {
                        const funnel = fieldProps.funnels.find((f) => f.id === event.target.value);
                        const reducedStages = funnel?.stages.reduce(
                            (acc: Array<{ id: string; name: string }>, s: any) => [...acc, ...s.arr],
                            []
                        );
                        setFieldValue(field.id, funnel);
                        setFieldValue('funnel_stage', reducedStages?.[0]);
                    }}
                    value={value ? value.id : null}
                    items={fieldProps.funnels.map(({ id, name }) => ({ id, text: name }))}
                />
            );
        }
        case 'system_select_funnel_stage': {
            const reducedStages = fieldProps.currentFunnel?.stages?.reduce(
                (acc: Array<{ id: string; name: string }>, s) => [...acc, ...s.arr],
                []
            );
            return (
                <Select
                    onChange={(event) => {
                        setFieldValue(
                            field.id,
                            reducedStages.find((f) => f.id === event.target.value)
                        );
                    }}
                    value={value ? value.id : null}
                    items={reducedStages?.map(({ id, name }) => ({ id, text: name }))}
                />
            );
        }
        case 'system_fk': {
            // const code = 'department-employees';
            return (
                <ForeignKeyField
                    field={field}
                    value={value}
                    setValue={setFieldValue}
                    edit
                    error={error}
                    openConnectModal={openConnectModal}
                />
            );
        }
        case 'system_m2m': {
            return (
                <ManyToManyField
                    field={field}
                    value={value}
                    setValue={setFieldValue}
                    edit
                    openConnectModal={openConnectModal}
                />
            );
        }
        case 'uuid': {
            return (
                <UuidField
                    field={field}
                    value={field.settings.many ? (Array.isArray(value) ? value : []) : value}
                    setValue={setFieldValue}
                    edit
                    openConnectModal={openConnectModal}
                />
            );
        }
        case 'fk_department':
        case 'fk_employee':
        case 'fk': {
            return (
                <ForeignKeyField
                    field={field}
                    value={value}
                    setValue={setFieldValue}
                    edit
                    openConnectModal={openConnectModal}
                />
            );
        }
        case 'm2m_department':
        case 'm2m_employee':
        case 'm2m': {
            return (
                <ManyToManyField
                    field={field}
                    value={value}
                    setValue={setFieldValue}
                    edit
                    openConnectModal={openConnectModal}
                />
            );
        }
        case 'multi_select': {
            return (
                <MultiSelect
                    onDelete={(items) => setFieldValue(field.id, items)}
                    items={
                        field.settings.options?.map((option) => {
                            const [key, value] = Object.entries(option)[0];
                            return { id: key, text: value as string };
                        }) || []
                    }
                    value={value || []}
                    error={!!error}
                    placeholder={field.placeholder}
                    onChange={(event) => {
                        if (typeof event.target.value === 'object') {
                            setFieldValue(field.id, event.target.value as Array<string>);
                        }
                    }}
                />
            );
        }
        case 'select': {
            return (
                <Select
                    items={
                        field.settings.options?.map((option) => {
                            const [key, value] = Object.entries(option)[0];
                            return { id: key, text: value as string };
                        }) || []
                    }
                    value={value}
                    error={!!error}
                    placeholder={field.placeholder}
                    onChange={(event) => setFieldValue(field.id, event.target.value)}
                    {...fieldProps}
                />
            );
        }
        case 'bool': {
            return <CheckboxField field={field} value={value} edit setValue={setFieldValue} error={error} />;
        }
        case 'phone': {
            return <PhoneField field={field} value={value} edit setValue={setFieldValue} error={error} />;
        }
        case 'int': {
            return <NumberField field={field} value={value} edit setValue={setFieldValue} error={error} />;
        }
        case 'float': {
            return <NumberField field={field} value={value} edit setValue={setFieldValue} error={error} />;
        }
        case 'date': {
            return (
                <DateTimeField
                    format={'date'}
                    field={field}
                    value={value ? (Array.isArray(value) ? value.map(dayjs) : dayjs(value)) : null}
                    setValue={setFieldValue}
                    edit
                />
            );
        }
        case 'datetime': {
            return (
                <DateTimeField
                    format={'datetime'}
                    field={field}
                    value={value ? (Array.isArray(value) ? value.map(dayjs) : dayjs(value)) : null}
                    setValue={setFieldValue}
                    edit
                />
            );
        }
        case 'time': {
            return (
                <DateTimeField
                    field={field}
                    value={
                        value
                            ? Array.isArray(value)
                                ? value.map((v) => dayjs(v, 'HH:mm'))
                                : dayjs(value, 'HH:mm')
                            : null
                    }
                    format={'time'}
                    setValue={setFieldValue}
                    edit
                />
            );
        }
        case 'link': {
            return <LinkField edit field={field} value={value} setValue={setFieldValue} error={error} />;
        }
        default: {
            return <DefaultField field={field} value={value} setValue={setFieldValue} edit error={error} />;
        }
    }
};

type FieldProps = {
    helperText?: string;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    value: any;
    name: string;
    error?: string | string[] | FormikErrors<any> | FormikErrors<any>[];
    openConnectModal?: (data: Omit<ConnectEntityModalSettingsType, 'isOpen'>) => void;
    funnels: Array<{ id: string; stages: Array<{ id: string; name: string }>; name: string }>;
    currentFunnel: { id: string; stages: Array<{ arr: Array<{ id: string; name: string }> }>; name: string };
};

const renderField = (field: Field, renderType: 'read' | 'write', fieldProps: FieldProps) => {
    if (renderType === 'read') {
        return generateReadableField(field, fieldProps.value);
    } else {
        return generateWritableField(field, fieldProps);
    }
};

export default DraggableField;
