import { useStore } from 'effector-react';
import { notificationEffects, notificationStores, notificationEvents } from '../../model/notifications';
import { INotificationStorage } from '../../types/notifications';
import { useCallback } from 'react';
import { useUsersStorage } from '../../../../services/StorageAdapter';

const { $notificationsStore, $notificationStore, $lastNotificationsStore } = notificationStores;
const {
    getNotificationsReceiversFx,
    getNotificationsReceiverFx,
    updateNotificationReceiverFx,
    deleteNotificationReceiverFx,
    bulkUpdateNotificationReceiverFx,
    getNotificationsReceiversAllReadFx,
    getLastNotificationsReceiversFx,
    updateLastNotificationReceiverFx,
} = notificationEffects;
const { clearNotifications, clearNotification, clearLastNotifications, signalNotification } =
    notificationEvents;
export function useNotificationStorage(): INotificationStorage {
    const notifications = useStore($notificationsStore);
    const notification = useStore($notificationStore);
    const lastNotifications = useStore($lastNotificationsStore);
    const allReadLoading = useStore(getNotificationsReceiversAllReadFx.pending);
    const loading = useStore(getNotificationsReceiversFx.pending);
    const { me } = useUsersStorage();

    const handleAllRead = useCallback(async () => {
        if (me.employee) {
            await getNotificationsReceiversAllReadFx(me.employee?.id).then(() => {
                getNotificationsReceiversFx({ ...notifications.params, receiver: me.employee?.id || '' });
                getLastNotificationsReceiversFx({
                    ...lastNotifications.params,
                    receiver: me.employee?.id || '',
                });
            });
        }
    }, [me.employee]);

    return {
        notifications,
        notification,
        lastNotifications,
        allReadLoading,
        loading,
        getNotificationsReceivers: getNotificationsReceiversFx,
        getNotificationsReceiver: getNotificationsReceiverFx,
        updateNotificationReceiver: updateNotificationReceiverFx,
        updateLastNotificationReceiver: updateLastNotificationReceiverFx,
        deleteNotificationReceiver: deleteNotificationReceiverFx,
        bulkUpdateNotificationReceiver: bulkUpdateNotificationReceiverFx,
        getNotificationsReceiversAllRead: getNotificationsReceiversAllReadFx,
        getLastNotificationsReceivers: getLastNotificationsReceiversFx,
        clearNotifications,
        clearNotification,
        clearLastNotifications,
        signalNotification,
        handleAllRead,
    };
}
