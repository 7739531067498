import { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useState } from 'react';
import { DEFAULT_GROUP } from '../const/groups';
interface ISelectedGroupProps {
    children?: ReactNode;
}
interface ISelectedGroupContext {
    selectedGroup: string;
    setSelectedGroup: Dispatch<SetStateAction<string>>;
}

const SelectedGroupContext = createContext<ISelectedGroupContext>({
    selectedGroup: DEFAULT_GROUP,
    setSelectedGroup: () => {},
});

export const SelectedGroupProvider: FC<ISelectedGroupProps> = ({ children }) => {
    const [selectedGroup, setSelectedGroup] = useState(DEFAULT_GROUP);
    return (
        <SelectedGroupContext.Provider value={{ selectedGroup, setSelectedGroup }}>
            {children}
        </SelectedGroupContext.Provider>
    );
};

export const useSelectedGroup = () => {
    const { selectedGroup, setSelectedGroup } = useContext(SelectedGroupContext);
    const onSelectedGroup = (group: string) => setSelectedGroup(group);

    return {
        selectedGroup,
        onSelectedGroup,
    };
};
