import { createEffect, createEvent, createStore, forward, sample } from 'effector';
import { Field, SimpleFieldsGroup } from 'domain/field';
import { EntityData } from 'domain/entity';
import { entityDetailEffects } from 'services/store/entityDetail/entityDetail';
import { metaEffects, metaStores } from 'services/store/meta/meta';
import { fieldsService } from 'shared/api/fields.service';
import { entityService } from 'shared/api/entity';

const {
    fetchEntityDataFx,
    updateEntityTitleFx,
    updateEntityDataFx,
    updateEntityFieldsFx,
    updateEntityFieldFx,
    updateFieldsGroupFx,
    changeEntityStageFx,
    createEntityFx,
    deleteEntityFx,
    fetchEntityDataUpdaterFx,
} = entityDetailEffects;
const { fetchMetaFx, fetchMetaUpdaterFx } = metaEffects;
const { $metaStore } = metaStores;

export const initialLocalEntityData: EntityData = {
    deleted_at: '',
    entity: '',
    funnel: { id: '', name: '' },
    funnel_stage: { id: '', name: '' },
    id: '',
    is_deleted: false,
    title: '',
};

export const initialLocalFormEntityData: any = {};

const updateLocalEntityFields = createEvent<Field[]>();
const updateLocalEntityData = createEvent<Partial<EntityData>>();
const resetEntityDetail = createEvent();

const deleteGroupFx = createEffect(async (groupId: string) => {
    return await fieldsService.deleteGroup(groupId);
});

const createGroupFx = createEffect(
    async ({
        tempId,
        entityId,
        order,
    }: {
        tempId: string;
        entityId: string;
        order: number;
    }): Promise<[string, string, any]> => {
        return [tempId, entityId, await fieldsService.createGroup(entityId, order)];
    }
);

const deleteGroup = createEvent<string>();
const createGroup = createEvent<{ tempId: string; entityId: string; order: number }>();
const copyForm = createEvent<any>();

const fetchEntityFieldsGroupsFx = createEffect(async (entityId: string) => {
    return await fieldsService.getGroups(entityId);
});

const $entityFieldsGroupsStore = createStore<SimpleFieldsGroup[]>([])
    .on(fetchEntityFieldsGroupsFx.doneData, (_, data: any) => data.results)
    .on(updateFieldsGroupFx.doneData, (state, response: any) =>
        state.map((g) =>
            g.id === response.id
                ? {
                      ...g,
                      name: response.name,
                  }
                : g
        )
    )
    .on(createGroup, (state, { tempId, entityId }) => {
        const newState: SimpleFieldsGroup[] = [
            ...state,
            {
                id: '',
                name: '',
                order: state?.length,
                entity: entityId,
                tempId,
            },
        ];
        return newState;
    })
    .on(createGroupFx.doneData, (state, [tempId, _, data]) => {
        return state.map((g) => (g.tempId === tempId ? { ...g, ...data } : g));
    });

const hasErrorsFx = createEffect((err: any) => err);

const resetEntityFormData = createEvent();

const $entityFormDataStore = createStore<any>({})
    .on(copyForm, (_, payload) => payload)
    .on(hasErrorsFx.doneData, (old, payload) => {
        return { ...old, hasErrors: payload?.response?.data || payload };
    })
    .reset(resetEntityFormData);

forward({
    from: deleteGroup,
    to: deleteGroupFx,
});

forward({
    from: createGroup,
    to: createGroupFx,
});

sample({
    clock: [updateEntityFieldFx.doneData, deleteGroupFx.doneData],
    source: $metaStore,
    target: fetchMetaFx,
    fn: (sourceStore) => ({ meta: sourceStore.entityName, params: { funnel_view_type: 'detail' } }),
});

const $localEntityFields = createStore<Field[]>([])
    .on(fetchMetaFx.doneData, (_, meta) => meta[0].views.find((v) => v.view_type === 'detail')!.entity_fields)
    .on(
        fetchMetaUpdaterFx.doneData,
        (_, meta) => meta[0].views.find((v) => v.view_type === 'detail')!.entity_fields
    )
    .on(updateLocalEntityFields, (_, fields) => fields)
    .on(updateFieldsGroupFx.doneData, (state, response: any) =>
        state.map((f) =>
            f.group === response.id
                ? {
                      ...f,
                      group_label: response.name,
                  }
                : f
        )
    )
    .reset(resetEntityDetail)
    .reset(deleteEntityFx);

const uploadFileFx = createEffect(async ({ file, abortSignal }: { file: File; abortSignal: AbortSignal }) => {
    return await entityService.uploadFile(file, abortSignal);
});

const syncLocalTitle = createEvent<string>();
const changeStage = createEvent<{ id: string; name: string }>();

const $localEntityData = createStore<EntityData>(initialLocalEntityData)
    .on(fetchEntityDataFx.doneData, (_, entityData) => entityData)
    .on(fetchEntityDataUpdaterFx.doneData, (_, entityData) => entityData)
    .on(createEntityFx.doneData, (_, entityData) => entityData)
    .on(fetchMetaFx.doneData, (state, meta) => ({
        ...state,
        title: meta[0].name ? meta[0].name : state.title,
    }))
    .on(updateLocalEntityData, (state, newState) => {
        Object.keys(state).forEach((key) => {
            state[key] = newState[key];
        });
        return { ...state };
    })
    .on(updateEntityDataFx.doneData, (state, result) => {
        Object.keys(state).forEach((key) => {
            state[key] = result[key];
        });
        return {
            ...state,
            ...result,
        };
    })
    .on(changeEntityStageFx.done, (state, { params }) => {
        return {
            ...state,
            funnel_stage: params.stage,
        };
    })
    .on(syncLocalTitle, (state, title) => ({ ...state, title }))
    .on(changeStage, (state, stage) => ({ ...state, funnel_stage: stage }))
    .reset(resetEntityDetail);

sample({
    clock: $localEntityData,
    source: $metaStore,
    target: fetchEntityFieldsGroupsFx,
    filter: (metaStore, dataStore) => {
        return !!metaStore.id && !!dataStore.id;
    },
    fn: (sourceData) => {
        return sourceData.id;
    },
});

const syncLocalFields = createEvent();
const syncLocalData = createEvent();

sample({
    clock: syncLocalTitle,
    source: { entity: $localEntityData, meta: $metaStore },
    target: updateEntityTitleFx,
    fn: ({ meta, entity }, title) => ({ code: meta.entityName, entityId: entity.id, title: title }),
});

sample({
    clock: syncLocalData,
    source: { data: $localEntityData, meta: $metaStore },
    target: updateEntityDataFx,
    fn: ({ data, meta }) => {
        if (meta.code === 'tasks') {
            // eslint-disable-next-line
            const { funnel, funnel_stage, ...otherData } = data;
            return { code: meta.entityName, entity: otherData };
        }
        return { code: meta.entityName, entity: data };
    },
});

forward({
    from: updateEntityDataFx.failData,
    to: hasErrorsFx,
});

sample({
    clock: syncLocalFields,
    source: $localEntityFields,
    fn: (fields) => ({ fields }),
    target: updateEntityFieldsFx,
});

export const localEntityDetailStores = {
    $localEntityFields,
    $localEntityData,
    $entityFieldsGroupsStore,
    $entityFormDataStore,
};

export const localEntityDetailEffects = {
    fetchEntityFieldsGroupsFx,
    uploadFileFx,
    hasErrorsFx,
};

export const localEntityDetailEvents = {
    syncLocalFields,
    syncLocalData,
    syncLocalTitle,
    updateLocalEntityFields,
    updateLocalEntityData,
    resetEntityDetail,
    changeStage,
    deleteGroup,
    createGroup,
    copyForm,
    resetEntityFormData,
};
