import React from 'react';
import {
    FormControlLabel,
    Switch as MuiSwitch,
    SwitchProps as MuiSwitchProps,
    FormControlLabelProps,
} from '@mui/material';

export type SwitchProps = MuiSwitchProps & {
    label?: string;
    labelPlacement?: FormControlLabelProps['labelPlacement'];
    labelSx?: FormControlLabelProps['sx'];
};

const Switch = ({ label, labelPlacement, labelSx, ...props }: SwitchProps) => {
    return (
        <FormControlLabel
            sx={labelSx}
            labelPlacement={labelPlacement}
            control={<MuiSwitch {...props} />}
            label={label}
        />
    );
};

export default Switch;
