import React, { FC, memo } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { useMenuStorage } from '../../lib/hooks/useMenuStorage';
import { useTranslation } from 'react-i18next';
interface SidebarEditNavListItemMenuProps {
    id: string;
    open: boolean;
    anchorEl: null | HTMLElement;
    handleClose: () => void;
    handleToggleChangeName: () => void;
}
export const SidebarEditNavListItemMenu: FC<SidebarEditNavListItemMenuProps> = memo(
    ({ id, open, handleClose, anchorEl, handleToggleChangeName }) => {
        const { t } = useTranslation();
        const { updateMenuIsEdit, deleteMenu } = useMenuStorage();

        const handleChange = () => {
            handleToggleChangeName();
            handleClose();
            updateMenuIsEdit({ id, isEdit: true });
        };

        const handleDelete = async () => {
            await deleteMenu({ id });
        };
        return (
            <Menu
                id={'sidebar-group-menu'}
                open={open}
                MenuListProps={{
                    'aria-labelledby': 'add-holiday-button',
                    sx: {
                        width: 272,
                    },
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                anchorEl={anchorEl}
                onClose={handleClose}
            >
                <MenuItem onClick={handleChange}>{t('rename')}</MenuItem>
                {/*<MenuItem>Скрыть группу</MenuItem>*/}
                <MenuItem onClick={handleDelete}>{t('deleteGroup')}</MenuItem>
            </Menu>
        );
    }
);
