import {
    TFieldsGroupsResponse,
    TFieldsResponse,
    TFieldsMetaResponse,
    TParamsRequest,
} from 'shared/api/types';
import { createEffect, createEvent, createStore } from 'effector';
import { IFieldsApiService } from 'application/ports/Api';
import { fieldsApiService } from 'services/ApiAdapter';
import { TFields, TFieldsMeta } from 'domain/field';

const initialFieldsEntityStore: TFieldsResponse = {
    next: '',
    previous: '',
    count: 0,
    results: [],
    params: {
        offset: 0,
        limit: 10,
    },
};

const initialFieldsGroupsStore: TFieldsGroupsResponse = {
    next: '',
    previous: '',
    count: 0,
    results: [],
    params: {
        offset: 0,
        limit: 15,
    },
};

const fieldsApi: IFieldsApiService = fieldsApiService();

const fetchFieldsFx = createEffect(async (params: TParamsRequest): Promise<TFieldsResponse> => {
    return await fieldsApi.getFields(params);
});

const fetchFieldsAddFx = createEffect(async (params: TParamsRequest): Promise<TFieldsResponse> => {
    return await fieldsApi.getFields(params);
});

const fetchFieldsGroupsFx = createEffect(async (params: TParamsRequest): Promise<TFieldsGroupsResponse> => {
    return await fieldsApi.getFieldsGroups(params);
});

const createEntityFieldFx = createEffect((params: TFields): Promise<TFields> => {
    return fieldsApi.createEntityField(params);
});

const updateEntityGroupFieldFx = createEffect(
    ({ id, params }: { id: string; params: TFields }): Promise<TFields> => {
        return fieldsApi.updateEntityGroupField(id, params);
    }
);

const deleteFieldFx = createEffect((id: string): Promise<any> => {
    return fieldsApi.deleteField(id);
});

const createFieldsGroupsFx = createEffect(
    async ({
        name,
        code,
        entity,
        order,
    }: {
        name: string;
        code: string;
        entity?: string;
        order?: number;
    }): Promise<TFieldsGroupsResponse> => {
        return await fieldsApi.createFieldsGroups(name, code, entity, order);
    }
);

const deleteFieldsGroupsFx = createEffect(({ code }: { code: string }): Promise<TFieldsGroupsResponse> => {
    return fieldsApi.deleteGroups(code);
});

const getFieldFx = createEffect((id: string): Promise<TFieldsMeta> => {
    return fieldsApi.getField(id);
});

const updateFieldFx = createEffect(
    ({ id, params }: { id: string; params: TParamsRequest }): Promise<TFieldsMeta> => {
        return fieldsApi.updateField(id, params);
    }
);

const updateMetaFieldFx = createEffect(
    ({ id, params }: { id: string; params: TParamsRequest }): Promise<TFieldsMeta> => {
        return fieldsApi.updateMetaField(id, params);
    }
);

const bulkUpdateEntityGroupsFx = createEffect((params: any[]): Promise<TFieldsGroupsResponse> => {
    return fieldsApi.bulkUpdateEntityGroups(params);
});

const updateGroupFieldsFx = createEffect((params: TFields[]): Promise<TFieldsGroupsResponse> => {
    return fieldsApi.updateGroupFields(params);
});

const bindMetaFieldsToEntityGroupFx = createEffect((params: TFields[]): Promise<TFieldsGroupsResponse> => {
    return fieldsApi.bindMetaFieldsToEntityGroup(params);
});

const updateFieldsEntity = createEvent<TFieldsResponse>();
const clearFieldsEntity = createEvent();

const $fieldsEntityStore = createStore<TFieldsResponse>(initialFieldsEntityStore)
    .on(updateFieldsEntity, (_, fields) => fields)
    .on(fetchFieldsFx.doneData, (_, fields) => fields)
    .on(fetchFieldsAddFx.doneData, (_, fields) => fields)
    .reset(clearFieldsEntity);

const initialFieldsMetaStore: TFieldsMetaResponse = {
    next: '',
    previous: '',
    count: 0,
    results: [],
    params: {
        offset: 0,
        limit: 10,
    },
};

// Meta fields
const createMetaFieldFx = createEffect((params: Partial<TFieldsMeta>): Promise<TFieldsMeta> => {
    return fieldsApi.createMetaField(params);
});

const getMetaFieldFx = createEffect(async (id: string): Promise<TFieldsMeta> => {
    return await fieldsApi.getMetaField(id);
});

const getFieldsMetaFx = createEffect(async (params: TParamsRequest): Promise<TFieldsMetaResponse> => {
    return await fieldsApi.getFieldsMeta(params);
});

const deleteFieldFromMetaFx = createEffect((id: string): Promise<any> => {
    return fieldsApi.deleteFieldFromMeta(id);
});

const restoreFieldFromMetaFx = createEffect((id: string): Promise<any> => {
    return fieldsApi.restoreFieldFromMeta(id);
});

const bulkHardDeleteFieldFromMetaFx = createEffect((entities: string[]): Promise<any> => {
    return fieldsApi.bulkHardDeleteFieldFromMeta(entities);
});

const bulkRestoreFieldMetaFx = createEffect((entities: string[]): Promise<any> => {
    return fieldsApi.bulkRestoreFieldMeta(entities);
});

const updateFieldsMeta = createEvent<TFieldsMetaResponse>();
const clearFieldsMeta = createEvent();

const $fieldsMetaStore = createStore<TFieldsMetaResponse>(initialFieldsMetaStore)
    .on(updateFieldsMeta, (_, meta) => meta)
    .on(getFieldsMetaFx.doneData, (_, meta) => meta)
    .reset(clearFieldsMeta);

export type FetchParams = { limit: number; count: number; offset: number; hasNext: boolean };
const resetParams = createEvent();
const $metaFieldsParams = createStore<FetchParams>({
    limit: 20,
    count: 0,
    offset: 0,
    hasNext: true,
})
    .on(getFieldsMetaFx.doneData, (state, data) => ({
        count: data.count,
        hasNext: !!data.next,
        limit: 20,
        offset: state.offset + state.limit,
    }))
    .reset(resetParams);

const $entityFieldsParams = createStore<FetchParams>({
    limit: 20,
    count: 0,
    offset: 0,
    hasNext: true,
})
    .on(fetchFieldsFx.doneData, (state, data) => ({
        count: data.count,
        hasNext: !!data.next,
        limit: 20,
        offset: state.offset + state.limit,
    }))
    .on(fetchFieldsAddFx.doneData, (state, data) => ({
        count: data.count,
        hasNext: !!data.next,
        limit: 20,
        offset: state.offset + state.limit,
    }))
    .reset(resetParams);

const updateFieldsGroups = createEvent<TFieldsGroupsResponse>();
const clearFieldsGroups = createEvent();

const $fieldsGroupsStore = createStore<TFieldsGroupsResponse>(initialFieldsGroupsStore)
    .on(updateFieldsGroups, (_, groups) => groups)
    .on(fetchFieldsGroupsFx.doneData, (state, groups) => ({
        ...groups,
        results: groups.results.map((newGroup) => {
            const oldGroup = state.results.find((group) => group.id === newGroup.id);
            if (oldGroup) {
                newGroup.fields = oldGroup.fields;
            }
            return newGroup;
        }),
    }))
    .reset(clearFieldsEntity);

// sample({
//     clock: createFieldsGroupsFx,
//     // source: $fieldsGroupsStore.getState() || $fieldsGroupsStore.defaultState,
//     filter: (data) => !!data.entity,
//     target: fetchFieldsGroupsFx,
// });

// sample({
//     clock: deleteFieldsGroupsFx,
//     // source: $fieldsGroupsStore.getState() || $fieldsGroupsStore.defaultState,
//     filter: () => true,
//     target: fetchFieldsGroupsFx,
// });

export const fieldsStores = {
    $fieldsEntityStore,
    $fieldsGroupsStore,
    $fieldsMetaStore,
    $metaFieldsParams,
    $entityFieldsParams,
};

export const fieldsEffects = {
    fetchFieldsFx,
    fetchFieldsAddFx,
    fetchFieldsGroupsFx,
    createEntityFieldFx,
    updateEntityGroupFieldFx,
    deleteFieldFx,
    createFieldsGroupsFx,
    deleteFieldsGroupsFx,
    getFieldFx,
    updateFieldFx,
    updateMetaFieldFx,
    updateGroupFieldsFx,
    getMetaFieldFx,
    getFieldsMetaFx,
    createMetaFieldFx,
    bulkUpdateEntityGroupsFx,
    bindMetaFieldsToEntityGroupFx,
    deleteFieldFromMetaFx,
    restoreFieldFromMetaFx,
    bulkHardDeleteFieldFromMetaFx,
    bulkRestoreFieldMetaFx,
};

export const fieldsEvents = {
    resetParams,
    updateFieldsEntity,
    clearFieldsEntity,
    updateFieldsMeta,
    clearFieldsMeta,
    updateFieldsGroups,
    clearFieldsGroups,
};
