import React, { FC } from 'react';
import { CircularProgress, Grid, Typography } from '@mui/material';
import { CircularProgressProps } from '@mui/material/CircularProgress/CircularProgress';

interface LoaderProps extends CircularProgressProps {
    text?: string;
}
const Loader: FC<LoaderProps> = ({ text, ...otherProps }) => {
    return (
        <Grid container display={'flex'} alignItems={'center'} justifyContent={'center'}>
            <CircularProgress {...otherProps} size={'16px'} />
            <Typography variant={'body1'} marginLeft={1.5}>
                {text}
            </Typography>
        </Grid>
    );
};

export default Loader;
