import React from 'react';
import { Grid, Tab, Tabs } from '@mui/material';
function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const HeaderTab = (props: {
    currentTab: number;
    handleChangeTab: (_: React.SyntheticEvent, newValue: number) => void;
}) => {
    const { currentTab, handleChangeTab } = props;
    return (
        <Grid mt={3}>
            <Tabs aria-label="basic tabs example" value={currentTab} onChange={handleChangeTab}>
                <Tab sx={{ textTransform: 'none' }} {...a11yProps(0)} label={'Тип документов'} />
                <Tab sx={{ textTransform: 'none' }} {...a11yProps(1)} label={'Шаблоны'} />
            </Tabs>
        </Grid>
    );
};

export default HeaderTab;
