import Api from 'shared/api/axios';

export const entityService = {
    uploadFile(file: File, abortSignal: AbortSignal) {
        const formData = new FormData();
        formData.append('file', file);
        return Api.post('/api/v1/core/entities/files/upload', formData, {
            signal: abortSignal,
        });
    },
    getAllRelations(code: string, objectId: string, params: any) {
        return Api.get(`/api/v1/core/entities/data/${code}/${objectId}/get-all-relations`, {
            params,
        });
    },
    getRelations(code: string) {
        return Api.get(`/api/v1/core/entities/meta/${code}/relations`);
    },
    getRelatedObjects(code: string, objectId: string) {
        return Api.get(`/api/v1/core/entities/data/${code}/get-related-objects?object_id=${objectId}`);
    },
    createTask(data: any) {
        return Api.post<null, any>(`/api/v1/tasks/`, { ...data });
    },
    deleteTask(id: string) {
        return Api.delete<null, any>(`/api/v1/tasks/${id}`);
    },
    updateTask(id: string, params: any) {
        return Api.patch<null, any>(`/api/v1/tasks/${id}`, params);
    },
    createEntity(code: string, params: any) {
        return Api.post<null, any>(`/api/v1/core/entities/data/${code}`, { ...params });
    },
    deleteEntity(code: string, id: string) {
        return Api.delete<null, any>(`/api/v1/core/entities/data/${code}/${id}/soft-delete`);
    },
    getEntities(code: string, params?: any) {
        let baseUrl;
        if (code === 'department-employees') {
            baseUrl = '/api/v1/organizations/department-employees';
        } else if (code === 'departments') {
            baseUrl = '/api/v1/organizations/departments';
        } else if (code === 'roles') {
            baseUrl = '/api/v1/access/roles';
        } else if (code === 'uuid') {
            baseUrl = '/api/v1/core/entities/entity-object/search';
        } else if (code === 'tasks') {
            baseUrl = '/api/v1/tasks/';
        } else {
            baseUrl = `/api/v1/core/entities/data/${code}`;
        }

        return Api.get<null, any>(baseUrl, { params });
    },
    getEntity(code: string, id: string) {
        return Api.get<null, any>(`/api/v1/core/entities/data/${code}/${id}`);
    },
    updateEntity(code: string, id: string, params: any) {
        let baseUrl;
        if (code === 'tasks') {
            baseUrl = `/api/v1/tasks/${id}`;
        } else {
            baseUrl = `/api/v1/core/entities/data/${code}/${id}`;
        }
        return Api.patch<null, any>(baseUrl, params);
    },
    bulkUpdateEntites(code: string, params: any) {
        let baseUrl;
        if (code === 'tasks') {
            baseUrl = '/api/v1/tasks/bulk-update';
        } else {
            baseUrl = `/api/v1/core/entities/data/${code}/bulk-update`;
        }
        return Api.post<null, any>(baseUrl, params);
    },
    exportData({ code, ...data }: { code: string; all_fields: boolean; format: string; funnel_id: string }) {
        return Api.post<null, any>(`/api/v1/core/entities/data/${code}/export-data`, data, {
            responseType: 'blob',
        });
    },
};
