import { createEffect, createEvent, createStore, sample } from 'effector';
import { IDataApiService } from 'application/ports/Api';
import { dataApiService } from 'services/ApiAdapter';
import { TData } from 'domain/data';
import { TParamsRequest } from 'shared/api/types';
import { entityDetailEffects } from 'services/store/entityDetail/entityDetail';
import { taskEffects } from 'services/store/tasks/tasks';

const { deleteEntityFx: deleteObject } = entityDetailEffects;

const initialDataStore: TData = {
    next: '',
    previous: '',
    count: 0,
    results: [],
    params: {
        offset: 0,
        limit: 10,
    },
};

const dataApi: IDataApiService = dataApiService();

const fetchEntityDataFx = createEffect(async ({ code, id }: { code: string; id: string }) => {
    return await dataApi.getEntityData(code, id);
});

const fetchDataCodeIDFx = createEffect(
    async ({ code, params, p }: { code: string; params: TParamsRequest; p?: any }) => {
        return await dataApi.getDataCodeID(code, params, p);
    }
);

const fetchDataCodeUpdaterFx = createEffect(
    async ({ code, params, p }: { code: string; params: TParamsRequest; p?: any }) => {
        return await dataApi.getDataCodeID(code, params, p);
    }
);

const deleteEntityFx = createEffect(
    async ({ code, id, type }: { code: string; id: string; type: string }) => {
        return await dataApi.deleteEntity(code, id, type);
    }
);

const removeObjectFromEntityFx = createEffect(
    async ({ code, entity_object_ids }: { code: string; entity_object_ids: string[] }) => {
        return await dataApi.removeObjectFromEntity(code, entity_object_ids);
    }
);

const restoreEntityFx = createEffect(async ({ code, id }: { code: string; id: string }) => {
    return await dataApi.restoreEntity(code, id);
});

const bulkHardDeleteFx = createEffect(
    async (data: { code: string; entities: string[] }): Promise<{ entities: string[] }> => {
        return await dataApi.bulkHardDelete(data);
    }
);

const bulkRestoreFx = createEffect(
    async (data: { code: string; entities: string[] }): Promise<{ entities: string[] }> => {
        return await dataApi.bulkRestore(data);
    }
);

const updateData = createEvent<TData>();
const clearData = createEvent();
const resetOffset = createEvent();

const $dataStore = createStore<TData>(initialDataStore)
    .on(updateData, (_, newData) => newData)
    .on(fetchDataCodeIDFx.doneData, (_, newData) => newData)
    .on(fetchDataCodeUpdaterFx.doneData, (_, newData) => newData)
    .on(deleteObject.done, (state, { params }) => {
        return { ...state, results: state.results.filter((item) => item.id !== params.id) };
    })
    .on(resetOffset, (state) => {
        return { ...state, params: { ...state.params, offset: 0 } };
    })
    .reset(clearData);

const { updateTaskDataFx, createTaskFx } = taskEffects;

sample({
    clock: [updateTaskDataFx.doneData, createTaskFx.doneData],
    source: $dataStore,
    fn: (sourceData) => ({ code: 'tasks', params: sourceData.params }),
    target: fetchDataCodeIDFx,
});

const updateEntityTitle = createEvent<string>();

const $entityDetailDataStore = createStore<any>({})
    .on(fetchEntityDataFx.doneData, (_, data) => data)
    .on(fetchEntityDataFx.doneData, (_, data) => data)
    .on(updateEntityTitle, (state) => ({ ...state }));

export const dataStores = {
    $dataStore,
    $entityDetailDataStore,
};

export const dataEffects = {
    fetchDataCodeIDFx,
    deleteEntityFx,
    restoreEntityFx,
    fetchEntityDataFx,
    removeObjectFromEntityFx,
    bulkHardDeleteFx,
    bulkRestoreFx,
    fetchDataCodeUpdaterFx,
};

export const dataEvents = {
    updateData,
    clearData,
    resetOffset,
};
