import React, { Dispatch, FC, SetStateAction, memo, useState } from 'react';
import { Box, Divider, Grid, List, ListItem, ListItemText, Typography } from '@mui/material';
import KeyboardArrowUpRounded from '@mui/icons-material/KeyboardArrowUpRounded';
import { KeyboardArrowDownRounded } from '@mui/icons-material';
import { mockAdminMenus, mockUserMenus, SidebarNavLink } from '../../lib/const/consts';
import { useUsersStorage } from '../../../../services/StorageAdapter';
import { useTranslation } from 'react-i18next';

interface SidebarLocalListItemProps {
    name: string;
    uri: string;
    setSidebarOpen: Dispatch<SetStateAction<boolean>>;
}

const SidebarLocalListItem: FC<SidebarLocalListItemProps> = memo(({ name, uri = '', setSidebarOpen }) => {
    return (
        <ListItem sx={{ pr: 0, pl: 0 }}>
            <SidebarNavLink to={uri} onClick={() => setSidebarOpen((prev) => !prev)}>
                <ListItemText>{name}</ListItemText>
            </SidebarNavLink>
        </ListItem>
    );
});

export const SidebarAppList = memo(
    ({ setSidebarOpen }: { setSidebarOpen: Dispatch<SetStateAction<boolean>> }) => {
        const { t } = useTranslation();
        const [isOpen, setIsOpen] = useState<boolean>(false);
        const { me } = useUsersStorage();

        const onOpen = () => setIsOpen((prev) => !prev);
        return (
            <Box
                sx={{
                    flexDirection: 'column',
                    border: isOpen ? '1px solid rgba(0, 0, 0, 0.16)' : null,
                    borderRadius: isOpen ? '12px' : null,
                    padding: 2,
                    mb: 3,
                }}
            >
                <Grid
                    container
                    onClick={onOpen}
                    justifyContent="space-between"
                    flexWrap={'nowrap'}
                    sx={{ cursor: 'pointer' }}
                >
                    <Typography marginRight={2}>{t('appPages')}</Typography>
                    {isOpen ? (
                        <KeyboardArrowUpRounded color={'action'} />
                    ) : (
                        <KeyboardArrowDownRounded color={'action'} />
                    )}
                </Grid>
                {isOpen ? <Divider flexItem variant={'fullWidth'} sx={{ mt: 2 }} /> : null}
                {isOpen ? (
                    <Grid container>
                        <List disablePadding sx={{ mt: 1.5 }}>
                            {me.is_admin
                                ? mockAdminMenus(t).map(({ id, name, uri }) => (
                                      <SidebarLocalListItem
                                          setSidebarOpen={setSidebarOpen}
                                          key={id}
                                          uri={uri || ''}
                                          name={name}
                                      />
                                  ))
                                : mockUserMenus(t).map(({ id, name, uri }) => (
                                      <SidebarLocalListItem
                                          setSidebarOpen={setSidebarOpen}
                                          key={id}
                                          uri={uri || ''}
                                          name={name}
                                      />
                                  ))}
                        </List>
                    </Grid>
                ) : null}
            </Box>
        );
    }
);
