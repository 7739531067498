import { TFields, TFieldsGroups, TFieldsMeta } from 'domain/field';
import Api from 'shared/api/axios';
import { TFieldsGroupsResponse, TFieldsMetaResponse, TFieldsResponse, TParamsRequest } from './types';

export const fieldService = {
    getFields(params: TParamsRequest) {
        return Api.get<null, TFieldsResponse>(`/api/v1/core/entities/fields`, {
            params: { ...params },
        }).then((res) => {
            return { ...res, params: params };
        });
    },
    getFieldsGroups(params: TParamsRequest) {
        return Api.get<null, TFieldsGroupsResponse>(`/api/v1/core/entities/fields/groups`, {
            params: { ...params },
        }).then((res) => {
            return { ...res, params: params };
        });
    },
    createEntityField(params: TFields) {
        return Api.post<null, TFields>(`/api/v1/core/entities/fields`, { ...params });
    },
    updateEntityGroupField(id: string, params: TFields) {
        return Api.patch<null, TFields>(`/api/v1/core/entities/fields/${id}`, { ...params });
    },
    deleteField(id: string) {
        return Api.delete<null, any>(`/api/v1/core/entities/fields/${id}/soft-delete`);
    },
    createFieldsGroups(name: string, code: string, entity?: string, order?: number) {
        return Api.post<null, TFieldsGroupsResponse>(`/api/v1/core/entities/fields/groups`, {
            name,
            code,
            entity,
            order,
        });
    },
    deleteGroups(code: string) {
        return Api.delete<null, TFieldsGroupsResponse>(`/api/v1/core/entities/fields/groups/${code}`);
    },
    bulkUpdateEntityGroups(params: TFieldsGroups[]) {
        return Api.post<null, TFieldsGroupsResponse>(
            `/api/v1/core/entities/fields/groups/bulk-update`,
            params
        );
    },
    getField(id: string) {
        return Api.get<null, TFieldsMeta>(`/api/v1/core/entities/fields/${id}`);
    },
    updateField(id: string, params: TParamsRequest) {
        return Api.patch<null, TFieldsMeta>(`/api/v1/core/entities/fields/${id}`, { ...params });
    },
    updateMetaField(id: string, params: TParamsRequest) {
        return Api.patch<null, TFieldsMeta>(`/api/v1/core/entities/fields/meta/${id}`, {
            ...params,
        });
    },
    updateGroupFields(params: TFields[]) {
        return Api.post<null, TFieldsGroupsResponse>(`/api/v1/core/entities/fields/bulk-update`, params);
    },

    getMetaField(id: string) {
        return Api.get<null, TFieldsMeta>(`/api/v1/core/entities/fields/meta/${id}`);
    },
    getFieldsMeta(params: TParamsRequest) {
        return Api.get<null, TFieldsMetaResponse>(`/api/v1/core/entities/fields/meta`, {
            params: { ...params },
        }).then((res) => {
            return { ...res, params: params };
        });
    },
    createMetaField(params: Partial<TFieldsMeta>) {
        return Api.post<null, TFieldsMeta>(`/api/v1/core/entities/fields/meta`, { ...params });
    },
    bindMetaFieldsToEntityGroup(params: TFields[]) {
        return Api.post<null, TFieldsGroupsResponse>(`/api/v1/core/entities/fields/bulk-create`, params);
    },
    deleteFieldFromMeta(id: string) {
        return Api.delete<null, any>(`/api/v1/core/entities/fields/meta/${id}/soft-delete`);
    },
    restoreFieldFromMeta(id: string) {
        return Api.put<null, any>(`/api/v1/core/entities/fields/meta/${id}/restore`, { id });
    },
    bulkHardDeleteFieldFromMeta(entities: string[]) {
        return Api.patch<null, any>(`/api/v1/core/entities/fields/meta/bulk-hard-delete`, {
            entities,
        });
    },
    bulkRestoreFieldMeta(entities: string[]) {
        return Api.patch<null, any>(`/api/v1/core/entities/fields/meta/bulk-restore`, {
            entities,
        });
    },
};
