import React, { Dispatch, FC, SetStateAction, memo } from 'react';
import { List } from '@mui/material';
import { TMenuItem } from '../../../../domain/menu';
import { SidebarListItem } from '../SidebarListItem/SidebarListItem';

interface SidebarListProps {
    items: TMenuItem[];
    setSidebarOpen: Dispatch<SetStateAction<boolean>>;
}
export const SidebarList: FC<SidebarListProps> = memo(({ items, setSidebarOpen }) => {
    return (
        <List disablePadding sx={{ mt: 1.5 }}>
            {items.map(({ id, name, uri, entity_code }) => (
                <SidebarListItem
                    key={id}
                    uri={uri || ''}
                    entityCode={entity_code || ''}
                    name={name}
                    setSidebarOpen={setSidebarOpen}
                />
            ))}
        </List>
    );
});
