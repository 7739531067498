import {
    EEvent,
    IEntityObject,
    INotification,
    ITask,
    IUpdateNotificationsReceiverParams,
} from '../../types/notifications';
import { Box, Typography, Link } from '@mui/material';
import i18n from '../../../../shared/config/i18n/i18n';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { forwardRef } from 'react';

interface IProps {
    event: EEvent;
    data: INotification;
    receiver: string;
    update: (param: IUpdateNotificationsReceiverParams) => void;
    handleClose?: () => void;
}

interface IEventProps {
    entity_object: IEntityObject | null;
    task: ITask | null;
    handleClick: () => void;
    title?: string;
    tKey: string;
}

const LinkBehavior = forwardRef<
    HTMLAnchorElement,
    Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
    const { href, ...other } = props;
    return <RouterLink ref={ref} to={href} {...other} />;
});

const Event = (props: IEventProps) => {
    const { entity_object, task, title = '', tKey, handleClick } = props;
    return entity_object ? (
        <Typography variant={'body1'}>
            {i18n.t(tKey, { title: entity_object?.entity_title })}{' '}
            <Link
                href={`/entity/${entity_object?.entity}/${entity_object?.id}`}
                component={LinkBehavior}
                onClick={handleClick}
            >
                {entity_object?.title}
            </Link>
        </Typography>
    ) : (
        <Typography variant={'body1'}>
            {i18n.t(tKey, { title })}{' '}
            <Link href={`/entity/tasks/?task_id=${task?.id}`} component={LinkBehavior} onClick={handleClick}>
                {task?.title}
            </Link>
        </Typography>
    );
};

export const getEvent = (props: IProps) => {
    const { event, update, receiver, data, handleClose } = props;
    const { entity_object, event_history, task, id } = data;

    const handleUpdate = async () => {
        await update({ id, receiver, is_read: true });
    };

    const handleClick = async () => {
        handleClose?.();
        await handleUpdate();
    };
    switch (event) {
        case EEvent.Object_Created:
            return (
                <Event
                    entity_object={entity_object}
                    task={task}
                    tKey={'objectCreated'}
                    handleClick={handleClick}
                />
            );
        case EEvent.Object_Stage_Changed:
            return (
                <Box>
                    <Typography variant={'body1'}>
                        {i18n.t('objectStageChanged', { title: entity_object?.entity_title })}{' '}
                        <Link
                            href={`/entity/${entity_object?.entity}/${entity_object?.id}`}
                            component={LinkBehavior}
                            onClick={handleClick}
                        >
                            {entity_object?.title}
                        </Link>
                    </Typography>
                    {event_history ? (
                        <Typography variant={'body1'}>
                            {event_history.before?.name} → {event_history.after?.name}
                        </Typography>
                    ) : null}
                </Box>
            );
        case EEvent.Object_Funnel_Changed:
            return (
                <Box>
                    <Typography variant={'body1'}>
                        {i18n.t('objectFunnelChanged', { title: entity_object?.entity_title })}{' '}
                        <Link
                            href={`/entity/${entity_object?.entity}/${entity_object?.id}`}
                            component={LinkBehavior}
                            onClick={handleClick}
                        >
                            {entity_object?.title}
                        </Link>
                    </Typography>
                    {event_history ? (
                        <Typography variant={'body1'}>
                            {event_history.before?.name} → {event_history.after?.name}
                        </Typography>
                    ) : null}
                </Box>
            );
        case EEvent.Object_Deleted:
            return (
                <Typography variant={'body1'}>
                    {i18n.t('objectDeleted', { title: entity_object?.entity_title })}{' '}
                    <Link
                        href={`/entity/${entity_object?.entity}/${entity_object?.id}`}
                        component={LinkBehavior}
                        onClick={handleClick}
                    >
                        {entity_object?.title}
                    </Link>
                </Typography>
            );
        case EEvent.Object_Restored:
            return (
                <Typography variant={'body1'}>
                    {i18n.t('objectRestored', { title: entity_object?.entity_title })}{' '}
                    <Link
                        href={`/entity/${entity_object?.entity}/${entity_object?.id}`}
                        component={LinkBehavior}
                        onClick={handleClick}
                    >
                        {entity_object?.title}
                    </Link>
                </Typography>
            );
        case EEvent.Comment_Created:
            return (
                <Event
                    entity_object={entity_object}
                    task={task}
                    title={String(i18n.t('tasks')).toLowerCase()}
                    tKey={'commentCreated'}
                    handleClick={handleClick}
                />
            );
        case EEvent.Responsible_Changed:
            return (
                <Box>
                    <Event
                        entity_object={entity_object}
                        task={task}
                        title={String(i18n.t('tasks')).toLowerCase()}
                        tKey={'responsibleChanged'}
                        handleClick={handleClick}
                    />
                    {event_history ? (
                        <Typography variant={'body1'}>
                            {event_history.before?.name} → {event_history.after?.name}
                        </Typography>
                    ) : null}
                </Box>
            );
        case EEvent.Added_To_Observers:
            return (
                <Event
                    entity_object={entity_object}
                    task={task}
                    title={i18n.t('toTask')}
                    tKey={'addedToObservers'}
                    handleClick={handleClick}
                />
            );
        case EEvent.Deleted_From_Observers:
            return (
                <Event
                    entity_object={entity_object}
                    task={task}
                    title={i18n.t('toTask')}
                    tKey={'deletedFromObservers'}
                    handleClick={handleClick}
                />
            );
        case EEvent.Added_To_Coexecutors:
            return (
                <Event
                    entity_object={entity_object}
                    task={task}
                    title={i18n.t('toTask')}
                    tKey={'addedToExecutors'}
                    handleClick={handleClick}
                />
            );
        case EEvent.Deleted_From_Coexecutors:
            return (
                <Event
                    entity_object={entity_object}
                    task={task}
                    title={i18n.t('toTask')}
                    tKey={'deletedFromExecutors'}
                    handleClick={handleClick}
                />
            );
        case EEvent.Task_Modified:
            return (
                <Event
                    entity_object={entity_object}
                    task={task}
                    tKey={'taskModified'}
                    handleClick={handleClick}
                />
            );
    }
};
