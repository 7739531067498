import { MouseEvent, useRef, useState } from 'react';
import { Button, FormField, Input, Label, Modal } from 'shared/ui';
import { Avatar, Divider, Grid, IconButton, InputAdornment, Typography } from '@mui/material';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { changePasswordSchema } from 'shared/schemas/changePassword';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNotification, useUsersStorage } from 'services/StorageAdapter';
import { useTranslation } from 'react-i18next';

type InitialFormValuesType = { old_password: string; new_password1: string; new_password2: string };
const initialFormValues: InitialFormValuesType = { old_password: '', new_password1: '', new_password2: '' };

const ChangePassword = ({ open, user, onClose }: any) => {
    const { t } = useTranslation();
    const formRef = useRef<FormikProps<InitialFormValuesType>>(null);
    const { changePassword } = useUsersStorage();
    const { notify } = useNotification();
    const [nonFieldErrors, setNonFieldErrors] = useState([]);
    const handleSubmit = (values: InitialFormValuesType, _actions: FormikHelpers<InitialFormValuesType>) => {
        changePassword(values)
            .then(() => {
                notify({
                    message: 'Пароль успешно изменён!',
                });
                onClose();
            })
            .catch((err) => {
                if (formRef.current) {
                    if (err.response.data.non_field_errors) {
                        setNonFieldErrors(err.response.data.non_field_errors);
                    }
                    formRef.current.setErrors(err.response.data);
                }
            });
    };
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <Modal open={open} title={t('changePassword')} width={633} onClose={onClose}>
            <Formik
                innerRef={formRef}
                initialValues={initialFormValues}
                onSubmit={handleSubmit}
                validationSchema={changePasswordSchema}
            >
                {(formik: FormikProps<InitialFormValuesType>) => {
                    return (
                        <form onSubmit={formik.handleSubmit}>
                            <Grid px={'24px'} pt={'24px'}>
                                <Grid container gap={2} alignItems="center" mb={'24px'}>
                                    <Avatar
                                        variant="rounded"
                                        alt={user?.first_name}
                                        src={user?.avatar_url || user?.avatar}
                                        sx={{ width: 74, height: 74 }}
                                    />
                                    <Grid>
                                        <Label
                                            label={`${user?.last_name} ${user?.first_name} ${
                                                user?.surname || ''
                                            }`}
                                        />
                                        <Button sx={{ p: 0 }}>{user.email}</Button>
                                    </Grid>
                                </Grid>
                                <Label label={t('confirmAccountText')} />
                                <Input
                                    error={!!formik.errors.old_password && formik.touched.old_password}
                                    fullWidth
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder={t('enterCurrentPassword')}
                                    helperText={formik.touched.old_password ? formik.errors.old_password : ''}
                                    {...formik.getFieldProps('old_password')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Divider sx={{ mt: '24px' }} />
                            <Grid>
                                <Grid my={'24px'} px={'24px'}>
                                    <Grid container gap={'12px'} mb={'28px'}>
                                        <Typography variant={'body1'}>{t('passwordHelpText')}</Typography>
                                    </Grid>
                                    <FormField label={t('newPassword')}>
                                        <Input
                                            error={
                                                !!formik.errors.new_password1 && formik.touched.new_password1
                                            }
                                            id={'password1'}
                                            fullWidth
                                            sx={{ mb: '24px' }}
                                            type={showPassword ? 'text' : 'password'}
                                            placeholder={t('newPassword')}
                                            helperText={
                                                formik.touched.new_password1
                                                    ? formik.errors.new_password1
                                                    : ''
                                            }
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {showPassword ? (
                                                                <VisibilityOff />
                                                            ) : (
                                                                <Visibility />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            {...formik.getFieldProps('new_password1')}
                                        />
                                        <Input
                                            error={
                                                !!formik.errors.new_password2 && formik.touched.new_password2
                                            }
                                            id={'password2'}
                                            fullWidth
                                            type={showPassword ? 'text' : 'password'}
                                            placeholder={t('confirmNewPassword')}
                                            helperText={
                                                formik.touched.new_password2
                                                    ? formik.errors.new_password2
                                                    : ''
                                            }
                                            {...formik.getFieldProps('new_password2')}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {showPassword ? (
                                                                <VisibilityOff />
                                                            ) : (
                                                                <Visibility />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </FormField>
                                    {!!nonFieldErrors.length && (
                                        <FormField>
                                            <Typography color={'red'}>{nonFieldErrors[0]}</Typography>
                                        </FormField>
                                    )}
                                </Grid>
                                <Divider />
                                <Grid container justifyContent="flex-end" px={'24px'} pt={'16px'} gap={'8px'}>
                                    <Button variant="contained" type={'submit'}>
                                        {t('varyPassword')}
                                    </Button>
                                    <Button onClick={onClose} variant="outlined" type={'submit'}>
                                        {t('cancel')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    );
                }}
            </Formik>
        </Modal>
    );
};

export default ChangePassword;
