import { FC, memo, useEffect } from 'react';
import { CustomDialog, Transition } from '../lib/const/components';
import { ProfileDrawerClose } from './ProfileDrawerClose/ProfileDrawerClose';
import { useUsersStorage } from '../../../services/StorageAdapter';
import { ProfileInfo } from './ProfileInfo/ProfileInfo';
import { ProfileDepartment } from './ProfileDepartment/ProfileDepartment';
import { ProfileAdditionalInfo } from './ProfileAdditionalInfo/ProfileAdditionalInfo';

interface UserProfileProps {
    open: boolean;
    handleClose: () => void;
    userId: string;
}
export const UserProfile: FC<UserProfileProps> = memo(({ open, handleClose, userId }) => {
    const { fetchUser, clearUser } = useUsersStorage();

    useEffect(() => {
        if (userId) {
            fetchUser(userId);
        }
        return () => {
            clearUser();
        };
    }, [userId]);
    return (
        <CustomDialog
            fullScreen
            sx={{ maxWidth: '612px', left: 'auto' }}
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <ProfileDrawerClose handleClose={handleClose} />
            <ProfileInfo />
            <ProfileDepartment />
            <ProfileAdditionalInfo open={open} />
        </CustomDialog>
    );
});
