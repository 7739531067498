import * as Yup from 'yup';

export const changePasswordSchema = Yup.object().shape({
    old_password: Yup.string().required('Обязательное поле'),
    new_password1: Yup.string().min(8, 'Длина пароля менее 8 символов').required('Обязательное поле'),
    new_password2: Yup.string()
        .min(8, 'Длина пароля менее 8 символов')
        .required('Обязательное поле')
        .oneOf([Yup.ref('new_password1')], 'Пароли должны совпадать'),
});
