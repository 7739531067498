import { Field } from 'domain/field';
import Api from 'shared/api/axios';

export const fieldsService = {
    updateField(fieldId: string, data: any) {
        return Api.patch(`api/v1/core/entities/fields/${fieldId}`, data);
    },
    bulkUpdateFields(fields: Partial<Field>[]) {
        return Api.post(`api/v1/core/entities/fields/bulk-update`, fields);
    },
    updateFieldsGroup(groupId: string, data: any) {
        return Api.patch(`api/v1/core/entities/fields/groups/${groupId}`, data);
    },
    createFieldsGroup(data: any) {
        return Api.post(`api/v1/core/entities/fields/groups`, data);
    },
    bulkUpdateGroups(groups: { id: string; [k: string]: any }[]) {
        return Api.post(`api/v1/core/entities/fields/groups/bulk-update`, groups);
    },
    deleteGroup(groupId: string) {
        return Api.delete(`api/v1/core/entities/fields/groups/${groupId}`);
    },
    createGroup(entityId: string, order: number) {
        return Api.post(`api/v1/core/entities/fields/groups`, { entity: entityId, order });
    },
    getGroups(entityId: string) {
        return Api.get(`api/v1/core/entities/fields/groups`, {
            params: {
                entity: entityId,
                limit: 100,
            },
        });
    },
};
