import Api from 'shared/api/axios';
import { TResponse } from 'shared/api/types';

export const roleService = {
    getRole(id: string) {
        return Api.get<null, any>(`/api/v1/access/roles/${id}`);
    },
    getRoles(params: any) {
        return Api.get<null, TResponse>(`/api/v1/access/roles`, {
            params: { ...params },
        }).then((res) => {
            return { ...res, params: params };
        });
    },
    updateRole(role: any) {
        return Api.put(`/api/v1/access/roles/${role.id}`, role);
    },
    deleteRole(id: string) {
        return Api.delete<null, any>(`/api/v1/access/roles/${id}/soft-delete`);
    },
    createRole(role: any) {
        return Api.post(`/api/v1/access/roles`, role);
    },
};
