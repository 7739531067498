import React from 'react';
import { TextField, TextFieldProps, Typography } from '@mui/material';

export type InputProps = TextFieldProps & {
    isEdit?: boolean;
};

const Input = ({ children, size = 'small', isEdit = true, value, ...props }: InputProps) => {
    return (
        <>
            {isEdit ? (
                <TextField value={value} size={size} {...props}>
                    {children}
                </TextField>
            ) : (
                <Typography variant={'body1'}>{value as string}</Typography>
            )}
        </>
    );
};

export default Input;
