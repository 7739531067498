import { FC, useMemo, useState } from 'react';
import { Avatar, Box, Button, createTheme, PaletteMode, ThemeProvider, Typography } from '@mui/material';
import { avatarSx } from '../../ChatBar/ui/ChatBarDialog/ChatBarDialog.styled';
import { stringToHslColor } from '../../../shared/helpers/stringToHslColor';
import dayjs from 'dayjs';
import { getDesignTokens } from '../../../shared/ui';
import { useSnackbar } from 'notistack';
import { EEvent, INotification } from '../../../pages/Notifications/types/notifications';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getEvent } from '../../../pages/Notifications/lib/const/type';
import { useUsersStorage } from '../../../services/StorageAdapter';
import { useNotificationStorage } from '../../../pages/Notifications';

interface SnackbarProps {
    data: INotification;
}

export const NotificationUser: FC<SnackbarProps> = ({ data }) => {
    const { t } = useTranslation();
    const [mode] = useState<PaletteMode>('light');
    const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);
    const { closeSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { me } = useUsersStorage();
    const { updateNotificationReceiver } = useNotificationStorage();
    const onOpen = () => {
        closeSnackbar();
        navigate('/notification');
    };

    return (
        <ThemeProvider theme={theme}>
            <Box display={'flex'} alignItems={'center'}>
                <Box display={'flex'} alignItems={'center'} marginRight={1.5}>
                    <Avatar
                        sx={{
                            ...avatarSx,
                            backgroundColor: stringToHslColor(data.author?.fullname, 70, 45),
                        }}
                        variant="rounded"
                        src={data.author?.avatar}
                    />
                    <Box marginLeft={1.5}>
                        <Typography variant={'subtitle2'} color={'rgba(0, 0, 0, 0.87)'}>
                            {data.author?.fullname}
                        </Typography>
                        <Typography variant={'body2'} color={'rgba(0, 0, 0, 0.6)'}>
                            {getEvent({
                                event: data.event || EEvent.Object_Created,
                                data,
                                update: updateNotificationReceiver,
                                receiver: me.employee || '',
                            })}
                        </Typography>
                    </Box>
                </Box>
                <Box display={'flex'} flexDirection={'column'}>
                    <Typography variant={'body2'} textAlign={'center'} color={'rgba(0, 0, 0, 0.6)'}>
                        {dayjs(data.created).format('DD.MM.YYYY')}
                    </Typography>
                    <Button size={'small'} onClick={onOpen}>
                        {t('open')}
                    </Button>
                </Box>
            </Box>
        </ThemeProvider>
    );
};
