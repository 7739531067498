import { createEffect, createEvent, createStore, sample } from 'effector';
import { IDepartmentApiService } from 'application/ports/Api';
import { departmentApiService } from 'services/ApiAdapter';
import { TResponse } from 'shared/api/types';
import { DepartmentType } from 'domain/employee';
import { employeesEffects } from 'services/store/employee/employee';

const initialDepartmentsStore = {
    next: '',
    previous: '',
    count: 0,
    results: [],
    params: {
        offset: 0,
        limit: 10,
    },
};

const fetchDepartmentsFx = createEffect(async ({ params }: { params: any }): Promise<TResponse> => {
    const departmentApi: IDepartmentApiService = departmentApiService();
    return await departmentApi.getDepartments(params);
});

const deleteDepartmentFx = createEffect(async (departmentId: string): Promise<any> => {
    const departmentApi: IDepartmentApiService = departmentApiService();
    return await departmentApi.deleteDepartment(departmentId);
});

const getDepartmentFx = createEffect(async (departmentId: string): Promise<any> => {
    const departmentApi: IDepartmentApiService = departmentApiService();
    const department = await departmentApi.getDepartment(departmentId);
    department.employees = department.employees.map((e: any) => {
        const id = e.user;
        e.user = {
            id,
            first_name: e.first_name,
            last_name: e.last_name,
        };
        return e;
    });
    return department;
});

const updateDepartmentFx = createEffect(
    async ({ departmentId, data }: { departmentId: string; data: any }): Promise<any> => {
        const departmentApi: IDepartmentApiService = departmentApiService();
        return await departmentApi.updateDepartment(departmentId, data);
    }
);

const createDepartmentFx = createEffect(async (data: any): Promise<any> => {
    const departmentApi: IDepartmentApiService = departmentApiService();
    return await departmentApi.createDepartment(data);
});

const setDepartmentsRoles = createEffect(
    async ({ departmentId, role }: { departmentId: string; role: string }) => {
        const departmentApi: IDepartmentApiService = departmentApiService();
        return await departmentApi.setRoles(departmentId, role);
    }
);

const setDepartmentUsers = createEffect(
    async ({
        departmentId,
        users,
        _create = false,
    }: {
        departmentId: string;
        users: string[];
        _create: boolean;
    }) => {
        const departmentApi: IDepartmentApiService = departmentApiService();
        return await departmentApi.setUsers(departmentId, users);
    }
);

const updateDepartments = createEvent<TResponse>();
const clearDepartments = createEvent();

const $departmentsStore = createStore<TResponse>(initialDepartmentsStore)
    .on(updateDepartments, (_, user) => user)
    .on(deleteDepartmentFx.done, (state, { params }) => {
        return { ...state, results: state.results.filter((d) => d.id !== params) };
    })
    .on(fetchDepartmentsFx.doneData, (_, user) => user)
    .on(updateDepartmentFx.doneData, (state, data) => {
        return {
            ...state,
            results: state.results.map((department) => {
                if (department.id === data.id) {
                    return {
                        ...department,
                        ...data,
                    };
                }
                return department;
            }),
        };
    })
    .on(createDepartmentFx.doneData, (state, data) => {
        return {
            ...state,
            results: [...state.results, { ...data, children: [] }],
        };
    })
    .reset(clearDepartments);

const clearDepartment = createEvent();

const $departmentStore = createStore<DepartmentType>({
    name: 'Новый департамент',
    parent: '',
    head: null,
    id: '',
    role: null,
})
    .on(getDepartmentFx.doneData, (_, data) => {
        return data;
    })
    // .on(updateDepartmentFx.doneData, (state, data) => ({ ...state, ...data }))
    .reset(deleteDepartmentFx.doneData)
    .reset(clearDepartment);

sample({
    clock: createDepartmentFx.done,
    filter: ({ params }) => params.role,
    fn: ({ params, result }) => ({ departmentId: result.id, role: params.role }),
    target: setDepartmentsRoles,
});

sample({
    source: $departmentStore,
    clock: updateDepartmentFx.done,
    filter: ({ role }, { params }) => params.data.role || role,
    fn: (_, { params, result }) => ({ departmentId: result.id, role: params.data.role, _create: false }),
    target: setDepartmentsRoles,
});

sample({
    clock: createDepartmentFx.done,
    filter: ({ params }) => params.employees.length > 0,
    fn: ({ params, result }) => ({ departmentId: result.id, users: params.employees, _create: true }),
    target: setDepartmentUsers,
});

sample({
    clock: setDepartmentUsers.done,
    filter: ({ params }) => !params._create,
    fn: ({ params }) => params.departmentId,
    target: getDepartmentFx,
});

const { fetchEmployeesFx } = employeesEffects;

sample({
    clock: setDepartmentUsers.doneData,
    fn: () => ({ limit: 100000 }),
    target: fetchEmployeesFx,
});

sample({
    clock: updateDepartmentFx.done,
    filter: ({ params }) => params.data.employees.length > 0,
    fn: ({ params, result }) => ({ departmentId: result.id, users: params.data.employees, _create: false }),
    target: setDepartmentUsers,
});

export const departmentStores = {
    $departmentsStore,
    $departmentStore,
};

export const departmentEvents = {
    updateDepartments,
    clearDepartments,
    clearDepartment,
};

export const departmentEffects = {
    fetchDepartmentsFx,
    getDepartmentFx,
    deleteDepartmentFx,
    updateDepartmentFx,
    createDepartmentFx,
};
