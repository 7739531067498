import { useContext, useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Button } from 'shared/ui';
import { DuplicatesEnum, DuplicatesStateContext } from 'pages/Duplicates/Duplicates';
import { useTranslation } from 'react-i18next';

const SearchStartedStep = () => {
    const { t: duplicatesT } = useTranslation('duplicates');
    const { setState } = useContext(DuplicatesStateContext);
    const [searchProgress, setSearchProgress] = useState(0);

    const handleCancelSearch = () => {
        setState?.(DuplicatesEnum.searchNotStarted);
    };

    const handleCompleteSearch = () => {
        setState?.(DuplicatesEnum.duplicatesFound);
    };

    useEffect(() => {
        let timer: unknown;
        if (searchProgress === 100) {
            handleCompleteSearch();
        } else {
            timer = setTimeout(() => setSearchProgress((prev) => prev + 50), 1500) as unknown;
        }
        return () => {
            timer && clearTimeout(timer as number);
        };
    }, [searchProgress]);

    return (
        <Grid container flexDirection={'column'} alignItems={'flex-start'} gap={'12px'}>
            <Grid container flexDirection={'column'}>
                <Grid container justifyContent={'space-between'}>
                    <Typography variant={'body1'}>{searchProgress}%</Typography>
                    <Typography variant={'body1'}>{duplicatesT('scanLoading')}</Typography>
                </Grid>
                <LinearProgress variant={'determinate'} value={searchProgress} />
            </Grid>
            <Grid container gap={'13px'} flexWrap={'nowrap'}>
                <InfoOutlinedIcon fontSize={'small'} />
                <Typography variant={'body2'}>{duplicatesT('scanDesc')}</Typography>
            </Grid>
            <Button onClick={handleCancelSearch} size={'large'} variant={'contained'}>
                {duplicatesT('scanCancel')}
            </Button>
        </Grid>
    );
};

export default SearchStartedStep;
