import React, { FC, memo, useCallback } from 'react';
import { MenuItem } from '@mui/material';
import { NestedMenuItem } from 'mui-nested-menu';
import { useTranslation } from 'react-i18next';
import { useMenuStorage } from '../../lib/hooks/useMenuStorage';
import { TMenuItem } from '../../../../domain/menu';

interface MoveMenuItemProps {
    open: boolean;
    item: TMenuItem;
    handleClose: () => void;
    currMenuId: string;
}
export const MoveMenuItem: FC<MoveMenuItemProps> = memo(({ open, item, handleClose, currMenuId }) => {
    const { menu, updateMenuItem, updateMenuItemMove } = useMenuStorage();
    const { t } = useTranslation();

    const handleUpdate = useCallback(
        async (menu: any) => {
            await updateMenuItem({ id: item.id, menu });
            updateMenuItemMove({ menu, item, currMenuId });
            handleClose();
        },
        [item, currMenuId]
    );
    return (
        <NestedMenuItem label={t('move')} parentMenuOpen={open} sx={{ pl: '8px', pr: '8px' }}>
            {menu.results.map(({ id, name }) => (
                <MenuItem key={id} onClick={() => handleUpdate(id)}>
                    {name}
                </MenuItem>
            ))}
        </NestedMenuItem>
    );
});
