import { useStore } from 'effector-react';
import decode from 'jwt-decode';
import { Centrifuge, PublishResult } from 'centrifuge';
import { chatEffects, chatEvents, chatStores } from '../../models/chat/store/chat';
import { TChatPublishMessageParamsRequest } from '../../types/chat';
import { useSnackbar } from 'notistack';
import { ChatSnackbar } from '../../ui/ChatSnackbar/ChatSnackbar';
import { useChatToggle } from '../../../../shared/context/ChatContext';
import { TChatNotification } from '../../../../shared/api/types';
import { useSelectedChat } from '../context/SelectedChatContext';
import { useSelectedUser } from '../context/SelectedUserContext';
import { ChatSnackbarSystem } from '../../ui/ChatSnackbarSystem/ChatSnackbarSystem';
import { useSelectedGroup } from '../context/SelectedGroupContext';
import { SnackbarCloseButton } from '../../ui/SnackbarCloseButton/SnackbarCloseButton';
import config from '../../../../config';

const { $chatStore, $redirectChannelStore } = chatStores;
const {
    clearWS,
    updateChatWS,
    updateChatMessage,
    removeChatList,
    updateNotifications,
    updateRedirectChannel,
} = chatEvents;
const {
    fetchChatConnectFx,
    fetchChatListFx,
    fetchChatMessagesFx,
    createChannelFx,
    uploadChatFileFx,
    closeChatChannelFx,
    redirectChatChannelFx,
    chatLastReadChannelFx,
} = chatEffects;

const user: null | { user_id: string } = localStorage?.getItem('token')
    ? decode(localStorage?.getItem('token') || '')
    : null;
export function useChatStorage() {
    const chat = useStore($chatStore);
    const redirectChannel = useStore($redirectChannelStore);
    const { enqueueSnackbar } = useSnackbar();
    const { setOpen } = useChatToggle();
    const { onSelectedChat } = useSelectedChat();
    const { onSelectedUser } = useSelectedUser();
    const { onSelectedGroup } = useSelectedGroup();
    const connectChatWS = (token: string) => {
        const centrifuge = new Centrifuge(config.ws_base_url || '', {
            token: token,
        });
        centrifuge
            .on('connecting', function () {})
            .on('connected', function () {})
            .on('disconnected', function () {})
            .connect();
        updateChatWS({ ...chat, centrifuge: centrifuge });
    };

    const subscribeToChatChannel = (channel_name: string) => {
        const subscribe = chat.centrifuge?.newSubscription(channel_name);
        subscribe
            ?.on('publication', function (ctx) {
                updateChatMessage(ctx?.data);
            })
            .on('subscribing', function () {
                // console.log(`subscribing: `, ctx);
            })
            .on('subscribed', function () {
                // console.log('subscribed', ctx);
            })
            .on('unsubscribed', function (ctx) {
                if (ctx?.channel && ctx?.reason === 'server unsubscribe') {
                    removeChatList(ctx);
                }
                // console.log(`unsubscribed`, ctx);
            })
            .subscribe();
        updateChatWS({ ...chat, subscribe: subscribe });
    };

    const onEnqueue = (notification: TChatNotification) => {
        enqueueSnackbar(
            notification.type === 'new_message' ? (
                <ChatSnackbar
                    notification={notification}
                    setOpen={setOpen}
                    onSelectedChat={onSelectedChat}
                    onSelectedUser={onSelectedUser}
                />
            ) : (
                <ChatSnackbarSystem
                    notification={notification}
                    setOpen={setOpen}
                    onSelectedGroup={onSelectedGroup}
                />
            ),
            {
                autoHideDuration: 5000,
                action: (snackbarKey) => <SnackbarCloseButton snackbarKey={snackbarKey} />,
            }
        );
    };

    const subscribeToChatPersonalChannel = (channel_name: any) => {
        try {
            const subscribe = chat.centrifuge?.newSubscription(channel_name);
            subscribe
                ?.on('publication', function (ctx) {
                    onEnqueue(ctx.data);
                })
                .on('subscribing', function () {})
                .on('subscribed', function () {})
                .on('unsubscribed', function () {})
                .subscribe();
            updateChatWS({ ...chat, personalSubscribe: subscribe });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
        }
    };
    const publishMessage = ({
        channel_uuid,
        text,
        replayed_message_uuid = null,
    }: TChatPublishMessageParamsRequest): Promise<PublishResult> | undefined => {
        return chat?.subscribe?.publish({
            created_at: new Date(),
            replayed_message_uuid,
            channel_uuid,
            text,
            user: {
                uuid: chat?.connect?.user_uuid,
                name: chat?.connect?.user_name,
                service_id: user?.user_id,
                source: 'crm',
            },
        });
    };

    const updateLastRead = ({ channel_uuid }: { channel_uuid: string }): any => {
        return chat.centrifuge?.rpc('update_last_read', {
            message_uuid: null,
            text: null,
            channel_uuid,
        });
    };

    return {
        chat,
        redirectChannel,
        clearWS,
        connectChatWS,
        publishMessage,
        subscribeToChatChannel,
        subscribeToChatPersonalChannel,
        updateLastRead,
        removeChatList,
        updateNotifications,
        updateRedirectChannel,
        fetchChatConnect: fetchChatConnectFx,
        fetchChatList: fetchChatListFx,
        fetchChatMessages: fetchChatMessagesFx,
        createChannel: createChannelFx,
        updateChatFile: uploadChatFileFx,
        closeChatChannel: closeChatChannelFx,
        redirectChatChannel: redirectChatChannelFx,
        chatLastReadChannel: chatLastReadChannelFx,
    };
}
