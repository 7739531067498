import {
    AlertColor,
    alpha,
    Avatar,
    Card,
    CardContent,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Typography,
    useTheme,
} from '@mui/material';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import { useMenu } from '../../hooks/useMenu';
import { useTranslation } from 'react-i18next';

type NotificationProps = {
    title?: string;
    type?: AlertColor;
    data?: any;
    handleUpdate?: (id: string) => void;
    isMore?: boolean;
};

const Notification = ({ data, handleUpdate, isMore = false }: NotificationProps) => {
    const { t } = useTranslation();
    const { anchorEl, open, handleClose, handleClick } = useMenu();
    const {
        palette: {
            info: { main: infoMain },
            background: { paper },
        },
    } = useTheme();

    return (
        <Card sx={{ width: '100%', backgroundColor: data.read ? paper : alpha(infoMain, 0.04) }}>
            <CardContent>
                <Grid container spacing={1}>
                    <Grid item xs={11} display="flex" alignItems="center" gap={2}>
                        <Grid item display="flex" alignItems="center" gap={1}>
                            <Avatar
                                sx={{
                                    width: 24,
                                    height: 24,
                                    borderRadius: 2,
                                }}
                                src={data?.user?.avatar}
                            >
                                <Typography variant={'overline'}>
                                    {data?.user?.name.match(/^.|.$/g)?.join('') || 'Нурмухамед Адиев'}
                                </Typography>
                            </Avatar>
                            <Typography variant={'body2'} color="primary.main">
                                {data?.user?.name || 'Нурмухамед Адиев'}
                            </Typography>
                        </Grid>
                        <Typography variant={'body1'} color="text.secondary">
                            {data?.date}
                        </Typography>
                    </Grid>
                    {isMore ? (
                        <Grid item xs={1} display="flex" justifyContent="end">
                            <IconButton onClick={handleClick}>
                                <MoreHorizRoundedIcon fontSize={'inherit'} />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            >
                                <MenuItem onClick={() => handleUpdate?.(data?.id)}>
                                    {t('markAsRead')}
                                </MenuItem>
                                {/*<MenuItem onClick={handleClose}>Отправить в архив</MenuItem>*/}
                            </Menu>
                        </Grid>
                    ) : null}
                    <Grid item xs={12}>
                        {data?.content}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default Notification;
