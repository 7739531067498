import { useStore } from 'effector-react';
import { IFilterStorage } from '../../types/filter';
import { filterEffects, filterEvents, filterStores } from '../../model/filter';
import { sample } from 'effector';

const { $filtersStore, $filterStore } = filterStores;
const { getFilterUsersFx, getFiltersUsersFx, createFilterUsersFx, updateFilterUsersFx, deleteFilterUsersFx } =
    filterEffects;
const { clearFilterStore, updateFilterStore } = filterEvents;

export function useFilterStorage(): IFilterStorage {
    const filter = useStore($filterStore);
    const filters = useStore($filtersStore);

    // sample({
    //     clock: createFilterUsersFx.doneData,
    //     filter: ({ id }) => !!id,
    //     fn: ({ user }) => ({ ...filters.params, user: user as string }),
    //     target: getFiltersUsersFx,
    // });

    sample({
        clock: createFilterUsersFx.done,
        filter: ({ result }) => !!result.id,
        fn: ({ result }) => ({ ...result }),
        target: updateFilterStore,
    });

    // sample({
    //     clock: deleteFilterUsersFx.done,
    //     filter: () => true,
    //     fn: ({ result }) => ({ ...filters.params, user: filters?.params?.user || (result?.user as string) }),
    //     target: getFiltersUsersFx,
    // });

    // guard({
    //     clock: createFilterUsersFx.doneData,
    //     source: $filterStore,
    //     filter: (data) => !!data.id,
    //     target: getFiltersUsersFx,
    // });

    return {
        filter,
        filters,
        getFiltersUsers: getFiltersUsersFx,
        getFilterUsers: getFilterUsersFx,
        createFilterUsers: createFilterUsersFx,
        updateFilterUsers: updateFilterUsersFx,
        deleteFilterUsers: deleteFilterUsersFx,
        clearFilterStore,
        updateFilterStore,
    };
}
