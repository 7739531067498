import React from 'react';
import { alpha, Box, Grid, Typography, useTheme } from '@mui/material';

type StageItemProps = {
    id: string;
    color: string;
    title: string;
    isActive: boolean;
    handleClickStage: (stage: { id: string; name: string }) => void;
};

const StageItem = ({ id, title, color, isActive, handleClickStage }: StageItemProps) => {
    const {
        palette: {
            action: { disabled },
        },
    } = useTheme();
    return (
        <div
            onClick={() => handleClickStage({ id, name: title })}
            style={{
                width: '100%',
                maxWidth: '268px',
            }}
        >
            <Grid container flexWrap={'nowrap'} alignItems={'center'}>
                <Box
                    sx={{
                        backgroundColor: isActive ? alpha(color || '#0000FF', 0.12) : disabled,
                        borderRadius: '8px 0px 0px 8px',
                        overflow: 'hidden',
                        maxWidth: '268px',
                        height: '38px',
                        position: 'relative',
                        flexGrow: 1,
                    }}
                >
                    <Box
                        px={'12px'}
                        sx={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            borderBottom: `2px solid ${color || '#0000FF'}`,
                        }}
                    >
                        <Typography variant={'subtitle2'} whiteSpace={'nowrap'}>
                            {title}
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: 0,
                        height: 0,
                        borderStyle: 'solid',
                        borderWidth: '19px 0 19px 17px',
                        borderColor: `transparent transparent transparent ${
                            isActive ? alpha(color || '#0000FF', 0.12) : disabled
                        }`,
                    }}
                />
            </Grid>
        </div>
    );
};

type Stage = {
    id: string;
    color: string;
    name: string;
    isActive: boolean;
};

type StageLineProps = {
    stages: Stage[];
    handleClickStage: (stage: { id: string; name: string }) => void;
};

const StageLine = ({ stages, handleClickStage }: StageLineProps) => {
    return (
        <Grid
            container
            gap={'12px'}
            flexWrap={'nowrap'}
            width={'100%'}
            sx={{
                overflowX: 'auto',
                ['&::-webkit-scrollbar']: {
                    visibility: 'hidden',
                    height: 0,
                },
            }}
        >
            {stages.map(({ color, name, isActive, id }) => (
                <StageItem
                    key={id}
                    id={id}
                    color={color}
                    title={name}
                    isActive={isActive}
                    handleClickStage={handleClickStage}
                />
            ))}
        </Grid>
    );
};

export default StageLine;
