import Api from '../../../../shared/api/axios';
import { TParamsRequest } from '../../../../shared/api/types';
import { IReportsService, TReportsResponse } from '../../types/reports';

const reportsService = {
    getAll(params: TParamsRequest) {
        return Api.get<null, TReportsResponse>(`/api/v1/reports/`, {
            params,
        });
    },
    delete(id: string) {
        return Api.delete<null, null>(`/api/v1/reports/${id}`);
    },
    create(data: any) {
        return Api.post(`/api/v1/reports/`, data);
    },
    save(id: string, data: any) {
        return Api.put(`/api/v1/reports/${id}`, data);
    },
    fetchDetail(id: string) {
        return Api.get(`/api/v1/reports/${id}`);
    },
};

export function reportsApiService(): IReportsService {
    return {
        getAll: reportsService.getAll,
        delete: reportsService.delete,
        create: reportsService.create,
        save: reportsService.save,
        fetchDetail: reportsService.fetchDetail,
    };
}
