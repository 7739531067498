import React from 'react';
import { TFieldProps } from 'widgets/DraggableField/Fields/types';
import { Button, Input } from 'shared/ui';
import DefaultField from 'widgets/DraggableField/Fields/DefaultField/DefaultField';
import { Grid, IconButton } from '@mui/material';
import { CancelRounded } from '@mui/icons-material';
import AddCircleRounded from '@mui/icons-material/AddCircleRounded';
import { Nullable } from 'shared/types';
import { useTranslation } from 'react-i18next';

const NumberField = ({
    field,
    setValue,
    value,
    edit,
    error,
}: TFieldProps<Nullable<number> | Nullable<number>[] | any>) => {
    const { t } = useTranslation();
    const isMany = field.settings.many;
    return (
        <>
            {edit ? (
                <>
                    {isMany && Array.isArray(value) ? (
                        <Grid container flexDirection={'column'} gap={'8px'}>
                            {value.map((v, i) => (
                                <Grid key={i} container flexWrap={'nowrap'} gap={'8px'}>
                                    <Input
                                        error={!!error}
                                        value={v}
                                        placeholder={field.placeholder}
                                        onChange={(e) => {
                                            if (e.target.value.length > 0) {
                                                value[i] = +e.target.value;
                                            } else {
                                                value[i] = null;
                                            }
                                            setValue?.(field.id, [...value]);
                                        }}
                                        type={'number'}
                                    />
                                    {i !== 0 && (
                                        <IconButton
                                            onClick={() => {
                                                //@ts-ignore
                                                value.splice(i, 1);
                                                setValue?.(field.id, [...value]);
                                            }}
                                            size={'small'}
                                        >
                                            <CancelRounded fontSize={'inherit'} />
                                        </IconButton>
                                    )}
                                </Grid>
                            ))}
                        </Grid>
                    ) : (
                        <Input
                            error={!!error}
                            value={value}
                            placeholder={field.placeholder}
                            onChange={(e) => {
                                if (e.target.value.length > 0) {
                                    value = +e.target.value;
                                } else {
                                    value = null;
                                }
                                setValue?.(field.id, value);
                            }}
                            type={'number'}
                        />
                    )}
                    {isMany && Array.isArray(value) && (
                        <Grid mt={'8px'}>
                            <Button
                                startIcon={<AddCircleRounded />}
                                onClick={() => {
                                    setValue?.(field.id, [...value, null]);
                                }}
                            >
                                {t('add')}
                            </Button>
                        </Grid>
                    )}
                </>
            ) : (
                <>
                    {isMany && Array.isArray(value) ? (
                        <Grid container flexDirection={'column'} gap={'8px'}>
                            {value.map((v, i) => (
                                <DefaultField
                                    key={i}
                                    field={field}
                                    value={v?.toString() || ''}
                                    error={error}
                                />
                            ))}
                        </Grid>
                    ) : (
                        <DefaultField field={field} value={value?.toString() || ''} error={error} />
                    )}
                </>
            )}
        </>
    );
};

export default NumberField;
