import { MaskField } from 'react-mask-field';

// Component with MaskField
export default function InputMask({ inputRef, ...otherProps }: any) {
    return (
        <MaskField
            style={{ height: '5px' }}
            ref={inputRef}
            showMask={true}
            mask="+7 (___) ___-__-__"
            replacement={{ _: /\d/ }}
            {...otherProps}
        />
    );
}
