import { useChatToggle } from '../../shared/context/ChatContext';
import { ChatBarButton } from './ui/ChatBarButton/ChatBarButton';
import { ChatBarDialog } from './ui/ChatBarDialog/ChatBarDialog';
const ChatBar = () => {
    const { open, setOpen } = useChatToggle();
    const onOpen = () => setOpen((prev) => !prev);
    const onClose = () => setOpen(false);
    return (
        <>
            <ChatBarButton open={open} onOpen={onOpen} />
            <ChatBarDialog open={open} onClose={onClose} />
        </>
    );
};

export default ChatBar;
