import Api from '../../../../shared/api/axios';
import {
    IGetNotificationsReceiversParams,
    IGetNotificationsReceiverParams,
    INotificationService,
    TNotificationsResponse,
    INotification,
    IUpdateNotificationsReceiverParams,
    IUpdateNotificationsReceiverResponse,
    IBulkUpdateNotificationsReceiverParams,
} from '../../types/notifications';

const notificationService = {
    getNotificationsReceivers({ receiver, ...params }: IGetNotificationsReceiversParams) {
        return Api.get<null, TNotificationsResponse>(`/api/v1/notifications/receiver/${receiver}`, {
            params,
        });
    },
    getNotificationsReceiver({ id, receiver }: IGetNotificationsReceiverParams) {
        return Api.get<null, INotification>(`/api/v1/notifications/receiver/${receiver}/${id}`);
    },
    updateNotificationReceiver({ id, receiver, is_read }: IUpdateNotificationsReceiverParams) {
        return Api.patch<null, IUpdateNotificationsReceiverResponse>(
            `/api/v1/notifications/receiver/${receiver}/${id}`,
            {
                is_read,
            }
        );
    },
    deleteNotificationReceiver({ id, receiver }: IGetNotificationsReceiverParams) {
        return Api.delete<null, null>(`/api/v1/notifications/receiver/${receiver}/${id}`);
    },
    bulkUpdateNotificationReceiver({ receiver, is_read }: IBulkUpdateNotificationsReceiverParams) {
        return Api.post<null, IUpdateNotificationsReceiverResponse>(
            `/api/v1/notifications/receiver/${receiver}/bulk-update`,
            { is_read }
        );
    },
    getNotificationsReceiversAllRead(receiver: string) {
        return Api.get<null, null>(`/api/v1/notifications/receiver/${receiver}/all/read`);
    },
};

export function notificationApiService(): INotificationService {
    return {
        getNotificationsReceivers: notificationService.getNotificationsReceivers,
        getNotificationsReceiver: notificationService.getNotificationsReceiver,
        updateNotificationReceiver: notificationService.updateNotificationReceiver,
        deleteNotificationReceiver: notificationService.deleteNotificationReceiver,
        bulkUpdateNotificationReceiver: notificationService.bulkUpdateNotificationReceiver,
        getNotificationsReceiversAllRead: notificationService.getNotificationsReceiversAllRead,
    };
}
