import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';
import Input, { InputProps } from '../Input/Input';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { ReactNode, useState } from 'react';

type SearchProps = InputProps & {
    endAdornment?: ReactNode;
    onClear?: () => void;
};

const Search = ({ endAdornment, onClear = () => {}, ...props }: SearchProps) => {
    const [focus, setFocus] = useState(false);
    return (
        <Input
            type={'text'}
            fullWidth
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
                endAdornment: (
                    <>
                        {props.value && (
                            <CloseRoundedIcon onClick={onClear} sx={{ cursor: 'pointer' }} color={'action'} />
                        )}
                        {endAdornment}
                    </>
                ),
            }}
            placeholder={props?.placeholder || 'Искать'}
            onFocus={() => setFocus(!focus)}
            onBlur={() => setFocus(!focus)}
            {...props}
        />
    );
};

export default Search;
