import { FC, memo } from 'react';
import { Box } from '@mui/material';
import { Button } from '../../../../shared/ui';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useTranslation } from 'react-i18next';

interface ProfileDrawerCloseProps {
    handleClose: () => void;
}
export const ProfileDrawerClose: FC<ProfileDrawerCloseProps> = memo(({ handleClose }) => {
    const { t } = useTranslation();
    return (
        <Box
            sx={{
                position: 'fixed',
            }}
        >
            <Button
                startIcon={<CloseRoundedIcon />}
                variant={'contained'}
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: '-140px',
                    borderBottomRightRadius: 0,
                    borderTopRightRadius: 0,
                }}
            >
                {t('close')}
            </Button>
        </Box>
    );
});
