import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import JSZipUtils from 'jszip-utils';

function convertDataURIToBinary(dataURI) {
    const BASE64_MARKER = ';base64,';
    const base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    const base64 = dataURI.substring(base64Index);
    const raw = window.atob(base64);
    const rawLength = raw.length;
    const array = new Uint8Array(new ArrayBuffer(rawLength));

    for (let i = 0; i < rawLength; i++) {
        array[i] = raw.charCodeAt(i);
    }
    return array;
}

export function urlToPromise(url, isImage) {
    return new Promise(function (resolve, reject) {
        if (!isImage) {
            JSZipUtils.getBinaryContent(url, function (err, data) {
                if (err) {
                    reject(err);
                } else {
                    resolve(data);
                }
            });
        } else {
            fetch(url, { method: 'GET' })
                .then((response) => response.blob())
                .then((blob) => {
                    const r = new FileReader();
                    r.onload = function (e) {
                        const base64Img = e.target.result;
                        const binaryImg = convertDataURIToBinary(base64Img);
                        resolve(binaryImg);
                    };
                    r.readAsDataURL(blob);
                });
        }
    });
}

export function downloadAsZip(urls) {
    const zip = new JSZip();
    urls.forEach(({ fileName, url, isImage }) => {
        zip.file(fileName, urlToPromise(url, isImage), { binary: true });
    });
    zip.generateAsync({ type: 'blob' }, function updateCallback() {}).then(
        function callback(blob) {
            // see FileSaver.js
            saveAs(blob, 'files.zip');
        },
        function (e) {
            console.warn(e);
        }
    );

    return false;
}
