import React from 'react';
import { Chip } from '@mui/material';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import dayjs from 'dayjs';

const PeriodOfExecution = ({ value }: { value: string }) => {
    return (
        <div>
            <Chip
                variant={'outlined'}
                icon={<NotificationsRoundedIcon />}
                color="error"
                label={dayjs(value).format('DD MMMM, HH:mm')}
            />
        </div>
    );
};

export default PeriodOfExecution;
