import { TFilter } from 'domain/filter';
import Api from 'shared/api/axios';
import { TParamsRequest, TResponse } from './types';

export const organizationService = {
    getDepartmentEmployees(params?: any) {
        return Api.get<null, TResponse>(`/api/v1/organizations/department-employees`, {
            params,
        });
    },
    updateDepartmentEmployee(employeeId: string, data: any) {
        return Api.patch(`/api/v1/users/${employeeId}`, data);
    },
    getDepartmentEmployee(id: string) {
        return Api.get<null, any>(`/api/v1/organizations/department-employees/${id}`);
    },
    setRole({ id, roles }: { id: string; roles: string[] | string }) {
        return Api.post<null, string[]>(`/api/v1/organizations/department-employees/${id}/set-role`, {
            role: roles,
        });
    },
    setPermissions(data: any) {
        return Api.post<null, any>(
            `/api/v1/organizations/department-employees/${data.id}/set-permissions`,
            data
        );
    },
    unsetRole({ id, roles, all }: { id: string; roles: string[]; all?: boolean }) {
        return Api.post<null, { roles: string[]; all: boolean }>(
            `/api/v1/organizations/department-employees/${id}/unset-role`,
            {
                roles,
                all,
            }
        );
    },
    getFiltersUsers(params: TParamsRequest) {
        return Api.get<null, any>(`/api/v1/organizations/filter-users`, { params: { ...params } });
    },
    getFilterUsers(id: string) {
        return Api.get<null, TFilter>(`/api/v1/organizations/filter-users/${id}`);
    },
    createFilterUsers(params: TFilter) {
        return Api.post<null, TFilter>(`/api/v1/organizations/filter-users`, params);
    },
    updateFilterUsers(params: TFilter) {
        return Api.put<null, TFilter>(`/api/v1/organizations/filter-users/${params.id}`, params);
    },
    deleteFilterUsers(id: string) {
        return Api.delete<null, TFilter>(`/api/v1/organizations/filter-users/${id}`);
    },
};
