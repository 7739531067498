import { createEvent, createStore } from 'effector';

export type Notification = {
    id: string;
    message: string;
    duration: number;
    closeHandler?: () => void;
    action?: {
        handler: (id: string) => void;
        text: string;
    };
};

const notify = createEvent<Notification>();
const removeNotification = createEvent<string>();

const notificationsStore = createStore<Notification[]>([])
    .on(notify, (state, payload) => {
        setTimeout(() => removeNotification(payload.id), payload.duration);
        return [...state, payload];
    })
    .on(removeNotification, (state, payload) => state.filter((n) => n.id !== payload));

export const notificationsStores = {
    notificationsStore,
};

export const notificationsEvents = {
    notify,
    removeNotification,
};
