import { createEffect, createEvent, createStore } from 'effector';
import { ITimeline, ITimelineApiService, TTimelineResponse } from '../types/timeline';
import { timelineApiService } from '../api/services/timeline.service';
import { TParamsRequest } from 'shared/api/types';

const initialTimelineStore: TTimelineResponse = {
    next: '',
    previous: '',
    count: 0,
    results: [],
    params: {
        offset: 0,
        limit: 10,
    },
};

const api: ITimelineApiService = timelineApiService();

const createCommentFx = createEffect(
    async ({
        entity_uuid,
        comment,
        type,
    }: {
        entity_uuid: string;
        comment: { comment: string; files: string[] };
        type?: string;
    }): Promise<ITimeline> => {
        if (type === 'task') {
            return await api.createCommentTask(entity_uuid, comment);
        }
        return await api.createComment(entity_uuid, comment);
    }
);

const updateCommentFx = createEffect(
    async ({
        entity_uuid,
        comment,
        comment_id,
        type,
    }: {
        entity_uuid: string;
        comment: { comment: string; files: string[] };
        comment_id: string;
        type?: string;
    }): Promise<ITimeline> => {
        if (type === 'task') {
            return await api.updateCommentTask(entity_uuid, comment, comment_id);
        }
        return await api.updateComment(entity_uuid, comment, comment_id);
    }
);

const deleteCommentFx = createEffect(
    async ({
        entity_uuid,
        comment_id,
        type,
    }: {
        entity_uuid: string;
        comment_id: string;
        type?: string;
    }): Promise<ITimeline> => {
        if (type === 'task') {
            return await api.deleteCommentTask(entity_uuid, comment_id);
        }
        return await api.deleteComment(entity_uuid, comment_id);
    }
);

const getTimelinesFx = createEffect(
    async ({
        entity_uuid,
        params,
        type,
    }: {
        entity_uuid: string;
        params: TParamsRequest;
        type?: string;
    }): Promise<TTimelineResponse> => {
        if (type === 'task') {
            return await api.getTimelinesTask(entity_uuid, params);
        }
        return await api.getTimelines(entity_uuid, params);
    }
);

const getTimelinesUpdaterFx = createEffect(
    async ({
        entity_uuid,
        params,
        type,
    }: {
        entity_uuid: string;
        params: TParamsRequest;
        type?: string;
    }): Promise<TTimelineResponse> => {
        if (type === 'task') {
            return await api.getTimelinesTask(entity_uuid, params);
        }
        return await api.getTimelines(entity_uuid, params);
    }
);

const clearTimeline = createEvent();
const updateTimeline = createEvent<TTimelineResponse>();

const $timelineStore = createStore(initialTimelineStore)
    .on(updateTimeline, (_, payload) => payload)
    .on(getTimelinesFx.doneData, (_, payload) => payload)
    .on(getTimelinesUpdaterFx.doneData, (_, payload) => payload)
    .reset(clearTimeline);

export const timelineStores = {
    $timelineStore,
};
export const timelineEvents = {
    clearTimeline,
    updateTimeline,
};

export const timelineEffects = {
    getTimelinesUpdaterFx,
    createCommentFx,
    updateCommentFx,
    deleteCommentFx,
    getTimelinesFx,
};
