import React from 'react';
import { Box, Drawer, DrawerProps } from '@mui/material';
import { Button } from 'shared/ui/index';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useTranslation } from 'react-i18next';

type DefaultDrawerProps = {
    onClose: () => void;
} & DrawerProps;

const DefaultDrawer = ({ onClose, children, ...props }: DefaultDrawerProps) => {
    const { t } = useTranslation();
    return (
        <Drawer sx={{ position: 'relative' }} anchor={'right'} {...props}>
            <Box
                sx={{
                    position: 'fixed',
                }}
            >
                <Button
                    startIcon={<CloseRoundedIcon />}
                    variant={'contained'}
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        top: '24px',
                        left: '-116px',
                        borderBottomRightRadius: 0,
                        borderTopRightRadius: 0,
                    }}
                >
                    {t('close')}
                </Button>
            </Box>
            {children}
        </Drawer>
    );
};

export default DefaultDrawer;
