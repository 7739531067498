import { Box, Divider, Grid, IconButton, Skeleton, styled, SwipeableDrawer, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { ReactNode } from 'react';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const drawerBleeding = 56;

const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
}));

interface IActionListProps {
    onOpen: () => void;
    onClose: () => void;
    onStepBack?: () => void;
    window?: () => Window;
    title?: string;
    open: boolean;
    children: ReactNode;
    maxDrawerHeightPercent?: string;
}

export const ActionList = ({
    onOpen,
    onClose,
    onStepBack,
    window,
    title,
    open,
    children,
    maxDrawerHeightPercent = '50%',
}: IActionListProps) => {
    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <>
            <SwipeableDrawer
                PaperProps={{
                    sx: {
                        height: `calc(${maxDrawerHeightPercent} - ${drawerBleeding}px)`,
                        overflow: 'visible',
                    },
                }}
                container={container}
                anchor="bottom"
                open={open}
                onClose={onClose}
                onOpen={onOpen}
                swipeAreaWidth={drawerBleeding}
                disableSwipeToOpen={false}
                ModalProps={{
                    keepMounted: true,
                }}
            >
                <StyledBox
                    sx={{
                        position: 'absolute',
                        top: -5,
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                        visibility: 'visible',
                        right: 0,
                        left: 0,
                        p: 2,
                    }}
                >
                    <Puller />
                </StyledBox>
                <StyledBox
                    sx={{
                        px: 2,
                        py: 2,
                        height: '100%',
                        overflow: 'auto',
                    }}
                >
                    {title && (
                        <>
                            <Grid
                                container
                                alignContent={'center'}
                                justifyContent={'space-between'}
                                gap={1}
                                my={1.5}
                            >
                                <Grid
                                    item
                                    display={'flex'}
                                    alignContent={'center'}
                                    gap={1}
                                    onClick={onStepBack}
                                >
                                    <ArrowBackIosNewRoundedIcon fontSize="medium" />
                                    <Typography variant="body1" fontWeight={500}>
                                        {title}
                                    </Typography>
                                </Grid>
                                <IconButton
                                    size={'small'}
                                    color="default"
                                    onClick={onClose}
                                    sx={{ mt: -0.5 }}
                                >
                                    <CloseRoundedIcon color="action" />
                                </IconButton>
                            </Grid>
                            <Divider />
                        </>
                    )}
                    {children || <Skeleton variant="rectangular" height="100%" />}
                </StyledBox>
            </SwipeableDrawer>
        </>
    );
};
