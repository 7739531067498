import { TMessageItem } from 'domain/chat';
import { wsApi } from 'shared/api/axios';
import { TChatConnect, TChatResponse, TMessagesResponse } from './types';

export const wsService = {
    connect(id: string, name: string, source?: string | null) {
        return wsApi.post<null, TChatConnect>(`/chats/api/auth/connect/`, { id, name, source });
    },
    list(user_uuid: string) {
        return wsApi.post<null, TChatResponse>(`/chats/api/channel/list/`, { user_uuid });
    },
    file(
        user_uuid: string,
        channel_uuid: string,
        replayed_message_uuid?: string | null,
        attachment_type?: string | null,
        text?: string | null,
        files?: FileList | null
    ) {
        const formData = new FormData();

        formData.append('user_uuid', user_uuid);
        formData.append('channel_uuid', channel_uuid);
        replayed_message_uuid && formData.append('replayed_message_uuid', replayed_message_uuid);
        attachment_type && formData.append('attachment_type', attachment_type);
        text && formData.append('text', text);
        if (files?.length) {
            for (let index = 0; index < files?.length; index++) {
                formData.append('files', files[index]);
            }
        }

        return wsApi.post<null, TMessageItem>(`/chats/api/message/publish/file/`, formData);
    },
    messages(user_uuid: string, channel_uuid: string) {
        return wsApi
            .post<null, TMessagesResponse>(`/chats/api/channel/messages/`, {
                user_uuid,
                channel_uuid,
            })
            .then((res: TMessagesResponse) => {
                return { ...res, items: res.items.reverse() };
            });
    },
    redirect(channel_uuid: string, crm_manager_service_id: string, crm_manager_name: string) {
        return wsApi.post<null, TChatResponse>(`/chats/api/channel/redirect/`, {
            channel_uuid,
            crm_manager_service_id,
            crm_manager_name,
        });
    },
};
