import React, { FC, memo } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { useMenuStorage } from '../../lib/hooks/useMenuStorage';
import uuid from 'react-uuid';
import { TMenuItem } from '../../../../domain/menu';
import { useTranslation } from 'react-i18next';
import { MoveMenuItem } from '../SidebarEditNavListItemMenu/MoveMenuItem';

interface SidebarEditListItemMenuProps {
    item: TMenuItem;
    open: boolean;
    anchorEl: null | HTMLElement;
    handleClose: () => void;
    handleDelete: (id: string) => void;
    handleSetMenuItems: (ids: string[]) => void;
    system: boolean;
    menuId: string;
}
export const SidebarEditListItemMenu: FC<SidebarEditListItemMenuProps> = memo(
    ({ item, anchorEl, handleClose, open, handleDelete, handleSetMenuItems, system, menuId }) => {
        const { t } = useTranslation();
        const { updateMenuEvent, menu, updateMenuItemIsEdit } = useMenuStorage();

        const handleUpdate = () => {
            handleClose();
            updateMenuEvent({
                ...menu,
                results: [
                    { id: uuid(), system: false, name: '', items: [item], isEdit: true },
                    ...menu.results,
                ],
            });
        };

        const handleChangeMenuItemName = () => {
            handleClose();
            updateMenuItemIsEdit({ menuId, id: item.id, isEdit: true });
        };
        return (
            <Menu
                id={'sidebar-item-menu'}
                open={open}
                MenuListProps={{
                    'aria-labelledby': 'add-holiday-button',
                    sx: {
                        width: 272,
                    },
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                anchorEl={anchorEl}
                onClose={handleClose}
            >
                <MoveMenuItem open={open} item={item} handleClose={handleClose} currMenuId={menuId} />
                <MenuItem onClick={handleChangeMenuItemName}>{t('rename')}</MenuItem>
                {/*<MenuItem>Сделать стартовой страницей</MenuItem>*/}
                <MenuItem onClick={handleUpdate}>{t('createGroup')}</MenuItem>
                <MenuItem onClick={() => handleSetMenuItems([item.id])}>{t('hideItem')}</MenuItem>
                {!system ? (
                    <MenuItem sx={{ color: '#D32F2F' }} onClick={() => handleDelete(item.id)}>
                        {t('deleteItem')}
                    </MenuItem>
                ) : null}
            </Menu>
        );
    }
);
