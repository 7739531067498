import React from 'react';
import { TFieldProps } from 'widgets/DraggableField/Fields/types';
import Checkbox from '@mui/material/Checkbox';
import DefaultField from 'widgets/DraggableField/Fields/DefaultField/DefaultField';
import { useTranslation } from 'react-i18next';

const CheckboxField = ({ edit, field, setValue, value }: TFieldProps<boolean>) => {
    const { t } = useTranslation();
    return (
        <>
            {edit ? (
                <Checkbox checked={!!value} onChange={(e) => setValue?.(field.id, e.target.checked)} />
            ) : (
                <DefaultField field={field} value={value ? t('yes') : t('no')} />
            )}
        </>
    );
};

export default CheckboxField;
