import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
    TimePicker as MuiTimePicker,
    TimePickerProps as MuiTimePickerProps,
} from '@mui/x-date-pickers/TimePicker';
import ScheduleIcon from '@mui/icons-material/Schedule';
import Input from '../Input/Input';
import { Dayjs } from 'dayjs';
import 'dayjs/locale/ru';
import { Typography } from '@mui/material';

export type TimePickerProps = Omit<MuiTimePickerProps<Dayjs, Dayjs>, 'renderInput'> & {
    placeholder?: string;
    isEdit?: boolean;
    helperText?: string;
    name?: string;
    required?: boolean;
};

const TimePicker = ({
    placeholder,
    isEdit = true,
    value,
    name,
    helperText,
    required,
    ...props
}: TimePickerProps) => {
    return (
        <>
            {isEdit ? (
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'ru'}>
                    <MuiTimePicker
                        components={{
                            OpenPickerIcon: ScheduleIcon,
                        }}
                        {...props}
                        value={value}
                        renderInput={(params) => (
                            <Input
                                required={required}
                                name={name}
                                helperText={helperText}
                                placeholder={placeholder}
                                size={'small'}
                                {...params}
                                sx={{ width: '100%' }}
                            />
                        )}
                    />
                </LocalizationProvider>
            ) : (
                <Typography variant={'body1'}>{value?.format('HH:MM')}</Typography>
            )}
        </>
    );
};

export default TimePicker;
