import { useEffect } from 'react';
import NotificationSound from '../../../assets/sound/notification-sound.mp3';
import { Centrifuge } from 'centrifuge';
import config from '../../../config';
import { SnackbarCloseButton } from '../../ChatBar/ui/SnackbarCloseButton/SnackbarCloseButton';
import { useUsersStorage } from '../../../services/StorageAdapter';
import { useChatStorage } from '../../ChatBar/lib/hooks/useChatStorage';
import { useAudio } from '../../../shared/hooks/useAudio';
import { useSnackbar } from 'notistack';
import { NotificationUser } from './NotificationUser';
import { INotification } from '../../../pages/Notifications/types/notifications';
import { useNotificationStorage } from '../../../pages/Notifications';

export const NotificationSnackbar = () => {
    const { me } = useUsersStorage();
    const { chat } = useChatStorage();
    const { audioPlayer, playSound, stopSound } = useAudio();
    const { enqueueSnackbar } = useSnackbar();
    const { signalNotification } = useNotificationStorage();
    const onEnqueue = (notification: INotification) => {
        enqueueSnackbar(<NotificationUser data={notification} />, {
            autoHideDuration: 5000,
            action: (snackbarKey) => <SnackbarCloseButton snackbarKey={snackbarKey} />,
        });
    };
    useEffect(() => {
        if (me.employee && chat.connect) {
            const centrifuge = new Centrifuge(config.ws_base_url || '', {
                token: chat.connect?.access_token,
            });
            centrifuge.connect();
            const subscribe = centrifuge.newSubscription(`employee_${me.employee}`);
            subscribe
                ?.on('publication', function (ctx) {
                    signalNotification(ctx.data);
                    onEnqueue(ctx.data);
                    playSound();
                })
                .subscribe();
        }

        return () => {
            stopSound();
        };
    }, [me.employee, chat.connect]);
    return <audio ref={audioPlayer} src={NotificationSound} />;
};
