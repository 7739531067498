import React, { useContext, useState } from 'react';
import { Button, FormField } from 'shared/ui';
import {
    alpha,
    Checkbox,
    Divider,
    FormControlLabel,
    FormGroup,
    Grid,
    InputAdornment,
    Typography,
    useTheme,
} from '@mui/material';
import Chip from 'shared/ui/Chip/Chip';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { CloseRounded } from '@mui/icons-material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Input from 'shared/ui/Input/Input';
import SearchIcon from '@mui/icons-material/Search';
import BasicModal from 'shared/ui/Modal/Modal';
import { DuplicatesEnum, DuplicatesStateContext } from 'pages/Duplicates/Duplicates';
import { useTranslation } from 'react-i18next';

const SelectFieldsStep = () => {
    const { t } = useTranslation();
    const { t: duplicatesT } = useTranslation('duplicates');
    const { setState } = useContext(DuplicatesStateContext);
    const {
        palette: {
            action: { active },
        },
    } = useTheme();
    const [isFieldsModalOpen, setIsFieldsModalOpen] = useState(false);
    const [fields, setFields] = useState([
        { id: 0, label: 'Компании', checked: false },
        { id: 1, label: 'Пользователи', checked: false },
        { id: 2, label: 'Сделки', checked: false },
        { id: 3, label: 'Лиды', checked: false },
        { id: 4, label: 'Реквизиты', checked: false },
        { id: 5, label: 'Договор', checked: false },
    ]);
    const [selectedFields, setSelectedFields] = useState<{ id: number; label: string }[]>([]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFields((prev) =>
            prev.map((f) => (f.id === +event.target.name ? { ...f, checked: event.target.checked } : f))
        );
    };

    const checkSelected = () => fields.some((f) => f.checked);
    const checkAll = () => setFields((prev) => prev.map((f) => ({ ...f, checked: true })));
    const uncheckAll = () => setFields((prev) => prev.map((f) => ({ ...f, checked: false })));
    const handleAddFields = () => {
        const checkedFields = fields.filter((f) => f.checked);
        setSelectedFields((prev) => [...prev, ...checkedFields.map((f) => ({ id: f.id, label: f.label }))]);
        handleCancelAddFields();
    };
    const handleCancelAddFields = () => {
        setFields((prev) => prev.map((f) => ({ ...f, checked: false })));
        handleFieldsModalClose();
    };
    const handleFieldsModalOpen = () => setIsFieldsModalOpen(true);
    const handleFieldsModalClose = () => setIsFieldsModalOpen(false);
    const handleClearSelectedFields = () => setSelectedFields([]);
    const handleRemoveSelectedField = (id: number) =>
        setSelectedFields((prev) => prev.filter((f) => f.id !== id));

    const handleStartSearch = () => setState?.(DuplicatesEnum.searchStarted);

    return (
        <>
            <Grid container flexDirection={'column'} gap={'12px'} alignItems={'flex-start'}>
                <FormField label={'Поля для поиска дубликатов'}>
                    {selectedFields?.length === 0 ? (
                        <Typography variant={'body1'}>{duplicatesT('emptyDuplicates')}</Typography>
                    ) : (
                        <Grid container gap={'16px'}>
                            {selectedFields.map(({ id, label }) => (
                                <Chip
                                    size={'medium'}
                                    variant={'outlined'}
                                    key={id}
                                    label={label}
                                    actionIcon={<CancelRoundedIcon />}
                                    actionHandler={() => handleRemoveSelectedField(id)}
                                />
                            ))}
                        </Grid>
                    )}
                    <Grid container gap={'30px'} mt={'8px'}>
                        <Button onClick={handleFieldsModalOpen} sx={{ px: 0 }} endIcon={<AddRoundedIcon />}>
                            {t('add')}
                        </Button>
                        <Button
                            onClick={handleClearSelectedFields}
                            sx={{ px: 0 }}
                            color={'error'}
                            endIcon={<CloseRounded />}
                        >
                            {t('clear')}
                        </Button>
                    </Grid>
                </FormField>
                <Grid container gap={'13px'} flexWrap={'nowrap'}>
                    <InfoOutlinedIcon fontSize={'small'} />
                    <Typography variant={'body2'}>{duplicatesT('startScanText')}</Typography>
                </Grid>
                <Button onClick={handleStartSearch} size={'large'} variant={'contained'}>
                    {duplicatesT('startScan')}
                </Button>
            </Grid>
            <BasicModal open={isFieldsModalOpen} title={duplicatesT('searchFieldsTitle')} width={'633px'}>
                <Grid
                    container
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    borderBottom={'1px solid #0000001F'}
                    px={2}
                    py={2}
                >
                    <Input
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        placeholder={t('searchEntity')}
                    />
                </Grid>
                <Grid container flexDirection={'column'} px={'24px'}>
                    <FormGroup>
                        {fields
                            .filter((f) => !selectedFields.some((selectedField) => f.id === selectedField.id))
                            .map(({ id, label, checked }) => (
                                <FormControlLabel
                                    key={id}
                                    control={
                                        <Checkbox
                                            size={'small'}
                                            checked={checked}
                                            onChange={handleChange}
                                            name={id?.toString()}
                                        />
                                    }
                                    label={label}
                                />
                            ))}
                    </FormGroup>
                </Grid>
                <Grid container flexDirection={'column'} gap={'12px'} flexWrap={'nowrap'} px={'8px'}>
                    <Divider variant={'fullWidth'} />
                    <Grid
                        item
                        container
                        width={'auto'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                    >
                        <Grid item container width={'auto'} gap={'8px'}>
                            <Button onClick={checkAll} sx={{ color: alpha(active, 0.54) }} size={'small'}>
                                {t('chooseAll')}
                            </Button>
                            <Button onClick={uncheckAll} sx={{ color: alpha(active, 0.54) }} size={'small'}>
                                {t('removeSelection')}
                            </Button>
                        </Grid>
                        <Grid item container width={'auto'} gap={'8px'}>
                            <Button
                                onClick={handleAddFields}
                                size={'small'}
                                disabled={!checkSelected()}
                                variant={'outlined'}
                            >
                                {t('add')}
                            </Button>
                            <Button onClick={handleCancelAddFields} size={'small'} variant={'outlined'}>
                                {t('cancel')}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </BasicModal>
        </>
    );
};

export default SelectFieldsStep;
