import { Grid, Typography, useTheme } from '@mui/material';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Button from '../Button/Button';

export interface ITEmptyTable {
    children: React.ReactElement | string;
    btn_text?: string;
    func?: () => void;
}
export default function EmptyTable({ children, btn_text, func }: ITEmptyTable): JSX.Element {
    const {
        palette: {
            text: { secondary },
        },
    } = useTheme();
    return (
        <Grid container justifyContent="center" alignItems="center" height={400}>
            <Grid item gap={3} textAlign="center">
                <PlaylistRemoveIcon
                    sx={{
                        fontSize: 80,
                        p: '22px 17px',
                        bgcolor: 'rgba(88, 70, 250, 0.08)',
                        borderRadius: 2,
                        mb: 2,
                    }}
                    color="primary"
                />
                {children && (
                    <Typography variant="body1" color={secondary} mb={3}>
                        {children}
                    </Typography>
                )}
                {btn_text && (
                    <Button variant="contained" startIcon={<AddCircleIcon />} onClick={func}>
                        {btn_text}
                    </Button>
                )}
            </Grid>
        </Grid>
    );
}
