import React, { FC, useCallback } from 'react';
import {
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Typography,
} from '@mui/material';
import { useMenuStorage } from '../../lib/hooks/useMenuStorage';
// import DragIndicatorRoundedIcon from '@mui/icons-material/DragIndicatorRounded';
import { SettingsRounded } from '@mui/icons-material';
import { useMenu } from '../../../../shared/hooks/useMenu';
import { useUsersStorage } from '../../../../services/StorageAdapter';
import { useTranslation } from 'react-i18next';

interface SidebarEditHideMenuListItemProps {
    id: string;
    name: string;
    handleUnSetMenuItems: (ids: string[]) => void;
}
const SidebarEditHideMenuListItem: FC<SidebarEditHideMenuListItemProps> = ({
    id,
    name,
    handleUnSetMenuItems,
}) => {
    const { t } = useTranslation();
    const { open, anchorEl, handleClose, handleClick } = useMenu();
    return (
        <ListItem
            disablePadding
            sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            <Grid
                container
                alignItems={'center'}
                justifyContent={'space-between'}
                flexWrap={'nowrap'}
                px={1}
                py={1.5}
            >
                {/*<ListItemIcon>*/}
                {/*    <DragIndicatorRoundedIcon color={'action'} />*/}
                {/*</ListItemIcon>*/}
                <ListItemText>{name}</ListItemText>
                <ListItemIcon
                    sx={{
                        justifyContent: 'flex-end',
                    }}
                >
                    <IconButton
                        color={open ? 'primary' : 'default'}
                        sx={{
                            width: 28,
                            height: 28,
                            borderRadius: '4px',
                            backgroundColor: open ? 'rgba(88, 70, 250, 0.3)' : null,
                        }}
                        onClick={handleClick}
                    >
                        <SettingsRounded
                            sx={{
                                fontSize: '20px',
                            }}
                        />
                    </IconButton>
                </ListItemIcon>
                <Menu
                    id={'sidebar-hide-menu'}
                    open={open}
                    MenuListProps={{
                        sx: {
                            width: 272,
                        },
                    }}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                >
                    <MenuItem onClick={() => handleUnSetMenuItems([id])}>{t('returnToList')}</MenuItem>
                </Menu>
            </Grid>
        </ListItem>
    );
};

export const SidebarEditHideMenu = () => {
    const { t } = useTranslation();
    const { menuItem, unSetMenuItems, fetchMenu } = useMenuStorage();
    const { me } = useUsersStorage();

    const handleUnSetMenuItems = useCallback(
        async (menu_items: string[]) => {
            if (me.id) {
                await unSetMenuItems({ id: me.id, menu_items }).then(() => fetchMenu());
            }
        },
        [me.id]
    );

    // useEffect(() => {
    //     fetchMenuItem({ limit: 100, offset: 0 });
    // }, []);
    return (
        <>
            <Divider>
                <Typography variant={'overline'}>{t('hidden')}</Typography>
            </Divider>
            <List
                disablePadding
                sx={{
                    ['& > li']: {
                        mt: 1.5,
                        ['&:first-child']: {
                            mt: 0,
                        },
                    },
                    mb: 3,
                }}
            >
                {menuItem.results.map(({ id, name }) => (
                    <SidebarEditHideMenuListItem
                        key={id}
                        id={id}
                        name={name}
                        handleUnSetMenuItems={handleUnSetMenuItems}
                    />
                ))}
            </List>
        </>
    );
};
