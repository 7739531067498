import React from 'react';

interface UseMenuResult {
    open: boolean;
    handleClick: (e: React.MouseEvent<any>) => void;
    handleClose: () => void;
    anchorEl: HTMLElement | null;
}
export const useMenu = (): UseMenuResult => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return {
        open,
        handleClick,
        handleClose,
        anchorEl,
    };
};
