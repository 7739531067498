import { createContext, Dispatch, SetStateAction, useState } from 'react';
import { ConflictsResolvingTable, SearchResults, SearchStartedStep, SelectFieldsStep } from 'widgets';
import { alpha, Divider, Grid, IconButton, Typography, useTheme } from '@mui/material';
import { Button, Card } from 'shared/ui';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import { TransitionProps } from '@mui/material/transitions';
// import { styled } from '@mui/material/styles';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { useTranslation } from 'react-i18next';

// const Transition = React.forwardRef(function Transition(
//     props: TransitionProps & {
//         children: React.ReactElement;
//     },
//     ref: React.Ref<unknown>
// ) {
//     return <Slide direction="left" ref={ref} {...props} />;
// });

// const CustomDialog = styled(Dialog)(() => ({
//     '& .MuiPaper-elevation': {
//         padding: '20px 24px 31px',
//         borderRadius: '12px 0 0 12px',
//     },
// }));

export const DuplicatesEnum = {
    searchNotStarted: 0,
    searchStarted: 1,
    duplicatesNotFound: 2,
    duplicatesFound: 3,
    mergeStarted: 4,
    conflictsFound: 5,
    conflictsResolving: 6,
} as const;

export type DuplicatesSearchStateType = typeof DuplicatesEnum[keyof typeof DuplicatesEnum];

type DuplicatesStateContextType = {
    state?: DuplicatesSearchStateType;
    setState?: Dispatch<SetStateAction<DuplicatesSearchStateType>>;
};

export const DuplicatesStateContext = createContext<DuplicatesStateContextType>({});

const Duplicates = () => {
    const { t } = useTranslation();
    const { t: duplicatesT } = useTranslation('duplicates');
    // const [open, setOpen] = useState(true);
    const {
        palette: {
            action: { active },
        },
    } = useTheme();

    const [duplicateSearchState, setDuplicateSearchState] = useState<DuplicatesSearchStateType>(
        DuplicatesEnum.conflictsResolving
    );

    return (
        <DuplicatesStateContext.Provider
            value={{ state: duplicateSearchState, setState: setDuplicateSearchState }}
        >
            <>
                <Grid mb={'16px'} container flexDirection={'column'} gap={'20px'}>
                    <Grid container gap={'7px'} alignItems={'center'}>
                        <Typography variant={'h5'}>{duplicatesT('entityTitle')}</Typography>
                    </Grid>
                    <Divider />
                </Grid>
                <Grid>
                    {duplicateSearchState !== DuplicatesEnum.conflictsResolving ? (
                        <Card>
                            {duplicateSearchState === DuplicatesEnum.searchNotStarted ? (
                                <SelectFieldsStep />
                            ) : duplicateSearchState === DuplicatesEnum.searchStarted ? (
                                <SearchStartedStep />
                            ) : (
                                <SearchResults />
                            )}
                        </Card>
                    ) : (
                        <Grid container flexDirection={'column'} alignItems={'flex-start'} gap={'24px'}>
                            <Button sx={{ color: alpha(active, 0.54) }} endIcon={<ArrowDropDownIcon />}>
                                {duplicatesT('viewAllFields')}
                            </Button>
                            <ConflictsResolvingTable />
                            <Card sx={{ width: '100%' }}>
                                <Grid
                                    container
                                    justifyContent={'space-between'}
                                    flexWrap={'nowrap'}
                                    width={'100%'}
                                >
                                    <Grid container width={'auto'} gap={'12px'} flexWrap={'nowrap'}>
                                        <Button size={'small'} variant={'contained'}>
                                            {t('unite')}
                                        </Button>
                                        <Button size={'small'} variant={'outlined'}>
                                            {t('postpone')}
                                        </Button>
                                    </Grid>
                                    <Grid container width={'auto'} gap={'40px'} flexWrap={'nowrap'}>
                                        <Grid
                                            container
                                            gap={'17px'}
                                            alignItems={'center'}
                                            width={'auto'}
                                            flexWrap={'nowrap'}
                                        >
                                            <IconButton size={'small'}>
                                                <ChevronLeftRoundedIcon
                                                    sx={{ color: alpha(active, 0.6) }}
                                                    fontSize={'inherit'}
                                                />
                                            </IconButton>
                                            <Typography variant={'body1'} whiteSpace={'nowrap'}>
                                                {duplicatesT('caseCount')}
                                            </Typography>
                                            <IconButton size={'small'}>
                                                <ChevronRightRoundedIcon
                                                    sx={{ color: alpha(active, 0.6) }}
                                                    fontSize={'inherit'}
                                                />
                                            </IconButton>
                                        </Grid>
                                        <Button> {duplicatesT('matchList')}</Button>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    )}
                </Grid>
                {/*<CustomDialog*/}
                {/*    fullScreen*/}
                {/*    sx={{ maxWidth: '612px', left: 'auto' }}*/}
                {/*    open={open}*/}
                {/*    TransitionComponent={Transition}*/}
                {/*>*/}
                {/*    <DuplicatesTable />*/}
                {/*</CustomDialog>*/}
            </>
        </DuplicatesStateContext.Provider>
    );
};

export default Duplicates;
