import React, { FC, useEffect, useState } from 'react';
import { Button, Modal } from 'shared/ui';
import {
    AccordionSummaryProps,
    Box,
    Checkbox,
    Divider,
    FormControlLabel,
    Grid,
    useMediaQuery,
} from '@mui/material';
import { MOBILE_QUERY_WIDTH } from 'shared/consts';
import { useFunnelsListStorage } from 'services/StorageAdapter';
import { styled } from '@mui/material/styles';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import { useTranslation } from 'react-i18next';

interface RequiredStagesModalProps {
    open: boolean;
    handleClose: () => void;
    entity: string;
    handleSubmit: (selected: any) => void;
    currentSelected: Array<any>;
}

const RequiredStagesModal: FC<RequiredStagesModalProps> = ({
    open,
    handleClose,
    entity,
    handleSubmit,
    currentSelected,
}) => {
    const { t } = useTranslation();
    const mobileQuery = useMediaQuery(MOBILE_QUERY_WIDTH);
    const { funnelsList, fetchFunnelsList } = useFunnelsListStorage();
    const [selected, setSelected] = useState<Array<any>>([]);

    useEffect(() => {
        setSelected(currentSelected);
    }, [currentSelected]);

    useEffect(() => {
        if (entity) {
            fetchFunnelsList({
                entity,
                limit: 100,
            });
        }
    }, [entity]);

    const allChecked = (id: string) => {
        const funnel = funnelsList.results.find((f) => f.id === id);
        const stages = selected.filter((stage) => stage.funnel === id);
        return (
            funnel?.stagesDefault.length === stages.length ||
            !funnel?.stagesDefault.some((stage: any) => !selected.find((s) => s.id === stage.id))
        );
    };

    const someChecked = (id: string) => {
        const funnel = funnelsList.results.find((f) => f.id === id);
        const stages = selected.filter((stage) => stage.funnel === id);
        return (
            stages.length > 0 ||
            funnel?.stagesDefault.some((stage: any) => selected.find((s) => s.id === stage.id))
        );
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            width={'633px'}
            title={t('bind')}
            style={
                mobileQuery
                    ? {
                          top: 'unset',
                          bottom: 0,
                          transform: 'translateX(-50%)',
                      }
                    : {}
            }
        >
            <Box sx={{ overflowY: 'auto' }} px={'24px'} height={'424px'}>
                {funnelsList.results.map(({ id, stagesDefault, name }) => {
                    return (
                        <Accordion key={id} defaultExpanded>
                            <Grid container flexWrap={'nowrap'}>
                                <Checkbox
                                    checked={allChecked(id)}
                                    indeterminate={someChecked(id) && !allChecked(id)}
                                    onChange={(_, checked) => {
                                        if (checked) {
                                            setSelected((prev) => {
                                                return [
                                                    ...prev.filter(
                                                        (s) =>
                                                            !stagesDefault.find(
                                                                (stage: any) => s.id === stage.id
                                                            )
                                                    ),
                                                    ...stagesDefault,
                                                ];
                                            });
                                        } else {
                                            setSelected((prev) =>
                                                prev.filter(
                                                    (s) =>
                                                        !stagesDefault.find((stage: any) => s.id === stage.id)
                                                )
                                            );
                                        }
                                    }}
                                />
                                <AccordionSummary>{name}</AccordionSummary>
                            </Grid>
                            <AccordionDetails>
                                <Grid container flexDirection={'column'}>
                                    {stagesDefault.map((stage: any) => {
                                        const { id: stageId, name } = stage;
                                        return (
                                            <FormControlLabel
                                                key={stageId}
                                                onChange={(_, checked) =>
                                                    setSelected((prev) => [
                                                        ...(checked
                                                            ? [...prev, stage]
                                                            : prev.filter((s) => s.id !== stageId)),
                                                    ])
                                                }
                                                control={
                                                    <Checkbox
                                                        checked={selected.some(
                                                            (stage: any) => stage.id === stageId
                                                        )}
                                                    />
                                                }
                                                label={name}
                                            />
                                        );
                                    })}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </Box>
            <Divider />
            <Grid container justifyContent={'space-between'} px={'8px'} pt={'16px'}>
                <Grid container width={'auto'} gap={'8px'}>
                    <Button
                        onClick={() => {
                            setSelected(funnelsList.results.map((f) => f.stagesDefault).flat());
                        }}
                    >
                        {t('selectAll')}
                    </Button>
                    <Button
                        onClick={() => {
                            setSelected([]);
                        }}
                    >
                        {t('removeSelection')}
                    </Button>
                </Grid>
                <Grid container width={'auto'} gap={'8px'}>
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={() => {
                            handleSubmit(selected);
                            handleClose();
                        }}
                    >
                        {t('add')}
                    </Button>
                    <Button
                        onClick={() => {
                            handleClose();
                            setSelected([]);
                        }}
                        variant={'outlined'}
                    >
                        {t('cancel')}
                    </Button>
                </Grid>
            </Grid>
        </Modal>
    );
};

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
    backgroundColor: 'transparent',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
    padding: '0 32px',
}));

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
    border: `none`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

export default RequiredStagesModal;
