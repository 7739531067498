import React from 'react';
import { AvatarProps as MuiAvatarProps, Avatar as MuiAvatar } from '@mui/material';

type AvatarSize = 'small' | 'medium' | 'large';

type AvatarProps = {
    size: AvatarSize | string;
    circle?: boolean;
    src?: string;
} & Pick<MuiAvatarProps, 'variant' | 'children' | 'style'>;

const AvatarSize = {
    small: 24,
    medium: 36,
    large: 48,
} as const;

const Avatar = ({ size, circle, children, ...props }: AvatarProps) => {
    return (
        <MuiAvatar
            {...props}
            sx={{
                width: AvatarSize[size as keyof typeof AvatarSize] || size + 'px',
                height: AvatarSize[size as keyof typeof AvatarSize] || size + 'px',
                borderRadius: circle ? '' : '4px',
                background: 'rgba(0, 0, 0, 0.25)',
                fontSize: '12px',
            }}
        >
            {children}
        </MuiAvatar>
    );
};

export default Avatar;
