import React from 'react';
import { Alert as MuiAlert, AlertColor, AlertProps as MuiAlertProps, AlertTitle } from '@mui/material';

type AlertProps = MuiAlertProps & {
    title?: string;
    type?: AlertColor;
};

const Alert = ({ title, children, type = 'success', ...props }: AlertProps) => {
    return (
        <MuiAlert severity={type} {...props}>
            {title && <AlertTitle>{title}</AlertTitle>}
            {children}
        </MuiAlert>
    );
};

export default Alert;
