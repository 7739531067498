import { List } from '@mui/material';
import { FC, memo, useCallback } from 'react';
import { TMenuItem } from '../../../../domain/menu';
import { SidebarEditListItem } from '../SidebarEditListItem/SidebarEditListItem';
import { DragDropContext, Droppable, OnDragEndResponder } from '@hello-pangea/dnd';
import { useMenuStorage } from '../../lib/hooks/useMenuStorage';
import { useUsersStorage } from '../../../../services/StorageAdapter';
import { reorder } from '../../../../shared/helpers/reorder';
import { getItemsUri } from '../../lib/const/getItemsUri';

interface SidebarEditListProps {
    menuId: string;
    items: TMenuItem[];
    system: boolean;
}

export const SidebarEditList: FC<SidebarEditListProps> = memo(({ menuId, items, system }) => {
    const {
        deleteMenuItem,
        fetchMenu,
        setMenuItems,
        fetchMenuItem,
        updateMenuItemEvent,
        bulkUpdateMenu,
        menu,
    } = useMenuStorage();
    const { me } = useUsersStorage();
    const onDragEnd: OnDragEndResponder = async (result) => {
        if (!result.destination) return;
        const updatedItems = reorder(items, result.source.index, result.destination.index);
        const orderedItems = updatedItems.map((i, idx) => ({
            id: i.id,
            name: i.name,
            menu: i.menu,
            entity: i.entity,
            uri: i.uri,
            order: idx,
        }));
        const newMenuItems = menu.results.map((m) => {
            if (m.id === menuId) {
                return {
                    ...m,
                    items: getItemsUri(orderedItems),
                };
            }
            return m;
        });
        updateMenuItemEvent({ items: orderedItems, menuId });
        await bulkUpdateMenu({ menu_list: newMenuItems as any });
    };

    const handleSetMenuItems = useCallback(
        async (menu_items: string[]) => {
            if (me.id) {
                await setMenuItems({ id: me.id, menuId, menu_items }).then(() => {
                    fetchMenuItem({ limit: 100, offset: 0 });
                });
            }
        },
        [me.id]
    );
    const handleDelete = async (id: string) => {
        await deleteMenuItem({ id }).then(() => fetchMenu());
    };
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="menuItems">
                {(provided) => (
                    <List
                        disablePadding
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        sx={{ width: '100%' }}
                    >
                        {items.map((menuItem, index) => (
                            <SidebarEditListItem
                                menuId={menuId}
                                key={menuItem.id}
                                index={index}
                                item={menuItem}
                                handleDelete={handleDelete}
                                handleSetMenuItems={handleSetMenuItems}
                                system={system}
                            />
                        ))}
                        {provided.placeholder}
                    </List>
                )}
            </Droppable>
        </DragDropContext>
    );
});
