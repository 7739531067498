// import { useChatData } from '../../../../widgets/ChatBar/lib/hooks/useChatData';
import { NotificationSnackbar } from '../../../../widgets/NotificationSnackbar';
import { Root } from '../../../../shared/routes';

export const AppContainer = () => {
    // useChatData();
    return (
        <>
            <NotificationSnackbar />
            <Root />
        </>
    );
};
