import { IBulkUpdateMenuItem, IMenuApiService, IMenuItem } from '../../types/menuSchema';
import Api from '../../../../shared/api/axios';
import { TMenuItemResponse, TMenuResponse, TParamsRequest } from '../../../../shared/api/types';
import { TMenu, TMenuItem } from '../../../../domain/menu';

const menuService = {
    fetchMenu() {
        return Api.get<null, TMenuResponse>(`/api/v1/menu/menu`);
    },
    createMenu(data: { name: string; order: number }) {
        return Api.post<null, TMenu>(`/api/v1/menu/menu`, data);
    },
    fetchMenuDetail({ id }: { id: string }) {
        return Api.get<null, TMenuItem>(`/api/v1/menu/menu/${id}`);
    },
    updateMenu({ id, ...data }: { id: string; name: string }) {
        return Api.patch<null, { name: string }>(`/api/v1/menu/menu/${id}`, data);
    },
    deleteMenu({ id }: { id: string }) {
        return Api.delete<null, null>(`/api/v1/menu/menu/${id}`);
    },
    fetchMenuItem(params: TParamsRequest) {
        return Api.get<null, TMenuItemResponse>(`/api/v1/menu/item`, params);
    },
    createMenuItem(data: IMenuItem) {
        return Api.post<null, IMenuItem>(`/api/v1/menu/item`, data);
    },
    fetchMenuItemDetail({ id }: { id: string }) {
        return Api.get<null, IMenuItem>(`/api/v1/menu/item/${id}`);
    },
    updateMenuItem({
        id,
        ...data
    }: {
        id: string;
        name?: string;
        uri?: string;
        entity?: string;
        menu?: string;
    }) {
        return Api.patch<null, IMenuItem>(`/api/v1/menu/item/${id}`, data);
    },
    deleteMenuItem({ id }: { id: string }) {
        return Api.delete<null, null>(`/api/v1/menu/item/${id}`);
    },
    setMenuItems({ id, menu_items }: { id: string; menu_items: string[] }) {
        return Api.post<null, null>(`/api/v1/users/${id}/set-menu-items`, { menu_items });
    },
    unSetMenuItems({ id, menu_items }: { id: string; menu_items: string[] }) {
        return Api.post<null, null>(`/api/v1/users/${id}/unset-menu-items`, { menu_items });
    },
    bulkUpdateMenu(data: IBulkUpdateMenuItem) {
        return Api.patch<null, IBulkUpdateMenuItem>(`/api/v1/menu/menu/bulk-update`, data);
    },
};

export function menuApiService(): IMenuApiService {
    return {
        fetchMenu: menuService.fetchMenu,
        createMenu: menuService.createMenu,
        fetchMenuDetail: menuService.fetchMenuDetail,
        updateMenu: menuService.updateMenu,
        deleteMenu: menuService.deleteMenu,
        fetchMenuItem: menuService.fetchMenuItem,
        createMenuItem: menuService.createMenuItem,
        fetchMenuItemDetail: menuService.fetchMenuItemDetail,
        updateMenuItem: menuService.updateMenuItem,
        deleteMenuItem: menuService.deleteMenuItem,
        setMenuItems: menuService.setMenuItems,
        unSetMenuItems: menuService.unSetMenuItems,
        bulkUpdateMenu: menuService.bulkUpdateMenu,
    };
}
