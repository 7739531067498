import React, { ChangeEvent, useEffect, useState } from 'react';
import { Grid, IconButton, Typography } from '@mui/material';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import useDebounce from 'shared/hooks/useDebounce';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

type EditableTitleProps = {
    editable?: boolean;
    onChange?: (newTitle: string) => void;
    title?: string;
    hint?: string;
};

const EditableTitle = ({ editable, onChange, title, hint }: EditableTitleProps) => {
    const [isEdit, setIsEdit] = useState(false);
    const [localTitle, setLocalTitle] = useState(title);
    const [touched, setTouched] = useState(false);

    const handleEditClick = () => {
        setTouched(true);
        setIsEdit(true);
    };

    const handleInputBlur = () => {
        setIsEdit(false);
    };

    const handleChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
        setLocalTitle(e.target.value);
    };

    const debouncedTitle = useDebounce(localTitle, 500);

    useEffect(() => {
        if (title !== localTitle) {
            setLocalTitle(title);
        }
    }, [title]);

    useEffect(() => {
        if (touched && !isEdit && debouncedTitle && debouncedTitle !== title) {
            onChange?.(debouncedTitle);
        }
    }, [debouncedTitle, title, isEdit, touched]);

    return (
        <Grid item container width={'auto'} alignItems={'center'} flexWrap={'nowrap'}>
            {editable && isEdit ? (
                <input
                    autoFocus
                    onBlur={handleInputBlur}
                    onChange={handleChangeTitle}
                    value={localTitle}
                    style={{
                        background: 'transparent',
                        border: 'none',
                        outline: 'none',
                        fontWeight: '500',
                        fontSize: '1.25rem',
                        lineHeight: '1.6',
                        fontFamily: 'Inter',
                    }}
                />
            ) : (
                <Typography variant={'h6'}>{title}</Typography>
            )}
            {hint && (
                <IconButton color={'primary'} size={'small'}>
                    <HelpOutlineOutlinedIcon fontSize={'inherit'} />
                </IconButton>
            )}
            {editable && (
                <IconButton onClick={handleEditClick} size={'medium'}>
                    <EditRoundedIcon fontSize={'inherit'} />
                </IconButton>
            )}
        </Grid>
    );
};

export default EditableTitle;
