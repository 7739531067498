import React from 'react';
import { FormControlLabel, FormControlLabelProps, useMediaQuery } from '@mui/material';
import { Radio as MuiRadio, RadioProps as MuiRadioProps } from '@mui/material';
import { MOBILE_QUERY_WIDTH } from 'shared/consts';

export type RadioProps = MuiRadioProps & {
    label: string;
    sxLabel?: FormControlLabelProps['sx'];
};

const Radio = ({ label, sxLabel, ...props }: RadioProps) => {
    const mobileQuery = useMediaQuery(MOBILE_QUERY_WIDTH);
    return (
        <FormControlLabel
            sx={{
                justifyContent: mobileQuery ? 'space-between' : 'flex-start',
                ml: 0,
                ...sxLabel,
            }}
            control={<MuiRadio {...props} />}
            label={label}
            labelPlacement={mobileQuery ? 'start' : 'end'}
        />
    );
};

export default Radio;
