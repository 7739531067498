import { TData } from 'domain/data';
import Api from 'shared/api/axios';
import { TParamsRequest } from './types';

export type ViewType = 'tabular' | 'detail' | 'canban';

export const dataService = {
    async getEntityData(code: string, id: string) {
        return await Api.get(`/api/v1/core/entities/data/${code}/${id}`);
    },
    async getTaskData(id: string) {
        return await Api.get(`/api/v1/tasks/${id}`);
    },
    async updateEntitySettings(props: { code: string; id: string; values: any }) {
        const { code, id, values } = props;
        return await Api.patch(`/api/v1/core/entities/data/${code}/${id}`, { ...values });
    },
    async getDataCodeID(code: string, { signal, ...params }: TParamsRequest, p?: any) {
        if (code !== 'tasks') {
            return await Api.get<null, TData>(`/api/v1/core/entities/data/${code}`, {
                params: p ? p : { ...params },
                signal,
            }).then((res) => {
                return { ...res, params: params };
            });
        }
        return await Api.get<null, TData>(`/api/v1/tasks/`, {
            params: { ...params },
            signal,
        }).then((res) => {
            return { ...res, params: params };
        });
    },
    deleteEntity(code: string, id: string, type: string) {
        return Api.delete<null, any>(`/api/v1/core/entities/data/${code}/${id}/${type}-delete`);
    },
    restoreEntity(code: string, id: string) {
        return Api.put<null, any>(`/api/v1/core/entities/data/${code}/${id}/restore`);
    },
    removeObjectFromEntity(code: string, entity_object_ids: string[]) {
        if (code !== 'tasks') {
            return Api.post<null, any>(`/api/v1/core/entities/data/${code}/soft-delete-ids`, {
                entity_object_ids: entity_object_ids,
            });
        }
        return Api.post<null, any>(`/api/v1/tasks/bulk-delete`, {
            task_ids: entity_object_ids,
        });
    },
    bulkHardDelete({ code, entities }: { code: string; entities: string[] }) {
        return Api.patch<null, { entities: string[] }>(
            `/api/v1/core/entities/data/${code}/bulk-hart-delete`,
            { entities }
        );
    },
    bulkRestore({ code, entities }: { code: string; entities: string[] }) {
        return Api.patch<null, { entities: string[] }>(`/api/v1/core/entities/data/${code}/bulk-restore`, {
            entities,
        });
    },
};
