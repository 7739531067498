import { createContext, FC, ReactNode, useContext, useState } from 'react';
import { IChatUser } from '../../types/chat';
interface ISelectedUserProps {
    children?: ReactNode;
}
interface ISelectedUserContext {
    selectedUser: IChatUser | undefined;
    setSelectedUser: any;
}

const SelectedUserContext = createContext<ISelectedUserContext>({
    selectedUser: {
        uuid: '',
        source: '',
        service_id: '',
        name: '',
    },
    setSelectedUser: () => {},
});

export const SelectedUserProvider: FC<ISelectedUserProps> = ({ children }) => {
    const [selectedUser, setSelectedUser] = useState<IChatUser>({
        uuid: '',
        source: '',
        service_id: '',
        name: '',
        avatar: '',
    });
    return (
        <SelectedUserContext.Provider value={{ selectedUser, setSelectedUser }}>
            {children}
        </SelectedUserContext.Provider>
    );
};

export const useSelectedUser = () => {
    const { selectedUser, setSelectedUser } = useContext(SelectedUserContext);
    const onSelectedUser = (user: IChatUser | undefined) => {
        setSelectedUser(user);
    };

    return {
        selectedUser,
        onSelectedUser,
    };
};
