import React from 'react';
import ReactDOM from 'react-dom/client';
import App from 'App';
import reportWebVitals from 'reportWebVitals';
import { ErrorBoundary } from './application/provider/ErrorBoundary';
import { BrowserRouter } from 'react-router-dom';
import { StableNavigateContextProvider } from 'shared/context/StableNavigateContext';
import i18n from './shared/config/i18n/i18n';
import { I18nextProvider } from 'react-i18next';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <BrowserRouter>
        <StableNavigateContextProvider>
            <ErrorBoundary>
                <I18nextProvider i18n={i18n}>
                    <App />
                </I18nextProvider>
            </ErrorBoundary>
        </StableNavigateContextProvider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
