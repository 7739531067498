export function normalize(values: any) {
    return (Object.keys(values) as Array<keyof typeof values>).reduce((acc, key) => {
        const item = values[key];
        if (Array.isArray(item)) {
            if (item.length === 0) {
                return { ...acc, [key]: key === 'files' ? [null] : [] };
            }
            if (item[0] && item[0].id) {
                return { ...acc, [key]: item.map(({ id }) => id) };
            }
            return {
                ...acc,
                [key]: item.filter((i) => i !== null && i !== '' && i !== false && i !== undefined),
            };
        } else if (item && typeof item === 'object' && 'id' in item) {
            return { ...acc, [key]: item.id };
        }
        return { ...acc, [key]: item };
    }, {});
}
