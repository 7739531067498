import { IOrganizationApiService } from 'application/ports/Api';
import { TFilter } from 'domain/filter';
import { createEffect, createEvent, createStore } from 'effector';
import { organizationApiService } from 'services/ApiAdapter';
import { TResponse } from 'shared/api/types';

const organizationApi: IOrganizationApiService = organizationApiService();
const initialOrganizationStore: any = {
    next: '',
    previous: '',
    count: 0,
    results: [],
};

const initialDepartmentEmployeeStore: any = {
    user: {},
    department: {},
    roles: [],
    personal_permissions: [],
};

const initialOrganizationFilterStore = {
    next: '',
    previous: '',
    count: 0,
    results: [],
    params: {
        offset: 0,
        limit: 10,
    },
};

const fetchOrganizationFx = createEffect(async ({ params }: { params?: any }): Promise<any> => {
    return await organizationApi.getDepartmentEmployees(params);
});
const fetchDepartmentEmployeeFx = createEffect(async (id: string): Promise<any> => {
    return await organizationApi.getDepartmentEmployee(id);
});
const setRoleFx = createEffect(
    async ({ id, roles }: { id: string; roles: string[] | string }): Promise<string[]> => {
        return await organizationApi.setRole({ id, roles });
    }
);
const setPermissionsFx = createEffect(async (data: any): Promise<any> => {
    return await organizationApi.setPermissions(data);
});
const unsetRoleFx = createEffect(
    async ({
        id,
        roles,
        all,
    }: {
        id: string;
        roles: string[];
        all?: boolean;
    }): Promise<{ roles: string[]; all: boolean }> => {
        return await organizationApi.unsetRole({ id, roles, all });
    }
);

const getFiltersUsersFx = createEffect(async (params: any): Promise<TResponse> => {
    return await organizationApi.getFiltersUsers(params);
});
const getFilterUsersFx = createEffect(async (id: string): Promise<TFilter> => {
    return await organizationApi.getFilterUsers(id);
});
const createFilterUsersFx = createEffect(async (params: any): Promise<TFilter> => {
    return await organizationApi.createFilterUsers(params);
});
const updateFilterUsersFx = createEffect(async (params: any): Promise<TFilter> => {
    return await organizationApi.updateFilterUsers(params);
});
const deleteFilterUsersFx = createEffect(async (id: string): Promise<TFilter> => {
    return await organizationApi.deleteFilterUsers(id);
});

const updateDepartmentEmployee = createEvent<any>();
const clearDepartmentEmployee = createEvent();

const $departmentEmployeeStore = createStore<any>(initialDepartmentEmployeeStore)
    .on(updateDepartmentEmployee, (_, newOrganization) => newOrganization)
    .on(fetchDepartmentEmployeeFx.doneData, (_, newOrganization) => newOrganization)
    .on(setRoleFx.doneData, (_, payload) => payload)
    .on(unsetRoleFx.doneData, (_, payload) => payload)
    .reset(clearDepartmentEmployee);

const updateOrganization = createEvent<any>();
const clearOrganization = createEvent();

const $organizationStore = createStore<any>(initialOrganizationStore)
    .on(updateOrganization, (_, newOrganization) => newOrganization)
    .on(fetchOrganizationFx.doneData, (_, newOrganization) => newOrganization)
    .reset(clearOrganization);

const updateOrganizationFilterStore = createEvent<any>();
const clearOrganizationFilterStore = createEvent();

const $organizationFilterStore = createStore<any>(initialOrganizationFilterStore)
    .on(updateOrganizationFilterStore, (_, newOrganization) => newOrganization)
    .on(getFiltersUsersFx.doneData, (_, newOrganization) => newOrganization)
    .reset(clearOrganizationFilterStore);

export const organizationStores = {
    $departmentEmployeeStore,
    $organizationStore,
    $organizationFilterStore,
};

export const organizationEffects = {
    fetchOrganizationFx,
    fetchDepartmentEmployeeFx,
    setRoleFx,
    setPermissionsFx,
    unsetRoleFx,

    getFiltersUsersFx,
    getFilterUsersFx,
    createFilterUsersFx,
    updateFilterUsersFx,
    deleteFilterUsersFx,
};

export const organizationEvents = {
    updateOrganization,
    updateDepartmentEmployee,
    clearDepartmentEmployee,
    clearOrganization,
    updateOrganizationFilterStore,
    clearOrganizationFilterStore,
};
