import { useEffect, useState } from 'react';

export function useFocusTab() {
    const [isFocused, setIsFocused] = useState(false);

    const onFocus = () => setIsFocused(true);
    const onBlur = () => setIsFocused(false);

    useEffect(() => {
        window.addEventListener('focus', onFocus);
        window.addEventListener('blur', onBlur);
        onFocus();
        return () => {
            window.removeEventListener('focus', onFocus);
            window.removeEventListener('blur', onBlur);
        };
    }, []);

    return isFocused;
}
