import { Button, Card, Select } from 'shared/ui';
import { Avatar, Grid, IconButton, Menu, MenuItem, TextareaAutosize, Typography } from '@mui/material';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import { useMenu } from 'shared/hooks/useMenu';
import { ChangeEvent, useEffect, useState } from 'react';
import {
    useMetaStorage,
    useNotification,
    useTaskDetailStorage,
    useUsersStorage,
} from 'services/StorageAdapter';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import dayjs from 'dayjs';
import { FileType } from 'widgets/DraggableField/Fields/FileField/FileField';
import { useTimelineStorage } from 'widgets/Timeline/lib/hooks/useTimelineStorage';
import { ITimeline } from 'widgets/Timeline/types/timeline';
import { switchContent } from 'widgets/Timeline/lib/helpers/switchContent';
import { switchIcon } from 'widgets/Timeline/lib/helpers/switchIcon';
import { TimelineCommentFormLoading } from '../TimelineCommentFormLoading/TimelineCommentFormLoading';
import { TimelineCommentFormUpload } from '../TimelineCommentFormUpload/TimelineCommentFormUpload';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const TimelineCard = ({
    props,
    objectId,
    handleSelectUserId,
    isResponsible = false,
}: {
    props: ITimeline;
    objectId: string;
    handleSelectUserId?: (id: string) => void;
    isResponsible?: boolean;
}) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [params] = useSearchParams();
    const objectType = params?.get('task_id') ? 'task' : '';
    const { me } = useUsersStorage();
    const { meta } = useMetaStorage();
    const { notify } = useNotification();
    const { updateTask } = useTaskDetailStorage();
    const [mainData, setMainData] = useState<ITimeline>(props);
    const [data, setData] = useState<ITimeline>(props);
    const [edit, setEdit] = useState<boolean>(false);
    const [status, setStatus] = useState('');
    const { timeline, getTimelines, updateComment, editCommentLoading, deleteComment } = useTimelineStorage();
    const { anchorEl, open, handleClick, handleClose } = useMenu();
    useEffect(() => {
        data?.status && setStatus(data?.status);
    }, [data]);
    const handleDeleteFile = (removeId: string) => {
        setData({ ...data, files: data.files.filter(({ id }) => id !== removeId) });
    };
    const handleCloseEdit = (comment: ITimeline) => {
        setEdit(!edit);
        setData(comment);
    };
    const handleSave = () => {
        const filesIds: string[] = data?.files?.length ? data?.files.map(({ id }) => id) : [];
        updateComment({
            entity_uuid: objectId,
            comment: { ...data, files: filesIds },
            comment_id: data.id,
            type: objectType,
        })
            .then((res) => {
                handleCloseEdit(res);
                setMainData(res);
                notify({ message: `Комментарий обновлен` });
            })
            .catch((err) => {
                const error: string[] = Object.values(err.response.data);
                error.length
                    ? error.map((e: string) => notify({ message: e }))
                    : notify({ message: t('somethingWentWrong') });
            });
    };
    const handleDelete = () => {
        deleteComment({ entity_uuid: objectId, comment_id: data.id, type: objectType })
            .then(() => {
                notify({ message: `Комментарий удален` });
                getTimelines({ entity_uuid: objectId, params: { ...timeline.params }, type: objectType });
            })
            .catch((err) => {
                const error: string[] = Object.values(err.response.data);
                error.length
                    ? error.map((e: string) => notify({ message: e }))
                    : notify({ message: t('somethingWentWrong') });
            });
    };
    const dateEqual = () => {
        const today = dayjs();
        let hours = today.diff(dayjs(data?.deadline), 'hours');
        const days = Math.floor(hours / 24);
        hours = hours - days * 24;
        return days >= 0 && hours > 0;
    };
    const handleStatus = (status: string) => {
        setStatus(status);
        updateTask({ id: data.id, status });
    };
    return (
        <>
            <Grid item xs={12} width={'100%'} position="relative">
                {editCommentLoading && edit && <TimelineCommentFormLoading />}
                <Card py="0">
                    <Grid container spacing={3} pt={2} alignItems={'start'}>
                        <Grid item xs={11} display="flex" gap={1} alignItems="center">
                            <IconButton
                                sx={{ p: 0 }}
                                onClick={() => handleSelectUserId?.(data.employee.user.id)}
                            >
                                {isResponsible ? (
                                    <Avatar
                                        alt={data?.responsible?.fullname}
                                        src={data?.responsible?.fullname || '/static/images/avatar/2.jpg'}
                                        variant={'rounded'}
                                        sx={{ width: 24, height: 24 }}
                                    />
                                ) : (
                                    <Avatar
                                        alt={
                                            data?.author?.fullname ||
                                            `${data?.employee?.user?.first_name} ${data?.employee?.user?.last_name}`
                                        }
                                        src={
                                            data?.employee?.user?.avatar ||
                                            data?.author?.avatar ||
                                            '/static/images/avatar/2.jpg'
                                        }
                                        variant={'rounded'}
                                        sx={{ width: 24, height: 24 }}
                                    />
                                )}
                            </IconButton>
                            {isResponsible ? (
                                <Typography
                                    variant={'body2'}
                                    color="#2E7D32"
                                    mr={1}
                                    fontWeight={500}
                                    onClick={() => handleSelectUserId?.(data?.responsible?.id as string)}
                                >
                                    {data.responsible?.fullname}
                                </Typography>
                            ) : (
                                <Typography
                                    variant={'body2'}
                                    color="#2E7D32"
                                    mr={1}
                                    fontWeight={500}
                                    onClick={() =>
                                        handleSelectUserId?.(data?.author?.id || data.employee.user.id)
                                    }
                                >
                                    {data?.author?.fullname ||
                                        `${data?.employee?.user?.first_name} ${data?.employee?.user?.last_name}`}
                                </Typography>
                            )}
                            <Typography variant={'body1'} color="GrayText">
                                {dayjs(data?.created).format('DD.MM.YYYY')} •{' '}
                                {dayjs(data?.created).format('HH:mm')}
                            </Typography>
                        </Grid>
                        {data?.type === 'comment' && (me.is_admin || me.id === data.employee.user.id) && (
                            <Grid item xs={1} display="flex" justifyContent="end">
                                <IconButton
                                    sx={{ p: 0 }}
                                    onClick={(e: React.MouseEvent<any, MouseEvent>) =>
                                        edit ? handleCloseEdit(props) : handleClick(e)
                                    }
                                >
                                    {edit ? (
                                        <CloseRoundedIcon color="disabled" />
                                    ) : (
                                        <MoreHorizRoundedIcon color="disabled" />
                                    )}
                                </IconButton>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={!!open}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    sx={{
                                        ['& .MuiList-root']: {
                                            overflowY: 'auto',
                                        },
                                    }}
                                >
                                    {me.id === data.employee.user.id && (
                                        <MenuItem
                                            onClick={() => {
                                                setEdit(!edit);
                                                handleClose();
                                            }}
                                        >
                                            {!edit ? t('edit') : t('cancelEdit')}
                                        </MenuItem>
                                    )}
                                    {(me.is_admin || me.id === data.employee.user.id) && (
                                        <MenuItem
                                            onClick={() => {
                                                handleClose();
                                                handleDelete();
                                            }}
                                        >
                                            <Typography color="red">{t('delete')}</Typography>
                                        </MenuItem>
                                    )}
                                </Menu>
                            </Grid>
                        )}
                        {data?.type === 'comment' && (
                            <Grid item xs={12}>
                                {!edit ? (
                                    <Typography variant={'body1'} width={'100%'}>
                                        {data?.comment}
                                    </Typography>
                                ) : (
                                    <TextareaAutosize
                                        placeholder={'Оставить комментарий...'}
                                        style={{
                                            border: 0,
                                            width: '100%',
                                            resize: 'none',
                                            outline: 'none',
                                            fontFamily: 'Inter',
                                            fontWeight: 500,
                                            fontSize: '0.875rem',
                                            lineHeight: 1.57,
                                        }}
                                        minRows={3}
                                        value={data?.comment}
                                        onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                                            setData({ ...data, comment: event.target.value })
                                        }
                                        autoFocus
                                    />
                                )}
                            </Grid>
                        )}
                        {data?.type === 'task' || data?.type === 'call' || data?.type === 'meeting' ? (
                            <Grid item xs={12}>
                                <Typography
                                    variant="subtitle1"
                                    onClick={() => navigate(`/entity/tasks/?task_id=${data?.id}`)}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    {data?.title}
                                </Typography>
                                <Grid container spacing={2} mt={0.1}>
                                    <Grid item>
                                        <Select
                                            items={[
                                                { id: 'new', text: 'Новая' },
                                                { id: 'in_progress', text: 'В работе' },
                                                { id: 'on_review', text: 'На проверке' },
                                                { id: 'done', text: 'Завершена' },
                                            ]}
                                            value={status}
                                            onChange={(e) => handleStatus(e.target.value as string)}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Button variant="outlined" color={dateEqual() ? 'error' : 'inherit'}>
                                            Срок:{' '}
                                            {data?.deadline
                                                ? `${dayjs(data?.deadline).format('DD MMMM')}, ${dayjs(
                                                      data?.deadline
                                                  ).format('HH:mm')}`
                                                : 'не указан'}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : (
                            data.event_type && (
                                <Grid item xs={12}>
                                    {switchContent(
                                        data.event_type,
                                        data.event_data,
                                        meta?.views?.find((view) => view.view_type === 'detail')
                                            ?.entity_fields || []
                                    )}
                                </Grid>
                            )
                        )}
                    </Grid>
                    <TimelineCommentFormUpload
                        edit={edit}
                        files={data?.files}
                        onDeleteFile={handleDeleteFile}
                        setFile={(file: FileType) => {
                            setData((prev) => ({ ...prev, files: [...(prev.files || []), file] }));
                        }}
                    />
                    <Card.Footer
                        leftSide={
                            <Grid container gap={1} alignItems="center">
                                {switchIcon(data?.type)}
                            </Grid>
                        }
                        rightSide={
                            edit ? (
                                <Grid container gap={1} alignItems="center">
                                    <Button onClick={handleSave} variant="contained" color="primary">
                                        {t('save')}
                                    </Button>
                                    <Button onClick={() => handleCloseEdit(mainData)} variant="outlined">
                                        {t('cancel')}
                                    </Button>
                                </Grid>
                            ) : (
                                <></>
                            )
                        }
                    />
                </Card>
            </Grid>
        </>
    );
};
