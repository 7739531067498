import { useCallback, useEffect, useState } from 'react';
import { useFocusTab } from 'shared/hooks/useFocusTab';

export function useTick(interval = 10) {
    const [tick, setTick] = useState(0);
    const isFocused = useFocusTab();

    const resetTick = useCallback(() => {
        setTick(0);
    }, []);

    useEffect(() => {
        if (!isFocused) {
            return;
        }
        const timer = setTimeout(() => {
            setTick((prev) => prev + 1);
        }, interval * 1000);

        return () => {
            clearTimeout(timer);
        };
    }, [tick, interval, isFocused]);

    return { tick, resetTick };
}
