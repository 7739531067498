import { createEffect, createStore, sample } from 'effector';
import { organizationService } from 'shared/api/organization.service';
import { EmployeeType } from 'domain/employee';

const fetchEmployeesFx = createEffect(async (params?: any): Promise<any> => {
    return await organizationService.getDepartmentEmployees(params);
});

const updateEmployeeFx = createEffect(async ({ employeeId, data }: { employeeId: string; data: any }) => {
    return await organizationService.updateDepartmentEmployee(employeeId, data);
});

sample({
    clock: updateEmployeeFx.doneData,
    fn: () => ({}),
    target: fetchEmployeesFx,
});

const $employeesStore = createStore<EmployeeType[]>([]).on(
    fetchEmployeesFx.doneData,
    (_, data) => data.results
);
// .on(updateEmployeeFx.doneData, (state, data: any) => {
//     return state.map((empl) => {
//         if (empl.id === data.id) {
//             return { ...empl, ...data };
//         }
//         return empl;
//     });
// });

export const employeesStores = {
    $employeesStore,
};

export const employeesEffects = {
    fetchEmployeesFx,
    updateEmployeeFx,
};
