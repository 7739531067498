import React, { FC, lazy, Suspense, useState } from 'react';
import { Grid } from '@mui/material';
import DialogTransition from '../../../DialogTransition';
import { ChatDialog, gridSx } from './ChatBarDialog.styled';
import { IChatBarDialogProps } from './ChatBarDialog.types';
import { useChatStorage } from '../../lib/hooks/useChatStorage';

const ChatBarDialogGroups = lazy(async () => await import('./ChatBarDialogGroups/ChatBarDialogGroups'));
const ChatBarDialogList = lazy(async () => await import('./ChatBarDialogList/ChatBarDialogList'));
const ChatBarDialogBackground = lazy(
    async () => await import('./ChatBarDialogBackground/ChatBarDialogBackground')
);
const ChatBarDialogCloseButton = lazy(
    async () => await import('./ChatBarDialogCloseButton/ChatBarDialogCloseButton')
);
export const ChatBarDialog: FC<IChatBarDialogProps> = ({ open, onClose }) => {
    const [isCreateChat, setIsCreateChat] = useState<boolean>(false);
    const { chat } = useChatStorage();
    return (
        <Suspense fallback={null}>
            <ChatDialog open={open} onClose={onClose} TransitionComponent={DialogTransition} fullScreen>
                <ChatBarDialogCloseButton onClose={onClose} />
                <Grid container sx={gridSx}>
                    <Grid item sx={{ width: '109px' }}>
                        <ChatBarDialogGroups setIsCreateChat={setIsCreateChat} />
                    </Grid>
                    <Grid item sx={{ width: '399px' }}>
                        <ChatBarDialogList isCreateChat={isCreateChat} setIsCreateChat={setIsCreateChat} />
                    </Grid>
                    <Grid item sx={{ width: `calc(100% - 508px)` }}>
                        <ChatBarDialogBackground chat={chat} />
                    </Grid>
                </Grid>
            </ChatDialog>
        </Suspense>
    );
};
