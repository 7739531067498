import { createEffect, createEvent, createStore } from 'effector';
import { Nullable } from 'shared/types';
import { SelectedFieldType } from 'pages/Report/AddFieldsModal/AddFieldsModal';
import { TMeta } from 'domain/meta';
import { FilterType } from 'pages/Report/Filter/filters';
import { ConnectedObjectType } from 'pages/Tasks/List/Card/TaskListCard';
import { reportsApiService } from 'pages/Reports/api/services/reportsService';

const initialData = {
    title: 'Новый отчёт',
    entity: null,
    relatedEntities: [],
    fields: [],
    filters: {},
    access_departments: [],
    access_employees: [],
    access_roles: [],
};

export type FilterItem = {
    id: string;
    field: string;
    operator: FilterType | ''; //gte, lte, icontains и все прочие,
    value: any;
    show_in_view: boolean;
    settings?: any;
};

export type ReportDetailInitialDataType = {
    title: string;
    entity: Nullable<TMeta>;
    relatedEntities: Array<string[]>;
    fields: SelectedFieldType[];
    filters: Record<string, FilterItem>;
    access_departments: Array<ConnectedObjectType>;
    access_employees: Array<ConnectedObjectType>;
    access_roles: Array<ConnectedObjectType>;
};

const createReportFx = createEffect(async (data: any) => {
    const service = reportsApiService();
    return await service.create(data);
});

const saveReportFx = createEffect(async ({ data, id }: { data: any; id: string }) => {
    const service = reportsApiService();
    return await service.save(id, data);
});

const fetchDetailReportFx = createEffect(async (id: string) => {
    const service = reportsApiService();
    return await service.fetchDetail(id);
});

const updateReport = createEvent<Partial<ReportDetailInitialDataType>>();
const resetReport = createEvent<Partial<ReportDetailInitialDataType>>();

const $reportDetail = createStore<ReportDetailInitialDataType>(initialData)
    .on(updateReport, (state, payload) => ({ ...state, ...payload }))
    .reset(resetReport);

export const reportStores = {
    $reportDetail,
};

export const reportEvents = {
    updateReport,
    resetReport,
};

export const reportEffects = {
    createReportFx,
    fetchDetailReportFx,
    saveReportFx,
};
