import { createEffect, createEvent, createStore } from 'effector';
import { IFunnelsApiService } from 'application/ports/Api';
import { funnelsApiService } from 'services/ApiAdapter';
import { TParamsRequest, TResponse } from 'shared/api/types';
import { states, TBulkStage } from 'domain/funnels';

const initialFunnelsListStore = {
    count: 0,
    next: '',
    previous: '',
    results: [],
    params: {
        offset: 0,
        limit: 10,
    },
};

const funnelsApi: IFunnelsApiService = funnelsApiService();

const fetchFunnelsListFx = createEffect(async (params: TParamsRequest): Promise<TResponse> => {
    return await funnelsApi.getFunnels(params);
});
const createFunnelsListFx = createEffect(async (data: any): Promise<any> => {
    return await funnelsApi.createFunnel(data);
});
const updateFunnelsListFx = createEffect(async (data: any): Promise<any> => {
    return await funnelsApi.updateFunnel(data);
});
const bulkUpdateFunnelFx = createEffect(async (data: any): Promise<any> => {
    return await funnelsApi.bulkUpdateFunnel(data);
});
const deleteFunnelsListFx = createEffect(async (id: string): Promise<any> => {
    return await funnelsApi.deleteFunnel(id);
});
const restoreFunnelsListFx = createEffect(async (id: string): Promise<any> => {
    return await funnelsApi.restoreFunnel(id);
});

const getStagesFx = createEffect(async (stage_id: string): Promise<any> => {
    return await funnelsApi.getStages(stage_id);
});
const createStageFx = createEffect(async (data: any): Promise<any> => {
    return await funnelsApi.createStage(data);
});
const updateStageFx = createEffect(async (data: any): Promise<any> => {
    return await funnelsApi.updateStage(data);
});
const bulkUpdateStageFx = createEffect(async (data: TBulkStage[]): Promise<TBulkStage[]> => {
    return await funnelsApi.bulkUpdateStage(data);
});
const deleteStageFx = createEffect(async (id: string): Promise<any> => {
    return await funnelsApi.deleteStage(id);
});

const updateFunnelsList = createEvent<TResponse>();
const clearFunnelsList = createEvent();

const $funnelsListStore = createStore<TResponse>(initialFunnelsListStore)
    .on(updateFunnelsList, (_, newFunnels) => newFunnels)
    .on(fetchFunnelsListFx.doneData, (_, newFunnels) => {
        const backlogFunnel = newFunnels.results.filter((f) => f.type === 'backlog');
        const withoutBacklogFunnel = newFunnels.results.filter((f) => f.type !== 'backlog');
        const sortedFunnels = [...backlogFunnel, ...withoutBacklogFunnel];
        const newResults = sortedFunnels.map((f: any) => {
            f.stagesDefault = f.stages;
            const newStages = states.map((state: any) => {
                return {
                    state,
                    arr: f.stages.filter((stage: any) => stage.state === state.type),
                };
            });
            f.stages = newStages;
            return f;
        });
        newFunnels.results = newResults;
        return newFunnels;
    })
    .on(createFunnelsListFx.doneData, (state, newFunnel) => {
        const newStages = states.map((state: any) => {
            return {
                state,
                stagesDefault: newFunnel.stages,
                arr: newFunnel.stages.filter((stage: any) => stage.state === state.type),
            };
        });
        newFunnel.stages = newStages;
        return { ...state, results: [...state.results, newFunnel] };
    })
    .on(createStageFx.doneData, (state, newStage) => {
        const newResults = state.results.map((f: any) => {
            if (f.id === newStage.funnel) {
                f.stagesDefault = f.stages;
                const newStages = f.stages.map((stage: any) => {
                    if (stage.state.type === 'in_progress') {
                        stage.arr = [...stage.arr, { ...newStage, state: 'in_progress' }];
                    }
                    return stage;
                });
                f.stages = newStages;
            }
            return f;
        });
        return { ...state, results: newResults };
    })
    .reset(clearFunnelsList);

export const funnelsListStores = {
    $funnelsListStore,
};

export const funnelsListEffects = {
    fetchFunnelsListFx,
    createFunnelsListFx,
    deleteFunnelsListFx,
    updateFunnelsListFx,
    bulkUpdateFunnelFx,
    restoreFunnelsListFx,
    createStageFx,
    updateStageFx,
    deleteStageFx,
    bulkUpdateStageFx,
    getStagesFx,
};

export const funnelsListEvents = {
    updateFunnelsList,
    clearFunnelsList,
};
