import React from 'react';
import { TFieldProps } from 'widgets/DraggableField/Fields/types';
import { Grid, IconButton, Link, Typography } from '@mui/material';
import { Button, Input } from 'shared/ui';
import AddCircleRounded from '@mui/icons-material/AddCircleRounded';
import { CancelRounded } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const LinkField = ({ field, edit, value, setValue, error }: TFieldProps<string | string[]>) => {
    const { t } = useTranslation();
    const isMany = field.settings.many;

    return (
        <>
            {edit ? (
                <>
                    {isMany && Array.isArray(value) ? (
                        <Grid container gap={'8px'}>
                            {value?.map((v: string, i: number) => {
                                return (
                                    <Grid key={i} container flexWrap={'nowrap'} gap={'8px'}>
                                        <Input
                                            onChange={(e) => {
                                                let newValue = e.target.value;
                                                if (e.target.value.startsWith('http')) {
                                                    newValue = newValue.replace(/https?:\/\//g, '');
                                                }
                                                value[i] = newValue;
                                                setValue?.(field.id, [...value]);
                                            }}
                                            error={!!error}
                                            placeholder={field.placeholder}
                                            value={v}
                                            InputProps={{
                                                startAdornment: <Typography>http://</Typography>,
                                            }}
                                        />
                                        {i !== 0 && (
                                            <IconButton
                                                onClick={() => {
                                                    value.splice(i, 1);
                                                    setValue?.(field.id, [...value]);
                                                }}
                                                size={'small'}
                                            >
                                                <CancelRounded fontSize={'inherit'} />
                                            </IconButton>
                                        )}
                                    </Grid>
                                );
                            })}
                        </Grid>
                    ) : (
                        <Input
                            onChange={(e) => {
                                let value = e.target.value;
                                if (e.target.value.startsWith('http')) {
                                    value = value.replace(/https?:\/\//g, '');
                                }
                                setValue?.(field.id, value);
                            }}
                            error={!!error}
                            placeholder={field.placeholder}
                            value={value}
                            InputProps={{
                                startAdornment: <Typography>http://</Typography>,
                            }}
                        />
                    )}
                    {isMany && Array.isArray(value) && (
                        <Grid mt={'8px'}>
                            <Button
                                startIcon={<AddCircleRounded />}
                                onClick={() => {
                                    setValue?.(field.id, [...value, '']);
                                }}
                            >
                                {t('add')}
                            </Button>
                        </Grid>
                    )}
                </>
            ) : (
                <>
                    {isMany ? (
                        <Grid container gap={'8px'} flexDirection={'column'}>
                            {Array.isArray(value) &&
                                value?.map((v: string, i: number) => {
                                    return (
                                        <Link
                                            key={i}
                                            onPointerUp={(e) => e.stopPropagation()}
                                            href={`http://${v}`}
                                            target={'_blank'}
                                        >
                                            <Typography variant={'body1'}>{v}</Typography>
                                        </Link>
                                    );
                                })}
                        </Grid>
                    ) : (
                        <Link
                            onPointerUp={(e) => e.stopPropagation()}
                            href={`http://${value}`}
                            target={'_blank'}
                        >
                            <Typography variant={'body1'}>{value}</Typography>
                        </Link>
                    )}
                </>
            )}
        </>
    );
};

export default LinkField;
