import { PaletteMode } from '@mui/material';
import { darkThemePalette, lightThemePalette } from './palette';
import { typography } from './typography';
import { components } from './components';

export const getDesignTokens = (mode: PaletteMode) => ({
    palette: {
        mode,
        ...(mode === 'light' ? lightThemePalette : darkThemePalette),
    },
    typography,
    components,
});
