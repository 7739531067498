import {
    Button,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    Menu,
    MenuItem,
    Typography,
    useTheme,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { useFilterStorage } from 'widgets/Filter/lib/hooks/useFilterStorage';
import { useEffect, useState } from 'react';
import { useMetaStorage, useNotification, useUsersStorage } from 'services/StorageAdapter';
import { useMenu } from 'shared/hooks/useMenu';
import { EditTitle } from 'shared/ui';
import { TParamsRequest } from 'shared/api/types';
import { useTranslation } from 'react-i18next';

interface IFilterPresetList {
    newPreset: boolean;
    newPresetTitle?: string;
    onNewPresetTitle?: (text: string) => void;
    onClose?: () => void;
    onReject?: () => void;
}

const FilterPresetList = ({
    newPreset,
    newPresetTitle,
    onNewPresetTitle,
    onClose,
    onReject,
}: IFilterPresetList) => {
    const { t } = useTranslation();
    const { me } = useUsersStorage();
    const { meta } = useMetaStorage();
    const { notify } = useNotification();
    const { filter, filters, getFiltersUsers, getFilterUsers, deleteFilterUsers, updateFilterUsers } =
        useFilterStorage();
    const { anchorEl, open, handleClose, handleClick } = useMenu();
    const [currentPreset, setCurrentPreset] = useState('user');
    const [edit, setEdit] = useState(false);
    const {
        palette: { navigation },
    } = useTheme();

    useEffect(() => {
        filter?.id && handleGetPreset(filter?.id as string);
    }, [filter.id]);

    useEffect(() => {
        handleGetAllPresets({ type: currentPreset });
    }, [currentPreset]);

    const handleGetAllPresets = (params: TParamsRequest) => {
        const newParams = {
            ...filters.params,
            entity: meta.id as string,
            limit: 10,
            offset: 0,
            ...params,
        };
        currentPreset === 'user' ? (newParams.user = me.id as string) : delete newParams?.user;
        getFiltersUsers(newParams);
    };

    const handleGetPreset = (id: string) => {
        getFilterUsers(id);
    };

    const handleRemove = (id: string) => {
        deleteFilterUsers(id)
            .then(() => {
                notify({
                    message: `Фильтр "${filters.results.find((item) => item.id === id)?.name}" удален`,
                });
                handleGetAllPresets({});
                onReject?.();
            })
            .catch(() => notify({ message: t('somethingWentWrong') }));
    };

    const handleSave = (id: string, name: string, entity: string, type: string, user: string) => {
        updateFilterUsers({
            id,
            name,
            entity,
            type,
            user: currentPreset === 'user' ? (me.id as string) : user,
        })
            .then(() => {
                notify({ message: `Фильтор "${name}" обнавлен` });
                handleGetAllPresets({});
            })
            .catch(() => notify({ message: t('somethingWentWrong') }));
    };

    return (
        <>
            <Grid
                item
                md={3}
                bgcolor={navigation}
                display="flex"
                flexDirection="column"
                alignItems={'flex-end'}
            >
                {filters.results.length || newPreset || newPresetTitle ? (
                    <Grid item flex="1 1 auto" width={'100%'}>
                        <Typography variant={'h6'} fontSize={14} px={2} py={1.6}>
                            {t('sets')}
                        </Typography>
                        <Divider />
                        <List sx={{ maxHeight: 378, overflowY: 'scroll !important' }}>
                            {filters.results.map((item) => (
                                <ListItem disablePadding key={item.id}>
                                    <ListItemButton
                                        onClick={() => !edit && handleGetPreset(item.id as string)}
                                    >
                                        {edit ? (
                                            <EditTitle
                                                title={item.name}
                                                onSave={(text) =>
                                                    handleSave?.(
                                                        item.id as string,
                                                        text,
                                                        item.entity as string,
                                                        item.type as string,
                                                        item.user as string
                                                    )
                                                }
                                                onClose={() => !newPresetTitle && onClose?.()}
                                                onRemove={() => handleRemove(item.id as string)}
                                                placeholder={t('nameSets')}
                                                fontSize={14}
                                            />
                                        ) : (
                                            <Typography variant={'body2'}>{item.name}</Typography>
                                        )}
                                    </ListItemButton>
                                </ListItem>
                            ))}
                            {(newPreset || newPresetTitle) && (
                                <ListItem disablePadding>
                                    <ListItemButton>
                                        <EditTitle
                                            title={newPresetTitle as string}
                                            onSave={(text) => onNewPresetTitle?.(text)}
                                            onClose={() => !newPresetTitle && onClose?.()}
                                            onRemove={() => onReject?.()}
                                            placeholder={t('newSet')}
                                            fontSize={14}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            )}
                        </List>
                    </Grid>
                ) : (
                    <Grid
                        item
                        p={3}
                        textAlign={'center'}
                        flex="1 1 auto"
                        display={'grid'}
                        alignItems={'center'}
                    >
                        <Grid item>
                            <Typography variant={'h6'} fontSize={16}>
                                {t('emptySets')}
                            </Typography>
                            <Typography variant={'body2'} color={'gray'}>
                                {t('emptySetsDesc')}
                            </Typography>
                        </Grid>
                    </Grid>
                )}

                <Grid
                    item
                    py={1.65}
                    px={1}
                    gap={1}
                    width="100%"
                    display="flex"
                    justifyContent="space-between"
                    borderTop={'1px solid #0000001F'}
                    flex="0 0 auto"
                >
                    {edit ? (
                        <Button
                            variant={'text'}
                            color="primary"
                            startIcon={<CheckCircleRoundedIcon fontSize={'small'} />}
                            onClick={() => setEdit(false)}
                            size="small"
                        >
                            {t('done')}
                        </Button>
                    ) : (
                        <>
                            <Button
                                variant={'text'}
                                color="primary"
                                endIcon={<ArrowDropUpRoundedIcon fontSize={'small'} />}
                                onClick={handleClick}
                                size="small"
                            >
                                {currentPreset === 'user' ? t('myFieldset') : t('defaultFieldset')}
                            </Button>
                            <Menu
                                anchorEl={anchorEl}
                                open={!!open}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                sx={{
                                    ['& .MuiList-root']: {
                                        overflow: 'hidden',
                                        padding: 0,
                                    },
                                }}
                            >
                                <MenuItem
                                    onClick={() => {
                                        setCurrentPreset('user');
                                        handleClose();
                                    }}
                                >
                                    {t('myFieldset')}
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        setCurrentPreset('default');
                                        handleClose();
                                    }}
                                >
                                    {t('defaultFieldset')}
                                </MenuItem>
                            </Menu>
                            <IconButton size="small" onClick={() => setEdit(true)}>
                                <SettingsIcon fontSize={'small'} />
                            </IconButton>
                        </>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default FilterPresetList;
