import React, { FC, memo } from 'react';
import ChatIcon from '@mui/icons-material/Chat';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { IChatBarButtonProps } from './ChatBarButton.types';
import { OutlineIconButton } from './ChatBarButton.styled';

export const ChatBarButton: FC<IChatBarButtonProps> = memo(({ open, onOpen }) => {
    return (
        <OutlineIconButton open={open} onClick={onOpen}>
            {open ? <ChatBubbleOutlineIcon color={'primary'} /> : <ChatIcon />}
        </OutlineIconButton>
    );
});
