import { useStore } from 'effector-react';
import { IMenuStorageService } from '../../types/menuSchema';
import { menuEffects, menuEvents, menuStores } from '../../model/store/menu';

const { $menuStore, $menuItemStore } = menuStores;
const {
    fetchMenuFx,
    deleteMenuFx,
    deleteMenuItemFx,
    createMenuItemFx,
    fetchMenuItemFx,
    setMenuItemsFx,
    unSetMenuItemsFx,
    createMenuFx,
    updateMenuItemFx,
    updateMenuFx,
    updateMenuNameFx,
    bulkUpdateMenuFx,
} = menuEffects;
const {
    clearMenu,
    clearMenuItem,
    updateMenu: updateMenuEvent,
    updateMenuIsEdit,
    updateMenuItemIsEdit,
    updateMenuItemMove,
    updateMenuItem,
} = menuEvents;
export function useMenuStorage(): IMenuStorageService {
    const menu = useStore($menuStore);
    const menuItem = useStore($menuItemStore);

    return {
        menu,
        menuItem,
        clearMenu,
        clearMenuItem,
        fetchMenu: fetchMenuFx,
        deleteMenu: deleteMenuFx,
        deleteMenuItem: deleteMenuItemFx,
        createMenuItem: createMenuItemFx,
        fetchMenuItem: fetchMenuItemFx,
        setMenuItems: setMenuItemsFx,
        unSetMenuItems: unSetMenuItemsFx,
        createMenu: createMenuFx,
        updateMenuItem: updateMenuItemFx,
        updateMenuEvent,
        updateMenuItemIsEdit,
        updateMenu: updateMenuFx,
        updateMenuName: updateMenuNameFx,
        updateMenuIsEdit,
        updateMenuItemMove,
        bulkUpdateMenu: bulkUpdateMenuFx,
        updateMenuItemEvent: updateMenuItem,
    };
}
