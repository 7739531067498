import React from 'react';
import { Grid, Link } from '@mui/material';
import { Button } from 'shared/ui';
import { TFieldProps } from 'widgets/DraggableField/Fields/types';
import CallRoundedIcon from '@mui/icons-material/CallRounded';
import DefaultField from 'widgets/DraggableField/Fields/DefaultField/DefaultField';
import { useTranslation } from 'react-i18next';

const PhoneField = ({ field, setValue, value, edit, error }: TFieldProps<string | string[]>) => {
    const { t } = useTranslation();
    const isMany = field.settings.many;
    return (
        <>
            {edit ? (
                <DefaultField field={field} value={value} setValue={setValue} error={error} edit />
            ) : (
                <>
                    {isMany && Array.isArray(value) ? (
                        <Grid container flexDirection={'column'} gap={'8px'}>
                            {value.map((v, i) => (
                                <Grid key={i} container flexWrap={'nowrap'} gap={'8px'}>
                                    <DefaultField field={field} value={v} />
                                    {v && (
                                        <Link
                                            onPointerUp={(e) => e.stopPropagation()}
                                            href={`tel:${v}`}
                                            underline={'none'}
                                        >
                                            <Button size={'small'} startIcon={<CallRoundedIcon />}>
                                                {t('call')}
                                            </Button>
                                        </Link>
                                    )}
                                </Grid>
                            ))}
                        </Grid>
                    ) : (
                        <Grid container flexWrap={'nowrap'} gap={'8px'}>
                            <DefaultField field={field} value={value} />
                            {value && (
                                <Link
                                    onPointerUp={(e) => e.stopPropagation()}
                                    href={`tel:${value}`}
                                    underline={'none'}
                                >
                                    <Button size={'small'} startIcon={<CallRoundedIcon />}>
                                        {t('call')}
                                    </Button>
                                </Link>
                            )}
                        </Grid>
                    )}
                </>
            )}
        </>
    );
};

export default PhoneField;
