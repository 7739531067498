import React, { ChangeEvent, useState } from 'react';
import { Grid, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import { useEntityDetailStorage } from 'services/StorageAdapter';
import { MOBILE_QUERY_WIDTH } from 'shared/consts';

type EntityTitleProps = {
    onSave: (title: string) => void;
    fontSize?: string;
    name?: string;
    onClickToTitle?: () => void;
    disabled?: boolean;
};

const EntityTitle = ({
    name,
    fontSize,
    onSave,
    onClickToTitle = () => {},
    disabled = false,
}: EntityTitleProps) => {
    const { title } = useEntityDetailStorage();
    const customTitle = name || name === '' ? name : title;
    const [isEntityTitleEdit, setIsEntityTitleEdit] = useState(false);
    const [entityTitle, setEntityTitle] = useState('');
    const mobileQuery = useMediaQuery(MOBILE_QUERY_WIDTH);
    const {
        palette: {
            text: { primary },
        },
    } = useTheme();

    const handleEntityTitleClick = () => {
        setIsEntityTitleEdit(true);
    };

    const handleChangeEntityTitle = (e: ChangeEvent<HTMLInputElement>) => {
        setEntityTitle(e.target.value);
    };

    const handleCancelEditTitle = () => {
        setIsEntityTitleEdit(false);
        setEntityTitle(customTitle);
    };

    const handleSaveTitle = () => {
        setIsEntityTitleEdit(false);
        onSave(entityTitle);
    };

    return (
        <>
            {isEntityTitleEdit ? (
                <Grid
                    container
                    alignItems={'center'}
                    justifyContent={mobileQuery ? 'center' : 'flex-start'}
                    flexWrap={'nowrap'}
                    width={'auto'}
                >
                    <Grid item flexGrow={1}>
                        <input
                            autoFocus
                            onChange={handleChangeEntityTitle}
                            value={entityTitle || entityTitle === '' ? entityTitle : customTitle}
                            style={{
                                background: 'transparent',
                                color: primary,
                                width: '100%',
                                border: 'none',
                                outline: 'none',
                                fontWeight: '400',
                                fontSize: mobileQuery ? '18px' : '24px',
                            }}
                        />
                    </Grid>
                    <Grid container gap={'8px'} width={'auto'} flexWrap={'nowrap'}>
                        <IconButton onClick={handleSaveTitle} size={'small'}>
                            <CheckCircleRoundedIcon fontSize={'inherit'} />
                        </IconButton>
                        <IconButton onClick={handleCancelEditTitle} size={'small'}>
                            <CancelRoundedIcon fontSize={'inherit'} />
                        </IconButton>
                    </Grid>
                </Grid>
            ) : (
                <Grid
                    container
                    flexWrap={'nowrap'}
                    justifyContent={mobileQuery ? 'center' : 'flex-start'}
                    maxWidth={'100%'}
                    alignItems={'center'}
                >
                    <div
                        onClick={handleEntityTitleClick}
                        style={{
                            maxHeight: '89px',
                            overflow: 'hidden',
                            display: '-webkit-box',
                            textOverflow: 'ellipsis',
                            boxOrient: 'vertical',
                            lineClamp: 3,
                        }}
                    >
                        <Typography
                            fontSize={fontSize}
                            variant={'h5'}
                            sx={{
                                cursor: 'pointer',
                                fontSize: mobileQuery ? '18px' : '24px',
                            }}
                            onClick={onClickToTitle}
                        >
                            {customTitle}
                        </Typography>
                    </div>
                    {!disabled && (
                        <IconButton onClick={handleEntityTitleClick} size={'medium'}>
                            <ModeEditOutlineIcon fontSize={'inherit'} />
                        </IconButton>
                    )}
                </Grid>
            )}
        </>
    );
};

export default EntityTitle;
