import { Grid, IconButton, Menu, useMediaQuery } from '@mui/material';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import FilterPresetList from './FilterPresetList/FilterPresetList';
import FilterPresetSettings from './FilterPresetSettings/FilterPresetSettings';
import { useMenu } from 'shared/hooks/useMenu';
import { useEffect, useState } from 'react';
import FilterPresetFields from './FilterPresetFields/FilterPresetFields';
import { MOBILE_QUERY_WIDTH } from 'shared/consts';
import { ActionList } from 'widgets/ActionList';
import { useMetaStorage } from 'services/StorageAdapter';
import { Button } from '../../../shared/ui';

const Filter = ({
    startSearch,
    disabled = false,
    isBtn,
}: {
    startSearch: () => void;
    disabled?: boolean;
    isBtn?: boolean;
}) => {
    const { meta } = useMetaStorage();
    const isMobile = useMediaQuery(MOBILE_QUERY_WIDTH);
    const { anchorEl, open, handleClose, handleClick } = useMenu();
    const [fields, setFields] = useState<string[]>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [newPreset, setNewPreset] = useState(false);
    const [presetTitle, setPresetTitle] = useState('');
    const isTask = Boolean(meta.code === 'tasks');

    useEffect(() => {
        return () => setIsOpen(false);
    }, []);

    const handleReject = () => {
        setNewPreset(false);
        setPresetTitle('');
        setFields([]);
    };

    const handleCreate = () => {
        setNewPreset(true);
        setPresetTitle('');
        setFields([]);
    };

    return (
        <>
            {isBtn ? (
                <Button variant="outlined" onClick={handleClick} startIcon={<FilterAltRoundedIcon />}>
                    Фильтр
                </Button>
            ) : (
                <IconButton size="small" color="primary" onClick={handleClick} disabled={disabled}>
                    <FilterAltRoundedIcon />
                </IconButton>
            )}
            {isMobile ? (
                <ActionList
                    open={open}
                    onClose={() => handleClose()}
                    onOpen={() => {}}
                    maxDrawerHeightPercent="100%"
                >
                    {isOpen ? (
                        <FilterPresetFields
                            onCloseFilter={handleClose}
                            onCloseFields={() => setIsOpen(false)}
                            onSetFields={setFields}
                        />
                    ) : (
                        <>
                            <FilterPresetSettings
                                onCloseFilter={handleClose}
                                onOpenFields={() => setIsOpen(true)}
                                onClearFields={() => setFields([])}
                                onNewPreset={handleCreate}
                                onRejectPreset={handleReject}
                                fields={fields}
                                newPreset={newPreset}
                                newPresetTitle={presetTitle}
                                startSearch={() => {
                                    startSearch();
                                    handleClose();
                                }}
                            />
                        </>
                    )}
                </ActionList>
            ) : (
                <Menu
                    anchorEl={anchorEl}
                    open={!!open}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    sx={{
                        ['& .MuiList-root']: {
                            overflow: 'hidden',
                            padding: 0,
                        },
                    }}
                >
                    <Grid container height={480} width={1000}>
                        {isOpen ? (
                            <FilterPresetFields
                                onCloseFilter={handleClose}
                                onCloseFields={() => setIsOpen(false)}
                                onSetFields={setFields}
                            />
                        ) : (
                            <>
                                {!isTask && (
                                    <FilterPresetList
                                        newPreset={newPreset}
                                        newPresetTitle={presetTitle}
                                        onNewPresetTitle={setPresetTitle}
                                        onClose={() => {
                                            setNewPreset(false);
                                            setFields([]);
                                        }}
                                        onReject={handleReject}
                                    />
                                )}
                                <FilterPresetSettings
                                    onCloseFilter={handleClose}
                                    onOpenFields={() => setIsOpen(true)}
                                    onClearFields={() => setFields([])}
                                    onNewPreset={handleCreate}
                                    onRejectPreset={handleReject}
                                    fields={fields}
                                    newPreset={newPreset}
                                    newPresetTitle={presetTitle}
                                    startSearch={() => {
                                        startSearch();
                                        handleClose();
                                    }}
                                />
                            </>
                        )}
                    </Grid>
                </Menu>
            )}
        </>
    );
};

export default Filter;
