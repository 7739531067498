import { RefObject, useState, useEffect } from 'react';

type ScrollDirection = 'left' | 'right';

let isScrollStarted = false;
let timer: ReturnType<typeof setTimeout>;
let prevScrollPos = 0;
const scrollConfig = {
    left: false,
    right: true,
};

export function useHorizontalScroll(containerRef: RefObject<HTMLElement>) {
    const [showLeftScroll, setShowLeftScroll] = useState(false);
    const [showRightScroll, setShowRightScroll] = useState(true);

    const scrollBehavior = (dir: ScrollDirection) => {
        if (isScrollStarted) {
            timer = setTimeout(() => {
                if (containerRef.current) {
                    containerRef.current.scrollBy({ left: dir === 'right' ? 2 : -2 });
                }
                if (isScrollStarted) {
                    scrollBehavior(dir);
                }
            }, 5);
        } else {
            clearTimeout(timer);
        }
        if (containerRef.current) {
            // console.log(
            //     containerRef.current.scrollLeft !== 0,
            //     containerRef.current.scrollLeft + containerRef.current.clientWidth !==
            //         containerRef.current.scrollWidth,
            //     !showLeftScroll || !showRightScroll
            // );
            if (containerRef.current.scrollLeft === 0 && scrollConfig.left) {
                scrollConfig.left = false;
                setShowLeftScroll(false);
                isScrollStarted = false;
                clearTimeout(timer);
            }
            if (
                containerRef.current.scrollLeft + containerRef.current.clientWidth ===
                    containerRef.current.scrollWidth &&
                scrollConfig.right
            ) {
                scrollConfig.right = false;
                setShowRightScroll(false);
                isScrollStarted = false;
                clearTimeout(timer);
            }
            if (
                containerRef.current.scrollLeft !== 0 &&
                containerRef.current.scrollLeft + containerRef.current.clientWidth !==
                    containerRef.current.scrollWidth &&
                (!scrollConfig.left || !scrollConfig.right)
            ) {
                scrollConfig.left = true;
                scrollConfig.right = true;
                setShowRightScroll(true);
                setShowLeftScroll(true);
            }
        }
    };

    const scrollStart = (dir: ScrollDirection) => {
        isScrollStarted = true;
        scrollBehavior(dir);
    };

    const scrollStop = () => {
        isScrollStarted = false;
        clearTimeout(timer);
    };

    const handleScrollEvent = (e: Event) => {
        const target = e.currentTarget as HTMLElement;
        if (!isScrollStarted) {
            const delta = prevScrollPos - target.scrollLeft;
            prevScrollPos = target.scrollLeft;
            scrollBehavior(delta > 0 ? 'left' : 'right');
        }
    };

    useEffect(() => {
        containerRef.current?.addEventListener('scroll', handleScrollEvent);

        return () => containerRef.current?.removeEventListener('scroll', handleScrollEvent);
    }, [showRightScroll, showRightScroll]);

    return { scrollStop, scrollStart, showLeftScroll, showRightScroll };
}
