import React from 'react';
import { TFieldProps } from 'widgets/DraggableField/Fields/types';
import { Button, Chip, Upload } from 'shared/ui';
import { FileForUploadType, FileUploadStatus } from 'shared/ui/Upload/Upload';
import { useEntityDetailStorage } from 'services/StorageAdapter';
import { Box, Grid, IconButton } from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import FilePresentRoundedIcon from '@mui/icons-material/FilePresentRounded';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import { downloadAsZip } from 'shared/helpers/downloadFiles';
import { useTranslation } from 'react-i18next';

export type FileType = {
    file: string;
    id: string;
    title: string;
};

const FileField = ({ field, setValue, value, edit }: TFieldProps<FileType[]>) => {
    const { t } = useTranslation();
    const { uploadFile } = useEntityDetailStorage();
    const uploadFiles = async (
        files: FileForUploadType[],
        progressCallback: (status: Omit<FileUploadStatus, 'file' | 'abortSignal' | 'controller'>) => void
    ) => {
        const filesArray: Array<FileType> = [...value];
        for (let i = 0; i < files.length; i++) {
            const { id, file, abortSignal, ...other } = files[i];
            if (file.size / 1024 ** 2 >= 10) {
                progressCallback({
                    ...other,
                    id,
                    status: 'error',
                    reason: t('fileFieldSizeError'),
                });
                continue;
            }
            progressCallback({
                ...other,
                id,
                status: 'uploading',
            });
            try {
                const uploadedFiles = await uploadFile({ file, abortSignal });
                progressCallback({
                    id,
                    status: 'success',
                });
                filesArray.push(...uploadedFiles);
                setValue?.(field.id, [...filesArray]);
            } catch (err) {
                const { id } = files[i];
                progressCallback({
                    id,
                    status: 'error',
                    reason: t('fileFieldError'),
                });
            }
        }
    };

    const handleDeleteFile = (removeId: string) => {
        setValue?.(
            field.id,
            value.filter(({ id }) => id !== removeId)
        );
    };

    const handleDownloadAllFiles = () => {
        if (Array.isArray(value)) {
            downloadAsZip(
                value.map(({ file, title }) => {
                    const [, fileName, ext] = /.*\/(.*\.(.*))\?/.exec(file) as string[];
                    const isImage = /\.(png|jpg|jpeg)/.test(fileName);
                    return { fileName: [title, ext].join('.'), url: file, isImage };
                })
            );
        }
    };

    return (
        <div>
            {!edit && value?.length > 0 && (
                <Button
                    onPointerUp={(e) => e.stopPropagation()}
                    onClick={handleDownloadAllFiles}
                    sx={{
                        mb: '8px',
                    }}
                    color={'primary'}
                    startIcon={<DownloadRoundedIcon />}
                    variant={'text'}
                >
                    {t('downloadAllFiles')}
                </Button>
            )}
            <Grid container mb={'12px'} gap={'8px'}>
                {Array.isArray(value) &&
                    value.map(({ file, id, title }) => {
                        const [, fileName = ''] = /.*\/(.*\..*)\?/.exec(file) || ([] as string[]);
                        const isImage = /\.(png|jpg|jpeg)/.test(fileName);
                        return (
                            <>
                                {isImage ? (
                                    <Box
                                        key={id}
                                        sx={{
                                            width: '80px',
                                            height: '80px',
                                            position: 'relative',
                                        }}
                                    >
                                        <img
                                            style={{
                                                borderRadius: '4px',
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                            }}
                                            src={file}
                                            alt={fileName}
                                        />
                                        {edit && (
                                            <Box
                                                onClick={() => handleDeleteFile(id)}
                                                sx={{
                                                    cursor: 'pointer',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    position: 'absolute',
                                                    top: '-6px',
                                                    right: '-6px',
                                                    width: '32px',
                                                    height: '32px',
                                                    backgroundColor: '#FFFFFF',
                                                    borderRadius: '4px',
                                                    boxShadow:
                                                        '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
                                                }}
                                            >
                                                <CancelRoundedIcon sx={{ color: '#00000029' }} />
                                            </Box>
                                        )}
                                    </Box>
                                ) : (
                                    <>
                                        {edit ? (
                                            <Chip
                                                key={id}
                                                avatar={<FilePresentRoundedIcon />}
                                                label={title}
                                                actionIcon={
                                                    <IconButton sx={{ color: '#00000029' }}>
                                                        <CancelRoundedIcon fontSize={'inherit'} />
                                                    </IconButton>
                                                }
                                                actionHandler={() => {
                                                    handleDeleteFile(id);
                                                }}
                                            />
                                        ) : (
                                            <Chip
                                                key={id}
                                                avatar={<FilePresentRoundedIcon />}
                                                label={title}
                                            />
                                        )}
                                    </>
                                )}
                            </>
                        );
                    })}
            </Grid>
            {edit && <Upload uploadedFiles={uploadFiles} />}
        </div>
    );
};

export default FileField;
