import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Box, Drawer, useMediaQuery, useTheme } from '@mui/material';
import { drawerWidth } from '../../lib/const/consts';
import { useMenuStorage } from 'widgets/Sidebar';
import { SidebarFooter } from '../SidebarFooter/SidebarFooter';
import { SidebarNavList } from '../SidebarNavList/SidebarNavList';
import { SidebarEditNavList } from '../SidebarEditNavList/SidebarEditNavList';
import { SidebarEditFooter } from '../SidebarEditFooter/SidebarEditFooter';
import { SidebarEditHideMenu } from '../SidebarEditHideMenu/SidebarEditHideMenu';
import { SidebarAppList } from '../SidebarAppList/SidebarAppList';
import { MOBILE_QUERY_WIDTH } from 'shared/consts';

type SidebarProps = {
    open: boolean;
    setOpen: (value: boolean) => void;
    setSidebarOpen: Dispatch<SetStateAction<boolean>>;
};

export const Sidebar = ({ open, setOpen, setSidebarOpen }: SidebarProps) => {
    const mobileQuery = useMediaQuery(MOBILE_QUERY_WIDTH);
    const { fetchMenu, menu, clearMenu } = useMenuStorage();
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const {
        palette: { navigation },
    } = useTheme();
    const onClose = () => setOpen(false);
    const handleEdit = () => setIsEdit((prev) => !prev);
    useEffect(() => {
        fetchMenu();

        return () => {
            clearMenu();
        };
    }, []);
    return (
        <Drawer
            PaperProps={{
                sx: {
                    background: navigation,
                },
            }}
            sx={{
                width: !mobileQuery ? drawerWidth : '100%',
                flexShrink: 0,
                zIndex: 11,
                ['& > div']: {
                    top: '55px',
                    height: 'calc(var(--vh, 1vh) * 100 - 55px)',
                    overflowY: 'auto',
                    width: !mobileQuery ? drawerWidth : '100%',
                    boxSizing: 'border-box',
                    borderRadius: 0,
                    zIndex: 11,
                },
            }}
            anchor={'left'}
            variant={'persistent'}
            open={open}
            onClose={onClose}
        >
            <Box role="presentation" padding={'24px 16px'} position={'relative'} height={'100%'}>
                {menu.results.length > 0 ? (
                    <>
                        {isEdit ? (
                            <>
                                <SidebarEditNavList />
                                <SidebarEditHideMenu />
                            </>
                        ) : (
                            <SidebarNavList setSidebarOpen={setSidebarOpen} />
                        )}
                    </>
                ) : null}
                {!isEdit ? <SidebarAppList setSidebarOpen={setSidebarOpen} /> : null}
                <Box>
                    {isEdit ? (
                        <SidebarEditFooter handleEdit={handleEdit} />
                    ) : (
                        <SidebarFooter onClose={onClose} handleEdit={handleEdit} />
                    )}
                </Box>
            </Box>
        </Drawer>
    );
};
