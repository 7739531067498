import { Avatar, Box, Button, createTheme, PaletteMode, ThemeProvider, Typography } from '@mui/material';
import { avatarSx } from '../ChatBarDialog/ChatBarDialog.styled';
import React, { Dispatch, FC, SetStateAction, useMemo, useState } from 'react';
import { getDesignTokens } from '../../../../shared/ui';
import { TChatNotification } from '../../../../shared/api/types';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import { stringToHslColor } from '../../../../shared/helpers/stringToHslColor';
import { useChatStorage } from '../../lib/hooks/useChatStorage';
import { useTranslation } from 'react-i18next';

interface ChatSnackbarSystemProps {
    notification: TChatNotification;
    setOpen: Dispatch<SetStateAction<boolean>>;
    onSelectedGroup: (group: string) => void;
}
export const ChatSnackbarSystem: FC<ChatSnackbarSystemProps> = ({
    notification,
    setOpen,
    onSelectedGroup,
}) => {
    const { t } = useTranslation();
    const [mode] = useState<PaletteMode>('light');
    const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);
    const { closeSnackbar } = useSnackbar();
    const { subscribeToChatChannel } = useChatStorage();
    const onOpen = () => {
        setOpen(true);
        onSelectedGroup('client');
        subscribeToChatChannel(notification.channel.name);
        closeSnackbar();
    };
    return (
        <ThemeProvider theme={theme}>
            <Box display={'flex'} alignItems={'center'}>
                <Box display={'flex'} alignItems={'center'} marginRight={1.5}>
                    <Avatar
                        sx={{
                            ...avatarSx,
                            backgroundColor: stringToHslColor(notification?.user?.name, 70, 45),
                        }}
                        variant="rounded"
                        src={notification?.user?.avatar || ''}
                    />
                    <Box marginLeft={1.5}>
                        <Typography variant={'subtitle2'} color={'rgba(0, 0, 0, 0.87)'}>
                            {t('systemMessage')}
                        </Typography>
                        <Typography variant={'body2'} color={'rgba(0, 0, 0, 0.6)'}>
                            {t('chatRedirectToManager')}
                        </Typography>
                    </Box>
                </Box>
                <Box display={'flex'} flexDirection={'column'}>
                    <Typography variant={'body2'} textAlign={'center'} color={'rgba(0, 0, 0, 0.6)'}>
                        {dayjs(notification.channel.created_at).format('DD.MM.YYYY')}
                    </Typography>
                    <Button size={'small'} onClick={onOpen}>
                        {t('open')}
                    </Button>
                </Box>
            </Box>
        </ThemeProvider>
    );
};
