import { Rename } from 'shared/api/types';
import { Nullable } from 'shared/types';

export const FieldValueTypes = {
    Int: 'int',
    Float: 'float',
    Str: 'str',
    Bool: 'bool',
    Date: 'date',
    Datetime: 'datetime',
    Time: 'time',
    Uuid: 'uuid',
    Fk: 'fk',
    FkEmployee: 'fk_employee',
    FkDepartment: 'fk_department',
    M2m: 'm2m',
    M2mEmployee: 'm2m_employee',
    M2mDepartment: 'm2m_department',
    Link: 'link',
    Select: 'select',
    MultiSelect: 'multi_select',
    SelectWithSearch: 'select-with-search',
    Phone: 'phone',
    Email: 'email',
    StringList: 'list(string)',
    SystemFk: 'system_fk',
    SystemM2m: 'system_m2m',
    SystemSelectFunnel: 'system_select_funnel',
    SystemSelectFunnelStage: 'system_select_funnel_stage',
    Children: 'children',
    File: 'file',
    System: 'system',
} as const;

export type FieldValueType = typeof FieldValueTypes[keyof typeof FieldValueTypes];

export type FieldSettingsType = {
    options?: Array<{ [key: string]: any }>;
    required?: boolean;
    [key: string]: any;
};

export interface Field {
    help_text?: string;
    group: Nullable<string>;
    id: string;
    name: string;
    placeholder: string;
    type: 'custom' | 'system';
    settings: FieldSettingsType;
    value_type: FieldValueType;
    order?: number;
    ordering: boolean;
    hidden: boolean;
    group_label: string;
    value?: Nullable<string>;
    code: string;
}

export type IOption = {
    id: string;
    name: string;
};

export type IOptionFullName = Rename<IOption, 'name', 'fullname'>;

export interface TFields {
    id?: string;
    settings?: TFieldsSettings;
    entity?: string;
    field?: string;
    group?: string;
    required?: boolean;
    order?: number;
    name?: string;
    placeholder?: string;
    help_text?: string;
    value_type?: string;
    code?: string;
}

export type TFieldsGroups = {
    id: string;
    name: string;
    code: string;
    order: number;
    fields?: any;
};

export type TFieldsMeta = {
    id: string;
    name: string;
    description: string;
    code: string;
    value_type: string;
    type: string;
    placeholder: string;
    settings: TFieldsSettings;
    is_deleted: boolean;
    deleted_at: string;
    help_text: string;
    entity: string;
    group: string;
    departments: IOption[];
    employees: IOptionFullName[];
    roles: IOption[];
    required_fields: Array<{ uuid: string; name: string }>;
};

export type FieldsGroup = {
    order: number;
    name: string;
    fields: Field[];
    isEdit: boolean;
    id: Nullable<string>;
};
export type TFieldsSettings = {
    default_value?: string | boolean;
    many?: boolean;
    max?: string;
    min?: string;
    show_unfilled?: boolean;
    required?: boolean;
    positive?: boolean;
    entity?: string;
    options?: Array<Record<string, string>>;
    unique?: boolean;
};

export type SimpleFieldsGroup = {
    id: string;
    name: string;
    order: number;
    entity: string;
    tempId?: string;
};
