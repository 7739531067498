import { Button, Grid, IconButton, Typography, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import SelectFields from 'widgets/SelectFields/SelectFields';
import { useMetaStorage } from 'services/StorageAdapter';
import { useEffect, useState } from 'react';
import { Field } from 'domain/field';
import { useTranslation } from 'react-i18next';
import { taskFields } from 'widgets/Filter/lib/const/taskFields';
import { MOBILE_QUERY_WIDTH } from 'shared/consts';

interface IFilterPresetFields {
    onCloseFilter: () => void;
    onCloseFields: () => void;
    onSetFields: (selected: string[]) => void;
}

const FilterPresetFields = ({ onCloseFilter, onCloseFields, onSetFields }: IFilterPresetFields) => {
    const { t } = useTranslation();
    const { meta } = useMetaStorage();
    const [data, setData] = useState<Field[]>([]);
    const [selected, setSelected] = useState<string[]>([]);
    const isMobile = useMediaQuery(MOBILE_QUERY_WIDTH);
    const isTask = Boolean(meta.code === 'tasks');

    const onApply = () => {
        onSetFields(selected);
        onCloseFields();
    };

    const checkMetaField = (field_id: string) => {
        const views = selected?.find((field) => field === field_id)
            ? [...selected?.filter((field) => field !== field_id)]
            : [...selected, field_id];
        const uniqueViews = [...new Set(views)];
        setSelected(uniqueViews);
    };

    const checkAllMetaField = () => {
        const views: string[] =
            meta?.views
                ?.find((view) => view?.view_type === meta?.tab)
                ?.entity_fields.map((field) => field.id) || [];
        const taskViews: string[] = taskFields?.map((field) => field.id) || [];
        setSelected(isTask ? taskViews : views);
    };

    const handleSearch = (text: string) => {
        if (text.length) {
            setData(
                isTask
                    ? taskFields
                    : meta.views
                          .find((view) => view.view_type === meta.tab)
                          ?.entity_fields.filter((field) =>
                              field.name.toLowerCase().includes(text.toLowerCase())
                          ) || []
            );
        } else {
            setData(
                isTask
                    ? taskFields
                    : meta.views.find((view) => view.view_type === meta.tab)?.entity_fields || []
            );
        }
    };

    useEffect(() => {
        setData(
            isTask ? taskFields : meta.views.find((view) => view.view_type === meta.tab)?.entity_fields || []
        );
    }, [meta.tab]);

    return (
        <>
            <Grid item xs={12} display="flex" flexDirection="column">
                <Grid
                    px={2}
                    py={1}
                    container
                    width="100%"
                    alignItems={'center'}
                    justifyContent="space-between"
                    borderBottom={'1px solid #0000001F'}
                    flex="0 0 auto"
                >
                    <Grid item xs={11} gap={1} display={'flex'} alignItems={'center'}>
                        <IconButton size="small" onClick={onCloseFields}>
                            <ArrowBackIosNewRoundedIcon fontSize={'small'} />
                        </IconButton>
                        <Typography variant="h6">{t('settingsSetFields')}</Typography>
                    </Grid>
                    <Grid item xs={1} display={'flex'} justifyContent={'end'} alignItems={'center'}>
                        <IconButton size="small" onClick={onCloseFilter}>
                            <CloseIcon fontSize={'small'} />
                        </IconButton>
                    </Grid>
                </Grid>

                <Grid
                    item
                    width={'100%'}
                    overflow={'scroll'}
                    flex="1 1 auto"
                    height={isMobile ? 'calc(100vh - 195px)' : '383px'}
                >
                    <SelectFields
                        data={data}
                        onCheck={checkMetaField}
                        onSearch={handleSearch}
                        selected={selected}
                    />
                </Grid>

                <Grid
                    item
                    py={1}
                    px={2}
                    gap={1}
                    width="100%"
                    display="flex"
                    justifyContent="space-between"
                    borderTop={'1px solid #0000001F'}
                    flex="0 0 auto"
                >
                    {isMobile ? (
                        <Button
                            variant={'text'}
                            color="primary"
                            size="small"
                            onClick={() =>
                                data?.length !== selected?.length ? checkAllMetaField() : setSelected([])
                            }
                        >
                            {data?.length !== selected?.length ? t('selectAll') : t('removeSelection')}
                        </Button>
                    ) : (
                        <Grid item gap={1} display="flex">
                            <Button
                                variant={'text'}
                                color="primary"
                                size="small"
                                onClick={checkAllMetaField}
                                disabled={data?.length === selected?.length}
                            >
                                {t('selectAll')}
                            </Button>
                            <Button
                                variant={'text'}
                                color="primary"
                                size="small"
                                onClick={() => setSelected([])}
                                disabled={data?.length !== selected?.length}
                            >
                                {t('removeSelection')}
                            </Button>
                        </Grid>
                    )}
                    <Button onClick={onApply} variant={'contained'} color="primary" size="small">
                        {t('apply')}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default FilterPresetFields;
