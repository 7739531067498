import { createEffect, createEvent, createStore } from 'effector';
import { IFilterApiService, TFilter } from '../types/filter';
import { filterApiService } from '../api/services/filterService';
import { TFilterResponse, TParamsRequest } from 'shared/api/types';

const initialFiltersStore: TFilterResponse = {
    next: '',
    previous: '',
    count: 0,
    results: [],
    params: {
        offset: 0,
        limit: 10,
    },
};

const initialFilterStore: TFilter = {
    name: '',
    filters: {},
    type: '',
    user: '',
    entity: '',
};

const api: IFilterApiService = filterApiService();

const getFiltersUsersFx = createEffect(async (params: TParamsRequest): Promise<TFilterResponse> => {
    return await api.getFiltersUsers(params);
});
const getFilterUsersFx = createEffect(async (id: string): Promise<TFilter> => {
    return await api.getFilterUsers(id);
});
const createFilterUsersFx = createEffect(async (params: TFilter): Promise<TFilter> => {
    return await api.createFilterUsers(params);
});
const updateFilterUsersFx = createEffect(async (params: TFilter): Promise<TFilter> => {
    return await api.updateFilterUsers(params);
});
const deleteFilterUsersFx = createEffect(async (id: string): Promise<TFilter> => {
    return await api.deleteFilterUsers(id);
});

const updateFiltersStore = createEvent<TFilterResponse>();
const clearFiltersStore = createEvent();

const updateFilterStore = createEvent<TFilter>();
const clearFilterStore = createEvent();

const $filtersStore = createStore<TFilterResponse>(initialFiltersStore)
    .on(updateFiltersStore, (_, payload) => payload)
    .on(getFiltersUsersFx.doneData, (_, payload) => payload)
    .reset(clearFiltersStore);

const $filterStore = createStore<TFilter>(initialFilterStore)
    .on(updateFilterStore, (_, payload) => payload)
    .on(getFilterUsersFx.doneData, (_, payload) => payload)
    .reset(clearFilterStore);

export const filterStores = { $filtersStore, $filterStore };

export const filterEffects = {
    getFiltersUsersFx,
    getFilterUsersFx,
    createFilterUsersFx,
    updateFilterUsersFx,
    deleteFilterUsersFx,
};

export const filterEvents = {
    updateFilterStore,
    clearFilterStore,
    updateFiltersStore,
    clearFiltersStore,
};
