import React, { FC, memo } from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import { Button, CheckBox, FormField, Input } from '../../../../shared/ui';
import { useFormik } from 'formik';
import { createMenuItemSchema } from '../../lib/validation/createMenuItemSchema';
import { useMenuStorage } from '../../lib/hooks/useMenuStorage';
import { useTranslation } from 'react-i18next';

interface SidebarAddedNewItemFormProps {
    handleClose: () => void;
}

interface IValues {
    name: string;
    uri: string;
    hide_in_another_tab: boolean;
}
export const SidebarAddedNewItemForm: FC<SidebarAddedNewItemFormProps> = memo(({ handleClose }) => {
    const { t } = useTranslation();
    const { createMenuItem, fetchMenu, menu } = useMenuStorage();
    const onSubmit = async (values: IValues) => {
        const { name, uri, hide_in_another_tab } = values;
        const systemMenu = menu.results.find((m) => m.system);
        await createMenuItem({ name, uri, menu: systemMenu?.id || '', order: 0 })
            .then(() => {
                fetchMenu();
                if (hide_in_another_tab) {
                    window.open(uri, '_blank');
                }
            })
            .finally(() => handleClose());
    };
    const { values, errors, touched, handleSubmit, handleChange, handleBlur, isSubmitting, isValid } =
        useFormik({
            initialValues: {
                name: '',
                uri: '',
                hide_in_another_tab: false,
            },
            validateOnBlur: true,
            validationSchema: createMenuItemSchema,
            onSubmit,
        });
    return (
        <form onSubmit={handleSubmit}>
            <Grid container gap={2}>
                <FormField
                    label={t('linkName')}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name ? errors.name : ''}
                    required
                >
                    <Input
                        name={'name'}
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={t('enterLinkName')}
                        sx={{ mb: -1 }}
                        fullWidth
                    />
                </FormField>
                <FormField
                    label={t('linkUrl')}
                    error={touched.uri && Boolean(errors.uri)}
                    helperText={touched.uri ? errors.uri : ''}
                    required
                >
                    <Input
                        name={'uri'}
                        value={values.uri}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={'http://www'}
                        sx={{ mb: -1 }}
                        fullWidth
                    />
                </FormField>
                {!errors.uri ? (
                    <Typography
                        fontWeight={400}
                        fontSize={12}
                        lineHeight={'20px'}
                        letterSpacing={'0.4px'}
                        color={'rgba(0, 0, 0, 0.6)'}
                        ml={1.5}
                        mt={-2}
                    >
                        {t('uriHelperText', { origin: window.location.origin })}
                    </Typography>
                ) : null}
                <CheckBox
                    name={'hide_in_another_tab'}
                    label={t('openInNewTab')}
                    value={values.hide_in_another_tab}
                    checked={values.hide_in_another_tab}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </Grid>
            <Divider sx={{ my: 3 }} />
            <Grid container justifyContent={'end'} spacing={2} gap={2}>
                <Button
                    type={'submit'}
                    variant={'contained'}
                    size={'medium'}
                    loading={isSubmitting}
                    disabled={isSubmitting || !isValid}
                >
                    {t('save')}
                </Button>
                <Button type={'button'} variant={'outlined'} size={'medium'} onClick={handleClose}>
                    {t('cancel')}
                </Button>
            </Grid>
        </form>
    );
});
