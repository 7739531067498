export const lightThemePalette = {
    text: {
        primary: '#000000de',
        secondary: '#00000099',
        disabled: '#00000061',
        light: '#00000099',
    },
    primary: {
        main: '#2E7D32',
        dark: '#66BB6A',
        light: '#8375FF',
        contrastText: '#FFFFFF',
    },
    secondary: {
        main: '#595959',
        dark: '#7B1FA2',
        light: '#BA68C8',
        contrastText: '#FFFFFF',
    },
    error: {
        main: '#D32F2F',
        dark: '#C62828',
        light: '#EF5350',
        contrastText: '#FFFFFF',
    },
    warning: {
        main: '#ED6C02',
        dark: '#E65100',
        light: '#FF9800',
        contrastText: '#FFFFFF',
    },
    info: {
        main: '#0288D1',
        dark: '#01579B',
        light: '#03A9F4',
        contrastText: '#FFFFFF',
    },
    success: {
        main: '#2E7D32',
        dark: '#1B5E20',
        light: '#4CAF50',
        contrastText: '#FFFFFF',
    },
    action: {
        active: '#0000008a',
        hover: '#0000000a',
        selected: '#00000014',
        disabled: '#00000042',
        disabledBackground: '#0000001f',
        focus: '#0000001f',
        activatedOpacity: 0.12,
        disabledOpacity: 0.12,
        focusOpacity: 0.12,
        hoverOpacity: 0.12,
        selectedOpacity: 0.12,
    },
    navigation: '#FFFFFF',
    outlineBorder: '#00000029',
    divider: '#0000001f',
};

export const darkThemePalette = {
    text: {
        primary: '#FFFFFF',
        secondary: '#FFFFFFB2',
        disabled: '#FFFFFF80',
        light: '#00000099',
    },
    primary: {
        main: '#2E7D32',
        dark: '#4D3DDB',
        light: '#E3F2FD',
        contrastText: '#FFFFFFDE',
    },
    secondary: {
        main: '#CE93D8',
        dark: '#AB47BC',
        light: '#F3E5F5',
        contrastText: '#000000DE',
    },
    error: {
        main: '#F44336',
        dark: '#D32F2F',
        light: '#E57373',
        contrastText: '#FFFFFF',
    },
    warning: {
        main: '#FFA726',
        dark: '#F57C00',
        light: '#FFB74D',
        contrastText: '#000000DE',
    },
    info: {
        main: '#29B6F6',
        dark: '#0288D1',
        light: '#4FC3F7',
        contrastText: '#000000DE',
    },
    success: {
        main: '#66BB6A',
        dark: '#388E3C',
        light: '#81C784',
        contrastText: '#000000DEF',
    },
    action: {
        active: '#FFFFFF8F',
        hover: '#FFFFFF14',
        selected: '#FFFFFF29',
        disabled: '#FFFFFF4D',
        disabledBackground: '#FFFFFF1F',
        focus: '#FFFFFF1F',
        activatedOpacity: 0.12,
        disabledOpacity: 0.12,
        focusOpacity: 0.12,
        hoverOpacity: 0.12,
        selectedOpacity: 0.12,
    },
    navigation: '#2A2A2A',
    divider: '#FFFFFF1F',
    outlineBorder: '#FFFFFF3B',
    standardInputLine: '#121212',
    ratingActive: '#FFB400',
    snackBarBackground: '#323232',
};
