import { useMemo, useState } from 'react';
import LoginBg from '../../../assets/image/image.webp';
import {
    Box,
    Button,
    createTheme,
    CssBaseline,
    Grid,
    PaletteMode,
    ThemeProvider,
    Typography,
} from '@mui/material';
import { WarningRounded } from '@mui/icons-material';
import { getDesignTokens } from '../../../shared/ui';
import { useTranslation } from 'react-i18next';

export const InternalServerError = () => {
    const { t } = useTranslation();
    const { t: internalServerT } = useTranslation('internalServerError');
    const [mode] = useState<PaletteMode>('light');
    const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);
    const onReload = () => window.location.reload();
    const onRedirect = () => {
        window.location.href = '/';
    };
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Grid
                container
                height={'100vh'}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{
                    background: `url("${LoginBg}")`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                }}
            >
                <Box
                    sx={{
                        background: 'white',
                        borderRadius: '12px',
                        padding: '32px 24px',
                        border: '1px solid rgba(0, 0, 0, 0.16)',
                        boxShadow:
                            '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
                        maxWidth: '600px',
                        position: 'relative',
                        overflow: 'hidden',
                    }}
                >
                    <Box
                        width={59}
                        height={59}
                        sx={{ backgroundColor: 'rgba(237, 108, 2, 0.12)' }}
                        borderRadius={'8px'}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                    >
                        <WarningRounded color={'warning'} sx={{ width: 35, height: 35 }} />
                    </Box>
                    <Typography variant={'h6'} marginTop={1.5}>
                        {internalServerT('title')}
                    </Typography>
                    <Typography variant={'body1'} marginTop={'3px'}>
                        {internalServerT('desc')}
                    </Typography>
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        size={'large'}
                        fullWidth
                        sx={{ marginTop: 3 }}
                        onClick={onReload}
                    >
                        {t('reloadPage')}
                    </Button>
                    <Button
                        variant={'outlined'}
                        color={'primary'}
                        size={'large'}
                        fullWidth
                        sx={{ marginTop: 1.5 }}
                        onClick={onRedirect}
                    >
                        {t('goBackMain')}
                    </Button>
                </Box>
            </Grid>
        </ThemeProvider>
    );
};
