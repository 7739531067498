import { createEffect, createEvent, createStore } from 'effector';
import dayjs, { Dayjs } from 'dayjs';
import { entityService } from 'shared/api/entity';
import { taskEffects } from 'services/store/tasks/tasks';

export interface ICalendarState {
    date: Dayjs;
    filter: string;
}
export interface ITask {
    id: string;
    deadline?: string;
    title: string;
}

const fetchTasksFx = createEffect(async (params: any) => {
    return await entityService.getEntities('tasks', params);
});

const fetchTasksWithoutDeadlineFx = createEffect(async (params: any) => {
    return await entityService.getEntities('tasks', { ...params, no_deadline: true });
});

const setCalendarState = createEvent<Partial<ICalendarState>>();

const $calendarState = createStore<ICalendarState>({
    date: dayjs(),
    filter: '',
}).on(setCalendarState, (state, newState) => ({ ...state, ...newState }));

const { updateTaskDataFx } = taskEffects;

const $tasksWithDeadline = createStore<ITask[]>([])
    .on(fetchTasksFx.doneData, (_, data) => {
        return data.results;
    })
    .on(updateTaskDataFx.doneData, (state, payload) => {
        return state.map((task) => {
            if (task.id === payload.id) {
                return { ...task, ...payload };
            }
            return task;
        });
    });
const $tasksWithoutDeadline = createStore<ITask[]>([])
    .on(fetchTasksWithoutDeadlineFx.doneData, (_, data) => {
        return data.results;
    })
    .on(updateTaskDataFx.doneData, (state, payload) => {
        return state.map((task) => {
            if (task.id === payload.id) {
                return { ...task, ...payload };
            }
            return task;
        });
    });

export const calendarStores = {
    $calendarState,
    $tasksWithDeadline,
    $tasksWithoutDeadline,
};

export const calendarEvents = {
    setCalendarState,
};

export const calendarEffects = {
    fetchTasksFx,
    fetchTasksWithoutDeadlineFx,
};
