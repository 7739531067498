import * as React from 'react';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { Box, Divider, Grid, Menu, MenuItem, TableSortLabel, Typography } from '@mui/material';
import { ITableDropdown } from './TableDropdown';
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from '@hello-pangea/dnd';
import DragIndicatorRoundedIcon from '@mui/icons-material/DragIndicatorRounded';
import { reorder } from 'shared/helpers/reorder';

type Order = 'asc' | 'desc';

interface EnhancedTableProps {
    numSelected: number;
    changeHeaders?: (result: any[]) => void;
    onRequestSort?: (property: any) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onClearAllClick: () => void;
    order?: Order;
    orderBy?: string;
    rowCount: number;
    edit: boolean;
    header: any[];
    system_header?: any[];
    settings?: ITableDropdown[];
    show_selected?: boolean;
    dnd: boolean;
    dndHead: boolean;
}

export default function EnhancedTableHead(props: EnhancedTableProps) {
    const {
        onSelectAllClick,
        onClearAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        edit,
        settings,
        show_selected = true,
        onRequestSort,
        changeHeaders,
        dnd = false,
        dndHead = false,
        system_header,
        header,
    } = props;
    const createSortHandler = (property: any) => () => {
        onRequestSort?.(property);
    };

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const onDragEnd: OnDragEndResponder = async (result) => {
        if (!result.destination) return;
        const updatedItems = reorder(header, result.source.index, result.destination?.index);
        changeHeaders?.(updatedItems);
    };
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="table" type="list" direction="horizontal">
                {(provided) => (
                    <TableHead ref={provided.innerRef} {...provided.droppableProps}>
                        <TableRow sx={{ pointerEvents: edit ? 'none' : 'auto' }}>
                            {dnd && (
                                <TableCell
                                    padding="checkbox"
                                    sx={{ width: '2.6%' }}
                                    align="center"
                                ></TableCell>
                            )}
                            {show_selected ? (
                                <TableCell padding="checkbox" sx={{ width: '2%' }} align="center">
                                    {numSelected > 0 && numSelected < rowCount ? (
                                        <Checkbox
                                            color="primary"
                                            indeterminate={true}
                                            onChange={onClearAllClick}
                                            inputProps={{
                                                'aria-label': 'select all desserts',
                                            }}
                                            disabled={edit}
                                        />
                                    ) : (
                                        <Checkbox
                                            color="primary"
                                            checked={rowCount > 0 && numSelected === rowCount}
                                            onChange={onSelectAllClick}
                                            inputProps={{
                                                'aria-label': 'select all desserts',
                                            }}
                                            disabled={edit}
                                        />
                                    )}
                                </TableCell>
                            ) : null}
                            <TableCell padding="checkbox" sx={{ width: '2%' }}>
                                {!!settings?.length && (
                                    <>
                                        <IconButton
                                            size="small"
                                            onClick={handleOpen}
                                            color={open ? 'primary' : 'default'}
                                            disabled={edit}
                                        >
                                            <SettingsIcon fontSize={'inherit'} />
                                        </IconButton>
                                        <Menu
                                            anchorEl={anchorEl}
                                            open={!!open}
                                            onClose={handleClose}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                        >
                                            {settings.map((setting, key) => (
                                                <>
                                                    {setting.divider && <Divider />}
                                                    {!setting.disabled && (
                                                        <MenuItem
                                                            onClick={() => {
                                                                setting?.func?.();
                                                                handleClose();
                                                            }}
                                                            key={key}
                                                        >
                                                            <Grid
                                                                container
                                                                spacing={2}
                                                                justifyContent="space-between"
                                                            >
                                                                <Grid item>{setting.title}</Grid>
                                                                <Grid item>
                                                                    {setting.checked && (
                                                                        <CheckRoundedIcon color="primary" />
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </MenuItem>
                                                    )}
                                                </>
                                            ))}
                                        </Menu>
                                    </>
                                )}
                            </TableCell>
                            {system_header?.length &&
                                system_header?.map((headCell: any) => (
                                    <TableCell
                                        key={headCell.id}
                                        align={'left'}
                                        padding={'checkbox'}
                                        sortDirection={orderBy === headCell.id ? order : false}
                                        sx={{ whiteSpace: 'nowrap', p: '15px' }}
                                    >
                                        {order ? (
                                            <TableSortLabel
                                                active={orderBy === headCell.id}
                                                direction={orderBy === headCell.id ? order : 'asc'}
                                                onClick={createSortHandler(headCell.id)}
                                                disabled={edit}
                                            >
                                                {headCell.name}
                                            </TableSortLabel>
                                        ) : (
                                            <Typography variant="body2" fontWeight={500}>
                                                {headCell.name}
                                            </Typography>
                                        )}
                                    </TableCell>
                                ))}
                            {header?.length ? (
                                header?.map((headCell: any, idx: number) => (
                                    <TableCell
                                        key={headCell.id}
                                        align={'left'}
                                        padding={'checkbox'}
                                        sortDirection={orderBy === headCell.id ? order : false}
                                        sx={{ whiteSpace: 'nowrap', p: '15px' }}
                                    >
                                        <Draggable key={headCell.id} draggableId={headCell.id} index={idx}>
                                            {(provided) => (
                                                <Box
                                                    display="flex"
                                                    alignItems="center"
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                >
                                                    {dndHead ? (
                                                        <Box {...provided.dragHandleProps}>
                                                            <DragIndicatorRoundedIcon
                                                                sx={{
                                                                    ml: -1,
                                                                    mt: 0.5,
                                                                    zIndex: -1,
                                                                }}
                                                                color={'action'}
                                                            />
                                                        </Box>
                                                    ) : null}
                                                    {order ? (
                                                        <TableSortLabel
                                                            active={orderBy === headCell.id}
                                                            direction={
                                                                orderBy === headCell.id ? order : 'asc'
                                                            }
                                                            onClick={createSortHandler(headCell.id)}
                                                            disabled={edit}
                                                        >
                                                            {headCell.name}
                                                        </TableSortLabel>
                                                    ) : (
                                                        <Typography variant="body2" fontWeight={500}>
                                                            {headCell.name}
                                                        </Typography>
                                                    )}
                                                </Box>
                                            )}
                                        </Draggable>
                                    </TableCell>
                                ))
                            ) : (
                                <TableCell
                                    align={'left'}
                                    padding={'checkbox'}
                                    sx={{ whiteSpace: 'nowrap', p: '15px' }}
                                ></TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                )}
            </Droppable>
        </DragDropContext>
    );
}
