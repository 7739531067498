import { createEffect, createEvent, createStore } from 'effector';
import { Entity } from 'domain/entity';
import { IEntityApiService } from 'application/ports/Api';
import { entityApiService } from 'services/ApiAdapter';

const initialEntityStore: Entity = {
    id: '',
    fieldGroups: [],
    permissions: [],
};

const fetchEntityFx = createEffect(async ({ code, id }: { code: string; id: string }): Promise<any> => {
    const entityApi: IEntityApiService = entityApiService();

    return entityApi.getEntity(code, id);
});

const fetchEntityCreateFx = createEffect(
    async ({ code, params, funnel_id }: { code: string; params: any; funnel_id: string }): Promise<any> => {
        const entityApi: IEntityApiService = entityApiService();
        const newArray: any = {};
        params.map((stage: any) => {
            stage.fields.map((field: any) => {
                newArray[`${field.id}`] = field.value;
                newArray['funnel'] = funnel_id;
            });
        });
        return entityApi.createEntity(code, newArray);
    }
);

const fetchEntityUpdateFx = createEffect(
    async ({ code, params, id }: { code: string; params: any; id: string }): Promise<any> => {
        const entityApi: IEntityApiService = entityApiService();
        const newArray: any = {};
        params.map((stage: any) => {
            stage.fields.map((field: any) => {
                newArray[`${field.id}`] = field.value;
            });
        });
        return entityApi.updateEntity(code, id, newArray);
    }
);

const exportDataFx = createEffect(
    async ({
        code,
        ...data
    }: {
        code: string;
        all_fields: boolean;
        format: string;
        funnel_id: string;
    }): Promise<any> => {
        const entityApi: IEntityApiService = entityApiService();

        return entityApi.exportData({ code, ...data });
    }
);

const updateEntity = createEvent<Entity>();
const clearEntity = createEvent();

const $entityStore = createStore<Entity>(initialEntityStore)
    .on(updateEntity, (_, newEntity) => newEntity)
    .on(fetchEntityFx.doneData, (oldEntity, newEntity) => {
        return {
            ...oldEntity,
            id: newEntity.id,
            fieldGroups: oldEntity.fieldGroups.map((field) => {
                return {
                    ...field,
                    isEdit: false,
                    fields: field.fields.map((fld) => {
                        return { ...fld, value: newEntity[fld.id] };
                    }),
                };
            }),
        };
    })
    .on(fetchEntityCreateFx.doneData, (oldEntity, newEntity) => {
        return {
            ...oldEntity,
            id: newEntity.id,
            fieldGroups: oldEntity.fieldGroups.map((field) => {
                return { ...field, isEdit: false };
            }),
        };
    })
    .on(fetchEntityUpdateFx.doneData, (oldEntity, newEntity) => {
        return {
            ...oldEntity,
            id: newEntity.id,
            fieldGroups: oldEntity.fieldGroups.map((field) => {
                return { ...field, isEdit: false };
            }),
        };
    })
    .reset(clearEntity);

export const entityStores = {
    $entityStore,
};

export const entityEffects = {
    fetchEntityFx,
    fetchEntityCreateFx,
    fetchEntityUpdateFx,
    exportDataFx,
};

export const entityEvents = {
    updateEntity,
    clearEntity,
};
