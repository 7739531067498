import { FC, memo, useEffect } from 'react';
import { Avatar, Chip, Divider, Grid, Stack, Typography } from '@mui/material';
import { Label } from '../../../../shared/ui';
import { notSpecified } from '../../lib/helpers/notSpecified';
import { useUsersStorage } from '../../../../services/StorageAdapter';
import { useTranslation } from 'react-i18next';

interface ProfileAdditionalInfoProps {
    open: boolean;
}
export const ProfileAdditionalInfo: FC<ProfileAdditionalInfoProps> = memo(({ open }) => {
    const { t } = useTranslation();
    const { fetchHead, head, user } = useUsersStorage();

    useEffect(() => {
        if (user.department && open) {
            fetchHead(user.department.head || '');
        }
    }, [user.department, open]);
    return (
        <Grid
            px={'24px'}
            py={'28px'}
            sx={{ border: '1px solid rgba(0, 0, 0, 0.16)', borderRadius: '12px', mb: '24px' }}
        >
            <Typography variant={'h6'}>{t('additionalInfo')}</Typography>
            <Divider sx={{ my: '24px' }} />
            <Label label={t('supervisor')} />
            {head ? (
                <Stack direction="row" spacing={1}>
                    <Chip
                        avatar={<Avatar alt={`${head.first_name} ${head.last_name}`} src={head.avatar} />}
                        label={`${head.first_name} ${head.last_name}`}
                        variant="outlined"
                    />
                </Stack>
            ) : (
                notSpecified('24px')
            )}
        </Grid>
    );
});
