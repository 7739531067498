import React, { ReactElement } from 'react';
import { Card as MuiCard, CardContent, CardProps as MuiCardProps, Divider, Grid } from '@mui/material';
import { EditableTitle } from 'shared/ui/index';

type CardHeadProps = {
    title?: string;
    controls?: ReactElement[];
    hint?: string;
    editable?: boolean;
    onTitleChange?: (newTitle: string) => void;
};

type CardProps = {
    py?: string;
    px?: string;
} & MuiCardProps;

const CardHead = ({ title, controls, hint, editable, onTitleChange }: CardHeadProps) => {
    return (
        <Grid container flexDirection={'column'} gap={'12px'}>
            <Grid item container justifyContent={'space-between'} alignItems={'center'} flexWrap={'nowrap'}>
                <EditableTitle hint={hint} editable={editable} onChange={onTitleChange} title={title} />
                <Grid item>{controls}</Grid>
            </Grid>
            <Divider variant={'fullWidth'} />
        </Grid>
    );
};

type CardFooterProps = {
    leftSide?: ReactElement[] | ReactElement;
    rightSide?: ReactElement[] | ReactElement;
};

const CardFooter = ({ leftSide, rightSide }: CardFooterProps) => {
    return (
        <Grid
            container
            flexDirection={'column'}
            gap={'12px'}
            flexWrap={'nowrap'}
            sx={{ display: { xs: 'flex', md: 'flex' } }}
        >
            <Divider variant={'fullWidth'} />
            <Grid item container width={'auto'} justifyContent={'space-between'} alignItems={'center'}>
                <Grid item>{leftSide}</Grid>
                <Grid item>{rightSide}</Grid>
            </Grid>
        </Grid>
    );
};

const Card = ({ children, py, px, ...props }: CardProps) => {
    return (
        <MuiCard {...props}>
            <CardContent sx={{ py, px }}>
                <Grid container flexDirection={'column'} gap={'12px'} alignItems={'flex-start'}>
                    {children}
                </Grid>
            </CardContent>
        </MuiCard>
    );
};

Card.Head = CardHead;
Card.Footer = CardFooter;

export default Card;
