import { useStore } from 'effector-react';
import { timelineEffects, timelineEvents, timelineStores } from '../../model/timeline';
import { ITimelineStorage } from '../../types/timeline';

const { $timelineStore } = timelineStores;
const { createCommentFx, updateCommentFx, deleteCommentFx, getTimelinesFx, getTimelinesUpdaterFx } =
    timelineEffects;
const { clearTimeline, updateTimeline } = timelineEvents;

export function useTimelineStorage(): ITimelineStorage {
    const timeline = useStore($timelineStore);
    const sendCommentLoading = useStore(createCommentFx.pending);
    const editCommentLoading = useStore(updateCommentFx.pending);
    const getCommentsLoading = useStore(getTimelinesFx.pending);

    return {
        timeline,
        createComment: createCommentFx,
        updateComment: updateCommentFx,
        deleteComment: deleteCommentFx,
        getTimelines: getTimelinesFx,
        getTimelinesUpdater: getTimelinesUpdaterFx,
        clearTimeline,
        sendCommentLoading,
        editCommentLoading,
        getCommentsLoading,
        updateTimeline,
    };
}
