export const currentPlaceholder: any = {
    fk_employee: 'Найти сотрудника',
    fk: 'Найти объект',
    m2m_employee: 'Найти сотрудников',
    m2m_department: 'Найти департаменты',
    fk_department: 'Найти департамент',
    uuid_many: 'Найти объекты',
    m2m: 'Найти объекты',
    uuid: 'Найти объект',
};
