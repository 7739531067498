import React from 'react';
import { TFieldProps } from 'widgets/DraggableField/Fields/types';
import dayjs, { Dayjs } from 'dayjs';
import { Button, DatePicker, DateTimePicker, TimePicker } from 'shared/ui';
import DefaultField from 'widgets/DraggableField/Fields/DefaultField/DefaultField';
import { Grid, IconButton } from '@mui/material';
import { Nullable } from 'shared/types';
import { CancelRounded } from '@mui/icons-material';
import AddCircleRounded from '@mui/icons-material/AddCircleRounded';
import { useTranslation } from 'react-i18next';

type DateTimeField = TFieldProps<Nullable<Dayjs | Dayjs[]>> & {
    format: 'time' | 'date' | 'datetime';
};

const DateTimeField = ({ field, format, value, setValue, error, edit }: DateTimeField) => {
    const { t } = useTranslation();
    const isMany = field.settings.many;
    return (
        <>
            {edit ? (
                <>
                    {isMany && Array.isArray(value) ? (
                        <Grid container flexDirection={'column'} gap={'8px'}>
                            {value.map((v, i) => {
                                return (
                                    <Grid key={i} container flexWrap={'nowrap'} gap={'8px'}>
                                        {format === 'date' ? (
                                            <DatePicker
                                                InputProps={{
                                                    error: !!error,
                                                }}
                                                placeholder={field.placeholder}
                                                onChange={(v) => {
                                                    const newVal: Nullable<any>[] = value.map((val) =>
                                                        val.isValid() ? val.format('YYYY-MM-DD') : null
                                                    );
                                                    const newDate = dayjs(v);
                                                    newVal[i] = newDate.isValid()
                                                        ? newDate.format('YYYY-MM-DD')
                                                        : null;
                                                    setValue?.(field.id, [...newVal]);
                                                }}
                                                value={dayjs(v)}
                                            />
                                        ) : format === 'time' ? (
                                            <TimePicker
                                                InputProps={{
                                                    error: !!error,
                                                }}
                                                placeholder={field.placeholder}
                                                onChange={(v) => {
                                                    const newVal: Nullable<any>[] = value.map((val) =>
                                                        val.isValid() ? val.format('HH:mm') : null
                                                    );
                                                    const newDate = dayjs(v);
                                                    newVal[i] = newDate.isValid()
                                                        ? newDate.format('HH:mm')
                                                        : null;
                                                    setValue?.(field.id, [...newVal]);
                                                }}
                                                value={dayjs(v, 'HH:mm')}
                                            />
                                        ) : (
                                            <DateTimePicker
                                                InputProps={{
                                                    error: !!error,
                                                }}
                                                placeholder={field.placeholder}
                                                onChange={(v) => {
                                                    const newVal: Nullable<any>[] = value.map((val) =>
                                                        val.isValid() ? val.format('YYYY-MM-DD HH:mm') : null
                                                    );
                                                    const newDate = dayjs(v);
                                                    newVal[i] = newDate.isValid()
                                                        ? newDate.format('YYYY-MM-DD HH:mm')
                                                        : null;
                                                    setValue?.(field.id, [...newVal]);
                                                }}
                                                value={dayjs(v)}
                                            />
                                        )}
                                        {i !== 0 && (
                                            <IconButton
                                                onClick={() => {
                                                    value.splice(i, 1);
                                                    setValue?.(field.id, [...value]);
                                                }}
                                                size={'small'}
                                            >
                                                <CancelRounded fontSize={'inherit'} />
                                            </IconButton>
                                        )}
                                    </Grid>
                                );
                            })}
                        </Grid>
                    ) : !Array.isArray(value) ? (
                        <>
                            {format === 'date' ? (
                                <DatePicker
                                    InputProps={{
                                        error: !!error,
                                    }}
                                    placeholder={field.placeholder}
                                    onChange={(value) => {
                                        const newDate = dayjs(value);
                                        setValue?.(
                                            field.id,
                                            newDate.isValid() ? newDate.format('YYYY-MM-DD') : value
                                        );
                                    }}
                                    value={dayjs(value)}
                                />
                            ) : format === 'time' ? (
                                <TimePicker
                                    InputProps={{
                                        error: !!error,
                                    }}
                                    placeholder={field.placeholder}
                                    onChange={(value) => {
                                        const newDate = dayjs(value);
                                        setValue?.(
                                            field.id,
                                            newDate.isValid() ? newDate.format('HH:mm') : value
                                        );
                                    }}
                                    value={dayjs(value, 'HH:mm')}
                                />
                            ) : (
                                <DateTimePicker
                                    InputProps={{
                                        error: !!error,
                                    }}
                                    placeholder={field.placeholder}
                                    onChange={(value) => {
                                        const newDate = dayjs(value);
                                        setValue?.(
                                            field.id,
                                            newDate.isValid() ? newDate.format('YYYY-MM-DD HH:mm') : value
                                        );
                                    }}
                                    value={dayjs(value)}
                                />
                            )}
                        </>
                    ) : (
                        <></>
                    )}
                    {isMany && Array.isArray(value) && (
                        <Grid mt={'8px'}>
                            <Button
                                startIcon={<AddCircleRounded />}
                                onClick={() => {
                                    setValue?.(field.id, [...value, '']);
                                }}
                            >
                                {t('add')}
                            </Button>
                        </Grid>
                    )}
                </>
            ) : (
                <>
                    {isMany && Array.isArray(value) ? (
                        <Grid container flexDirection={'column'} gap={'8px'}>
                            {value.map((v, i) => {
                                return (
                                    <>
                                        {v.isValid() ? (
                                            <>
                                                {format === 'date' ? (
                                                    <DefaultField
                                                        key={i}
                                                        field={field}
                                                        value={v.format('DD.MM.YYYY')}
                                                    />
                                                ) : format === 'time' ? (
                                                    <DefaultField
                                                        key={i}
                                                        field={field}
                                                        value={v.format('HH:mm')}
                                                    />
                                                ) : (
                                                    <DefaultField
                                                        key={i}
                                                        field={field}
                                                        value={v.format('DD.MM.YYYY HH:mm')}
                                                    />
                                                )}
                                            </>
                                        ) : (
                                            <DefaultField field={field} value={''} />
                                        )}
                                    </>
                                );
                            })}
                        </Grid>
                    ) : !Array.isArray(value) && value?.isValid() ? (
                        <>
                            {format === 'date' ? (
                                <DefaultField field={field} value={value.format('DD.MM.YYYY')} />
                            ) : format === 'time' ? (
                                <DefaultField field={field} value={value.format('HH:mm')} />
                            ) : (
                                <DefaultField field={field} value={value.format('DD.MM.YYYY HH:mm')} />
                            )}
                        </>
                    ) : (
                        <></>
                    )}
                </>
            )}
        </>
    );
};

export default DateTimeField;
