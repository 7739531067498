import React, { Fragment } from 'react';
import { TFieldProps } from 'widgets/DraggableField/Fields/types';
import { ConnectedObjectType } from 'pages/Tasks/List/Card/TaskListCard';
import { ConnectEntityModalSettingsType } from 'services/store/entityDetail/entityDetail';
import { Button, Chip } from 'shared/ui';
import { Grid } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import LinkIcon from '@mui/icons-material/Link';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type ManyToManyFieldProps = TFieldProps<ConnectedObjectType[]> & {
    openConnectModal?: (data: Omit<ConnectEntityModalSettingsType, 'isOpen'>) => void;
};

const ManyToManyField = ({ field, openConnectModal, value, setValue, edit }: ManyToManyFieldProps) => {
    const { t } = useTranslation();
    const code =
        field.value_type === 'm2m'
            ? field.settings.entity_code
            : field.value_type === 'm2m_department'
            ? 'departments'
            : field.value_type === 'm2m_employee' || field.value_type === 'system_m2m'
            ? 'department-employees'
            : 'uuid';
    const isClickable = field.value_type === 'm2m' || field.value_type === 'uuid';

    const navigate = useNavigate();

    const handleChipClick = (id: string, entity?: string) => {
        let url = `/entity`;
        if (field.settings.entity_code) {
            url += `/${field.settings.entity_code}`;
        } else {
            url += `/${entity}`;
        }
        if (field.settings.entity_code === 'tasks' || entity === 'tasks') {
            url += `/?task_id=${id}`;
        } else {
            url += `/${id}`;
        }
        navigate(url);
    };

    return (
        <>
            {edit ? (
                <Grid container flexDirection={'column'} alignItems={'flex-start'} gap={'12px'}>
                    {value && (
                        <Grid container gap={'8px'}>
                            {value.map((item: ConnectedObjectType, index) => {
                                const { name, user, id } = item;
                                const label = name
                                    ? name
                                    : user
                                    ? `${user.first_name} ${user.last_name}`
                                    : id;
                                return (
                                    <Fragment key={id}>
                                        {id && (
                                            <Chip
                                                label={label}
                                                actionIcon={<CancelIcon />}
                                                actionHandler={() => {
                                                    value.splice(index, 1);
                                                    setValue?.(field.id, [...value]);
                                                }}
                                            />
                                        )}
                                    </Fragment>
                                );
                            })}
                        </Grid>
                    )}
                    <Button
                        onClick={() => {
                            openConnectModal?.({
                                code,
                                type: field.value_type === 'uuid' ? 'uuid_many' : field.value_type,
                                handleSelect: (value) => {
                                    setValue?.(field.id, value);
                                },
                                selected: Array.isArray(value) ? value : [],
                            });
                        }}
                        startIcon={value ? <ChangeCircleIcon /> : <LinkIcon />}
                    >
                        {value
                            ? Array.isArray(value) && value.filter(Boolean).length > 0
                                ? t('change')
                                : t('bind')
                            : t('bind')}
                    </Button>
                </Grid>
            ) : (
                <Grid container gap={'8px'}>
                    {value?.map((item) => {
                        const { name, user, id, entity, code } = item;
                        const label = name ? name : user ? `${user.first_name} ${user.last_name}` : id;
                        return (
                            <Chip
                                onPointerUp={(e) => e.stopPropagation()}
                                onClick={
                                    isClickable
                                        ? () => {
                                              handleChipClick(id, entity || code);
                                          }
                                        : undefined
                                }
                                key={id}
                                label={label}
                            />
                        );
                    })}
                </Grid>
            )}
        </>
    );
};

export default ManyToManyField;
