import Api from 'shared/api/axios';
import { TResponse } from 'shared/api/types';

export const departmentService = {
    setUsers(departmentId: string, users: string[]) {
        return Api.post(`/api/v1/organizations/departments/${departmentId}/set-users`, {
            users,
        });
    },
    setRoles(departmentId: string, role: string) {
        return Api.post(`/api/v1/organizations/departments/${departmentId}/set-role`, {
            role,
        });
    },
    getDepartments(params: any) {
        return Api.get<null, TResponse>(`/api/v1/organizations/departments`, {
            params,
        }).then((res) => {
            return { ...res, params: params };
        });
    },
    createDepartment(data: any) {
        return Api.post('/api/v1/organizations/departments', data);
    },
    updateDepartment(departmentId: string, data: any) {
        return Api.patch(`/api/v1/organizations/departments/${departmentId}`, data);
    },
    deleteDepartment(departmentId: string) {
        return Api.delete(`/api/v1/organizations/departments/${departmentId}`);
    },
    getDepartment(departmentId: string) {
        return Api.get(`/api/v1/organizations/departments/${departmentId}`);
    },
};
