import { Components, Theme } from '@mui/material';

export const components: Components<Theme> = {
    MuiTab: {
        styleOverrides: {
            root: ({ theme }) => ({
                color: theme.palette.text.primary,
            }),
            selected: ({ theme }) => {
                return {
                    color: theme.palette.text.primary,
                };
            },
        },
    },
    MuiButton: {
        styleOverrides: {
            root: {
                textTransform: 'none',
                borderRadius: '12px',
                padding: '6px 16px',
                boxShadow: 'none',
            },
            sizeLarge: {
                padding: '8px 22px',
            },
            sizeMedium: {
                padding: '6px 16px',
            },
            sizeSmall: {
                padding: '4px 10px',
                borderRadius: '4px',
            },
        },
    },
    MuiButtonGroup: {
        styleOverrides: {
            root: {
                borderRadius: '12px',
            },
        },
    },
    MuiIconButton: {
        styleOverrides: {
            root: {
                borderRadius: '12px',
            },
        },
    },

    MuiMenuItem: {
        styleOverrides: {
            root: {
                borderRadius: '8px',
                padding: '14px 16px',
            },
        },
    },
    // MuiFormControl: {
    //     styleOverrides: {
    //         root: {
    //             'label + &': {
    //                 marginTop: '16px',
    //             },
    //         },
    //     },
    // },
    MuiOutlinedInput: {
        styleOverrides: {
            root: {
                borderRadius: '12px',
            },
        },
    },
    MuiCard: {
        styleOverrides: {
            root: ({ theme }) => ({
                boxShadow: 'none',
                border: `1px solid ${theme.palette.divider}`,
            }),
        },
    },
    MuiCardContent: {
        styleOverrides: {
            root: {
                padding: '20px 24px',
            },
        },
    },
    MuiAppBar: {
        styleOverrides: {
            root: {},
        },
    },
    MuiAvatar: {
        styleOverrides: {
            rounded: {
                borderRadius: '8px',
            },
        },
    },
    MuiPaper: {
        styleOverrides: {
            root: {
                borderRadius: '12px',
            },
        },
    },
    MuiChip: {
        styleOverrides: {
            root: {
                borderRadius: '4px',
            },
        },
    },
    MuiTableCell: {
        styleOverrides: {
            footer: {
                left: 0,
                bottom: 0, // <-- KEY
                zIndex: 2,
                position: 'sticky',
            },
        },
    },
};
