import React, { FC } from 'react';
import { Breadcrumbs as MuiBreadcrumbs, Link, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

type Route = {
    path?: string;
    name: string;
};

interface CustomBreadcrumbsProps {
    routes: Array<Route>;
}

const CustomBreadcrumbs: FC<CustomBreadcrumbsProps> = ({ routes }) => {
    return (
        <MuiBreadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            {routes.map(({ name, path }) => {
                if (path) {
                    return (
                        <Link key={path} underline="hover" color="text.secondary" href={path}>
                            {name}
                        </Link>
                    );
                }
                return (
                    <Typography key={path} color="text.primary">
                        {name}
                    </Typography>
                );
            })}
        </MuiBreadcrumbs>
    );
};

export default CustomBreadcrumbs;
