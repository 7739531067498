import { useContext } from 'react';
import { DuplicatesEnum, DuplicatesStateContext } from 'pages/Duplicates/Duplicates';
import { Grid, Typography } from '@mui/material';
import { Alert, Button } from 'shared/ui';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LinearProgress from '@mui/material/LinearProgress';
import { useTranslation } from 'react-i18next';

const SearchResults = () => {
    const { t: duplicatesT } = useTranslation('duplicates');
    const { state, setState } = useContext(DuplicatesStateContext);
    const searchResults = {
        duplicatesCount: 1,
        duplicatesFieldsCount: 0,
        conflictsCount: 0,
    };

    const handleStartMerge = () => setState?.(DuplicatesEnum.conflictsFound);
    const handleCancelMerge = () => setState?.(DuplicatesEnum.duplicatesFound);
    const handleRepeatSearch = () => setState?.(DuplicatesEnum.searchNotStarted);
    const handleStartManualMerge = () => setState?.(DuplicatesEnum.conflictsResolving);

    return (
        <Grid container flexDirection={'column'}>
            {(state === DuplicatesEnum.duplicatesFound ||
                state === DuplicatesEnum.conflictsFound ||
                state === DuplicatesEnum.duplicatesNotFound) && (
                <Grid container flexDirection={'column'} py={'12px'}>
                    <Typography variant={'subtitle2'}>{duplicatesT('findDuplicates')}</Typography>
                    <Typography variant={'body1'}>{searchResults.duplicatesCount}</Typography>
                </Grid>
            )}
            {state === DuplicatesEnum.duplicatesFound && (
                <>
                    <Grid container flexDirection={'column'} py={'12px'}>
                        <Typography variant={'subtitle2'}>{duplicatesT('matchDuplicates')}</Typography>
                        <Typography variant={'body1'}>{searchResults.duplicatesFieldsCount}</Typography>
                    </Grid>
                    <Grid container justifyContent={'flex-start'} py={'12px'}>
                        <Button size={'small'}>{duplicatesT('openMatchDuplicates')}</Button>
                    </Grid>
                </>
            )}
            {state === DuplicatesEnum.duplicatesNotFound ? (
                <>
                    <Grid py={'12px'}>
                        <Alert type={'success'}>{duplicatesT('nothingFoundDuplicates')}</Alert>
                    </Grid>
                    <Grid container justifyContent={'flex-start'} py={'12px'}>
                        <Button size={'large'} variant={'contained'}>
                            {duplicatesT('rescan')}
                        </Button>
                    </Grid>
                </>
            ) : state === DuplicatesEnum.duplicatesFound ? (
                <>
                    <Grid container gap={'13px'} flexWrap={'nowrap'} py={'12px'}>
                        <InfoOutlinedIcon fontSize={'small'} />
                        <Typography variant={'body2'}>{duplicatesT('conflictDuplicateText')}</Typography>
                    </Grid>
                    <Grid container justifyContent={'flex-start'} py={'12px'}>
                        <Button onClick={handleStartMerge} size={'large'} variant={'contained'}>
                            {duplicatesT('mergeAuto')}
                        </Button>
                    </Grid>
                </>
            ) : state === DuplicatesEnum.mergeStarted ? (
                <>
                    <Grid container flexDirection={'column'}>
                        <Grid container justifyContent={'space-between'}>
                            <Typography variant={'body1'}>{50}%</Typography>
                            <Typography variant={'body1'}>{duplicatesT('scanLoading')}</Typography>
                        </Grid>
                        <LinearProgress variant={'determinate'} value={50} />
                    </Grid>
                    <Grid container gap={'13px'} flexWrap={'nowrap'} py={'12px'}>
                        <InfoOutlinedIcon fontSize={'small'} />
                        <Typography variant={'body2'}>{duplicatesT('noConflictDuplicateText')}</Typography>
                    </Grid>
                    <Grid container justifyContent={'flex-start'} py={'12px'}>
                        <Button onClick={handleCancelMerge} size={'large'} variant={'contained'}>
                            {duplicatesT('stopMergeAuto')}
                        </Button>
                    </Grid>
                </>
            ) : (
                <>
                    <Grid container flexDirection={'column'} py={'12px'}>
                        <Typography variant={'subtitle2'}> {duplicatesT('attentionDuplicates')}</Typography>
                        <Typography variant={'body1'}>{searchResults.conflictsCount}</Typography>
                    </Grid>
                    <Grid container justifyContent={'flex-start'} py={'12px'}>
                        <Button size={'small'}> {duplicatesT('openConflictList')}</Button>
                    </Grid>
                    <Grid py={'12px'}>
                        <Alert type={'warning'}>{duplicatesT('attentionDuplicatesText')}</Alert>
                    </Grid>
                    <Grid container justifyContent={'flex-start'} py={'12px'} gap={'12px'}>
                        <Button onClick={handleStartManualMerge} size={'large'} variant={'contained'}>
                            {duplicatesT('mergeManually')}
                        </Button>
                        <Button onClick={handleRepeatSearch} variant={'outlined'}>
                            {duplicatesT('rescan')}
                        </Button>
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default SearchResults;
