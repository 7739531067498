import React from 'react';
import { CheckboxProps as MuiCheckboxProps, FormControlLabel } from '@mui/material';
import MuiCheckBox from '@mui/material/Checkbox';

export type CheckboxProps = MuiCheckboxProps & {
    label?: string;
};

const CheckBox = ({ label, ...props }: CheckboxProps) => {
    return <FormControlLabel control={<MuiCheckBox {...props} />} label={label} sx={{ m: 0 }} />;
};

export default CheckBox;
