import { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext } from 'react';
interface IChatContextProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    children?: ReactNode;
}

const ChatContext = createContext<IChatContextProps>({
    open: false,
    setOpen: () => {},
});

export const ChatProvider: FC<IChatContextProps> = ({ children, open, setOpen }) => {
    return <ChatContext.Provider value={{ open, setOpen }}>{children}</ChatContext.Provider>;
};

export const useChatToggle = () => {
    return useContext(ChatContext);
};
