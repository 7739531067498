import { Dispatch, memo, SetStateAction, useEffect } from 'react';
import {
    AppBar as MuiAppBar,
    Box,
    Divider,
    Grid,
    IconButton,
    Toolbar,
    Typography,
    useTheme,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
// import { Logo } from 'shared/ui';
import ChatBar from '../../../ChatBar';
import { NotificationBar } from 'widgets/NotificationBar';
import { AppBarProfile } from '../AppBarProfile/AppBarProfile';
import { useUsersStorage } from '../../../../services/StorageAdapter';

type AppBarProps = {
    setSidebarOpen: Dispatch<SetStateAction<boolean>>;
};

const AppBar = memo(({ setSidebarOpen }: AppBarProps) => {
    const {
        palette: { divider, navigation },
    } = useTheme();
    const { fetchMe } = useUsersStorage();

    useEffect(() => {
        fetchMe();
    }, []);
    return (
        <MuiAppBar
            position={'fixed'}
            sx={{
                boxShadow: 'none',
                borderBottom: `1px solid ${divider}`,
                borderRadius: 0,
                background: navigation,
            }}
        >
            <Toolbar sx={{ justifyContent: 'space-between', flexWrap: 'nowrap' }}>
                <Grid
                    container
                    gap={'31px'}
                    width={'auto'}
                    alignItems={'center'}
                    flexWrap="nowrap"
                    sx={{ width: '100%' }}
                >
                    <IconButton onClick={() => setSidebarOpen((prev) => !prev)}>
                        <MenuIcon />
                    </IconButton>
                    <Typography color={'#000000de'}>
                        <svg
                            width="34"
                            height="22"
                            viewBox="0 0 34 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M20.0744 7.34995L21.2581 5.30867L29.3885 19.3466H23.9468L12.7419 0L0 22H19.2531L14.1184 13.1356L12.5813 15.789L14.6416 19.3466H4.61153L12.7419 5.30867L22.4096 22H34L21.2581 0L18.5372 4.69649L20.0744 7.34995Z"
                                fill="url(#paint0_linear_284_26061)"
                            />
                            <defs>
                                <linearGradient
                                    id="paint0_linear_284_26061"
                                    x1="5.33995"
                                    y1="27.5294"
                                    x2="29.1276"
                                    y2="5.58358"
                                    gradientUnits="userSpaceOnUse"
                                >
                                    <stop stopColor="#95BE1F" />
                                    <stop offset="0.5" stopColor="#3DA938" />
                                    <stop offset="1" stopColor="#0E6835" />
                                </linearGradient>
                            </defs>
                        </svg>
                    </Typography>
                    {/*<Logo />*/}
                    {/*<GlobalSearch />*/}
                </Grid>
                {/* <Box sx={{ flexGrow: 1 }} /> */}
                <Grid container gap={'24px'} width={'auto'} flexWrap="nowrap">
                    <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        sx={{
                            display: { xs: 'none', md: 'block' },
                        }}
                    />
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <ChatBar />
                        <NotificationBar />
                        {/*<IconButton>*/}
                        {/*    <ListAltIcon />*/}
                        {/*</IconButton>*/}
                    </Box>
                    <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        sx={{
                            display: { xs: 'none', md: 'block' },
                        }}
                    />
                    <AppBarProfile />
                </Grid>
            </Toolbar>
        </MuiAppBar>
    );
});

export default AppBar;
