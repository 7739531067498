import { useEffect, useState } from 'react';
import { Grid, IconButton, Skeleton, Typography } from '@mui/material';
import { useFieldsStorage } from 'services/StorageAdapter';
import { Field, TFieldsMeta } from 'domain/field';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterFieldSwitch from './FilterFieldSwitch';
import { taskFields } from 'widgets/Filter/lib/const/taskFields';

const FilterField = ({
    value,
    onValue,
    onRemove,
}: {
    value: { [index: string]: any };
    onValue: (value: { [index: string]: any }) => void;
    onRemove: () => void;
}) => {
    const { getField } = useFieldsStorage();
    const [field, setField] = useState<TFieldsMeta | Field>();
    const [id] = Object.keys(value)?.[0]?.split('__');

    useEffect(() => {
        if (taskFields.find((field) => field.id === id)) {
            setField(taskFields.find((field) => field.id === id));
        } else {
            getField(id).then((res) => setField(res));
        }
    }, []);

    return field?.id ? (
        <>
            <Grid item container gap={1}>
                <Grid item xs={12} display="flex" alignItems="center">
                    <Typography variant={'body1'} color="#00000099">
                        {field?.name || <Skeleton variant="rounded" width={200} height={25} />}
                    </Typography>
                </Grid>
                <Grid item container xs={12} spacing={2} alignItems="start">
                    <Grid item container xs={11} spacing={2} alignItems="center">
                        <FilterFieldSwitch
                            value={value}
                            field={field as TFieldsMeta}
                            onValue={(value) => onValue(value)}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton color="error" onClick={onRemove}>
                            <DeleteIcon fontSize={'inherit'} color="error" />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
        </>
    ) : (
        <></>
    );
};

export default FilterField;
