import { Grid } from '@mui/material';
import { FC } from 'react';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/src/ReactCrop.scss';

interface ICropImage {
    minWidth?: number;
    minHeight?: number;
    classes?: string;
    imageSrc: any;
    crop: Crop;
    setCrop: any;
    imgRef: any;
    onSelectFile: any;
    onLoad: any;
}

const CropImage: FC<ICropImage> = ({ imageSrc, crop, setCrop, imgRef, onLoad }) => {
    return (
        <>
            {imageSrc && (
                <Grid container justifyContent="center" overflow={'hidden'}>
                    <ReactCrop
                        style={{ maxHeight: '600px' }}
                        crop={crop}
                        onChange={(c) => setCrop(c)}
                        aspect={1}
                    >
                        <img style={{ width: '100%' }} ref={imgRef} src={imageSrc} onLoad={onLoad} />
                    </ReactCrop>
                </Grid>
            )}
        </>
    );
};

export default CropImage;
