import { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useState } from 'react';
interface ISelectedChatReadOnlyProps {
    children?: ReactNode;
}
interface ISelectedChatReadOnlyContext {
    isReadOnly: boolean;
    setIsReadOnly: Dispatch<SetStateAction<boolean>>;
}

const SelectedChatReadOnlyContext = createContext<ISelectedChatReadOnlyContext>({
    isReadOnly: false,
    setIsReadOnly: () => {},
});

export const SelectedChatReadOnlyProvider: FC<ISelectedChatReadOnlyProps> = ({ children }) => {
    const [isReadOnly, setIsReadOnly] = useState(false);
    return (
        <SelectedChatReadOnlyContext.Provider value={{ isReadOnly, setIsReadOnly }}>
            {children}
        </SelectedChatReadOnlyContext.Provider>
    );
};

export const useSelectedIsReadOnly = () => {
    const { isReadOnly, setIsReadOnly } = useContext(SelectedChatReadOnlyContext);
    const handleIsReadOnly = (isReadOnly: boolean) => setIsReadOnly(isReadOnly);

    return {
        isReadOnly,
        handleIsReadOnly,
    };
};
