import { ReactNode, MouseEvent } from 'react';
import { Box, FormControl, FormHelperText, Grid, Tooltip, Typography, useTheme } from '@mui/material';
import FormFieldLabel from './FormFieldLabel';
import { Button } from 'shared/ui/index';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import { motion } from 'framer-motion';

export type FormFieldProps = {
    children?: ReactNode;
    label?: string;
    fullWidth?: boolean;
    helperText?: string;
    additionalBtnText?: string;
    required?: boolean;
    hint?: string;
    error?: boolean;
    additionalBtnHandler?: (e: MouseEvent<HTMLButtonElement>) => void;
};

const FormField = ({
    children,
    label,
    helperText,
    error,
    additionalBtnText,
    additionalBtnHandler,
    required,
    hint,
}: FormFieldProps) => {
    const {
        palette: {
            error: { main },
        },
    } = useTheme();

    return (
        <FormControl fullWidth variant={'standard'}>
            <Grid container alignItems={'flex-start'} gap={'12px'} flexDirection={'column'}>
                <Grid container item gap={'8px'} width={'100%'}>
                    <Grid
                        container
                        item
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        height={'30px'}
                    >
                        <Grid container item width={'auto'}>
                            {label && <FormFieldLabel>{label}</FormFieldLabel>}
                            {required && (
                                <Box ml={'4px'}>
                                    <Typography variant={'subtitle2'} color={main}>
                                        *
                                    </Typography>
                                </Box>
                            )}
                            {hint && (
                                <Box ml={'8px'}>
                                    <motion.div>
                                        <Tooltip title={hint} arrow placement="top">
                                            <HelpOutlineRoundedIcon color={'primary'} fontSize={'small'} />
                                        </Tooltip>
                                    </motion.div>
                                </Box>
                            )}
                        </Grid>
                        <Grid>
                            {additionalBtnText && (
                                <Button size={'small'} variant={'text'} onClick={additionalBtnHandler}>
                                    {additionalBtnText}
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                    <Grid container item alignItems={'center'} flexWrap={'nowrap'} gap={'7px'}>
                        <Grid container flexGrow={1} flexDirection={'column'} alignItems={'flex-start'}>
                            {children}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    {helperText && <FormHelperText error={!!error}>{helperText}</FormHelperText>}
                </Grid>
            </Grid>
        </FormControl>
    );
};

export default FormField;
