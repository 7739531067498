const config: {
    basename: string;
    base_url: string | undefined;
    base_chat_url: string | undefined;
    ws_base_url: string | undefined;
    is_dev: boolean;
} = {
    basename: '',
    base_url: process.env.REACT_APP_BASE_URL,
    base_chat_url: process.env.REACT_APP_BASE_CHAT_URL,
    ws_base_url: process.env.REACT_APP_WS_URL,
    is_dev: process.env.REACT_APP_BASE_URL === 'https://dev-crm.ndfs.kz/',
};

export default config;
