import { Navigate, Route, Routes } from 'react-router-dom';
import { authRoutes, protectedRoutes, routes } from './index';
import { usersStores } from 'services/store/users/users';
import { useStore } from 'effector-react';
import { useAuthStorage } from 'services/StorageAdapter';
import { lazy, Suspense } from 'react';
import { Layout } from 'widgets';
import { Loader } from '../ui';

const NotFoundLazy = lazy(async () => await import('pages/NotFound/ui/NotFound'));

const { $userStore } = usersStores;

function Root() {
    const { isAuthenticated } = useAuthStorage();
    const user = useStore($userStore);

    return isAuthenticated ? (
        <Layout>
            <Suspense fallback={<Loader text={'Загрузка ...'} />}>
                <Routes>
                    {routes.map(({ path, component: Component }) => {
                        if (protectedRoutes.includes(path)) {
                            if (user) {
                                return <Route key={path} path={path} element={<Component />} />;
                            }
                        } else {
                            return <Route key={path} path={path} element={<Component />} />;
                        }
                    })}

                    {authRoutes.map(({ path }) => {
                        return (
                            <Route
                                key={path}
                                path={path || '/'}
                                element={<Navigate to={'/entity/tasks'} />}
                            />
                        );
                    })}
                    <Route path={'/'} element={<Navigate to={'/entity/tasks'} />} />
                    <Route path="*" element={<NotFoundLazy />} />
                </Routes>
            </Suspense>
        </Layout>
    ) : (
        <Suspense fallback={<Loader text={'Загрузка ...'} />}>
            <Routes>
                {authRoutes.map(({ path, component: Component }) => {
                    return <Route key={path} path={path} element={<Component />} />;
                })}
                <Route path="*" element={<Navigate to={'/login'} replace={true} />} />
            </Routes>
        </Suspense>
    );
}

export default Root;
