import { IconButton, styled } from '@mui/material';
import { OutlineIconButtonProps } from './ChatBarButton.types';

const OutlineIconButton = styled(IconButton, {
    shouldForwardProp: (prop) => prop !== 'open',
})<OutlineIconButtonProps>(({ open }) => ({
    ...(open && {
        backgroundColor: 'rgba(88, 70, 250, 0.3)',
    }),
}));

export { OutlineIconButton };
