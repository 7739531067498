import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { styled, useMediaQuery, useTheme } from '@mui/material';
import { drawerWidth } from '../Sidebar/lib/const/consts';
import AppBar from '../AppBar/ui/AppBar/AppBar';
import { Sidebar } from '../Sidebar';
import { MOBILE_QUERY_WIDTH } from 'shared/consts';
import { ColorModeContext } from 'App';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
    isMobile?: boolean;
}>(({ theme, open, isMobile }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingTop: '93px',
    ...(!isMobile && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    }),
    marginLeft: `0`,
    ...(open &&
        !isMobile && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: `${drawerWidth}px`,
        }),
}));

type LayoutProps = {
    children: ReactNode;
};

type LayoutContextType = {
    isSidebarOpen: boolean;
};

export const LayoutContext = createContext<LayoutContextType>({
    isSidebarOpen: true,
});

const Layout = ({ children }: LayoutProps) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const mobileQuery = useMediaQuery(MOBILE_QUERY_WIDTH);
    const {
        palette: {
            background: { paper },
        },
    } = useTheme();
    const { currentMode } = useContext(ColorModeContext);
    const layoutContext = useMemo(
        () => ({
            isSidebarOpen,
        }),
        [isSidebarOpen]
    );

    useEffect(() => {
        setIsSidebarOpen(
            !mobileQuery
                ? localStorage.getItem('sidebar')
                    ? Boolean(localStorage.getItem('sidebar'))
                    : true
                : false
        );
    }, []);

    useEffect(() => {
        localStorage.setItem('sidebar', `${isSidebarOpen}`);
    }, [isSidebarOpen]);

    return (
        <LayoutContext.Provider value={layoutContext}>
            <AppBar setSidebarOpen={setIsSidebarOpen} />
            <Sidebar
                open={isSidebarOpen}
                setOpen={setIsSidebarOpen}
                setSidebarOpen={mobileQuery ? setIsSidebarOpen : () => {}}
            />
            <Main
                sx={{
                    backgroundColor: currentMode === 'light' ? '#FCFBFC' : paper,
                }}
                open={isSidebarOpen}
                isMobile={mobileQuery}
            >
                {/*{!mobileQuery && <Breadcrumbs />}*/}
                {children}
            </Main>
        </LayoutContext.Provider>
    );
};

export default Layout;
