import Api from 'shared/api/axios';
import { TMeta } from 'domain/meta';
import { TResponse } from 'shared/api/types';

export const metaService = {
    getMeta(code: string, { signal, ...params }: { signal?: any; params?: any }) {
        return Api.get<null, TMeta>(`/api/v1/core/entities/meta/${code}`, {
            params: { ...params },
            signal,
        });
    },
    createMeta(code: string, name: string, no_funnel_view: boolean, can_have_tasks?: boolean) {
        return Api.post<null, TMeta>(`/api/v1/core/entities/meta`, {
            code,
            name,
            no_funnel_view,
            can_have_tasks,
        });
    },
    updateMeta(code: string, name: string, no_funnel_view: boolean) {
        return Api.put<null, TMeta>(`/api/v1/core/entities/meta/${code}`, {
            name,
            no_funnel_view,
        });
    },
    getMetas(params: any) {
        return Api.get<null, TResponse>(`/api/v1/core/entities/meta`, {
            params: { ...params },
        }).then((res) => {
            return { ...res, params: params };
        });
    },
    deleteMeta(code: string) {
        return Api.delete<null, TMeta>(`/api/v1/core/entities/meta/${code}/soft-delete`);
    },
};
