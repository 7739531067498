import { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useState } from 'react';
interface ISelectedChatProps {
    children?: ReactNode;
}
interface ISelectedChatContext {
    selectedChat: string;
    setSelectedChat: Dispatch<SetStateAction<string>>;
}

const SelectedChatContext = createContext<ISelectedChatContext>({
    selectedChat: '',
    setSelectedChat: () => {},
});

export const SelectedChatProvider: FC<ISelectedChatProps> = ({ children }) => {
    const [selectedChat, setSelectedChat] = useState('');
    return (
        <SelectedChatContext.Provider value={{ selectedChat, setSelectedChat }}>
            {children}
        </SelectedChatContext.Provider>
    );
};

export const useSelectedChat = () => {
    const { selectedChat, setSelectedChat } = useContext(SelectedChatContext);
    const onSelectedChat = (chat: string) => setSelectedChat(chat);
    const onClearChat = () => setSelectedChat('');

    return {
        selectedChat,
        onSelectedChat,
        onClearChat,
    };
};
