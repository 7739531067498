import { Button, Card } from 'shared/ui';
import {
    Avatar,
    Grid,
    IconButton,
    TextareaAutosize,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ChangeEvent, useState } from 'react';
import { useNotification, useUsersStorage } from 'services/StorageAdapter';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { FileType } from 'widgets/DraggableField/Fields/FileField/FileField';
import { MOBILE_QUERY_WIDTH } from 'shared/consts';
import { useTimelineStorage } from 'widgets/Timeline/lib/hooks/useTimelineStorage';
import { TimelineCommentFormLoading } from '../TimelineCommentFormLoading/TimelineCommentFormLoading';
import { TimelineCommentFormUpload } from '../TimelineCommentFormUpload/TimelineCommentFormUpload';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface TimelineCommentFormProps {
    onTaskOpen: () => void;
    objectId: string;
}

export const TimelineCommentForm = ({ onTaskOpen, objectId }: TimelineCommentFormProps) => {
    const { t } = useTranslation();
    const [params] = useSearchParams();
    const objectType = params?.get('task_id') ? 'task' : '';
    const { me } = useUsersStorage();
    const { notify } = useNotification();
    const { createComment, sendCommentLoading, timeline, getTimelines } = useTimelineStorage();
    const [edit, setEdit] = useState<boolean>(false);
    const [comment, setComment] = useState<string>('');
    const [commentFiles, setCommentFiles] = useState<FileType[]>([]);
    const {
        palette: {
            text: { primary },
        },
    } = useTheme();
    const mobileQuery = useMediaQuery(MOBILE_QUERY_WIDTH);

    const handleDeleteFile = (removeId: string) => {
        setCommentFiles(commentFiles.filter(({ id }) => id !== removeId));
    };

    const handleClose = () => {
        setEdit(!edit);
        setComment('');
        setCommentFiles([]);
    };

    const handleSend = () => {
        const filesIds: string[] = commentFiles.map(({ id }) => id);
        createComment({
            entity_uuid: objectId,
            comment: { comment: comment, files: filesIds },
            type: objectType,
        })
            .then(() => {
                notify({ message: `Комментарий добавлен` });
                getTimelines({
                    entity_uuid: objectId,
                    params: { ...timeline.params, offset: 0 },
                    type: objectType,
                });
                handleClose();
            })
            .catch((err) => {
                const error: string[] = Object.values(err.response.data);
                error.length
                    ? error.map((e: string) => notify({ message: e }))
                    : notify({ message: t('somethingWentWrong') });
            });
    };

    return (
        <>
            <Grid py={'16px'} position="relative">
                {sendCommentLoading && <TimelineCommentFormLoading />}
                <Card>
                    <Grid container spacing={3} pb={1} alignItems={edit ? 'start' : 'center'}>
                        <Grid item xs={mobileQuery ? 2 : 1}>
                            <IconButton sx={{ p: 0 }}>
                                <Avatar
                                    alt="Remy Sharp"
                                    src={me.avatar || '/static/images/avatar/2.jpg'}
                                    variant={'rounded'}
                                    sx={{ width: 32, height: 32 }}
                                />
                            </IconButton>
                        </Grid>
                        <Grid item xs={mobileQuery ? 8 : 10}>
                            {!edit ? (
                                <Typography
                                    variant={'body1'}
                                    width={'100%'}
                                    whiteSpace={'nowrap'}
                                    onClick={() => setEdit(!edit)}
                                >
                                    Оставить комментарий...
                                </Typography>
                            ) : (
                                <TextareaAutosize
                                    placeholder={'Оставить комментарий...'}
                                    style={{
                                        background: 'transparent',
                                        color: primary,
                                        border: 0,
                                        width: '100%',
                                        resize: 'none',
                                        outline: 'none',
                                        fontFamily: 'Inter',
                                        fontWeight: 500,
                                        fontSize: '0.875rem',
                                        lineHeight: 1.57,
                                    }}
                                    minRows={3}
                                    value={comment}
                                    onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                                        setComment(event.target.value)
                                    }
                                    autoFocus
                                />
                            )}
                        </Grid>
                        <Grid item xs={1} display="flex" justifyContent="end">
                            {edit && (
                                <IconButton sx={{ p: 0 }} onClick={handleClose}>
                                    <CloseRoundedIcon color="disabled" />
                                </IconButton>
                            )}
                        </Grid>
                    </Grid>
                    <TimelineCommentFormUpload
                        edit={edit}
                        files={commentFiles}
                        onDeleteFile={handleDeleteFile}
                        setFile={(file: FileType) => setCommentFiles([...commentFiles, file])}
                    />
                    <Card.Footer
                        leftSide={
                            !edit && !objectType ? (
                                <Button
                                    endIcon={<ArrowDropDownIcon />}
                                    sx={{
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                    }}
                                    onClick={onTaskOpen}
                                >
                                    {t('createTask')}
                                </Button>
                            ) : (
                                <></>
                            )
                        }
                        rightSide={
                            edit ? (
                                <Grid container gap={2}>
                                    <Button
                                        onClick={handleSend}
                                        variant="contained"
                                        color="primary"
                                        disabled={!(comment.length || commentFiles.length)}
                                    >
                                        {t('submit')}
                                    </Button>
                                    <Button onClick={handleClose} variant="outlined">
                                        {t('cancel')}
                                    </Button>
                                </Grid>
                            ) : (
                                <></>
                            )
                        }
                    />
                </Card>
            </Grid>
        </>
    );
};
