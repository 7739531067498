import React, { FC, memo } from 'react';
import { Box, useTheme } from '@mui/material';
import { Button } from '../../../../shared/ui';
import AddCircleRounded from '@mui/icons-material/AddCircleRounded';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import { useMenu } from '../../../../shared/hooks/useMenu';
import { SidebarAddedNewItemMenu } from '../SidebarAddedNewItemMenu/SidebarAddedNewItemMenu';
import { useTranslation } from 'react-i18next';

interface SidebarEditFooterProps {
    handleEdit: () => void;
}
export const SidebarEditFooter: FC<SidebarEditFooterProps> = memo(({ handleEdit }) => {
    const { t } = useTranslation();
    const { open, handleClose, handleClick, anchorEl } = useMenu();
    const {
        palette: {
            background: { paper },
        },
    } = useTheme();

    return (
        <Box
            position={'sticky'}
            bottom={62}
            left={0}
            height={75}
            width={'calc(100% + 32px)'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            px={1}
            boxShadow={
                '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)'
            }
            bgcolor={paper}
            ml={-2}
            mr={-2}
            zIndex={10}
        >
            <Button
                size={'small'}
                color={'secondary'}
                variant={'text'}
                startIcon={<AddCircleRounded />}
                onClick={handleClick}
            >
                {t('itemLink')}
            </Button>
            <Button
                size={'small'}
                color={'primary'}
                variant={'contained'}
                endIcon={<ArrowDropDownRoundedIcon />}
                onClick={handleEdit}
            >
                {t('save')}
            </Button>
            <SidebarAddedNewItemMenu anchorEl={anchorEl} open={open} handleClose={handleClose} />
        </Box>
    );
});
