import { Replace, TResponse } from '../../../shared/api/types';
export interface IAuthor {
    id: string;
    fullname: string;
    avatar: string;
}

export interface IEntityObject {
    id: string;
    entity: string;
    entity_title: string;
    title: string;
}

export interface ITask {
    id: string;
    title: string;
}

export enum EEvent {
    Object_Created = 'object_created',
    Object_Stage_Changed = 'object_stage_changed',
    Object_Funnel_Changed = 'object_funnel_changed',
    Object_Deleted = 'object_deleted',
    Object_Restored = 'object_restored',
    Comment_Created = 'comment_created',
    Responsible_Changed = 'responsible_changed',
    Added_To_Observers = 'added_to_observers',
    Deleted_From_Observers = 'deleted_from_observers',
    Added_To_Coexecutors = 'added_to_coexecutors',
    Deleted_From_Coexecutors = 'deleted_from_coexecutors',
    Task_Modified = 'task_modified',
}

export enum System {
    System = 'system',
    User = 'user',
}

export interface IEventHistory {
    before: {
        funnel_stage: string;
        name: string;
    };
    after: {
        funnel_stage: string;
        name: string;
    };
}

export interface INotification {
    id: string;
    author: IAuthor | null;
    entity_object: IEntityObject | null;
    task: ITask | null;
    created: string;
    modified: string;
    event: EEvent | null;
    type: System | null;
    is_read: boolean;
    event_history: IEventHistory | null;
}

export type TNotificationsReplaceResponse = Replace<TResponse, 'results', INotification[]>;
export interface TNotificationsResponse extends TNotificationsReplaceResponse {
    count_of_unread: number;
}

export interface IGetNotificationsReceiversParams {
    created_after?: string;
    created_before?: string;
    is_read?: boolean;
    limit?: number;
    offset?: number;
    receiver: string;
}

export interface IGetNotificationsReceiverParams {
    id: string;
    receiver: string;
}

export interface IUpdateNotificationsReceiverParams extends IGetNotificationsReceiverParams {
    is_read: boolean;
}

export interface IUpdateNotificationsReceiverResponse {
    id: string;
    is_read: boolean;
}

export interface IBulkUpdateNotificationsReceiverParams {
    receiver: string;
    is_read: boolean;
}

export interface INotificationService {
    getNotificationsReceivers: (params: IGetNotificationsReceiversParams) => Promise<TNotificationsResponse>;
    getNotificationsReceiver: (params: IGetNotificationsReceiverParams) => Promise<INotification>;
    updateNotificationReceiver: (
        params: IUpdateNotificationsReceiverParams
    ) => Promise<IUpdateNotificationsReceiverResponse>;
    deleteNotificationReceiver: (params: IGetNotificationsReceiverParams) => Promise<null>;
    bulkUpdateNotificationReceiver: (
        params: IBulkUpdateNotificationsReceiverParams
    ) => Promise<IUpdateNotificationsReceiverResponse>;
    getNotificationsReceiversAllRead: (receiver: string) => Promise<null>;
}

export interface INotificationStorage {
    notifications: TNotificationsResponse;
    notification: INotification;
    lastNotifications: TNotificationsResponse;
    allReadLoading: boolean;
    loading: boolean;
    getNotificationsReceivers: (params: IGetNotificationsReceiversParams) => Promise<TNotificationsResponse>;
    getNotificationsReceiver: (params: IGetNotificationsReceiverParams) => Promise<INotification>;
    updateNotificationReceiver: (
        params: IUpdateNotificationsReceiverParams
    ) => Promise<IUpdateNotificationsReceiverResponse>;
    updateLastNotificationReceiver: (
        params: IUpdateNotificationsReceiverParams
    ) => Promise<IUpdateNotificationsReceiverResponse>;
    deleteNotificationReceiver: (params: IGetNotificationsReceiverParams) => Promise<null>;
    bulkUpdateNotificationReceiver: (
        params: IBulkUpdateNotificationsReceiverParams
    ) => Promise<IUpdateNotificationsReceiverResponse>;
    getNotificationsReceiversAllRead: (receiver: string) => Promise<null>;
    getLastNotificationsReceivers: (
        params: IGetNotificationsReceiversParams
    ) => Promise<TNotificationsResponse>;
    clearNotifications: () => void;
    clearNotification: () => void;
    clearLastNotifications: () => void;
    handleAllRead: () => void;
    signalNotification: (data: INotification) => void;
}
